import React from "react";
import { Link } from "react-router-dom";
import Button from "components/Button";

const ManagerPropiedades = () => {
  return (
    <div className="mx-auto pb-24" style={{ maxWidth: "1024px" }}>
      <div className="flex flex-col-reverse md:flex-row justify-between pb-10">
        <div className="flex flex-col">
          <div className="flex flex-col">
            <div id="tracking" className="pb-4">
              <h3 className="text-gris-oscuro">
                Panel de Administrador / Propiedades
              </h3>
            </div>
            <div id="title" className="pb-2">
              <h1 className="text-sport-blue font-bold text-5xl">
                Gestionar Propiedades
              </h1>
            </div>
          </div>
        </div>
        <div className="hidden lg:flex">
          <Link to="/panel">
            <Button className="w-full md:w-64 h-14 bg-green-field rounded-lg shadow-sm hover:bg-green-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight ">
              PANEL ADMIN.
            </Button>
          </Link>
        </div>
      </div>
      <div className="w-full md:w-80 flex flex-col md:flex-row md:justify-between">
        <div className="pb-5 md:pb-0">
          <Button className="w-full md:w-36 h-14 text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight ">
            NUEVO
          </Button>
        </div>
      </div>
      <div className="grid grid-flow-row pt-8 md:grid-cols-2 lg:grid-cols-3 gap-10">
        <Link to="/managerPalco">
          <Button className="w-full md:w-80 h-24 text-center font-bold text-2xl text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight">
            Gestionar Palcos
          </Button>
        </Link>
        <Link to="/">
          <Button className="w-full md:w-80 h-24 text-center font-bold text-2xl text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight">
            Gestionar Plateas
          </Button>
        </Link>
        <Link to="/">
          <Button className="w-full md:w-80 h-24 text-center font-bold text-2xl text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight">
            Gestionar Butacas
          </Button>
        </Link>
      </div>
      <div className="lg:hidden w-full pb-14 pt-14 md:pb-16 ">
        <Button className="w-full md:w-64 h-14 bg-green-field rounded-lg shadow-sm hover:bg-green-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight ">
          CERRAR SESIÓN
        </Button>
      </div>
    </div>
  );
};

export default ManagerPropiedades;
