import React from 'react';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, Link } from 'react-router-dom';
import configData from 'config.json';
import Loader from 'components/Loader';

const month = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sep',
  'Oct',
  'Nov',
  'Dic',
];

const Home = () => {
  const [data, setData] = useState({
    principalEvents: [],
    competitions: [],
    stadiums: [],
    events: [],
    teams: [],
  });
  const [filters, setFilters] = useState(null);
  const [notfilter, setNotfilter] = useState(false);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [principalEvents, setPrincipalEvents] = useState(0);
  const location = useLocation();
  const router = useHistory();
  const myRef = useRef(null);
  const [loader, setLoader] = useState(true);
  const { handleSubmit, register, control, formState, errors, watch } = useForm(
    {
      mode: 'all',
    }
  );

  const getData = async () => {
    try {
      const competitions = await axios
        .get(`${configData.SERVER_URL}/competition`)
        .then((res) => {
          return res.data.competition;
        });
      const stadiums = await axios
        .get(`${configData.SERVER_URL}/stadiums`)
        .then((res) => {
          return res.data.stadium;
        });
      const events = await axios
        .get(`${configData.SERVER_URL}/events/nextEvents`)
        .then((res) => {
          return res.data.events;
        });
      const teams = await axios
        .get(`${configData.SERVER_URL}/teams`)
        .then((res) => {
          return res.data.teams;
        });
      const nowDate = new Date().toISOString().slice(0, 10);
      const principalEvents = events.filter(
        (event) =>
          event.isImportant === true && event?.active && event.date >= nowDate
      );
      const activeEvents = events.filter((event) => event?.active === true);
      setData({
        principalEvents,
        competitions,
        stadiums,
        events: activeEvents,
        teams,
      });
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  data.events &&
    data.events.sort((a, b) =>
      a.date > b.date ? 1 : b.date > a.date ? -1 : 0
    );

  data.principalEvents &&
    data.principalEvents.sort((a, b) =>
      a.date > b.date ? 1 : b.date > a.date ? -1 : 0
    );

  const getCompetitionById = (id) => {
    if (data.competitions) {
      const result = data.competitions.find(
        (competition) => competition.id === id
      );
      return result;
    }
  };

  const getStadiumById = (id) => {
    if (data.stadiums) {
      return data.stadiums.find((stadium) => stadium.id === id);
    }
  };

  const getTeamById = (id) => {
    if (data.teams) {
      return data.teams.find((team) => team.id === id);
    }
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    setFilters(data);
    // const dataFormatted = Array.from(Object.entries(data)).map(
    //   ([key, value]) => [
    //     key,
    //     String(value?.value ?? value)
    //       .toLowerCase()
    //       .trim(),
    //   ],
    // );
    // router.push({
    //   pathname: '',
    //   search:
    //     '?' +
    //     new URLSearchParams(Object.fromEntries(dataFormatted)).toString(),
    // });
  };

  const Filter = (events, filters) => {
    const filterEventType = (event, event_type) =>
      event_type === '' || event_type === 'all'
        ? events
        : event_type === 'concierto'
        ? event?.type === event_type
        : event?.type === event_type;
    const filterStadium = (event, stadium_id) =>
      stadium_id === '' || stadium_id === 'all'
        ? events
        : event?.stadium_id === stadium_id;

    const eventsFiltered = events?.filter(
      (event) =>
        filterEventType(event, filters?.event_type) &&
        filterStadium(event, filters?.stadium)
    );
    if (eventsFiltered.length > 0) {
      setNotfilter(false);
      setFilteredEvents(eventsFiltered);
    } else {
      setNotfilter(true);
    }
  };

  useEffect(() => {
    // console.log(filters)
    filters && Filter(data.events, filters);
  }, [filters]);

  // useEffect(() => {
  //   const getData = () => {
  //     const params = new URLSearchParams(location.search);
  //     const urlParams = Object.fromEntries(params.entries());
  //     if (params.has('competition')) {
  //       Filter(data.events, urlParams);
  //     }
  //   };
  //   getData();
  // }, [location]);

  const getCard = (events) => {
    const nowDate = new Date().toISOString().slice(0, 10);
    const start = (currentPage - 1) * 10;
    const end = currentPage * 10;
    return events?.slice(start, end).map((event, idx) => {
      if (event.date >= nowDate) {
        const date = event?.date.split('-');
        return (
          <div className="af-class-cat-right-column">
            {event.type === 'partido' ? (
              <Link
                to={`/evento/${event.id}`}
                className="af-class-cat-eventcard w-inline-block"
              >
                <div className="af-class-cat-eventcard-available">
                  Lugares disponibles
                </div>
                <div className="af-class-cat-eventcard-data-wrapper">
                  <div className="af-class-cat-eventcard-title-wrapper">
                    <h3 className="af-class-cat-eventcard-title">
                      {getTeamById(event?.home)?.name}
                    </h3>
                    <div className="af-class-cat-gamecard-vs">VS</div>
                    <h3 className="af-class-cat-eventcard-title">
                      {getTeamById(event?.visitor)?.name}
                    </h3>
                  </div>
                  <div className="af-class-cat-eventcard-details-wrapper">
                    <div className="af-class-cat-eventcard-roundleague-wrapper">
                      <div className="af-class-cat-eventcard-round">{`Jornada ${event?.jornada}`}</div>
                      <div className="af-class-cat-eventcard-league">
                        {getCompetitionById(event?.competition_id)?.name}
                      </div>
                    </div>
                    <div className="af-class-cat-eventcard-stadium-wrapper">
                      <img
                        src="images/icon-stadium_deepBlue.svg"
                        loading="lazy"
                        alt=""
                        className="af-class-cat-eventcard-stadiumicon"
                      />
                      <div className="af-class-cat-eventcard-stadium">
                        {getStadiumById(event?.stadium_id).name}
                      </div>
                    </div>
                    <div className="af-class-cat-eventcard-datetime-wrapper">
                      <div className="af-class-cat-eventcard-data af-class-eventcard-databold">
                        {date[0]}
                      </div>
                      <div className="af-class-cat-eventcard-data af-class-eventcard-datamedium">
                        {date[1] === '0'
                          ? month[date[1].substr(-1)]
                          : month[date[1] - 1]}
                      </div>
                      <div className="af-class-cat-eventcard-data">
                        {date[2]}
                      </div>
                      <div className="af-class-cat-eventcard-data">···</div>
                      <div className="af-class-cat-eventcard-data af-class-eventcard-databold">
                        {event.time}
                      </div>
                      <div className="af-class-cat-eventcard-data">Hrs</div>
                    </div>
                  </div>
                </div>
                <div className="af-class-cat-eventcard-graphics-wrapper">
                  <img
                    src="images/triangleGraphicUp-white.svg"
                    loading="lazy"
                    alt=""
                    className="af-class-cat-eventcard-triangle"
                  />
                  <img
                    src="images/triangleGraphicDown-white.svg"
                    loading="lazy"
                    alt=""
                    className="af-class-cat-eventcard-trianglemob"
                  />
                  <div className="af-class-cat-eventcard-teamemblems-wrapper">
                    <img
                      src={`${getTeamById(event.home).images[0]}`}
                      loading="lazy"
                      sizes="(max-width: 767px) 71.328125px, 98.078125px"
                      srcSet={`${getTeamById(event.home).images[0]} 500w, ${
                        getTeamById(event.home).images[0]
                      } 535w`}
                      alt=""
                      className="af-class-cat-eventcard-teamemblem"
                    />
                    <div className="af-class-cat-gamecard-emblemsvs">Vs</div>
                    <img
                      src={`${getTeamById(event.visitor).images[0]}`}
                      loading="lazy"
                      alt=""
                      className="af-class-cat-eventcard-teamemblem"
                    />
                  </div>
                  <img
                    loading="lazy"
                    sizes="(max-width: 479px) 76vw, (max-width: 767px) 75vw, (max-width: 991px) 51vw, 33vw"
                    srcSet={`${
                      getStadiumById(event.stadium_id).images[0]
                    } 500w, ${
                      getStadiumById(event.stadium_id).images[0]
                    } 500w, ${
                      getStadiumById(event.stadium_id).images[0]
                    } 800w, ${
                      getStadiumById(event.stadium_id).images[0]
                    } 1080w, ${
                      getStadiumById(event.stadium_id).images[0]
                    } 1600w, ${
                      getStadiumById(event.stadium_id).images[0]
                    } 2000w, ${
                      getStadiumById(event.stadium_id).images[0]
                    } 2048w`}
                    alt=""
                    className="af-class-cat-eventcard-stadiumbg"
                  />
                </div>
              </Link>
            ) : (
              <Link
                to={`/evento/${event.id}`}
                className="af-class-cat-eventcard w-inline-block"
              >
                <div className="af-class-cat-eventcard-available">
                  Lugares disponibles
                </div>
                <div className="af-class-cat-eventcard-data-wrapper">
                  <div className="af-class-cat-eventcard-title-wrapper">
                    <h3 className="af-class-cat-eventcard-title">
                      {event.name}
                    </h3>
                  </div>
                  <div className="af-class-cat-eventcard-details-wrapper">
                    <div
                      className="af-class-cat-eventcard-tour"
                      style={{ backgroundColor: event.event_color }}
                    >
                      {event.tour_name}
                    </div>
                    <div className="af-class-cat-eventcard-stadium-wrapper">
                      <img
                        src="images/icon-stadium_deepBlue.svg"
                        loading="lazy"
                        alt=""
                        className="af-class-cat-eventcard-stadiumicon"
                      />
                      <div className="af-class-cat-eventcard-stadium">
                        {getStadiumById(event.stadium_id).name}
                      </div>
                    </div>
                    <div className="af-class-cat-eventcard-datetime-wrapper">
                      <div className="af-class-cat-eventcard-data af-class-eventcard-databold">
                        {date[0]}
                      </div>
                      <div className="af-class-cat-eventcard-data af-class-eventcard-datamedium">
                        {date[1] === '0'
                          ? month[date[1].substr(-1)]
                          : month[date[1] - 1]}
                      </div>
                      <div className="af-class-cat-eventcard-data">
                        {date[2]}
                      </div>
                      <div className="af-class-cat-eventcard-data">···</div>
                      <div className="af-class-cat-eventcard-data af-class-eventcard-databold">
                        {event.time}
                      </div>
                      <div className="af-class-cat-eventcard-data">Hrs</div>
                    </div>
                  </div>
                </div>
                <div className="af-class-cat-eventcard-graphics-wrapper">
                  <img
                    src="images/triangleGraphicUp-white.svg"
                    loading="lazy"
                    alt=""
                    className="af-class-cat-eventcard-triangle"
                  />
                  <img
                    src="images/triangleGraphicDown-white.svg"
                    loading="lazy"
                    alt=""
                    className="af-class-cat-eventcard-trianglemob"
                  />
                  <img
                    src={`${event.images[0]}`}
                    loading="lazy"
                    alt=""
                    className="af-class-cat-eventcard-eventlogo"
                  />
                  <img
                    src={`${event.images[1]}`}
                    loading="lazy"
                    sizes="(max-width: 479px) 76vw, (max-width: 767px) 75vw, (max-width: 991px) 51vw, 33vw"
                    srcSet={`${event.images[1]} 500w, ${event.images[1]} 800w, ${event.images[1]} 1280w`}
                    alt=""
                    className="af-class-cat-eventcard-eventbg"
                    style={{ backgroundColor: event.event_color }}
                  />
                  <div
                    className="af-class-cat-eventcard-gradient"
                    style={{
                      background: `linear-gradient(0deg, ${event.event_color} 0%, transparent 100%)`,
                    }}
                  />
                </div>
              </Link>
            )}
            {data.events.length - 1 !== idx && (
              <div className="af-class-cat-contentdivider" />
            )}
          </div>
        );
      }
    });
  };

  const cardImportantsEvents = (events) => {
    const nowDate = new Date().toISOString().slice(0, 10);
    return events?.slice(principalEvents, principalEvents + 1).map((event) => {
      const date = event?.date.split('-');
      if (event.isImportant === true) {
        return event.type === 'partido' ? (
          <div className="af-class-highlightslider-slide af-class-gameslide w-slide">
            <div className="af-class-highlightslider-soccer-wrapper">
              <div className="af-class-highlightslider-mosaic-wrapper">
                <div
                  className="af-class-highlightslider-external-wrapper af-class-hometeam"
                  style={{
                    background: `linear-gradient(-60deg, ${
                      getTeamById(event?.home)?.principal_color
                    } 30%, ${getTeamById(event?.home)?.secundary_color})`,
                  }}
                >
                  <div className="af-class-highlightslider-teamname-wrapper af-class-hometeam">
                    <h3 className="af-class-highlightslider-teamname af-class-hometeam">
                      {getTeamById(event?.home)?.name}
                    </h3>
                  </div>
                  <div className="af-class-highlightslider-datetime-wrapper">
                    <div className="af-class-highlightslider-date-wrapper">
                      <div className="af-class-highlightslider-day">
                        {date[0]}
                      </div>
                      <div className="af-class-highlightslider-monthyear-wrapper">
                        <div className="af-class-highlightslider-datetimedata af-class-month">
                          {date[1] === '0'
                            ? month[date[1].substr(-1)]
                            : month[date[1] - 1]}
                        </div>
                        <div className="af-class-highlightslider-datetimedata">
                          {date[2]}
                        </div>
                      </div>
                    </div>
                    <div className="af-class-highlightslider-datetimedata">
                      {event.time} HRS
                    </div>
                  </div>
                  <div className="af-class-highlightslider-stadium-wrapper">
                    <img
                      src="images/icon-stadium_white.svg"
                      loading="lazy"
                      alt=""
                      className="af-class-highlightslider-stadiumicon"
                    />
                    <div className="af-class-highlightslider-stadiumname">
                      {getStadiumById(event.stadium_id).name}
                    </div>
                  </div>
                  <img
                    src={`${getTeamById(event.home).images[1]}`}
                    loading="lazy"
                    sizes="(max-width: 767px) 100vw, (max-width: 991px) 22vw, 275px"
                    srcSet={`${getTeamById(event.home).images[1]} 1080w, ${
                      getTeamById(event.home).images[1]
                    } 1201w`}
                    alt=""
                    className="af-class-highlightslider-externalimage"
                  />
                </div>
                <div
                  className="af-class-highlightslider-internal-wrapper af-class-hometeam"
                  style={{
                    backgroundImage: `url(${
                      getTeamById(event.home).images.length > 2
                        ? getTeamById(event.home).images[2]
                        : getTeamById(event.home).images[1]
                    })`,
                  }}
                >
                  <div
                    className="af-class-highlightslider-internalgrad af-class-hometeam af-class-internal"
                    style={{
                      background: `linear-gradient(270deg, ${
                        getTeamById(event?.home)?.principal_color
                      } 0%, transparent 100%)`,
                    }}
                  />
                  <div className="af-class-highlightslider-teamname-wrapper af-class-hometeam af-class-internal">
                    <h3 className="af-class-highlightslider-teamname af-class-hometeam">
                      {getTeamById(event?.home)?.name}
                    </h3>
                  </div>
                </div>
                <div
                  className="af-class-highlightslider-internal-wrapper af-class-awayteam af-class-internal"
                  style={{
                    backgroundImage: `url(${
                      getTeamById(event.visitor).images.length > 2
                        ? getTeamById(event.visitor).images[2]
                        : getTeamById(event.visitor).images[1]
                    })`,
                  }}
                >
                  <div
                    className="af-class-highlightslider-internalgrad af-class-awayteam"
                    style={{
                      background: `linear-gradient(90deg, ${
                        getTeamById(event?.visitor)?.principal_color
                      } 0%, transparent 100%)`,
                    }}
                  />
                  <div className="af-class-highlightslider-teamname-wrapper af-class-awayteam af-class-internal">
                    <h3 className="af-class-highlightslider-teamname">
                      {getTeamById(event?.visitor)?.name}
                    </h3>
                  </div>
                </div>
                <div
                  className="af-class-highlightslider-external-wrapper af-class-awayteam"
                  style={{
                    background: `linear-gradient(60deg, ${
                      getTeamById(event?.visitor)?.principal_color
                    } 30%, ${getTeamById(event?.visitor)?.secundary_color})`,
                  }}
                >
                  <div className="af-class-highlightslider-teamname-wrapper af-class-awayteam">
                    <h3 className="af-class-highlightslider-teamname">
                      {getTeamById(event?.visitor)?.name}
                    </h3>
                  </div>
                  <img
                    src={`${getTeamById(event.visitor).images[1]}`}
                    loading="lazy"
                    sizes="(max-width: 767px) 100vw, (max-width: 991px) 22vw, 275px"
                    srcSet={`${getTeamById(event.visitor).images[1]} 1080w, ${
                      getTeamById(event.visitor).images[1]
                    } 1201w`}
                    alt=""
                    className="af-class-highlightslider-externalimage"
                  />
                  <img
                    src={`${
                      getCompetitionById(event.competition_id).images[0]
                    }`}
                    loading="lazy"
                    sizes="(max-width: 991px) 100vw, 140px"
                    srcSet={`${
                      getCompetitionById(event.competition_id).images[0]
                    } 500w, ${
                      getCompetitionById(event.competition_id).images[0]
                    } 500w, ${
                      getCompetitionById(event.competition_id).images[0]
                    } 800w, ${
                      getCompetitionById(event.competition_id).images[0]
                    } 1080w, ${
                      getCompetitionById(event.competition_id).images[0]
                    } 1600w, ${
                      getCompetitionById(event.competition_id).images[0]
                    } 1920w`}
                    alt=""
                    className="af-class-highlightslider-leaguelogo"
                  />
                  <div className="af-class-highlightslider-leagueroundtour-wrapper">
                    <div className="af-class-highlightslider-roundtour">{`Jornada ${event?.jornada}`}</div>
                    <div className="af-class-highlightslider-league">
                      {getCompetitionById(event?.competition_id)?.name}
                    </div>
                  </div>
                </div>
                <div className="af-class-highlightslider-emblems-wrapper">
                  <img
                    src={`${getTeamById(event.home).images[0]}`}
                    loading="lazy"
                    sizes="(max-width: 479px) 98.078125px, 115.90625px"
                    srcSet={`${getTeamById(event.home).images[0]} 500w, ${
                      getTeamById(event.home).images[0]
                    } 535w`}
                    alt=""
                    className="af-class-highlightslider-emblem"
                  />
                  <div className="af-class-highlightslider-vs">VS</div>
                  <img
                    src={`${getTeamById(event.visitor).images[0]}`}
                    loading="lazy"
                    alt=""
                    className="af-class-highlightslider-emblem"
                  />
                </div>
                <Link
                  to={`/evento/${event.id}`}
                  className="af-class-gen-button af-class-highlightsliderbutton w-button"
                >
                  Buscar lugares
                </Link>
              </div>
              <div className="af-class-highlightslider-mobdata-wrapper">
                <div className="af-class-highlightslider-mobdata-generalrow af-class-upperrow">
                  <div className="af-class-highlightslider-stadium-wrapper af-class-mobile">
                    <img
                      src="images/icon-stadium_deepBlue.svg"
                      loading="lazy"
                      alt=""
                      className="af-class-highlightslider-stadiumicon"
                    />
                    <div className="af-class-highlightslider-stadiumname">
                      {getStadiumById(event?.stadium_id).name}
                    </div>
                  </div>
                  <div className="af-class-highlightslider-leagueroundtour-wrapper af-class-mobile">
                    <div className="af-class-highlightslider-roundtour">
                      {' '}
                      <div className="af-class-highlightslider-roundtour">{`Jornada ${event?.jornada}`}</div>
                    </div>
                    <div className="af-class-highlightslider-league">
                      {getCompetitionById(event?.competition_id)?.name}
                    </div>
                  </div>
                </div>
                <div className="af-class-highlightslider-mobdata-generalrow">
                  <div className="af-class-highlightslider-datetime-wrapper af-class-mobile">
                    <img
                      src={`${
                        getCompetitionById(event.competition_id).images[0]
                      }`}
                      loading="lazy"
                      sizes="(max-width: 991px) 100vw, 140px"
                      srcSet={`${
                        getCompetitionById(event.competition_id).images[0]
                      } 500w, ${
                        getCompetitionById(event.competition_id).images[0]
                      } 500w, ${
                        getCompetitionById(event.competition_id).images[0]
                      } 800w, ${
                        getCompetitionById(event.competition_id).images[0]
                      } 1080w, ${
                        getCompetitionById(event.competition_id).images[0]
                      } 1600w, ${
                        getCompetitionById(event.competition_id).images[0]
                      } 1920w`}
                      alt=""
                      className="af-class-highlightslider-leaguelogo af-class-mobile"
                    />
                    <div className="af-class-highlightslider-date-wrapper">
                      <div className="af-class-highlightslider-day">
                        {date[0]}
                      </div>
                      <div className="af-class-highlightslider-monthyear-wrapper">
                        <div className="af-class-highlightslider-datetimedata af-class-month">
                          {date[1] === '0'
                            ? month[date[1].substr(-1)]
                            : month[date[1] - 1]}
                        </div>
                        <div className="af-class-highlightslider-datetimedata">
                          {date[2]}
                        </div>
                      </div>
                    </div>
                    <div className="af-class-highlightslider-datetimedata">
                      {event.time} HRS
                    </div>
                  </div>
                  <Link
                    to={`/evento/${event.id}`}
                    className="af-class-gen-button af-class-highlightsliderbutton af-class-highlightslidermobbutton w-button"
                  >
                    Buscar lugares
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="af-class-highlightslider-slide af-class-generalslide w-slide">
            <div className="af-class-highlightslider-general-wrapper">
              <div className="af-class-highlightslider-mosaic-wrapper">
                <div className="af-class-highlightslider-generalleft-wrapper">
                  <div className="af-class-highlightslider-datetime-wrapper">
                    <div className="af-class-highlightslider-date-wrapper">
                      <div className="af-class-highlightslider-day">
                        {date[0]}
                      </div>
                      <div className="af-class-highlightslider-monthyear-wrapper">
                        <div className="af-class-highlightslider-datetimedata af-class-month">
                          {date[1] === '0'
                            ? month[date[1].substr(-1)]
                            : month[date[1] - 1]}
                        </div>
                        <div className="af-class-highlightslider-datetimedata">
                          {date[2]}
                        </div>
                      </div>
                    </div>
                    <div className="af-class-highlightslider-datetimedata">
                      {event.time} HRS
                    </div>
                  </div>
                  <div className="af-class-highlightslider-stadium-wrapper">
                    <img
                      src="images/icon-stadium_white.svg"
                      loading="lazy"
                      alt=""
                      className="af-class-highlightslider-stadiumicon"
                    />
                    <div className="af-class-highlightslider-stadiumname">
                      {getStadiumById(event.stadium_id).name}
                    </div>
                  </div>
                  <img
                    src={`${getStadiumById(event.stadium_id).images[0]}`}
                    loading="lazy"
                    sizes="(max-width: 479px) 60px, (max-width: 767px) 19vw, (max-width: 991px) 180px, 240px"
                    srcSet={`${
                      getStadiumById(event.stadium_id).images[0]
                    } 500w, ${
                      getStadiumById(event.stadium_id).images[0]
                    } 800w, ${
                      getStadiumById(event.stadium_id).images[0]
                    } 1080w, images/${
                      getStadiumById(event.stadium_id).images[0]
                    } 1600w, ${
                      getStadiumById(event.stadium_id).images[0]
                    } 2000w, ${
                      getStadiumById(event.stadium_id).images[0]
                    } 2048w`}
                    alt=""
                    className="af-class-highlightslider-externalimage"
                  />
                  <div className="af-class-highlightslider-roundtour af-class-highlgihtslider-tourdesktop">
                    {event.tour_name}
                  </div>
                </div>
                <div
                  className="af-class-highlightslider-generalcenter-wrapper"
                  style={{
                    backgroundImage: `url(${event.images[1]})`,
                  }}
                >
                  <Link
                    to={`/evento/${event.id}`}
                    className="af-class-gen-button af-class-highlightsliderbutton af-class-concertbutton w-button"
                  >
                    Buscar lugares
                  </Link>
                  <h3 className="af-class-highlightslider-eventname">
                    {event.name}
                  </h3>
                  <div
                    className="af-class-highlightslider-generalgrad"
                    style={{
                      background: `linear-gradient(0deg, ${event.event_color} 0%, transparent 100%)`,
                    }}
                  />
                </div>
                <div
                  className="af-class-highlightslider-generalright-wrapper"
                  style={{ backgroundColor: `${event.event_color}` }}
                >
                  <img
                    src={`${event.images[2]}`}
                    loading="lazy"
                    sizes="(max-width: 991px) 100vw, 17vw"
                    srcSet={`${event.images[2]} 500w, ${event.images[2]} 800w, ${event.images[2]} 1080w, ${event.images[2]} 1385w`}
                    alt=""
                    className="af-class-highlightslider-externalimage"
                  />
                  <img
                    src={`${event.images[0]}`}
                    loading="lazy"
                    alt=""
                    className="af-class-highlightslider-eventlogo"
                  />
                </div>
                <img
                  src={`${event.images[0]}`}
                  loading="lazy"
                  alt=""
                  className="af-class-highlightslider-eventlogo af-class-mobeventlogo"
                />
              </div>
              <div className="af-class-highlightslider-mobdata-wrapper">
                <div className="af-class-highlightslider-mobdata-generalrow af-class-upperrow">
                  <div className="af-class-highlightslider-stadium-wrapper af-class-mobile">
                    <img
                      src="images/icon-stadium_deepBlue.svg"
                      loading="lazy"
                      alt=""
                      className="af-class-highlightslider-stadiumicon"
                    />
                    <div className="af-class-highlightslider-stadiumname">
                      {getStadiumById(event.stadium_id).name}
                    </div>
                  </div>
                  <div className="af-class-highlightslider-leagueroundtour-wrapper af-class-mobile">
                    <div className="af-class-highlightslider-roundtour">
                      {event.tour_name}
                    </div>
                  </div>
                </div>
                <div className="af-class-highlightslider-mobdata-generalrow">
                  <div className="af-class-highlightslider-datetime-wrapper af-class-mobile">
                    <div className="af-class-highlightslider-date-wrapper">
                      <div className="af-class-highlightslider-day">
                        {date[0]}
                      </div>
                      <div className="af-class-highlightslider-monthyear-wrapper">
                        <div className="af-class-highlightslider-datetimedata af-class-month">
                          {date[1] === '0'
                            ? month[date[1].substr(-1)]
                            : month[date[1] - 1]}
                        </div>
                        <div className="af-class-highlightslider-datetimedata">
                          {date[2]}
                        </div>
                      </div>
                    </div>
                    <div className="af-class-highlightslider-datetimedata">
                      {event.time} HRS
                    </div>
                  </div>
                  <Link
                    to={`/evento/${event.id}`}
                    className="af-class-gen-button af-class-highlightsliderbutton af-class-highlightslidermobbutton w-button"
                  >
                    Buscar lugares
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      }
    });
  };

  const changePag = (newPage) => {
    myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    setCurrentPage(newPage);
  };

  if (!loader) {
    return (
      <div>
        <span className="af-view">
          <div>
            <div className="af-class-gen-section-hero af-class-homehero">
              <div
                data-poster-url="videos/Hero_comp-poster-00001.jpg"
                data-video-urls="videos/Hero_comp-transcode.mp4,videos/Hero_comp-transcode.webm"
                data-autoplay="true"
                data-loop="true"
                data-wf-ignore="true"
                className="af-class-homeherovideo w-background-video w-background-video-atom"
              >
                <video
                  autoPlay
                  loop
                  style={{
                    backgroundImage: 'url("videos/Hero_comp-poster-00001.jpg")',
                  }}
                  muted
                  playsInline
                  data-wf-ignore="true"
                  data-object-fit="cover"
                >
                  <source
                    src="videos/Hero_comp-transcode.mp4"
                    data-wf-ignore="true"
                  />
                  <source
                    src="videos/Hero_comp-transcode.webm"
                    data-wf-ignore="true"
                  />
                </video>
              </div>
              <div
                data-poster-url="videos/Mob_Hero_comp-poster-00001.jpg"
                data-video-urls="videos/Mob_Hero_comp-transcode.mp4,videos/Mob_Hero_comp-transcode.webm"
                data-autoplay="true"
                data-loop="true"
                data-wf-ignore="true"
                className="af-class-homeherovideo af-class-mobhomeherovideo w-background-video w-background-video-atom"
              >
                <video
                  autoPlay
                  loop
                  style={{
                    backgroundImage:
                      'url("videos/Mob_Hero_comp-poster-00001.jpg")',
                  }}
                  muted
                  playsInline
                  data-wf-ignore="true"
                  data-object-fit="cover"
                >
                  <source
                    src="videos/Mob_Hero_comp-transcode.mp4"
                    data-wf-ignore="true"
                  />
                  <source
                    src="videos/Mob_Hero_comp-transcode.webm"
                    data-wf-ignore="true"
                  />
                </video>
              </div>
              <Link
                to="#events-cat"
                className="af-class-homehero-button w-inline-block"
              >
                <img
                  src="images/downSmallArrow-deepBlue.svg"
                  loading="lazy"
                  alt=""
                  className="af-class-homehero-buttonicon"
                />
                <div>Ver eventos</div>
                <img
                  src="images/downSmallArrow-deepBlue.svg"
                  loading="lazy"
                  alt=""
                  className="af-class-homehero-buttonicon"
                />
              </Link>
            </div>
            {data.principalEvents.length > 0 && (
              <div className="af-class-gen-section">
                <div className="af-class-gen-container">
                  <div
                    data-delay={6000}
                    data-animation="slide"
                    data-autoplay={1}
                    data-easing="ease-in-out"
                    data-duration={1000}
                    data-infinite={1}
                    className="af-class-highlightslider w-slider"
                  >
                    <div className="af-class-highlightslider-mask w-slider-mask">
                      {cardImportantsEvents(data.principalEvents)}
                    </div>
                    <div
                      onClick={() =>
                        principalEvents > 0
                          ? setPrincipalEvents(principalEvents - 1)
                          : setPrincipalEvents(data.principalEvents.length - 1)
                      }
                      className="af-class-highlightslider-arrow af-class-leftarrow w-slider-arrow-left"
                    >
                      <img
                        src="images/sliderArrowLeft-deepBlue.svg"
                        loading="lazy"
                        alt=""
                        className="af-class-slider-arrowicon"
                      />
                    </div>
                    <div
                      className="af-class-highlightslider-arrow w-slider-arrow-right"
                      onClick={() =>
                        principalEvents < data.principalEvents.length - 1
                          ? setPrincipalEvents(principalEvents + 1)
                          : setPrincipalEvents(0)
                      }
                    >
                      <img
                        src="images/sliderArrowRight-deepBlue.svg"
                        loading="lazy"
                        alt=""
                        className="af-class-slider-arrowicon"
                      />
                    </div>
                    <div className="af-class-highlightslider-nav w-slider-nav" />
                  </div>
                </div>
              </div>
            )}
            <div className="af-class-gen-section af-class-deepblue">
              <img
                src="images/triangleGraphicUp-white.svg"
                loading="lazy"
                alt=""
                className="af-class-gen-trianglegraphic af-class-lefttriangle"
              />
              <img
                src="images/triangleGraphicDown-white.svg"
                loading="lazy"
                alt=""
                className="af-class-gen-trianglegraphic"
              />
              <div
                data-w-id="badf0a48-d988-81f4-51fe-3a060c362563"
                className="af-class-gen-container"
              >
                <div className="af-class-rentinvite-top-wrapper">
                  <div className="af-class-rentinvite-icons-wrapper">
                    <div className="af-class-rentinvite-icons-subwrapper1">
                      <img
                        src="images/bigIcon-prop_rent_s1_white.svg"
                        loading="lazy"
                        data-w-id="f0d2244e-95fa-b7a1-97d8-9029916ef468"
                        alt=""
                        className="af-class-rentinvite-icon af-class-rentinvite-icon1"
                      />
                      <img
                        src="images/rentInviteArrow-aquaGrass.svg"
                        loading="lazy"
                        data-w-id="c8da785a-c93b-5975-eb9f-c0db2f3ed259"
                        alt=""
                        className="af-class-rentinvite-arrow af-class-rotatingarrow af-class-rentinvite-arrow1"
                      />
                      <img
                        src="images/bigIcon-prop_rent_s2_white.svg"
                        loading="lazy"
                        data-w-id="07396727-720c-e4b7-eb5b-05af060e691e"
                        alt=""
                        className="af-class-rentinvite-icon af-class-rentinvite-icon2"
                      />
                      <img
                        src="images/rentInviteArrow-aquaGrass.svg"
                        loading="lazy"
                        data-w-id="b887df60-b9e7-e3ec-b116-d8473cb52c61"
                        alt=""
                        className="af-class-rentinvite-arrow af-class-rotatingarrow af-class-rentinvite-arrow2"
                      />
                    </div>
                    <div className="af-class-rentinvite-icons-subwrapper2">
                      <img
                        src="images/bigIcon-prop_rent_s3_white.svg"
                        loading="lazy"
                        data-w-id="e4b5d523-b49c-a194-c1b2-99a2aa3241e8"
                        alt=""
                        className="af-class-rentinvite-icon af-class-rentinvite-icon3"
                      />
                      <img
                        src="images/rentInviteArrow-aquaGrass.svg"
                        loading="lazy"
                        data-w-id="c6ee4a6c-f6dd-638c-e260-c252ee127dd9"
                        alt=""
                        className="af-class-rentinvite-arrow af-class-rentinvite-arrow3"
                      />
                      <img
                        src="images/bigIcon-prop_rent_s4_white.svg"
                        loading="lazy"
                        data-w-id="beff3788-3374-f229-940e-259f98521e41"
                        alt=""
                        className="af-class-rentinvite-icon af-class-rentinvite-icon4"
                      />
                    </div>
                  </div>
                  <div className="af-class-rentinvite-title-wrapper af-class-rentinvitemobtitlewrapper">
                    <div className="af-class-rentinvite-titleremark-wrapper">
                      <div className="af-class-gen-titleremark af-class-leftremark-rentinvite">
                        <h2 className="af-class-gen-sectiontitle af-class-white af-class-titleremarktext">
                          Vuelve
                        </h2>
                      </div>
                      <div className="af-class-gen-titleremark">
                        <h2 className="af-class-gen-sectiontitle af-class-white af-class-titleremarktext">
                          rentable
                        </h2>
                      </div>
                    </div>
                    <h2 className="af-class-gen-sectiontitle af-class-white af-class-rentinvitetitle">
                      tu palco, platea o butacas
                    </h2>
                  </div>
                </div>
                <div className="af-class-rentinvite-bottom-wrapper">
                  <div className="af-class-rentinvite-title-wrapper">
                    <div className="af-class-rentinvite-titleremark-wrapper">
                      <div className="af-class-gen-titleremark af-class-leftremark-rentinvite">
                        <h2 className="af-class-gen-sectiontitle af-class-white af-class-titleremarktext">
                          Vuelve
                        </h2>
                      </div>
                      <div className="af-class-gen-titleremark">
                        <h2 className="af-class-gen-sectiontitle af-class-white af-class-titleremarktext">
                          rentable
                        </h2>
                      </div>
                    </div>
                    <h2 className="af-class-gen-sectiontitle af-class-white af-class-rentinvitetitle">
                      tu palco, platea o butacas
                    </h2>
                  </div>
                  <div className="af-class-rentinvite-content-wrapper">
                    <p className="af-class-gen-paragraph af-class-white af-class-rentinvite">
                      Sácale provecho a tus lugares y pases cuando no los
                      utilizas. Aprovecha nuestra plataforma para monetizar tu
                      propiedad, sin ningún esfuerzo. Por otro lado, si lo que
                      quieres es venderla, nosotros podemos ayudarte.
                    </p>
                    <div className="af-class-rentinvite-buttons-wrapper">
                      <Link
                        to="/estadios"
                        className="af-class-gen-button w-button"
                      >
                        Quiero Rentar
                      </Link>
                      <Link
                        to="/contacto"
                        className="af-class-gen-button w-button"
                      >
                        Quiero vender
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="events-cat"
              className="af-class-gen-section af-class-cat-section"
            >
              <div className="af-class-gen-container">
                <img
                  src="images/bigIcon-seats_blue.svg"
                  loading="lazy"
                  alt=""
                  className="af-class-cat-graphic"
                />
                <h2 className="af-class-gen-sectiontitle af-class-cat-title">
                  Los mejores eventos, con los mejores lugares
                </h2>
                <div className="af-class-cat-wrapper">
                  {/* <div
                    data-w-id="444a8a77-4349-446d-749e-b446e8ed84c7"
                    className="af-class-cat-filterstab"
                  >
                    <div className="af-class-cat-filterstab-contentcolumn">
                      <div className="af-class-cat-filters-wrapper w-form">
                        <form
                          id="wf-form-events-filters-form"
                          name="wf-form-events-filters-form"
                          data-name="events-filters-form"
                          onSubmit={handleSubmit(onSubmit)}
                        >
                          <div className="af-class-gen-paragraph">
                            Filtra según lo que necesitas.
                          </div>
                          <div className="af-class-cat-filtersdrop-wrapper">
                            <select
                              ref={register}
                              id="competition"
                              name="competition"
                              data-name="Filter League 2"
                              className="af-class-cat-filtersdrop-toggle w-select"
                            >
                              <option>Competición</option>
                              {data.competitions &&
                                data.competitions.map((competition) => {
                                  return (
                                    <option value={competition.id}>
                                      {competition.name}
                                    </option>
                                  );
                                })}
                            </select>
                            <select
                              ref={register}
                              id="stadium"
                              name="stadium"
                              data-name="Filter Stadium 2"
                              className="af-class-cat-filtersdrop-toggle w-select"
                            >
                              <option>Estadio</option>
                              {data.stadiums &&
                                data.stadiums.map((stadium) => {
                                  return (
                                    <option value={stadium.id}>
                                      {stadium.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="af-class-gen-paragraph">
                            Busca según el evento.
                          </div>
                          <div className="af-class-cat-filterstextinput-wrapper">
                            <input
                              ref={register}
                              type="text"
                              className="af-class-cat-filterstextinput w-input"
                              maxLength={256}
                              name="search"
                              data-name="Search 2"
                              placeholder="Buscar..."
                              id="search"
                            />
                            <div className="af-class-cat-filterstextinput-note">
                              Ejemplo: Atlas, Jornada 10, Guadalajara, etc.
                            </div>
                          </div>
                          <button
                            type="submit"
                            data-wait="....."
                            className="af-class-gen-button af-class-filtersapplybutton w-button"
                          >
                            Aplicar
                          </button>
                        </form>
                        {notfilter && (
                          <div className="af-class-cat-filterserror-wrapper">
                            <div>
                              ¡Algo salió mal! Por favor, inténtalo de nuevo.
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      data-w-id="32690a44-af6a-d474-76c1-123cb35d7c22"
                      className="af-class-cat-filtersmobbutton"
                    >
                      <img
                        src="images/bothArrow-gray.svg"
                        loading="lazy"
                        alt=""
                        className="af-class-cat-filtersmobbutton-icon"
                      />
                      <div className="af-class-cat-filtersmobbutton-label">
                        Filtros de búsqueda
                      </div>
                    </div>
                  </div> */}
                  <div className="af-class-cat-content-wrapper">
                    <div className="af-class-cat-left-column">
                      <div className="af-class-cat-filters-column">
                        <div className="af-class-cat-filters-wrapper w-form">
                          {/* filtros */}
                          <form
                            id="wf-form-events-filters-form"
                            name="wf-form-events-filters-form"
                            data-name="events-filters-form"
                            onSubmit={handleSubmit(onSubmit)}
                          >
                            <div className="af-class-gen-paragraph">
                              Filtra según lo que necesitas.
                            </div>
                            <div className="af-class-cat-filtersdrop-wrapper">
                              <select
                                id="event_type"
                                ref={register}
                                name="event_type"
                                data-name="event_type"
                                className="af-class-cat-filtersdrop-toggle w-select"
                              >
                                <option value="all">Todos los Eventos</option>
                                <option value="partido">Liga Mx</option>
                                <option value="concierto">Conciertos</option>
                              </select>
                              <select
                                id="stadium"
                                ref={register}
                                name="stadium"
                                data-name="stadium"
                                className="af-class-cat-filtersdrop-toggle w-select"
                              >
                                <option value="all">Estadio</option>
                                {data.stadiums &&
                                  data.stadiums.map((stadium) => {
                                    return (
                                      <option value={stadium.id}>
                                        {stadium.name}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                            <div className="af-class-gen-paragraph">
                              Busca según el evento.
                            </div>
                            <div className="af-class-cat-filterstextinput-wrapper">
                              <input
                                type="text"
                                ref={register}
                                className="af-class-cat-filterstextinput w-input"
                                maxLength={256}
                                name="search"
                                data-name="search"
                                placeholder="Buscar..."
                                id="search"
                              />
                            </div>
                            <div className="af-class-cat-filterstextinput-note">
                              Ejemplo: Atlas, Jornada 10, Guadalajara, etc.
                            </div>
                            <button
                              type="submit"
                              defaultValue="Aplicar"
                              data-wait="....."
                              className="af-class-gen-button af-class-filtersapplybutton w-button"
                            >
                              Buscar
                            </button>
                          </form>
                          {notfilter && (
                            <div className="af-class-cat-filterserror-wrapper">
                              <div>
                                ¡Algo salió mal! Por favor, inténtalo de nuevo.
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="af-class-cat-pag-wrapper">
                        <div className="af-class-gen-note">
                          Se muestran 10 eventos por página.
                        </div>
                        <div className="af-class-cat-pagbuttons-wrapper">
                          <div className="af-class-cat-pagbuttons-center-wrapper">
                            {filters
                              ? filteredEvents.map((_, idx) => {
                                  if (
                                    idx + 1 === 1 ||
                                    idx * 10 < filteredEvents.length
                                  ) {
                                    return currentPage === idx + 1 ? (
                                      <div className="af-class-cat-pagbutton af-class-pagbuttonactive">
                                        {idx + 1}
                                      </div>
                                    ) : (
                                      <div
                                        className="af-class-cat-pagbutton"
                                        onClick={() => changePag(idx + 1)}
                                      >
                                        {idx + 1}
                                      </div>
                                    );
                                  }
                                })
                              : data.events.map((_, idx) => {
                                  if (idx * 10 < data.events.length) {
                                    return currentPage === idx + 1 ? (
                                      <div className="af-class-cat-pagbutton af-class-pagbuttonactive">
                                        {idx + 1}
                                      </div>
                                    ) : (
                                      <div
                                        className="af-class-cat-pagbutton"
                                        onClick={() => changePag(idx + 1)}
                                      >
                                        {idx + 1}
                                      </div>
                                    );
                                  }
                                })}
                          </div>
                          {/* <div className="af-class-cat-pagbutton af-class-pagbuttonactive">1</div>
                          <div className="af-class-cat-pagnumberdivider">·····</div>
                          <div className="af-class-cat-pagbuttons-center-wrapper">
                            <div className="af-class-cat-pagbutton">2</div>
                            <div className="af-class-cat-pagbutton">3</div>
                            <div className="af-class-cat-pagbutton">4</div>
                          </div>
                          <div className="af-class-cat-pagnumberdivider">·····</div>
                          <div className="af-class-cat-pagbutton">8</div> */}
                        </div>
                      </div>
                    </div>
                    {/* card evento */}
                    <div ref={myRef} className="af-class-cat-right-column">
                      {getCard(
                        filteredEvents.length > 0 ? filteredEvents : data.events
                      )}
                    </div>
                  </div>
                </div>
                <div className="af-class-cat-pag-wrapper af-class-pagmob-wrapper">
                  <div className="af-class-gen-note">
                    Se muestran 10 eventos por página.
                  </div>
                  <div className="af-class-cat-pagbuttons-wrapper">
                    <div className="af-class-cat-pagbuttons-center-wrapper">
                      {filters
                        ? filteredEvents.map((_, idx) => {
                            if (
                              idx + 1 === 1 ||
                              idx * 10 < filteredEvents.length
                            ) {
                              return currentPage === idx + 1 ? (
                                <div className="af-class-cat-pagbutton af-class-pagbuttonactive">
                                  {idx + 1}
                                </div>
                              ) : (
                                <div
                                  className="af-class-cat-pagbutton"
                                  onClick={() => changePag(idx + 1)}
                                >
                                  {idx + 1}
                                </div>
                              );
                            }
                          })
                        : data.events.map((_, idx) => {
                            if (
                              idx + 1 === 1 ||
                              idx * 10 < data.events.length
                            ) {
                              return currentPage === idx + 1 ? (
                                <div className="af-class-cat-pagbutton af-class-pagbuttonactive">
                                  {idx + 1}
                                </div>
                              ) : (
                                <div
                                  className="af-class-cat-pagbutton"
                                  onClick={() => changePag(idx + 1)}
                                >
                                  {idx + 1}
                                </div>
                              );
                            }
                          })}
                    </div>
                    {/* <div className="af-class-cat-pagbutton af-class-pagbuttonactive">1</div>
                    <div className="af-class-cat-pagnumberdivider">·····</div>
                    <div className="af-class-cat-pagbuttons-center-wrapper">
                      <div className="af-class-cat-pagbutton">2</div>
                      <div className="af-class-cat-pagbutton">3</div>
                      <div className="af-class-cat-pagbutton">4</div>
                    </div>
                    <div className="af-class-cat-pagnumberdivider">·····</div>
                    <div className="af-class-cat-pagbutton">8</div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-gen-section">
              <div className="af-class-gen-container">
                <div className="af-class-socialinvite-wrapper">
                  <div className="af-class-socialinvite-img-wrapper">
                    <img
                      src="images/socialMedia-invite_comp.png"
                      loading="lazy"
                      sizes="(max-width: 767px) 100vw, (max-width: 991px) 278.015625px, 405.125px"
                      srcSet="images/socialMedia-invite_comp-p-500.png 500w, images/socialMedia-invite_comp-p-800.png 800w, images/socialMedia-invite_comp.png 1920w"
                      alt=""
                      className="af-class-socialinvite-image"
                    />
                    <img
                      src="images/socialMedia-invite_movil_comp.png"
                      loading="lazy"
                      sizes="(max-width: 479px) 76vw, (max-width: 767px) 350px, 100vw"
                      srcSet="images/socialMedia-invite_movil_comp-p-500.png 500w, images/socialMedia-invite_movil_comp-p-800.png 800w, images/socialMedia-invite_movil_comp.png 1786w"
                      alt=""
                      className="af-class-socialinvite-imgmob"
                    />
                    <img
                      src="images/sombreBloque.png"
                      loading="lazy"
                      alt=""
                      className="af-class-socialinvite-mobshadow"
                    />
                  </div>
                  <div className="af-class-socialinvite-content-wrapper">
                    <div className="af-class-socialinvite-graphic-wrapper">
                      <img
                        src="images/bigIcon-social_media_vertical.svg"
                        loading="lazy"
                        alt=""
                        className="af-class-socialinvite-graphicver"
                      />
                      <img
                        src="images/bigIcon-social_media_horiz.svg"
                        loading="lazy"
                        alt=""
                        className="af-class-socialinvite-graphichor"
                      />
                    </div>
                    <h2 className="af-class-gen-sectiontitle">
                      Mantente al día con lo último
                    </h2>
                    <p className="af-class-gen-paragraph af-class-socialinvite-desc">
                      Síguenos en redes sociales y mantente actualizado con las
                      últimas noticias y promociones.
                    </p>
                    <div className="af-class-socialinvite-platforms-wrapper">
                      <img
                        src="images/bigIcon-social_media_mobile.svg"
                        loading="lazy"
                        alt=""
                        className="af-class-socialinvite-graphicmob"
                      />
                      <div className="af-class-socialinvite-buttons-wrapper">
                        <a
                          href="https://www.facebook.com/palcosplus"
                          target="_blank"
                          alt=""
                          className="af-class-socialinvite-button af-class-socialinvite-fb w-inline-block"
                        >
                          <img
                            src="images/Facebook-white.svg"
                            loading="lazy"
                            alt=""
                            className="af-class-socialinvite-buttonicon"
                          />
                          <div>Me gusta</div>
                        </a>
                        <a
                          href="https://instagram.com/palcosplus?utm_medium=copy_link"
                          target="_blank"
                          className="af-class-socialinvite-button af-class-socialinvite-ig w-inline-block"
                        >
                          <img
                            src="images/Instagram-white.svg"
                            loading="lazy"
                            alt=""
                            className="af-class-socialinvite-buttonicon"
                          />
                          <div>Seguir</div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="af-class-socialinvite-grayblock" />
                </div>
              </div>
            </div>
          </div>
        </span>
      </div>
    );
  } else {
    return <Loader />;
  }
};

export default Home;
