import React, { forwardRef, useState, useEffect, FormEvent } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import ImageUploading from 'react-images-uploading';
import axios from 'axios';
import Switch from 'react-switch';
import configData from 'config.json';

import Tables, { DataCell } from 'components/Tables';
import Label from 'components/Label';
import Input from 'components/Input';
import Button from 'components/Button';
import Home from 'components/Icons/Home';
import Carousel from 'components/Carousel';
import Loader from 'components/Loader';

import './CreatePalco.css';

const CreatePalco = forwardRef((props, ref) => {
  const history = useHistory();
  const params = useParams();
  const [loader, setLoader] = useState(true);
  const [isImportant, setIsImportant] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [imagesVista, setImagesVista] = useState([]);
  const [imagesGaleria, setImagesGaleria] = useState([]);
  const [currentImageVista, setCurrentImageVista] = useState(0);
  const [currentImageGaleria, setCurrentImageGaleria] = useState(0);
  const [stadiums, setStadiums] = useState([]);
  const [events, setEvents] = useState([]);
  const [teams, setTeams] = useState([]);
  const [relationPE, setRelationPE] = useState([]);
  const [palco, setPalco] = useState();
  const [tariffType, setTariffType] = useState([]);
  const [newOwnerPrice, setNewOwnerPrice] = useState([]);
  const [newComision, setNewComision] = useState([]);
  const [chooseStadium, setChooseStadium] = useState('');

  const [amenities, setAmenities] = useState([]);
  const [selectedAmenities, setSelectedAmenities] = useState([]);

  const { control, handleSubmit, register, formState, errors } = useForm({
    defaultValues: { price: 2000 },
  });
  const [datos, setDatos] = useState({
    price: '',
    comision: '',
  });
  const maxNumber = 5;

  const getData = async () => {
    try {
      const stadium = await axios
        .get(`${configData.SERVER_URL}/stadiums`)
        .then((res) => {
          return res.data.stadium;
        });
      const palco = await axios
        .get(`${configData.SERVER_URL}/palcos/${params.id}`)
        .then((res) => {
          return res.data.palco;
        });
      palco.isImportant & (palco.isImportant === true) && setIsImportant(true);
      palco.active === false && setIsActive(false);
      const events = await axios
        .get(
          `${configData.SERVER_URL}/events/nextEvents/stadium/${palco.stadium_id}`
        )
        .then((res) => {
          return res.data.events;
        })
        .catch((err) => {
          console.log(err);
          return [];
        });
      const teams = await axios
        .get(`${configData.SERVER_URL}/teams`)
        .then((res) => {
          return res.data.teams;
        })
        .catch((err) => {
          console.log(err);
          return [];
        });
      const relationPE = await axios
        .get(`${configData.SERVER_URL}/eventPalco/`)
        .then((res) => {
          return res.data.relation;
        })
        .catch((err) => {
          console.log(err);
          return [];
        });
  
      // Set state with retrieved data
      setStadiums(stadium);
      setPalco(palco);
      setEvents(events);
      setTeams(teams);
      setRelationPE(relationPE);
      setDatos({
        price: parseInt(palco.owner_price),
        comision: parseInt(palco.comision),
      });
      setChooseStadium(palco.stadium_id);
  
      let galeria = [];
      for (let i = 1; i < palco.images.length; i++) {
        galeria.push({
          data_url: palco.images[i],
          file: {
            name: palco.images[i].split(
              'https://upload-images-palcosplus.s3.amazonaws.com/'
            )[1],
          },
        });
      }
      setImagesGaleria(galeria);
      setImagesVista([
        {
          data_url: palco.images[0],
          file: {
            name: palco.images[0].split(
              'https://upload-images-palcosplus.s3.amazonaws.com/'
            )[1],
          },
        },
      ]);
  
      setSelectedAmenities(palco.amenities);
      
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };
  

  useEffect(() => {
    setLoader(true);
    setAmenities([
      'Refrigerador',
      'TV',
      'Estacionamiento',
      'Baño',
      'Horno',
      'Mesero',
      'Derecho a ingresar bebidas',
      'Derecho a ingresar bebidas y alimentos',
    ]);
    getData();
  }, []);

  const onChangesetImagesVista = (imageList, addUpdateIndex) => {
    setImagesVista(imageList);
  };
  const onChangesetImagesGaleria = (imageList, addUpdateIndex) => {
    setImagesGaleria(imageList);
  };

  const handleAmenityChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedAmenities([...selectedAmenities, value]);
    } else {
      setSelectedAmenities(selectedAmenities.filter((amenity) => amenity !== value));
    }
  };

  const handleInputChange = (event) => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };

  const sendDataRequest = async (palco) => {
    try {
      const formData = new FormData();
      formData.append('name', palco.name);
      formData.append('type', palco.type);
      formData.append('zone', palco.zone);
      formData.append('access', palco.access);
      formData.append('num_cards', palco.num_cards);
      formData.append('description', palco.description);
      formData.append('price', palco.price);
      formData.append('owner_price', palco.owner_price);
      formData.append('stadium_id', palco.stadium_id);
      formData.append('comision', palco.comision);
      formData.append('active', palco.active);
      formData.append('isImportant', palco.isImportant);
      selectedAmenities.forEach((amenity) => {
        formData.append('amenities', amenity);
      });

      palco.imagesName.map((img) => {
        const url = img.data_url.split(
          'https://upload-images-palcosplus.s3.amazonaws.com/'
        );
        if (url[0] === '') {
          formData.append('imagesName', img.data_url);
        } else {
          formData.append('imagesName', '');
        }
      });
      palco.images.map((img) => {
        formData.append('images', img);
      });
      const res = await axios.patch(
        `${configData.SERVER_URL}/palcos/${params.id}`,
        formData
      );
      const response = res.data.palco;
    
      await saveNewRelation();
      // history.push('/managerPalco');
      history.go(0);
    } catch (err) {
      console.log(err);
    }
  };

  const onsubmit = (data, e) => {
    e.preventDefault();
    const imgVista = imagesVista.map((img, idx) => {
      const url = img.data_url.split(
        'https://upload-images-palcosplus.s3.amazonaws.com/'
      );
      if (url[0] === '') {
        return Object.defineProperty(img.file, 'name', {
          writable: 'true',
          value: img.data_url,
        });
      } else {
        const type = img.file.type.split('/');
        return Object.defineProperty(img.file, 'name', {
          writable: 'true',
          value: 'vista-' + data.name + '-' + (idx + 1) + '.' + type[1],
        });
      }
    });
    const imgGaleria = imagesGaleria.map((img, idx) => {
      const url = img.data_url.split(
        'https://upload-images-palcosplus.s3.amazonaws.com/'
      );
      if (url[0] === '') {
        return Object.defineProperty(img.file, 'name', {
          writable: 'true',
          value: img.data_url,
        });
      } else {
        const type = img.file.type.split('/');
        return Object.defineProperty(img.file, 'name', {
          writable: 'true',
          value: 'galeria-' + data.name + '-' + (idx + 1) + '.' + type[1],
        });
      }
    });
    const palco = {
      name: data.name,
      type: data.type,
      zone: data.zone,
      access: data.access,
      num_cards: data.cards,
      description: data.details,
      price:
        parseFloat(datos.price) +
        parseFloat(datos.price * (datos.comision / 100)),
      owner_price: parseInt(datos.price),
      stadium_id: chooseStadium,
      comision: parseInt(datos.comision),
      active: isActive,
      isImportant: isImportant,
      images: [...imgVista, ...imgGaleria],
      imagesName: [...imagesVista, ...imagesGaleria],
      amenities: selectedAmenities,
    };
   
    sendDataRequest(palco);
  };

  const getStadium = () => {
    return stadiums.map((stadium) => {
      return { value: stadium.id, label: stadium.name };
    });
  };

  const getZones = (id) => {
    if (id) {
      const stadium = stadiums.find((s) => s.id === id);
      const zones = stadium.zones.split(',');
      const options = zones.map((z) => {
        const color = z.split('-');
        return { value: color[0], label: color[0] };
      });
      return options;
    } else {
      return [{ value: '', label: '' }];
    }
  };

  const getAccessDoor = (id) => {
    if (id) {
      const stadium = stadiums.find((s) => s.id === id);
      const zones = stadium.access.split(',');
      const options = zones.map((z) => {
        return { value: z, label: z };
      });
      return options;
    } else {
      return [{ value: '', label: '' }];
    }
  };

  // const getEventsByDateStadium = (event) => {
  //   return data.allEvents.filter((e) => {
  //     const date1 = new Date(e.date);
  //     const date2 = new Date(event.date);
  //     if (
  //       date1 >= date2 &&
  //       e.stadium_id === event.stadium_id &&
  //       e.id !== event.id
  //     ) {
  //       return e;
  //     }
  //   });
  // };

  const titleHeaders = [
    'Evento',
    'Fecha',
    'Tarifa',
    'Pr. Dueño',
    'Com.',
    'Precio renta',
    'Com. PP',
  ];

  const sendTariff = (value, idx) => {
    const found = tariffType.findIndex((number) => number.id === idx);
    if (found === -1) {
      setTariffType([...tariffType, { id: idx, value: value }]);
    } else {
      let newArrray = tariffType;
      newArrray.splice(found, 1);
      setTariffType([...newArrray, { id: idx, value: value }]);
    }
  };

  const getTeamById = (id) => {
    if (teams) {
      return teams.find((team) => team.id === id);
    }
  };

  const manageNewPrices = (name, value) => {
    if (name === 'price_owner') {
      const found = newOwnerPrice.findIndex((number) => number.id === value.id);
      if (found === -1) {
        setNewOwnerPrice([...newOwnerPrice, value]);
      } else {
        let newArrray = newOwnerPrice;
        newArrray.splice(found, 1);
        setNewOwnerPrice([...newArrray, value]);
      }
    } else if (name === 'new_comision') {
      const found = newComision.findIndex((number) => number.id === value.id);
      if (found === -1) {
        setNewComision([...newComision, value]);
      } else {
        let newArrray = newComision;
        newArrray.splice(found, 1);
        setNewComision([...newArrray, value]);
      }
    }
  };

  const saveNewRelation = async () => {
    let newData = [];
    events.map((event, idx) => {
      let tmp = '';
      const tarifa = tariffType.find((number) => number.id === idx);
      const newPrice = newOwnerPrice.find((number) => number.id === idx);
      const newCom = newComision.find((number) => number.id === idx);
      const relation = relationPE.find(
        (pe) => pe.event_id === event.id && pe.palco_id === palco.id
      );
      const ownerPrice = newPrice?.value
        ? newPrice?.value
        : relation?.owner_price
          ? relation?.owner_price
          : palco.owner_price;
      const comision = newCom?.value
        ? newCom?.value
        : relation?.comision
          ? relation?.comision
          : palco.comision;
      if (tarifa?.value === 'No disponible') {
        tmp = {
          tarifa: tarifa?.value,
          active: false,
          event_id: event.id,
          palco_id: palco.id,
        };
      } else if (tarifa?.value === 'Especial' || newPrice || newCom) {
        tmp = {
          event_id: event.id,
          palco_id: palco.id,
          tarifa: 'Especial',
          price:
            parseFloat(ownerPrice) + parseFloat(ownerPrice * (comision / 100)),
          comision: comision,
          owner_price: ownerPrice,
          active: true,
        };
      } else if (tarifa?.value === 'Estandar') {
        tmp = {
          event_id: event.id,
          palco_id: palco.id,
          tarifa: tarifa?.value,
          price:
            parseFloat(ownerPrice) + parseFloat(ownerPrice * (comision / 100)),
          comision: palco.comision,
          owner_price: palco.owner_price,
          active: true,
        };
      }
      tmp && newData.push(tmp);
    });
    const res = await axios.post(
      `${configData.SERVER_URL}/eventPalco`,
      newData
    );
  };

  function displayRows(event, idx) {
    const tariff = tariffType.find((t) => t.id === idx);
    const newPrice = newOwnerPrice.find((t) => t.id === idx);
    const newCom = newComision.find((t) => t.id === idx);
    const relation = relationPE.find(
      (pe) => pe.event_id === event.id && pe.palco_id === palco.id
    );
    const tarifa = tariff?.value
      ? tariff?.value
      : relation?.tarifa
        ? relation?.tarifa
        : 'Estandar';
    const ownerPrice = newPrice?.value
      ? newPrice?.value
      : relation?.owner_price
        ? relation?.owner_price
        : palco.owner_price;
    const comision = newCom?.value
      ? newCom?.value
      : relation?.comision
        ? relation?.comision
        : palco.comision;
    return (
      <tr className="hover:bg-gray-100">
        <DataCell>
          {event.type === 'concierto'
            ? event?.name
            : getTeamById(event.home).name +
            ' vs ' +
            getTeamById(event.visitor).name}
        </DataCell>
        <DataCell>{event.date}</DataCell>
        <DataCell>
          <Input
            name={('tariff' + (idx + 1)).toString}
            input="select"
            defaultValue={relation ? relation?.tarifa : 'Estandar'}
            options={[
              { label: 'Estandar', value: 'Estandar' },
              { label: 'Especial', value: 'Especial' },
              { label: 'No disponible', value: 'No disponible' },
            ]}
            className="w-full h-10 bg-gris-auxiliar"
            onChange={(e) => sendTariff(e.target.value, idx)}
          />
        </DataCell>
        <DataCell>
          {tarifa === 'Especial' ? (
            <Input
              name={('price_owner' + (idx + 1)).toString}
              input="text"
              placeholder="$1,000.00"
              className="w-full h-10 bg-gris-auxiliar"
              defaultValue={
                relation?.owner_price
                  ? relation?.owner_price
                  : palco.owner_price
              }
              onChange={(e) =>
                manageNewPrices('price_owner', {
                  id: idx,
                  value: e.target.value,
                })
              }
            />
          ) : tarifa === 'No disponible' ? (
            <p className="text-center">---</p>
          ) : (
            '$' + palco.owner_price
          )}
        </DataCell>
        <DataCell>
          {tarifa === 'Especial' ? (
            <Input
              name={('new_comision' + (idx + 1)).toString}
              input="input"
              placeholder="10%"
              type="number"
              className="w-full h-10 bg-gris-auxiliar"
              defaultValue={
                relation?.comision ? relation?.comision : palco.comision
              }
              onChange={(e) =>
                manageNewPrices('new_comision', {
                  id: idx,
                  value: e.target.value,
                })
              }
            />
          ) : tarifa === 'No disponible' ? (
            <p className="text-center">---</p>
          ) : (
            palco.comision + '%'
          )}
        </DataCell>
        <DataCell>
          {tarifa === 'No disponible' ? (
            <p className="text-center">---</p>
          ) : (
            '$' +
            (parseFloat(ownerPrice) + parseFloat(ownerPrice * (comision / 100)))
          )}
        </DataCell>
        <DataCell>
          {tarifa === 'No disponible' ? (
            <p className="text-center">---</p>
          ) : (
            '$' + parseFloat(ownerPrice * (comision / 100))
          )}
        </DataCell>
      </tr>
    );
  }

  // function displayRows(event, idx) {
  //   const exist = tariffType.find((t) => t.id === idx);
  //   const relation = relationPE.find(
  //     (pe) => pe.event_id === event.id && pe.palco_id === palco.id
  //   );
  //   return (
  //     <tr className="hover:bg-gray-100">
  //       <DataCell>
  //         {event.type === 'concierto'
  //           ? event?.name
  //           : getTeamById(event.home).name +
  //             ' vs ' +
  //             getTeamById(event.visitor).name}
  //       </DataCell>
  //       <DataCell>{event.date}</DataCell>
  //       <DataCell>
  //         <Input
  //           name={('tariff' + (idx + 1)).toString}
  //           input="select"
  //           defaultValue={relation ? relation?.tarifa : 'Estandar'}
  //           options={[
  //             { label: 'Estandar', value: 'Estandar' },
  //             { label: 'Especial', value: 'Especial' },
  //             { label: 'No disponible', value: 'No disponible' },
  //           ]}
  //           className="w-full h-10 bg-gris-auxiliar"
  //           onChange={(e) => sendTariff(e.target.value, idx)}
  //         />
  //       </DataCell>
  //       <DataCell>
  //         {!exist & !relation ? (
  //           '$' + palco.owner_price
  //         ) : exist?.value === 'Estandar' ? (
  //           '$' + palco.owner_price
  //         ) : exist?.value === 'Especial' || relation?.tarifa === 'Especial' ? (
  //           <Input
  //             name={('price_owner' + (idx + 1)).toString}
  //             input="text"
  //             placeholder="$1,000.00"
  //             className="w-full h-10 bg-gris-auxiliar"
  //             defaultValue={
  //               relation?.owner_price
  //                 ? relation?.owner_price
  //                 : palco.owner_price
  //             }
  //             onBlur={(e) =>
  //               manageNewPrices('price_owner', {
  //                 id: idx,
  //                 value: e.target.value,
  //               })
  //             }
  //           />
  //         ) : exist?.value === 'No disponible' ||
  //           relation?.tarifa === 'No disponible' ? (
  //           <p className="text-center">---</p>
  //         ) : (
  //           '$' + palco.owner_price
  //         )}
  //       </DataCell>
  //       <DataCell>
  //         {!exist & !relation ? (
  //           palco.comision + '%'
  //         ) : exist?.value === 'Estandar' ? (
  //           palco.comision + '%'
  //         ) : exist?.value === 'Especial' || relation?.tarifa === 'Especial' ? (
  //           <Input
  //             name={('new_comision' + (idx + 1)).toString}
  //             input="input"
  //             placeholder="10%"
  //             type="number"
  //             className="w-full h-10 bg-gris-auxiliar"
  //             defaultValue={
  //               relation?.comision ? relation?.comision : palco.comision
  //             }
  //             onBlur={(e) =>
  //               manageNewPrices('new_comision', {
  //                 id: idx,
  //                 value: e.target.value,
  //               })
  //             }
  //           />
  //         ) : exist?.value === 'No disponible' ||
  //           relation?.tarifa === 'No disponible' ? (
  //           <p className="text-center">---</p>
  //         ) : (
  //           palco.comision + '%'
  //         )}
  //       </DataCell>
  //       <DataCell>
  //         {!exist & !relation ? (
  //           '$' + palco.price
  //         ) : exist?.value === 'Estandar' ? (
  //           '$' + palco.price
  //         ) : relation?.tarifa === 'No disponible' ? (
  //           <p className="text-center">---</p>
  //         ) : relation?.price ? (
  //           '$' + relation?.price
  //         ) : (
  //           '$' + palco.price
  //         )}
  //       </DataCell>
  //       <DataCell>
  //         {!exist & !relation ? (
  //           '$' + parseFloat(datos.price * (datos.comision / 100))
  //         ) : relation?.tarifa === 'No disponible' ? (
  //           <p className="text-center">---</p>
  //         ) : (
  //           '$' +
  //           parseFloat(
  //             (relation?.price ? relation?.price : datos.price) *
  //               ((relation?.comision ? relation?.comision : datos.comision) /
  //                 100)
  //           )
  //         )}
  //       </DataCell>
  //     </tr>
  //   );
  // }

  if (!loader) {
    return (
      <form className="w-full pt-28" onSubmit={handleSubmit(onsubmit)}>
        <div className="container-pp mx-auto pb-24">
          <div className="flex flex-col-reverse md:flex-row justify-between pb-10">
            <div className="flex flex-col">
              <div id="tracking" className="pb-4">
                <h3 className="text-gris-oscuro">
                  Panel de Administrador / Propiedades / Palcos / Modificar
                  Propiedad
                </h3>
              </div>
              <div id="title" className="pb-2">
                <h1 className="text-sport-blue font-bold text-5xl">
                  Modificar Propiedad {palco.name}
                </h1>
              </div>
            </div>
            <div className="flex flex-row pb-14 md:pb-0">
              <div className="">
                <Link to="/managerPalco">
                  <Button className="w-40 h-14 bg-green-field rounded-lg shadow-sm hover:bg-green-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight ">
                    Listado
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="grid grid-flow-row pt-8 md:grid-cols-2 lg:grid-cols-3 gap-12">
            <div id="col1">
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">
                    Nombre de la propiedad
                  </Label>
                </div>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: 'Este campo no debe estar vacío' }}
                  defaultValue={palco.name}
                  as={
                    <Input
                      name="name"
                      input="input"
                      type="text"
                      className="w-full h-10"
                    />
                  }
                />
                {errors.name && (
                  <span className="text-rojo-error">{errors.name.message}</span>
                )}
              </div>
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">Zona</Label>
                </div>
                <Controller
                  name="zone"
                  control={control}
                  rules={{ required: 'Este campo no debe estar vacío' }}
                  defaultValue={palco.zone}
                  as={
                    <Input
                      name="zone"
                      input="select"
                      type="text"
                      className="w-full h-10"
                      options={[...getZones(chooseStadium)]}
                    />
                  }
                />
                {errors.zone && (
                  <span className="text-rojo-error">{errors.zone.message}</span>
                )}
              </div>
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">Tipo de propiedad</Label>
                </div>
                <Controller
                  name="type"
                  control={control}
                  rules={{ required: 'Este campo no debe estar vacío' }}
                  defaultValue={palco.type}
                  as={
                    <Input
                      name="type"
                      input="select"
                      className="w-full h-10"
                      options={[
                        { value: 'palco', label: 'Palco' },
                        { value: 'butaca', label: 'Butaca' },
                        { value: 'platea', label: 'Platea   ' },
                      ]}
                    />
                  }
                />
              </div>
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">Precio de dueño</Label>
                </div>
                <Input
                  ref={register}
                  defaultValue={datos.price}
                  name="price"
                  input="input"
                  className="w-full h-10"
                  type="text"
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">Detalles</Label>
                </div>
                <Controller
                  name="details"
                  control={control}
                  rules={{ required: 'Este campo no debe estar vacío' }}
                  defaultValue={palco.description}
                  as={
                    <Input
                      name="details"
                      input="textarea"
                      className="w-full h-48"
                    />
                  }
                />
                {errors.details && (
                  <span className="text-rojo-error">
                    {errors.details.message}
                  </span>
                )}
              </div>
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">
                    Es un evento importante?
                  </Label>
                </div>
                <Switch
                  onColor={'#034AA6'}
                  onChange={() => setIsImportant(!isImportant)}
                  checked={isImportant}
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
              </div>
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">Activo</Label>
                </div>
                <Switch
                  onColor={'#034AA6'}
                  onChange={() => setIsActive(!isActive)}
                  checked={isActive}
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
              </div>
            </div>
            <div id="col2">
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">Estadio</Label>
                </div>
                <Input
                  defaultValue={chooseStadium}
                  ref={register}
                  onChange={(e) => setChooseStadium(e.target.value)}
                  name="stadium"
                  input="select"
                  type="text"
                  className="w-full h-10"
                  options={[...getStadium()]}
                />
              </div>
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">Puertas de acceso</Label>
                </div>
                <Controller
                  name="access"
                  control={control}
                  rules={{ required: 'Este campo no debe estar vacío' }}
                  defaultValue={palco.access}
                  as={
                    <Input
                      name="access"
                      input="select"
                      type="text"
                      className="w-full h-10"
                      options={[
                        { value: '', label: 'Selecciona puerta de acceso' },
                        ...getAccessDoor(chooseStadium),
                      ]}
                    />
                  }
                />
                {errors.access && (
                  <span className="text-rojo-error">
                    {errors.access.message}
                  </span>
                )}
              </div>
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">Numero de tarjetas</Label>
                </div>
                <Controller
                  name="cards"
                  control={control}
                  rules={{ required: 'Este campo no debe estar vacío' }}
                  defaultValue={palco.num_cards}
                  as={
                    <Input
                      name="cards"
                      input="input"
                      className="w-full h-10"
                      type="number"
                    />
                  }
                />
                {errors.cards && (
                  <span className="text-rojo-error">
                    {errors.cards.message}
                  </span>
                )}
              </div>
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">
                    Comisión de PalcosPlus
                  </Label>
                </div>
                <Input
                  ref={register}
                  defaultValue={datos.comision}
                  name="comision"
                  input="input"
                  className="w-full h-10"
                  type="number"
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
              <div id="costos" className="pb-8">
                <div className="pb-5">
                  <Label className="text-deep-blue">Precio de renta</Label>
                  <p className="text-4xl">
                    $
                    {datos.price & datos.comision
                      ? parseFloat(datos.price) +
                      parseFloat(datos.price * (datos.comision / 100))
                      : ''}
                  </p>
                </div>
                <div className="">
                  <Label className="text-deep-blue">
                    Comision de PalcosPlus
                  </Label>
                  <p className="text-4xl">
                    $
                    {datos.comision
                      ? parseFloat(datos.price * ('.' + datos.comision))
                      : ''}
                  </p>
                </div>
              </div>
            </div>
            <div id="col3">
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Amenidades</Label>
              </div>
              <div className="grid grid-cols-1 gap-4">
                {amenities.map((amenity) => (
                  <div key={amenity} className="flex items-center">
                    <input
                      type="checkbox"
                      id={amenity}
                      name={amenity}
                      value={amenity}
                      onChange={handleAmenityChange}
                      checked={selectedAmenities.includes(amenity)}
                    />
                    <label htmlFor={amenity} className="ml-2 mb-0">{amenity}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>


          </div>
          <div>
            <div id="subtitle">
              <h1 className="text-sport-blue font-bold text-4xl">Media</h1>
            </div>
            <div className="grid grid-flow-row pt-8 md:grid-cols-2 gap-12 pb-20">
              <ImageUploading
                multiple
                value={imagesVista}
                onChange={onChangesetImagesVista}
                maxNumber={1}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <div className="">
                    <h1 className="text-ocean-black font-bold text-xl">
                      Vista
                    </h1>
                    <div className={imagesVista.length === 0 && 'hidden'}>
                      {imagesVista.length !== 0 && (
                        <Carousel current={(e) => setCurrentImageVista(e)}>
                          {imagesVista}
                        </Carousel>
                      )}
                    </div>
                    <div className="flex flex-col">
                      <div className="flex flex-row">
                        <div className="">
                          <Button
                            type="button"
                            className={
                              'w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight'
                            }
                            style={isDragging ? { color: 'red' } : undefined}
                            onClick={onImageUpload}
                            disable={imagesVista.length === 5 && true}
                            {...dragProps}
                          >
                            CARGAR
                          </Button>
                        </div>
                        {imagesVista.length > 0 && (
                          <div className="pl-3">
                            <Button
                              type="button"
                              className={
                                'w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight '
                              }
                              disable={imagesVista.length === 0 && true}
                              onClick={() => onImageRemove(currentImageVista)}
                            >
                              Borrar
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </ImageUploading>
              <ImageUploading
                multiple
                value={imagesGaleria}
                onChange={onChangesetImagesGaleria}
                maxNumber={maxNumber}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <div className="">
                    <h1 className="text-ocean-black font-bold text-xl">
                      Galería
                    </h1>
                    <div className={imagesGaleria.length === 0 && 'hidden'}>
                      {imagesGaleria.length !== 0 && (
                        <Carousel current={(e) => setCurrentImageGaleria(e)}>
                          {imagesGaleria}
                        </Carousel>
                      )}
                    </div>
                    <div className="flex flex-col">
                      <div className="flex flex-row">
                        <div className="">
                          <Button
                            type="button"
                            className={
                              'w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight'
                            }
                            style={isDragging ? { color: 'red' } : undefined}
                            onClick={onImageUpload}
                            disable={imagesGaleria.length === 5 && true}
                            {...dragProps}
                          >
                            CARGAR
                          </Button>
                        </div>
                        {imagesGaleria.length > 0 && (
                          <div className="pl-3">
                            <Button
                              type="button"
                              className={
                                'w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight '
                              }
                              disable={imagesGaleria.length === 0 && true}
                              onClick={() => onImageRemove(currentImageGaleria)}
                            >
                              Borrar
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </ImageUploading>
            </div>
          </div>
          <div className="pb-10">
            <div id="subtitle">
              <h1 className="text-sport-blue font-bold text-4xl">
                Precios por evento
              </h1>
              <Tables className="w-auto" headers={titleHeaders}>
                {events && events.map((event, idx) => displayRows(event, idx))}
              </Tables>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="pr-5">
              <Button
                type="submit"
                className="w-44 h-14 font-bold text-xl text-ocean-black bg-green-field rounded-lg shadow-sm hover:bg-green-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight "
                disable={
                  imagesGaleria.length === 0 || imagesVista.length === 0
                    ? true
                    : false
                }
              >
                GUARDAR
              </Button>
            </div>
            <div className="pr-5">
              <Button
                onClick={() => history.goBack()}
                className="w-44 h-14 font-bold text-xl text-white bg-aqua-grass rounded-lg shadow-sm hover:bg-aqua-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight "
                type="button"
              >
                CANCELAR
              </Button>
            </div>
            {/* <div className="pr-5">
              <Button className="w-44 h-14 font-bold text-xl text-white bg-aqua-grass rounded-lg shadow-sm hover:bg-aqua-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight ">
                ELIMINAR
              </Button>
            </div> */}
          </div>
        </div>
      </form>
    );
  } else {
    return <Loader />;
  }
});

export default CreatePalco;
