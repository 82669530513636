import React, { forwardRef, useState, useEffect, FormEvent } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import ImageUploading from 'react-images-uploading';
import axios from 'axios';
import configData from 'config.json';

import Tables, { DataCell } from 'components/Tables';
import Label from 'components/Label';
import Input from 'components/Input';
import Button from 'components/Button';
import Home from 'components/Icons/Home';
import MultiSelect from 'react-multi-select-component';
import Carousel from 'components/Carousel';
import Loader from 'components/Loader';
import './Estadio.css';

const teamsLigamx = [
  { value: 'América', label: 'América' },
  { value: 'Atlas', label: 'Atlas' },
  { value: 'Toluca', label: 'Toluca' },
  { value: 'Monterrey', label: 'Monterrey' },
  { value: 'Tigres', label: 'Tigres' },
  { value: 'Cruz', label: 'Cruz' },
  { value: 'Atlético de San Luis', label: 'Atlético de San Luis' },
  { value: 'León', label: 'León' },
  { value: 'Santos', label: 'Santos' },
  { value: 'Pachuca', label: 'Pachuca' },
  { value: 'Guadalajara', label: 'Guadalajara' },
  { value: 'Mazatlán', label: 'Mazatlán' },
  { value: 'Juárez', label: 'Juárez' },
  { value: 'Necaxa', label: 'Necaxa' },
  { value: 'Puebla', label: 'Puebla' },
  { value: 'Querétaro', label: 'Querétaro' },
  { value: 'UNAM', label: 'UNAM' },
  { value: 'Tijuana', label: 'Tijuana' },
];

const UpdateEstadio = forwardRef((props, ref) => {
  const params = useParams();
  const [loader, setLoader] = useState(true);
  const [selected, setSelected] = useState([]);
  const [rows, setRows] = useState([]);
  const [postalCode, setPostalCode] = useState([]);
  const [tarifa, setTarifa] = useState([]);
  const [imagesMain, setImagesMain] = useState([]);
  const [imagesBanner, setImgBanner] = useState([]);
  const [imagesMap, setImagesMap] = useState([]);
  const [currentImageMain, setCurrentImageMain] = useState(0);
  const [currentImageBanner, setCurrentImageBanner] = useState(0);
  const [currentImageMap, setCurrentImageMap] = useState(0);
  const [stadium, setStadium] = useState();
  const history = useHistory();
  const maxNumber = 5;

  const getData = async () => {
    try {
      const stadium = await axios
        .get(`${configData.SERVER_URL}/stadiums/${params.id}`)
        .then((res) => {
          return res.data.stadium;
        });
      const tarifas = await axios
        .get(`${configData.SERVER_URL}/tarifas/stadium/${params.id}`)
        .then((res) => {
          return res.data.tarifas;
        });
      console.log(stadium);
      setStadium(stadium);
      let tarif = [];
      let pc = [];
      tarifas[0].tarifas.map((tarifa) => {
        tarif.push(tarifa.tarifa);
        pc.push(tarifa.postalCode);
      });
      setTarifa(tarif);
      setPostalCode(pc);
      if (stadium.images.length > 0) {
        let banner = [];
        for (let i = 1; i < stadium.images.length - 1; i++) {
          banner.push({
            data_url: stadium.images[i],
            file: {
              name: stadium.images[i].split(
                'https://upload-images-palcosplus.s3.amazonaws.com/'
              )[1],
            },
          });
        }
        setImgBanner(banner);
        setImagesMain([
          {
            data_url: stadium.images[0],
            file: {
              name: stadium.images[0].split(
                'https://upload-images-palcosplus.s3.amazonaws.com/'
              )[1],
            },
          },
        ]);
        setImagesMap([
          {
            data_url: stadium.images[stadium.images.length - 1],
            file: {
              name: stadium.images[stadium.images.length - 1].split(
                'https://upload-images-palcosplus.s3.amazonaws.com/'
              )[1],
            },
          },
        ]);
      }
      setRows(
        tarifas[0].tarifas.map((t, idx) => {
          return (
            <tr>
              <DataCell className="px-4 py-2">
                <Input
                  name={('tarifa' + (rows.length + 1)).toString()}
                  input="text"
                  className="w-full h-10 bg-gris-auxiliar"
                  onBlur={(e) => updateTarifas(idx, e.target.value, 'tarifa')}
                  defaultValue={t.tarifa}
                />
              </DataCell>
              <DataCell className="px-4 py-2">
                <Input
                  name={('postal_code' + (rows.length + 1)).toString}
                  input="text"
                  className="w-full h-10 bg-gris-auxiliar"
                  onBlur={(e) => updateTarifas(idx, e.target.value, 'postal')}
                  // onChange={e=>hola(idx,e.target.value)}
                  defaultValue={t.postalCode}
                />
              </DataCell>
            </tr>
          );
        })
      );
      const local = stadium.local_teams.split(',');
      let localObj = [];
      for (let i = 0; i < local.length; i++) {
        localObj.push({ value: local[i], label: local[i] });
      }
      setSelected(localObj);
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  const updateTarifas = (i, value, name) => {
    // console.log(i)
    // console.log(h)
    if (name === 'postal') {
      let newPostalCode = postalCode;
      newPostalCode[i] = value;
      setPostalCode(newPostalCode);
    } else {
      let newTarifa = tarifa;
      newTarifa[i] = value;
      setTarifa(newTarifa);
    }
  };

  useEffect(() => {
    setLoader(true);
    getData();
  }, []);

  const onChangesetImgMain = (imageList, addUpdateIndex) => {
    setImagesMain(imageList);
  };
  const onChangesetImgBanner = (imageList, addUpdateIndex) => {
    setImgBanner(imageList);
  };
  const onChangesetMap = (imageList, addUpdateIndex) => {
    setImagesMap(imageList);
  };

  const { control, handleSubmit, register, formState, errors } = useForm();

  const saveTarifa = async (stadiumId) => {
    await axios.delete(`${configData.SERVER_URL}/tarifas/${params.id}`);
    let tarifas = [];
    for (let i = 0; i < rows.length; i++) {
      if (tarifa[i] !== null && postalCode[i]) {
        tarifas.push({ tarifa: tarifa[i], postalCode: postalCode[i] });
      }
    }
    const req = { tarifas: tarifas, stadium_id: stadiumId };
    await axios.post(`${configData.SERVER_URL}/tarifas`, req);
  };

  const sendDataRequest = async (stadium) => {
    try {
      const formData = new FormData();
      formData.append('name', stadium.name);
      formData.append('country', stadium.country);
      formData.append('city', stadium.city);
      formData.append('capacity', stadium.capacity);
      formData.append('fundation_date', stadium.fundation_date);
      formData.append('local_teams', stadium.local_teams);
      formData.append('description', stadium.description);
      formData.append('location', stadium.location);
      formData.append('delivery_zone', stadium.delivery_zone);
      formData.append('zones', stadium.zones);
      formData.append('access', stadium.access);
      stadium.imagesName.map((img) => {
        const url = img.data_url.split(
          'https://upload-images-palcosplus.s3.amazonaws.com/'
        );
        if (url[0] === '') {
          formData.append('imagesName', img.data_url);
        } else {
          formData.append('imagesName', '');
        }
      });
      stadium.images.map((img) => {
        formData.append('images', img);
      });
      const res = await axios.patch(
        `${configData.SERVER_URL}/stadiums/${params.id}`,
        formData
      );
      const response = res.data.stadium;
      await saveTarifa(response._id);
      history.push('/managerEstadio');
      history.go(0);
    } catch (err) {
      console.log(err);
    }
  };

  const onsubmit = (data, e) => {
    e.preventDefault();
    data.teams = selected;
    let teams = '';
    data.teams.map((team) => {
      if (teams === '') {
        teams = team.value;
      } else {
        teams = teams + ',' + team.value;
      }
    });

    const imgMain = imagesMain.map((img, idx) => {
      const url = img.data_url.split(
        'https://upload-images-palcosplus.s3.amazonaws.com/'
      );
      if (url[0] === '') {
        return Object.defineProperty(img.file, 'name', {
          writable: 'true',
          value: img.data_url,
        });
      } else {
        const type = img.file.type.split('/');
        return Object.defineProperty(img.file, 'name', {
          writable: 'true',
          value: 'principal-' + data.name + '-' + (idx + 1) + '.' + type[1],
        });
      }
    });
    const imgBanner = imagesBanner.map((img, idx) => {
      const url = img.data_url.split(
        'https://upload-images-palcosplus.s3.amazonaws.com/'
      );
      if (url[0] === '') {
        return Object.defineProperty(img.file, 'name', {
          writable: 'true',
          value: img.data_url,
        });
      } else {
        const type = img.file.type.split('/');
        return Object.defineProperty(img.file, 'name', {
          writable: 'true',
          value: 'banner-' + data.name + '-' + (idx + 1) + '.' + type[1],
        });
      }
    });
    const imgMap = imagesMap.map((img, idx) => {
      const url = img.data_url.split(
        'https://upload-images-palcosplus.s3.amazonaws.com/'
      );
      if (url[0] === '') {
        return Object.defineProperty(img.file, 'name', {
          writable: 'true',
          value: img.data_url,
        });
      } else {
        const type = img.file.type.split('/');
        return Object.defineProperty(img.file, 'name', {
          writable: 'true',
          value: 'map-' + data.name + '-' + (idx + 1) + '.' + type[1],
        });
      }
    });
    const req = {
      name: stadium.name,
      country: stadium.country,
      city: stadium.city,
      capacity: stadium.capacity,
      fundation_date: stadium.fundation_date,
      local_teams: teams,
      description: stadium.description,
      location: stadium.location,
      zones: stadium.zones,
      delivery_zone: stadium.delivery_zone,
      access: stadium.access,
      images: [...imgMain, ...imgBanner, ...imgMap],
      imagesName: [...imagesMain, ...imagesBanner, ...imagesMap],
    };
    sendDataRequest(req);
  };

  const titleHeaders = ['Tarifa', 'Códigos postales'];

  if (!loader) {
    return (
      <form className="w-full pt-28" onSubmit={handleSubmit(onsubmit)}>
        <div className="mx-auto pb-24" style={{ maxWidth: '1080px' }}>
          <div className="flex flex-col-reverse md:flex-row justify-between pb-10">
            <div className="flex flex-col">
              <div id="tracking" className="pb-4">
                <h3 className="text-gris-oscuro">
                  Panel de Administrador / Estadios / Modificar Estadio
                </h3>
              </div>
              <div id="title" className="pb-2">
                <h1 className="text-sport-blue font-bold text-5xl">
                  Modificar Estadio {stadium.name}
                </h1>
              </div>
            </div>
            <div className="flex flex-row pb-14 md:pb-0">
              <div className="">
                <Link to="/managerEstadio">
                  <Button className="w-40 h-14 bg-green-field rounded-lg shadow-sm hover:bg-green-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight ">
                    Listado
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="grid grid-flow-row pt-8 md:grid-cols-2 lg:grid-cols-3 gap-12">
            <div id="col1">
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">Nombre del Estadio</Label>
                </div>
                <Input
                  ref={register}
                  defaultValue={stadium.name}
                  onChange={(e) =>
                    setStadium({ ...stadium, name: e.target.value })
                  }
                  name="name"
                  input="input"
                  type="text"
                  className="w-full h-10"
                  required
                />
              </div>
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">Ciudad</Label>
                </div>
                <Input
                  ref={register}
                  defaultValue={stadium.city}
                  onChange={(e) =>
                    setStadium({ ...stadium, city: e.target.value })
                  }
                  name="city"
                  input="input"
                  type="text"
                  className="w-full h-10"
                  required
                />
              </div>
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">Capacidad</Label>
                </div>
                <Input
                  ref={register}
                  defaultValue={stadium.capacity}
                  onChange={(e) =>
                    setStadium({ ...stadium, capacity: e.target.value })
                  }
                  name="capacidad"
                  input="input"
                  type="text"
                  className="w-full h-10"
                  required
                />
              </div>
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">Equipos locales</Label>
                </div>
                <Controller
                  name="teams"
                  control={control}
                  rules={{ required: 'Este campo no debe estar vacío' }}
                  defaultValue={selected}
                  render={({ onChange }) => (
                    <MultiSelect
                      name="teams"
                      input="select"
                      value={selected}
                      onChange={setSelected}
                      hasSelectAll={false}
                      // options={teams.map(team=>{
                      //   local_teams.map(local=> team===local && )
                      // })}
                      options={teamsLigamx}
                    />
                  )}
                />
              </div>
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">Descripción</Label>
                </div>
                <Input
                  ref={register}
                  defaultValue={stadium.description}
                  onChange={(e) =>
                    setStadium({ ...stadium, description: e.target.value })
                  }
                  name="description"
                  input="textarea"
                  type="text"
                  className="w-full h-48"
                  required
                />
              </div>
            </div>
            <div id="col2">
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">País</Label>
                </div>
                <Input
                  ref={register}
                  defaultValue={stadium.country}
                  onChange={(e) =>
                    setStadium({ ...stadium, country: e.target.value })
                  }
                  name="country"
                  input="select"
                  type="text"
                  className="w-full h-10"
                  options={[{ value: 'Mexico', label: 'México' }]}
                  required
                />
              </div>
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">
                    Ubicación (URL Google Maps)
                  </Label>
                </div>
                <Input
                  ref={register}
                  defaultValue={stadium.location}
                  onChange={(e) =>
                    setStadium({ ...stadium, location: e.target.value })
                  }
                  name="location"
                  input="input"
                  type="text"
                  className="w-full h-10"
                  required
                />
              </div>
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">Fundación</Label>
                </div>
                <Input
                  ref={register}
                  defaultValue={stadium.fundation_date}
                  onChange={(e) =>
                    setStadium({ ...stadium, fundation_date: e.target.value })
                  }
                  name="fundation"
                  input="input"
                  type="date"
                  className="w-full h-10"
                  required
                />
              </div>
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">Zonas</Label>
                </div>
                <Input
                  ref={register}
                  defaultValue={stadium.zones}
                  onChange={(e) =>
                    setStadium({ ...stadium, zones: e.target.value })
                  }
                  name="zone"
                  input="input"
                  type="text"
                  className="w-full h-10"
                  placeholder="poniente-#034AA6, oriente-#034AA6"
                  required
                />
              </div>
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">Puertas de acceso</Label>
                </div>
                <Input
                  ref={register}
                  defaultValue={stadium.access}
                  onChange={(e) =>
                    setStadium({ ...stadium, access: e.target.value })
                  }
                  name="access"
                  input="input"
                  type="text"
                  className="w-full h-10"
                  required
                />
              </div>
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">Zona de entrega</Label>
                </div>
                <Input
                  ref={register}
                  defaultValue={stadium.delivery_zone}
                  onChange={(e) =>
                    setStadium({ ...stadium, delivery_zone: e.target.value })
                  }
                  name="delivery_zone"
                  input="input"
                  type="text"
                  className="w-full h-10"
                  required
                />
              </div>
            </div>
          </div>
          <div>
            <div id="subtitle">
              <h1 className="text-sport-blue font-bold text-4xl">Media</h1>
            </div>
            <div className="grid grid-flow-row pt-8 grid-cols-1 md:grid-cols-2 gap-12 pb-20">
              <ImageUploading
                multiple
                value={imagesMain}
                onChange={onChangesetImgMain}
                maxNumber={1}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <div className="">
                    <h1 className="text-ocean-black font-bold text-xl">
                      Imagen principal
                    </h1>
                    <div className={imagesMain.length === 0 && 'hidden'}>
                      {imagesMain.length !== 0 && (
                        // <img src={configData.SERVER_URL+'/'+imagesMain} alt="" />
                        <Carousel current={(e) => setCurrentImageMain(e)}>
                          {imagesMain}
                        </Carousel>
                      )}
                    </div>
                    <div className="flex flex-col">
                      <div className="flex flex-row">
                        <div className="">
                          <Button
                            type="button"
                            className={
                              'w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight'
                            }
                            style={isDragging ? { color: 'red' } : undefined}
                            onClick={onImageUpload}
                            disable={imagesMain.length === 5 && true}
                            {...dragProps}
                          >
                            CARGAR
                          </Button>
                        </div>
                        {imagesMain.length > 0 && (
                          <div className="pl-3">
                            <Button
                              type="button"
                              className={
                                'w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight '
                              }
                              disable={imagesMain.length === 0 && true}
                              onClick={() => onImageRemove(currentImageMain)}
                            >
                              Borrar
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </ImageUploading>
              <ImageUploading
                multiple
                value={imagesBanner}
                onChange={onChangesetImgBanner}
                maxNumber={maxNumber}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <div className="">
                    <h1 className="text-ocean-black font-bold text-xl">
                      Imagen Banner
                    </h1>
                    <div className={imagesBanner.length === 0 && 'hidden'}>
                      {imagesBanner.length !== 0 && (
                        <Carousel current={(e) => setCurrentImageBanner(e)}>
                          {imagesBanner}
                        </Carousel>
                      )}
                    </div>
                    <div className="flex flex-col">
                      <div className="flex flex-row">
                        <div className="">
                          <Button
                            type="button"
                            className={
                              'w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight'
                            }
                            style={isDragging ? { color: 'red' } : undefined}
                            onClick={onImageUpload}
                            disable={imagesBanner.length === 5 && true}
                            {...dragProps}
                          >
                            CARGAR
                          </Button>
                        </div>
                        {imagesBanner.length > 0 && (
                          <div className="pl-3">
                            <Button
                              type="button"
                              className={
                                'w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight '
                              }
                              disable={imagesBanner.length === 0 && true}
                              onClick={() => onImageRemove(currentImageBanner)}
                            >
                              Borrar
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </ImageUploading>
              <ImageUploading
                multiple
                value={imagesMap}
                onChange={onChangesetMap}
                maxNumber={1}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <div className="">
                    <h1 className="text-ocean-black font-bold text-xl">
                      Imagen Mapa
                    </h1>
                    <div className={imagesMap.length === 0 && 'hidden'}>
                      {imagesMap.length !== 0 && (
                        <Carousel current={(e) => setCurrentImageMap(e)}>
                          {imagesMap}
                        </Carousel>
                      )}
                    </div>
                    <div className="flex flex-col">
                      <div className="flex flex-row">
                        <div className="">
                          <Button
                            type="button"
                            className={
                              'w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight'
                            }
                            style={isDragging ? { color: 'red' } : undefined}
                            onClick={onImageUpload}
                            disable={imagesMap.length === 5 && true}
                            {...dragProps}
                          >
                            CARGAR
                          </Button>
                        </div>
                        {imagesMap.length > 0 && (
                          <div className="pl-3">
                            <Button
                              type="button"
                              className={
                                'w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight '
                              }
                              disable={imagesMap.length === 0 && true}
                              onClick={() => onImageRemove(currentImageMap)}
                            >
                              Borrar
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </ImageUploading>
            </div>
          </div>
          <div className="pb-24">
            <h1 className="text-sport-blue font-bold text-4xl">
              Tarifas de entrega
            </h1>
            <div className="pt-2 pb-5" style={{ width: '750px' }}>
              <Tables idheader={0} classHead="w-36" headers={titleHeaders}>
                {rows.length !== 0 && rows.map((row) => row)}
              </Tables>
            </div>
            <div>
              <Button
                type="button"
                className="text-white p-2 bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight"
                onClick={() => {
                  setRows([
                    ...rows,
                    <tr>
                      <DataCell className="px-4 py-2">
                        <Input
                          name={('tarifa' + (rows.length + 1)).toString()}
                          input="text"
                          className="w-full h-10 bg-gris-auxiliar"
                          onBlur={(e) => (tarifa[rows.length] = e.target.value)}
                        />
                      </DataCell>
                      <DataCell className="px-4 py-2">
                        <Input
                          name={('postal_code' + (rows.length + 1)).toString}
                          input="text"
                          className="w-full h-10 bg-gris-auxiliar"
                          onBlur={(e) =>
                            (postalCode[rows.length] = e.target.value)
                          }
                        />
                      </DataCell>
                    </tr>,
                  ]);
                }}
              >
                AGREGAR NUEVA TARIFA
              </Button>
            </div>
          </div>
          {/* <div className="pb-24">
            <h1 className="text-sport-blue font-bold text-4xl">
              Registro de Actividad
            </h1>
            <div className="w-3/4 grid grid-flow-row grid-cols-2">
              <div className="flex flex-col">
                <h4 className="text-ocean-black font-bold text-xl pt-4">
                  Fecha de creacion
                </h4>
                <p>18/09/20 - 15:00</p>
              </div>
              <div className="flex flex-col">
                <h4 className="text-ocean-black font-bold text-xl pt-4">
                  Fecha de última modificacion
                </h4>
                <p>27/12/20 - 10:00</p>
              </div>
            </div>
          </div> */}
          <div className="flex flex-row">
            <div className="pr-5">
              <Button
                type="submit"
                className="w-44 h-14 font-bold text-xl text-ocean-black bg-green-field rounded-lg shadow-sm hover:bg-green-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight "
                disable={
                  imagesMain.length === 0 ||
                    imagesBanner.length === 0 ||
                    imagesMap.length === 0
                    ? true
                    : false
                }
              >
                GUARDAR
              </Button>
            </div>
            <div className="pr-5">
              <Button className="w-44 h-14 font-bold text-xl text-white bg-aqua-grass rounded-lg shadow-sm hover:bg-aqua-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight ">
                CANCELAR
              </Button>
            </div>
            {/* <div className="pr-5">
              <Button className="w-44 h-14 font-bold text-xl text-white bg-aqua-grass rounded-lg shadow-sm hover:bg-aqua-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight ">
                ELIMINAR
              </Button>
            </div> */}
          </div>
        </div>
      </form>
    );
  } else {
    return <Loader />;
  }
});

export default UpdateEstadio;
