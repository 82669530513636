import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import Loader from 'components/Loader';
import configData from 'config.json';

const ContactoView = () => {
  const [loader, setLoader] = useState(false);
  const [aplicationMessage, setAplicationMessage] = useState();
  const [questionMessage, setQuestionMessage] = useState();
  const { handleSubmit, register, control, formState, errors, watch } = useForm(
    { mode: 'all' }
  );

  const sendQuestions = async (submit, e) => {
    setLoader(true);
    // window.scrollTo(0, 0);
    e.preventDefault();
    const sendData = {
      name: submit.name,
      phone: submit.phone,
      message: submit.message,
      email: submit.email,
    };
    const email = await axios
      .post(`${configData.SERVER_URL}/sendEmail/question`, sendData)
      .then(
        (res) => {
          setQuestionMessage(true);
          return res.data.message;
        },
        (err) => {
          setQuestionMessage(false);
          console.log(err);
          return err;
        }
      );
    setLoader(false);
  };

  const sendApplication = async (submit, e) => {
    setLoader(true);
    // window.scrollTo(0, 0);
    e.preventDefault();
    const sendData = {
      application: submit.application,
      name: submit.req_name,
      phone: submit.req_phone,
      email: submit.req_email,
      stadium: submit.req_stadium,
      prop_type: submit.req_type,
      num_cards: submit.req_num,
    };
    const email = await axios
      .post(`${configData.SERVER_URL}/sendEmail/application`, sendData)
      .then(
        (res) => {
          setAplicationMessage(true);
          return res.data.message;
        },
        (err) => {
          setAplicationMessage(false);
          console.log(err);
          return err;
        }
      );
    setLoader(false);
  };

  // if (loader) {
  //   return <Loader />;
  // } else {
  return (
    <span>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/front-end-palcosplus.webflow.css);


              html {
                -webkit-tap-highlight-color: rgba(0,0,0,0);
              }
              textarea {
                resize: vertical;
            }
        `,
        }}
      />
      <span className="af-view">
        <div>
          <div className="af-class-gen-herosection">
            <h1 className="af-class-gen-pagetitle">
              ¿Dudas, preguntas? Estás en el lugar correcto
            </h1>
            <div
              data-poster-url="videos/topPage_soporte_comp-poster-00001.jpg"
              data-video-urls="videos/topPage_soporte_comp-transcode.mp4,videos/topPage_soporte_comp-transcode.webm"
              data-autoplay="true"
              data-loop="true"
              data-wf-ignore="true"
              className="af-class-gen-pagetitle-video w-background-video w-background-video-atom"
            >
              <video
                autoPlay
                loop
                style={{
                  backgroundImage:
                    'url("videos/topPage_soporte_comp-poster-00001.jpg")',
                }}
                muted
                playsInline
                data-wf-ignore="true"
                data-object-fit="cover"
              >
                <source
                  src="videos/topPage_soporte_comp-transcode.mp4"
                  data-wf-ignore="true"
                />
                <source
                  src="videos/topPage_soporte_comp-transcode.webm"
                  data-wf-ignore="true"
                />
              </video>
            </div>
          </div>
          <div className="af-class-gen-section">
            <div className="af-class-gen-container">
              <p className="af-class-gen-paragraph af-class-contact-introparagraph">
                Envíanos tus preguntas, en breve te las respondemos.
              </p>
              <div className="af-class-contact-form-wrapper">
                <div className="af-class-form-block w-form">
                  <form
                    onSubmit={handleSubmit(sendQuestions)}
                    id="wf-form-contact-form"
                    name="wf-form-contact-form"
                    data-name="contact-form"
                    className="af-class-form-wrapper"
                  >
                    <div className="af-class-form-content-wrapper">
                      <div className="af-class-form-field-wrapper">
                        <div className="af-class-form-label">Nombre *</div>
                        <input
                          ref={register}
                          type="text"
                          className="af-class-form-textinput w-input"
                          maxLength={256}
                          name="name"
                          data-name="name"
                          placeholder="....."
                          id="name"
                          required
                        />
                      </div>
                      <div className="af-class-form-field-wrapper">
                        <div className="af-class-form-label">Teléfono *</div>
                        <input
                          ref={register}
                          type="tel"
                          className="af-class-form-textinput w-input"
                          maxLength={256}
                          name="phone"
                          data-name="phone"
                          placeholder="....."
                          id="phone"
                          required
                        />
                      </div>
                      <div
                        id="w-node-bae848c2-526b-f079-4e2b-c3aa9b0d0959-934c38f1"
                        className="af-class-form-field-wrapper af-class-form-2rowsfield-wrapper"
                      >
                        <div className="af-class-form-label">Mensaje *</div>
                        <textarea
                          ref={register}
                          placeholder="....."
                          maxLength={5000}
                          id="message"
                          name="message"
                          data-name="message"
                          required
                          className="af-class-form-textboxinput w-input"
                          defaultValue={''}
                        />
                      </div>
                      <div className="af-class-form-field-wrapper">
                        <div className="af-class-form-label">
                          Correo electrónico *
                        </div>
                        <input
                          ref={register}
                          type="email"
                          className="af-class-form-textinput w-input"
                          maxLength={256}
                          name="email"
                          data-name="email"
                          placeholder="....."
                          id="email"
                          required
                        />
                      </div>
                      {loader === false ? (
                        <button
                          type="submit"
                          data-wait="....."
                          id="w-node-_98dfa31d-27d3-ed67-4c3a-7ed0f75fe1a9-934c38f1"
                          className="af-class-gen-button af-class-form-sendbutton w-button"
                        >
                          Enviar solicitud
                        </button>
                      ) : (
                        <div className="loading">
                          <Loader />
                        </div>
                      )}
                    </div>
                  </form>
                  {questionMessage === true && (
                    <div className="af-class-form-success-wrapper">
                      <img
                        src="images/bigIcon_questions_faqs.svg"
                        loading="lazy"
                        alt=""
                        className="af-class-form-success-icon af-class-form-success-contacticon"
                      />
                      <div>
                        <span className="af-class-form-success-boldtext">
                          ¡Gracias por tu mensaje!
                          <br />
                        </span>
                        Mantente atento a tu correo electrónico, nos pondremos
                        en contacto contigo para atender tus dudas.
                      </div>
                    </div>
                  )}
                  {questionMessage === false && (
                    <div className="af-class-form-error-wrapper">
                      <div>
                        Al parecer hay un error con el formulario, por favor
                        inténtalo de nuevo. Si los problemas persisten por favor
                        contáctanos directamente por correo electrónico.
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="af-class-gen-section af-class-deepblue">
            <div className="af-class-gen-container">
              <div className="af-class-contact-data-wrapper">
                <div className="af-class-contact-data-content">
                  <div className="af-class-gen-paragraph af-class-white af-class-contact-dataparagraph">
                    palcosplus@gmail.com
                  </div>
                  <div className="af-class-gen-paragraph af-class-white af-class-contact-dataparagraph">
                    3326569988
                  </div>
                  <div className="af-class-contact-datasocial-wrapper">
                    <div className="af-class-contact-data-sociallabel">
                      ¡Síguenos en redes sociales!
                    </div>
                    <a
                      href="https://www.facebook.com/palcosplus"
                      rel="noreferrer"
                      target="_blank"
                      className="af-class-contact-data-sociallink w-inline-block"
                    >
                      <img
                        src="images/Facebook-white.svg"
                        loading="lazy"
                        alt=""
                        className="af-class-contact-data-sociallogo"
                      />
                    </a>
                    <a
                      href="https://instagram.com/palcosplus?utm_medium=copy_link"
                      rel="noreferrer"
                      target="_blank"
                      className="af-class-contact-data-sociallink w-inline-block"
                    >
                      <img
                        src="images/Instagram-white.svg"
                        loading="lazy"
                        alt=""
                        className="af-class-contact-data-sociallogo"
                      />
                    </a>
                  </div>
                </div>
                <div
                  id="w-node-_64d1853a-94fc-ee8b-1505-412c0b8701e9-934c38f1"
                  className="af-class-contact-datatitle-wrapper"
                >
                  <h2 className="af-class-gen-sectiontitle af-class-white af-class-contact-datatitle af-class-gen-titleremark">
                    Contáctanos
                  </h2>
                  <h2 className="af-class-gen-sectiontitle af-class-white af-class-contact-datatitle">
                    cuando lo necesites
                  </h2>
                </div>
              </div>
            </div>
            <img
              src="images/triangleGraphicUp-white.svg"
              loading="lazy"
              alt=""
              className="af-class-gen-trianglegraphic af-class-lefttriangle"
            />
            <img
              src="images/triangleGraphicDown-white.svg"
              loading="lazy"
              alt=""
              className="af-class-gen-trianglegraphic"
            />
          </div>
          <div className="af-class-gen-section" id="application">
            <div className="af-class-gen-container">
              <div className="af-class-contact-rentinvite-title-wrapper">
                <h2 className="af-class-gen-sectiontitle af-class-contact-rentinvite-title">
                  Vuelve rentable tu propiedad, rápido y seguro
                </h2>
                <div
                  id="w-node-_268bbd1d-b10b-91bd-ee6f-8bc917eb40d8-934c38f1"
                  className="af-class-contact-rentinvite-graphics-wrapper"
                >
                  <img
                    src="images/bigIcon_rent_invite_form_s1_blue.svg"
                    loading="lazy"
                    alt=""
                    className="af-class-contact-rentinvite-graphic"
                  />
                  <img
                    src="images/rentInviteArrow-aquaGrass.svg"
                    loading="lazy"
                    alt=""
                    className="af-class-contact-rentinvite-triangle"
                  />
                  <img
                    src="images/bigIcon_rent_invite_form_s4_blue.svg"
                    loading="lazy"
                    alt=""
                    className="af-class-contact-rentinvite-graphic"
                  />
                </div>
              </div>
              <p className="af-class-gen-paragraph af-class-contact-introparagraph">
                Envíanos tu solicitud de renta o venta, nosotros te
                contactaremos para compartirte toda la información del proceso.
              </p>
              <div className="af-class-contact-form-wrapper">
                <div className="af-class-form-block w-form">
                  <form
                    onSubmit={handleSubmit(sendApplication)}
                    id="wf-form-request-form"
                    name="wf-form-request-form"
                    data-name="request-form"
                    className="af-class-form-wrapper"
                  >
                    <div className="af-class-form-content-wrapper">
                      <div className="af-class-form-field-wrapper">
                        <div className="af-class-form-label">
                          Tipo de solicitud *
                        </div>
                        <select
                          ref={register}
                          id="application"
                          name="application"
                          required
                          data-name="application"
                          className="af-class-form-selector w-select"
                        >
                          <option value>Seleccionar...</option>
                          <option value="Renta de propiedad">
                            Renta de propiedad
                          </option>
                          <option value="Venta de propiedad">
                            Venta de propiedad
                          </option>
                        </select>
                      </div>
                      <div className="af-class-form-field-wrapper">
                        <div className="af-class-form-label">
                          Nombre completo *
                        </div>
                        <input
                          ref={register}
                          type="text"
                          className="af-class-form-textinput w-input"
                          maxLength={256}
                          name="req_name"
                          data-name="name"
                          placeholder="....."
                          id="req_name"
                          required
                        />
                      </div>
                      <div className="af-class-form-field-wrapper">
                        <div className="af-class-form-label">Teléfono *</div>
                        <input
                          ref={register}
                          type="tel"
                          className="af-class-form-textinput w-input"
                          maxLength={256}
                          name="req_phone"
                          data-name="phone"
                          placeholder="....."
                          id="req_phone"
                          required
                        />
                      </div>
                      <div className="af-class-form-field-wrapper">
                        <div className="af-class-form-label">
                          Correo electrónico *
                        </div>
                        <input
                          ref={register}
                          type="email"
                          className="af-class-form-textinput w-input"
                          maxLength={256}
                          name="req_email"
                          data-name="email"
                          placeholder="....."
                          id="req_email"
                          required
                        />
                      </div>
                      <div className="af-class-form-field-wrapper">
                        <div className="af-class-form-label">Estadio *</div>
                        <input
                          ref={register}
                          type="text"
                          className="af-class-form-textinput w-input"
                          maxLength={256}
                          name="req_stadium"
                          data-name="stadium"
                          placeholder="....."
                          id="req_stadium"
                          required
                        />
                      </div>
                      <div className="af-class-form-field-wrapper">
                        <div className="af-class-form-label">
                          Tipo de propiedad *
                        </div>
                        <select
                          ref={register}
                          id="req_type"
                          name="req_type"
                          required
                          data-name="prop"
                          className="af-class-form-selector w-select"
                        >
                          <option value>Seleccionar...</option>
                          <option value="Palco">Palco</option>
                          <option value="Platea">Platea</option>
                          <option value="Butacas">Butacas</option>
                        </select>
                      </div>
                      <div className="af-class-form-field-wrapper">
                        <div className="af-class-form-label">
                          Número de tarjetas *
                        </div>
                        <input
                          ref={register}
                          type="number"
                          className="af-class-form-textinput w-input"
                          maxLength={256}
                          name="req_num"
                          data-name="pass-num"
                          placeholder="....."
                          id="req_num"
                          required
                        />
                      </div>
                      {loader === false ? (
                        <button
                          type="submit"
                          data-wait="....."
                          id="w-node-_5efd0dee-3a5a-c951-69ad-7f987e2b2671-934c38f1"
                          className="af-class-gen-button af-class-form-sendbutton w-button"
                        >
                          Enviar solicitud
                        </button>
                      ) : (
                        <div className="loading">
                          <Loader />
                        </div>
                      )}
                    </div>
                  </form>
                  {aplicationMessage === true && (
                    <div className="af-class-form-success-wrapper">
                      <img
                        src="images/title-confirmRent_blue.svg"
                        loading="lazy"
                        alt=""
                        className="af-class-form-success-icon"
                      />
                      <div>
                        <span className="af-class-form-success-boldtext">
                          ¡Tu solicitud ha sido enviada correctamente!
                          <br />
                        </span>
                        Mantente atento a tu correo electrónico, revisaremos tu
                        solicitud y nos pondremos en contacto contigo.
                      </div>
                    </div>
                  )}
                  {aplicationMessage === false && (
                    <div className="af-class-form-error-wrapper">
                      <div>
                        Al parecer hay un error con la solicitud, por favor
                        inténtalo de nuevo. Si los problemas persisten por favor
                        contáctanos directamente por correo electrónico.
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>
    </span>
  );
};
// };

export default ContactoView;

/* eslint-enable */
