import React, { forwardRef, useState, useEffect, FormEvent } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import TimeField from 'react-simple-timefield';
import Switch from 'react-switch';
import configData from 'config.json';

import Tables, { DataCell } from 'components/Tables';
import Label from 'components/Label';
import Input from 'components/Input';
import Button from 'components/Button';
import Home from 'components/Icons/Home';

const CreatePartido = forwardRef((props, ref) => {
  const [teams, setTeams] = useState([]);
  const [competitions, setCompetition] = useState([]);
  const [stadiums, setStadiums] = useState([]);
  const [isImportant, setIsImportant] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const history = useHistory();

  const redirect = () => {
    history.push('/createConcierto');
  };

  useEffect(() => {
    axios
      .get(`${configData.SERVER_URL}/teams`)
      .then((res) => {
        const teams = res.data;
        setTeams(teams.teams);
      })
      .catch((err) => {
        console.log(err);
      });
    axios.get(`${configData.SERVER_URL}/competition`).then((res) => {
      const competition = res.data;
      setCompetition(competition.competition);
    });
    axios.get(`${configData.SERVER_URL}/stadiums`).then((res) => {
      const stadium = res.data;
      setStadiums(stadium.stadium);
    });
  }, []);

  const { control, handleSubmit, register, formState, errors } = useForm();

  const onsubmit = async (data, e) => {
    try {
      e.preventDefault();
      const time = data.time.split(':');
      if (time[0].length === 3) {
        time[0] = time[0].slice(0, -1);
        data.time = time[0] + ':' + time[1];
      } else if (time[1].length === 3) {
        time[1] = time[1].slice(0, -1);
        data.time = time[0] + ':' + time[1];
      }
      const event = {
        type: data.type,
        home: data.local,
        visitor: data.visitante,
        date: data.date,
        time: data.time,
        jornada: data.jornada,
        competition_id: data.competition,
        stadium_id: data.stadium,
        isImportant: isImportant,
        active: isActive,
      };
      const res = await axios.post(`${configData.SERVER_URL}/events`, event);
      const response = res.data.event;
      history.push('/managerEventos');
      history.go(0);
    } catch (err) {
      console.log(err);
    }
  };

  const titleHeaders = ['Tarifa', 'Códigos postales'];

  function displayRows() {
    <tr className="">
      <DataCell className="px-4 py-2">Gratis</DataCell>
      <DataCell className="px-4 py-2">
        <Input
          name="type"
          input="text"
          className="w-full h-10 bg-gris-auxiliar"
          value={'45030, 45031, 45033, 45035, 45036'}
        />
      </DataCell>
    </tr>;
  }

  const getTeams = () => {
    return teams.map((team) => {
      return { value: team.id, label: team.name };
    });
  };

  const getStadium = () => {
    return stadiums.map((stadium) => {
      return { value: stadium.id, label: stadium.name };
    });
  };

  const getCompetition = () => {
    return competitions.map((competitions) => {
      return { value: competitions.id, label: competitions.name };
    });
  };

  return (
    <form className="w-full pt-28" onSubmit={handleSubmit(onsubmit)}>
      <div className="mx-auto pb-24" style={{ maxWidth: '1080px' }}>
        <div className="flex flex-col-reverse md:flex-row justify-between pb-10">
          <div className="flex flex-col">
            <div id="tracking" className="pb-4">
              <h3 className="text-gris-oscuro">
                Panel de Administrador / Eventos / Agregar Partido
              </h3>
            </div>
            <div id="title" className="pb-2">
              <h1 className="text-sport-blue font-bold text-5xl">
                Agregar Nuevo Partido
              </h1>
            </div>
          </div>
          <div className="flex flex-row pb-14 md:pb-0">
            <div className="">
              <Link to="/managerEventos">
                <Button className="w-40 h-14 bg-green-field rounded-lg shadow-sm hover:bg-green-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight ">
                  Listado
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="grid grid-flow-row pt-8 md:grid-cols-2 lg:grid-cols-3 gap-12">
          <div id="col1">
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Tipo de Evento</Label>
              </div>
              <Controller
                name="type"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={'partido'}
                render={({ onChange }) => (
                  <Input
                    name="type"
                    input="select"
                    type="text"
                    className="w-full h-10"
                    options={[
                      { value: 'partido', label: 'Partido' },
                      { value: 'concierto', label: 'General' },
                    ]}
                    onChange={() => redirect()}
                  />
                )}
              />
              {errors.type && (
                <span className="text-rojo-error">{errors.type.message}</span>
              )}
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Hora local</Label>
              </div>
              <Controller
                name="time"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={'00:00'}
                as={
                  <div className="flex relative w-full">
                    <TimeField
                      name="time"
                      style={{ width: '100%' }}
                      className=" h-10 border rounded-sm focus:outline-none focus:ring-2 focus:ring-gris-oscuro text-gris-oscuro bg-gris-auxiliar "
                    />
                  </div>
                }
              />
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Equipo Visitante</Label>
              </div>
              <Controller
                name="visitante"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={''}
                as={
                  <Input
                    name="visitante"
                    input="select"
                    type="text"
                    className="w-full h-10"
                    options={[
                      { value: '', label: 'Selecciona un equipo' },
                      ...getTeams(),
                    ]}
                  />
                }
              />
              {errors.visitante && (
                <span className="text-rojo-error">
                  {errors.visitante.message}
                </span>
              )}
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Jornada</Label>
              </div>
              <Controller
                name="jornada"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={''}
                as={
                  <Input
                    name="jornada"
                    input="input"
                    type="text"
                    className="w-full h-10"
                    placeholder="1"
                  />
                }
              />
              {errors.jornada && (
                <span className="text-rojo-error">
                  {errors.jornada.message}
                </span>
              )}
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Evento destacado</Label>
              </div>
              <Switch
                onColor={'#034AA6'}
                onChange={() => setIsImportant(!isImportant)}
                checked={isImportant}
                checkedIcon={false}
                uncheckedIcon={false}
              />
            </div>
          </div>
          <div id="col2">
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Fecha</Label>
              </div>
              <Controller
                name="date"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={''}
                as={
                  <Input
                    name="date"
                    input="input"
                    className="w-full h-10"
                    type="date"
                  />
                }
              />
              {errors.date && (
                <span className="text-rojo-error">{errors.date.message}</span>
              )}
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Equipo Local</Label>
              </div>
              <Controller
                name="local"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={''}
                as={
                  <Input
                    name="local"
                    input="select"
                    type="text"
                    className="w-full h-10"
                    options={[
                      { value: '', label: 'Selecciona un equipo' },
                      ...getTeams(),
                    ]}
                  />
                }
              />
              {errors.local && (
                <span className="text-rojo-error">{errors.local.message}</span>
              )}
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Competición</Label>
              </div>
              <Controller
                name="competition"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={''}
                as={
                  <Input
                    name="competition"
                    input="select"
                    type="text"
                    className="w-full h-10"
                    options={[
                      { value: '', label: 'Selecciona un estadio' },
                      ...getCompetition(),
                    ]}
                  />
                }
              />
              {errors.competition && (
                <span className="text-rojo-error">
                  {errors.competition.message}
                </span>
              )}
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Estadio</Label>
              </div>
              <Controller
                name="stadium"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={''}
                as={
                  <Input
                    name="stadium"
                    input="select"
                    type="text"
                    className="w-full h-10"
                    options={[
                      { value: '', label: 'Selecciona un estadio' },
                      ...getStadium(),
                    ]}
                  />
                }
              />
              {errors.stadium && (
                <span className="text-rojo-error">
                  {errors.stadium.message}
                </span>
              )}
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Activo</Label>
              </div>
              <Switch
                onColor={'#034AA6'}
                onChange={() => setIsActive(!isActive)}
                checked={isActive}
                checkedIcon={false}
                uncheckedIcon={false}
              />
            </div>
          </div>
        </div>
        {/* <div className="pb-24 pt-10">
          <h1 className="text-sport-blue font-bold text-4xl">
            Disponibilidad de Propiedades
          </h1>
          <div className="pt-2 pb-5" style={{ width: "750px" }}>
            <Tables idheader={0} classHead="w-36" headers={titleHeaders}>
              <tr>
                <DataCell className="px-4 py-2">Butacas</DataCell>
                <DataCell className="px-4 py-2">
                  <Input
                    name="butacas"
                    input="text"
                    className="w-full h-10 bg-gris-auxiliar"
                  />
                </DataCell>
              </tr>
              <tr>
                <DataCell className="px-4 py-2">Plateas</DataCell>
                <DataCell className="px-4 py-2">
                  <Input
                    name="plateas"
                    input="text"
                    className="w-full h-10 bg-gris-auxiliar"
                  />
                </DataCell>
              </tr>
              <tr>
                <DataCell className="px-4 py-2">Palcos</DataCell>
                <DataCell className="px-4 py-2">
                  <Input
                    name="palcos"
                    input="text"
                    className="w-full h-10 bg-gris-auxiliar"
                  />
                </DataCell>
              </tr>
            </Tables>
          </div>
        </div> */}
        {/* <div className="pb-24">
          <h1 className="text-sport-blue font-bold text-4xl">
            Registro de Actividad
          </h1>
          <div className="w-3/4 grid grid-flow-row grid-cols-2">
            <div className="flex flex-col">
              <h4 className="text-ocean-black font-bold text-xl pt-4">
                Fecha de creacion
              </h4>
              <p>18/09/20 - 15:00</p>
            </div>
            <div className="flex flex-col">
              <h4 className="text-ocean-black font-bold text-xl pt-4">
                Fecha de última modificacion
              </h4>
              <p>27/12/20 - 10:00</p>
            </div>
          </div>
        </div> */}
        <div className="flex flex-row">
          <div className="pr-5">
            <Button
              type="submit"
              className="w-44 h-14 font-bold text-xl text-ocean-black bg-green-field rounded-lg shadow-sm hover:bg-green-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight "
            >
              GUARDAR
            </Button>
          </div>
          <div className="pr-5">
            <Button
              onClick={() => history.goBack()}
              className="w-44 h-14 font-bold text-xl text-white bg-aqua-grass rounded-lg shadow-sm hover:bg-aqua-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight "
              type="button"
            >
              CANCELAR
            </Button>
          </div>
          {/* <div className="pr-5">
            <Button className="w-44 h-14 font-bold text-xl text-white bg-aqua-grass rounded-lg shadow-sm hover:bg-aqua-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight ">
              ELIMINAR
            </Button>
          </div> */}
        </div>
      </div>
    </form>
  );
});

export default CreatePartido;
