import { DialogOverlay, DialogContent } from '@reach/dialog';
import React, { useState, useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import axios from 'axios';
import ModalCreateUser from './ModalCreateUser';
import '@reach/dialog/styles.css';
import '../App.css';
import { AuthContext } from './auth-context';
import configData from 'config.json';

const ModalInicioSesion = ({ isOpen, onDimiss }) => {
  const auth = useContext(AuthContext);
  const [dimensions, setDimensions] = useState(window.innerWidth);
  const [widthstyle, setWidthstyle] = useState();
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const router = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const { handleSubmit, register, control, formState, errors, watch } = useForm(
    {
      mode: 'all',
    }
  );

  const login = async (user) => {
    await axios.post(`${configData.SERVER_URL}/user/login`, user).then(
      (res) => {
        auth.login(res.data.userId, res.data.token, res.data.role);
        if (error === true) {
          setError(false);
        }
        onDimiss();
        return res.data;
      },
      (err) => {
        setError(true);
        return err;
      }
    );
  };

  const onsubmit = (data, e) => {
    e.preventDefault();
    login(data);
  };

  const signup = async (res, origin, type) => {
    try {
      let userData;
      if (origin === 'Facebook') {
        userData = {
          name: res.name,
          email: res.email,
          password: res.id,
          imageUrl: res.picture.data.url,
          origin: 'facebook',
        };
      } else if (origin === 'Google') {
        userData = {
          name: res.profileObj.givenName,
          last_name: res.profileObj.familyName,
          email: res.profileObj.email,
          password: res.googleId,
          imageUrl: res.profileObj.imageUrl,
          origin: 'google',
        };
      }
      const response = await axios.post(
        `${configData.SERVER_URL}/user/signup`,
        userData
      );
      auth.login(response.data.userId, response.data.token);
      onDimiss();
    } catch (err) {
      console.log(err);
    }
  };

  const signupFacebook = (response) => {
    signup(response, 'Facebook', 'signup');
  };

  const signupGoogle = (response) => {
    signup(response, 'Google', 'signup');
  };

  const loginFacebook = (res) => {
    login({ email: res.email, password: res.id, origin: 'facebook' });
  };

  const loginGoogle = (res) => {
    login({
      email: res.profileObj.email,
      password: res.googleId,
      origin: 'google',
    });
  };

  useEffect(() => {
    function handleResize() {
      setDimensions(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  });

  useEffect(() => {
    if (dimensions > 768) {
      setWidthstyle('50%');
    } else if (dimensions > 591) {
      setWidthstyle('70%');
    } else {
      setWidthstyle('90%');
    }
  }, [dimensions]);

  return (
    <DialogOverlay
      aria-label="modal"
      aria-labelledby="dialog-title"
      isOpen={isOpen}
      onDismiss={onDimiss}
      className="z-40 border"
    >
      <DialogContent
        className="container rounded-lg"
        aria-labelledby="dialog-content"
        style={{ width: widthstyle }}
      >
        <div className="af-view">
          <form onSubmit={handleSubmit(onsubmit)}>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <label className="text-deep-blue">Correo Electrónico</label>
              </div>
              <Controller
                name="email"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={''}
                as={
                  <input
                    name="email"
                    className="af-class-form-textinput w-input"
                    type="email"
                  />
                }
              />
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <label className="text-deep-blue">Contraseña</label>
              </div>
              <Controller
                name="password"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={''}
                as={
                  <input
                    name="password"
                    className="af-class-form-textinput w-input"
                    type={showPassword ? 'text' : 'password'}
                  />
                }
              />
              <div className="pt-4">
                <input
                  type="checkbox"
                  id="show_password"
                  name="show_password"
                  className="w-checkbox-input w-checkbox-input--inputType-custom af-class-form-checkbox-box"
                  onClick={() => setShowPassword(!showPassword)}
                />{' '}
                <span className="af-class-form-checkbox-label">
                  Mostrar contraseña
                </span>
              </div>
              {error && (
                <div className="af-class-form-error-wrapper">
                  <div>
                    Usuario o contraseña incorrectos, favor de volverlo a
                    intentar.
                  </div>
                </div>
              )}
            </div>
            <button
              className="af-class-gen-button h-14 bg-green-field shadow-sm hover:bg-green-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight font-bold text-lg"
              style={{ width: '100%' }}
            >
              INICIAR SESIÓN
            </button>
          </form>
          <div className="pt-8">
            <div className="pb-4">
              <GoogleLogin
                render={(renderProps) => (
                  <div
                    className="af-class-gen-button af-class-google"
                    onClick={renderProps.onClick}
                  >
                    <img
                      src="images/GoogleIcon.svg"
                      loading="lazy"
                      alt=""
                      className="af-class-login-ext-icon"
                    />
                    <div>acceder con Google</div>
                  </div>
                )}
                clientId="178671465892-g1gm7kpl5o68on47ih6t3gm3mvhuoe3b.apps.googleusercontent.com"
                buttonText="INGRESAR CON GOOGLE"
                onSuccess={loginGoogle}
                onFailure={loginGoogle}
                cookiePolicy={'single_host_origin'}
              />
            </div>
            <div>
              <FacebookLogin
                render={(renderProps) => (
                  <div
                    className="af-class-gen-button af-class-facebook"
                    onClick={renderProps.onClick}
                  >
                    <img
                      src="images/FacebookRound-white.svg"
                      loading="lazy"
                      alt=""
                      className="af-class-login-ext-icon"
                    />
                    <div>acceder con Facebook</div>
                  </div>
                )}
                appId="365324594994729"
                autoLoad={false}
                fields="name,email,picture"
                callback={loginFacebook}
              />
            </div>
          </div>
          <div className="container rounded-lg md:w-auto pt-8 pb-3 text-lg font-bold ">
            Soy nuevo, no estoy registrado...
          </div>
          <div className="block text-sm font-medium">
            <button
              onClick={() => setOpenModal(true)}
              className="af-class-gen-button w-full h-14 bg-green-field shadow-sm hover:bg-green-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight font-bold text-lg"
              style={{ width: '100%' }}
            >
              REGISTRARME
            </button>
            <div className="pt-8">
              <div className="pb-4">
                <GoogleLogin
                  render={(renderProps) => (
                    <div
                      className="af-class-gen-button af-class-google"
                      onClick={renderProps.onClick}
                    >
                      <img
                        src="images/GoogleIcon.svg"
                        loading="lazy"
                        alt=""
                        className="af-class-login-ext-icon"
                      />
                      <div>Registrarme con Google</div>
                    </div>
                  )}
                  clientId="178671465892-g1gm7kpl5o68on47ih6t3gm3mvhuoe3b.apps.googleusercontent.com"
                  buttonText="INGRESAR CON GOOGLE"
                  onSuccess={signupGoogle}
                  onFailure={signupGoogle}
                  cookiePolicy={'single_host_origin'}
                />
              </div>
              <div>
                <FacebookLogin
                  render={(renderProps) => (
                    <div
                      className="af-class-gen-button af-class-facebook"
                      onClick={renderProps.onClick}
                    >
                      <img
                        src="images/FacebookRound-white.svg"
                        loading="lazy"
                        alt=""
                        className="af-class-login-ext-icon"
                      />
                      <div>Registrarme con Facebook</div>
                    </div>
                  )}
                  appId="365324594994729"
                  autoLoad={false}
                  fields="name,email,picture"
                  callback={signupFacebook}
                />
              </div>
            </div>
          </div>
        </div>
        {openModal && (
          <ModalCreateUser openModal={openModal} onDimiss={() => onDimiss()} />
        )}
      </DialogContent>
    </DialogOverlay>
  );
};

export default ModalInicioSesion;
