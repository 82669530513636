import Home from 'views/Home';
import EstadiosView from 'views/EstadiosView';
import PagEstadioView from 'views/PagEstadioView';
import PagEventoView from 'views/PagEventoView';
import Panel from 'views/Administrator/panel';
import ManagerUsers from 'views/Administrator/users/ManagerUsers';
import ManagerManual from 'views/Administrator/manual/ManagerManual';
import CreateManual from 'views/Administrator/manual/CreateManual';
import ManagerPropiedades from 'views/Administrator/managerPropiedades';
import ManagerEstadio from 'views/Administrator/estadios/ManagerEstadio';
import UpdateEstadio from 'views/Administrator/estadios/UpdateEstadio';
import CreateEstadio from 'views/Administrator/estadios/CreateEstadio';
import ManagerEventos from 'views/Administrator/eventos/ManagerEventos';
import CreatePartido from 'views/Administrator/eventos/partidos/CreatePartido';
import UpdatePartido from 'views/Administrator/eventos/partidos/UpdatePartido';
import CreateConcierto from 'views/Administrator/eventos/concierto/CreateConcierto';
import UpdateConcierto from 'views/Administrator/eventos/concierto/UpdateConcierto';
import CreatePalco from 'views/Administrator/palcos/CreatePalco';
import UpdatePalco from 'views/Administrator/palcos/UpdatePalco';
import ManagerPalco from 'views/Administrator/palcos/ManagerPalco';
import ManagerEquipos from 'views/Administrator/equipos/ManagerEquipos';
import ActiveReservation from 'views/Administrator/reservations/ActiveReservation';
import AllReservation from 'views/Administrator/reservations/AllReservations';
import CreateEquipo from 'views/Administrator/equipos/CreateEquipo';
import UpdateEquipo from 'views/Administrator/equipos/UpdateEquipo';
import ManagerCompetencias from 'views/Administrator/competencias/ManagerCompetencias';
import CreateCompetencia from 'views/Administrator/competencias/CreateCompetencia';
import UpdateCompetencia from 'views/Administrator/competencias/UpdateCompetencia';
import PagPropiedadView from 'views/PagPropiedadView';
import CreacionCuentaView from 'views/CreacionCuentaView';
import CheckoutPopupsView from 'views/CheckoutPopupsView';
import CheckoutView from 'views/CheckoutView';
import ConfirmacionView from 'views/ConfirmacionView';
import CarritoView from 'views/CarritoView';
import NosotrosView from 'views/NosotrosView';
import ContactoView from 'views/ContactoView';
import PreguntasView from 'views/PreguntasView';

const Routes = [
  {
    exact: true,
    path: '/nosotros',
    name: 'NosotrosView',
    component: NosotrosView,
    admin: false,
  },
  {
    exact: true,
    path: '/contacto',
    name: 'ContactoView',
    component: ContactoView,
    admin: false,
  },
  {
    exact: true,
    path: '/faqs',
    name: 'PreguntasView',
    component: PreguntasView,
    admin: false,
  },
  {
    exact: true,
    path: '/carrito',
    name: 'CarritoView',
    component: CarritoView,
    admin: false,
  },
  {
    exact: true,
    path: '/confirmacion/:id',
    name: 'ConfirmacionView',
    component: ConfirmacionView,
    admin: false,
  },
  {
    exact: true,
    path: '/checkout/:propiedad/:event',
    name: 'CheckoutView',
    component: CheckoutView,
    admin: false,
  },
  {
    exact: true,
    path: '/popup',
    name: 'CheckoutPopupsView',
    component: CheckoutPopupsView,
    admin: false,
  },
  {
    exact: true,
    path: '/',
    name: 'Home',
    component: Home,
    admin: false,
  },
  {
    exact: true,
    path: '/estadios',
    name: 'EstadiosView',
    component: EstadiosView,
    admin: false,
  },
  {
    exact: true,
    path: '/estadio/:id',
    name: 'PagEstadioView',
    component: PagEstadioView,
    admin: false,
  },
  {
    exact: true,
    path: '/evento/:id',
    name: 'PagEventoView',
    component: PagEventoView,
    admin: false,
  },
  {
    exact: true,
    path: '/propiedad/:id/:event',
    name: 'PagPropiedadView',
    component: PagPropiedadView,
    admin: false,
  },
  {
    exact: true,
    path: '/createUser',
    name: 'CreacionCuentaView',
    component: CreacionCuentaView,
    admin: false,
  },
  {
    exact: true,
    path: '/managerEstadio',
    name: 'ManagerEstadio',
    component: ManagerEstadio,
    admin: true,
  },
  {
    exact: true,
    path: '/createEstadio',
    name: 'CreateEstadio',
    component: CreateEstadio,
    admin: true,
  },
  {
    exact: true,
    path: '/updateEstadio/:id',
    name: 'UpdateEstadio',
    component: UpdateEstadio,
    admin: true,
  },
  {
    exact: true,
    path: '/managerEventos',
    name: 'ManagerEventos',
    component: ManagerEventos,
    admin: true,
  },
  {
    exact: true,
    path: '/createPartido',
    name: 'CreatePartido',
    component: CreatePartido,
    admin: true,
  },
  {
    exact: true,
    path: '/updatePartido/:id',
    name: 'UpdatePartido',
    component: UpdatePartido,
    admin: true,
  },
  {
    exact: true,
    path: '/createConcierto',
    name: 'CreateConcierto',
    component: CreateConcierto,
    admin: true,
  },
  {
    exact: true,
    path: '/updateConcierto/:id',
    name: 'UpdateConcierto',
    component: UpdateConcierto,
    admin: true,
  },
  {
    exact: true,
    path: '/createPalco',
    name: 'CreatePalco',
    component: CreatePalco,
    admin: true,
  },
  {
    exact: true,
    path: '/updatePalco/:id',
    name: 'UpdatePalco',
    component: UpdatePalco,
    admin: true,
  },
  {
    exact: true,
    path: '/managerPalco',
    name: 'ManagerPalco',
    component: ManagerPalco,
    admin: true,
  },
  {
    exact: true,
    path: '/managerEquipos',
    name: 'ManagerEquipos',
    component: ManagerEquipos,
    admin: true,
  },
  {
    exact: true,
    path: '/createEquipo',
    name: 'CreateEquipo',
    component: CreateEquipo,
    admin: true,
  },
  {
    exact: true,
    path: '/updateEquipo/:id',
    name: 'UpdateEquipo',
    component: UpdateEquipo,
    admin: true,
  },
  {
    exact: true,
    path: '/managerCompetencias',
    name: 'ManagerCompetencias',
    component: ManagerCompetencias,
    admin: true,
  },
  {
    exact: true,
    path: '/createCompetencia',
    name: 'CreateCompetencia',
    component: CreateCompetencia,
    admin: true,
  },

  {
    exact: true,
    path: '/updateCompetencia/:id',
    name: 'UpdateCompetencia',
    component: UpdateCompetencia,
    admin: true,
  },
  {
    exact: true,
    path: '/panel',
    name: 'Panel',
    component: Panel,
    admin: true,
  },
  {
    exact: true,
    path: '/managerUsers',
    name: 'ManagerUsers',
    component: ManagerUsers,
    admin: true,
  },
  {
    exact: true,
    path: '/managerManual',
    name: 'ManagerManual',
    component: ManagerManual,
    admin: true,
  },
  {
    exact: true,
    path: '/createManual',
    name: 'CreateManual',
    component: CreateManual,
    admin: true,
  },
  {
    exact: true,
    path: '/managerPropiedades',
    name: 'ManagerPropiedades',
    component: ManagerPropiedades,
    admin: true,
  },
  {
    exact: true,
    path: '/activeReservation',
    name: 'ActiveReservation',
    component: ActiveReservation,
    admin: true,
  },
  {
    exact: true,
    path: '/allReservation',
    name: 'AllReservation',
    component: AllReservation,
    admin: true,
  },
];

export default Routes;
