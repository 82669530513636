import React, {useState, useCallback} from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import Routes from "./routes/index.js";
import Layout from "./components/Layout";
import NavbarView from "views/NavbarView.js";
import { AuthContext } from "views/auth-context.js";
import { useAuth } from "views/auth-hook.js";
import ScrollToTop from "components/ScrollToTop.js";
import Footer from "views/Footer.js";

const AppRoutes = () => {
    return Routes.map((route) => {
        return (
        <Route key={route.path} path={route.path} exact={route.exact}>
          <Layout>
              <route.component
                exact={route.exact}
                key={route.path}
                component={route.component}
                path={route.path}
              />          
          </Layout>
        </Route>
      )
    })
};

function App() {
  const { isLoggedIn, userId, login, logout, role, token } = useAuth();

  return (
    <AuthContext.Provider
      value={{
        role: role,
        isLoggedIn: isLoggedIn,
        token: token,
        userId: userId,
        login: login,
        logout: logout
      }}
    >
      <BrowserRouter>
          <NavbarView className="z-50" />
          <ScrollToTop />
          <Switch>
            {AppRoutes()}
            <Redirect to="/" />
          </Switch>
          <Footer />
      </BrowserRouter>
    </AuthContext.Provider>
  );
}

export default App;
