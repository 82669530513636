import React, { useState, useEffect } from 'react';
import Loader from 'components/Loader';
import { Link } from 'react-router-dom';

const PreguntasView = () => {
  const [loader, setLoader] = useState(true);
  const [section, setSection] = useState(1);
  useEffect(() => {
    setLoader(false);
  }, []);

  if (!loader) {
    return (
      <span>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/front-end-palcosplus.webflow.css);

          @media (max-width:991px) and (min-width:768px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"f33c7ba5-e908-c0af-9f07-9789a2c9b627\"] {-webkit-transform:translate3d(-350px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(-350px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(-350px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(-350px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);}}@media (max-width:767px) and (min-width:480px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"f33c7ba5-e908-c0af-9f07-9789a2c9b627\"] {-webkit-transform:translate3d(-350px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(-350px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(-350px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(-350px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);}}@media (max-width:479px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"f33c7ba5-e908-c0af-9f07-9789a2c9b627\"] {-webkit-transform:translate3d(-270px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(-270px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(-270px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(-270px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);}}


            html {
              -webkit-tap-highlight-color: rgba(0,0,0,0);
            }
            textarea {
              resize: vertical;
            }
            .w-lightbox-backdrop {
              background: rgba(0,1,13,.85);
            }
        `,
          }}
        />
        <span className="af-view">
          <div>
            <div className="af-class-gen-herosection">
              <h1 className="af-class-gen-pagetitle">
                Encuentra solución a tus preguntas más frecuentes
              </h1>
              <div
                data-poster-url="videos/topPage_FAQs_comp-poster-00001.jpg"
                data-video-urls="videos/topPage_FAQs_comp-transcode.mp4,videos/topPage_FAQs_comp-transcode.webm"
                data-autoplay="true"
                data-loop="true"
                data-wf-ignore="true"
                className="af-class-gen-pagetitle-video w-background-video w-background-video-atom"
              >
                <video
                  autoPlay
                  loop
                  style={{
                    backgroundImage:
                      'url("videos/topPage_FAQs_comp-poster-00001.jpg")',
                  }}
                  muted
                  playsInline
                  data-wf-ignore="true"
                  data-object-fit="cover"
                >
                  <source
                    src={
                      window.location.origin +
                      '/videos/topPage_FAQs_comp-transcode.mp4'
                    }
                    data-wf-ignore="true"
                  />
                  <source
                    src={
                      window.location.origin +
                      '/videos/topPage_FAQs_comp-transcode.webm'
                    }
                    data-wf-ignore="true"
                  />
                </video>
              </div>
            </div>
            <div className="af-class-gen-section af-class-cat-section">
              <div className="af-class-gen-container af-class-faq-container">
                <div className="af-class-cat-wrapper">
                  <div
                    data-w-id="f33c7ba5-e908-c0af-9f07-9789a2c9b627"
                    className="af-class-cat-filterstab"
                  >
                    <div className="af-class-cat-filterstab-contentcolumn">
                      <div className="af-class-faq-index-wrapper">
                        <h2 className="af-class-faq-index-title">
                          Índice de contenido
                        </h2>
                        <div className="af-class-faq-index-list">
                          <div className="af-class-faq-index-link af-class-faq-index-link-active">
                            Preguntas frecuentes
                          </div>
                          <div className="af-class-faq-index-link">
                            Clientes
                          </div>
                          <div className="af-class-faq-index-link">
                            Propietarios
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      data-w-id="f33c7ba5-e908-c0af-9f07-9789a2c9b63d"
                      className="af-class-cat-filtersmobbutton"
                    >
                      <img
                        src={
                          window.location.origin + '/images/bothArrow-gray.svg'
                        }
                        loading="lazy"
                        alt=""
                        className="af-class-cat-filtersmobbutton-icon"
                      />
                      <div className="af-class-cat-filtersmobbutton-label">
                        ÍNDICE DE CONTENIDO
                      </div>
                    </div>
                  </div>
                  <div className="af-class-cat-content-wrapper">
                    <div className="af-class-cat-left-column">
                      <div className="af-class-cat-filters-column">
                        <div className="af-class-faq-index-wrapper">
                          <h2 className="af-class-faq-index-title">
                            Índice de contenido
                          </h2>
                          <div className="af-class-faq-index-list">
                            <div
                              className={
                                'af-class-faq-index-link ' +
                                (section === 1 &&
                                  'af-class-faq-index-link-active')
                              }
                              onClick={() => setSection(1)}
                            >
                              Preguntas frecuentes
                            </div>
                            <div
                              className={
                                'af-class-faq-index-link ' +
                                (section === 2 &&
                                  'af-class-faq-index-link-active')
                              }
                              onClick={() => setSection(2)}
                            >
                              Clientes
                            </div>
                            <div
                              className={
                                'af-class-faq-index-link ' +
                                (section === 3 &&
                                  'af-class-faq-index-link-active')
                              }
                              onClick={() => setSection(3)}
                            >
                              Propietarios
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="af-class-cat-right-column">
                      <div
                        data-duration-in={500}
                        data-duration-out={500}
                        className="af-class-faq-pages w-tabs"
                      >
                        <div className="af-class-faq-tabs-menu w-tab-menu">
                          <a
                            data-w-tab="Sección 1"
                            className="w-inline-block w-tab-link"
                          >
                            <div>Preguntas frecuentes</div>
                          </a>
                          <a
                            data-w-tab="Sección 2"
                            className="w-inline-block w-tab-link"
                          >
                            <div>Clientes</div>
                          </a>
                          <a
                            data-w-tab="Sección 3"
                            className="w-inline-block w-tab-link"
                          >
                            <div>Propietarios</div>
                          </a>
                        </div>
                        <div className="w-tab-content">
                          <div
                            id="sec1"
                            data-w-tab="Sección 1"
                            className={
                              section === 1 ? ' w--tab-active' : 'w-tab-pane'
                            }
                          >
                            <div className="af-class-faq-section-page">
                              <h1 className="af-class-gen-sectiontitle af-class-faq-section-title">
                                Preguntas frecuentes
                              </h1>
                              <div className="af-class-faq-questions-list">
                                <div className="af-class-faq-question-wrapper">
                                  <div className="af-class-form-sectiontitle af-class-faq-question">
                                    ¿Quiénes somos?
                                  </div>
                                  <p
                                    className="af-class-gen-paragraph"
                                    style={{ textAlign: 'justify' }}
                                  >
                                    Palcosplus es una plataforma digital
                                    exclusiva que te permite rentar palcos para
                                    eventos deportivos, musicales, culturales en
                                    tu ciudad.
                                  </p>
                                </div>
                                <div className="af-class-faq-question-wrapper text-justify">
                                  <div className="af-class-form-sectiontitle af-class-faq-question">
                                    ¿Qué beneficios obtienes con nosotros?
                                  </div>
                                  <p
                                    className="af-class-gen-paragraph"
                                    style={{ textAlign: 'justify' }}
                                  >
                                    En caso de ser dueño, Palcosplus te acercará
                                    a los clientes potenciales para tu
                                    propiedad, si tú no asistes a todos los
                                    partidos y eventos, dar de alta tu propiedad
                                    con nosotros te permite generar rendimiento
                                    para aquellas fechas que tú no lo utilices.
                                  </p>
                                </div>
                                <div className="af-class-faq-question-wrapper">
                                  <div className="af-class-form-sectiontitle af-class-faq-question">
                                    ¿Cómo registrar mi propiedad?
                                  </div>
                                  <p
                                    className="af-class-gen-paragraph"
                                    style={{ textAlign: 'justify' }}
                                  >
                                    Envía un correo electrónico a
                                    palcosplus@gmail.com con el asunto: QUIERO
                                    REGISTRARME, necesitas tener a la mano los
                                    siguientes documentos:
                                    <ul>
                                      <li>
                                        - ¿Qué propiedad tienes y el número?
                                        (Palco o butaca){' '}
                                      </li>
                                      <li>- ¿En qué estadio? </li>
                                      <li>
                                        - ¿En qué zona del estadio está ubicada?{' '}
                                      </li>
                                      <li>
                                        - ¿Cuántos boletos tienes disponible?{' '}
                                      </li>
                                      <li>- Nombre del propietario </li>
                                      <li>
                                        - Amenidades: Refrigerador, sala,
                                        cocina, baño, televisión, otro.{' '}
                                      </li>
                                      <li>
                                        - ¿Qué modalidad te interesa: por evento
                                        o por temporada?
                                      </li>
                                      <li>
                                        - Fotografías de la propiedad, en caso
                                        de no contar con ellas uno de nuestros
                                        agentes puede acudir al palco a
                                        conseguirlas
                                      </li>
                                    </ul>
                                  </p>
                                </div>
                                <div className="af-class-faq-question-wrapper">
                                  <div className="af-class-form-sectiontitle af-class-faq-question">
                                    ¿Cómo rento mi propiedad?
                                  </div>
                                  <p
                                    className="af-class-gen-paragraph"
                                    style={{ textAlign: 'justify' }}
                                  >
                                    Envíanos un correo solicitando los
                                    requisitos, en la mayor brevedad posible uno
                                    de nuestros agentes se pondrá en contacto
                                    contigo para recabar la información
                                    necesaria.
                                  </p>
                                </div>
                                <div className="af-class-faq-question-wrapper">
                                  <div className="af-class-form-sectiontitle af-class-faq-question">
                                    ¿Qué documentos se necesitan para registrar
                                    mi propiedad?
                                  </div>
                                  <p
                                    className="af-class-gen-paragraph"
                                    style={{ textAlign: 'justify' }}
                                  >
                                    - Título de propiedad de tu <br />
                                    - Comprobante de domiclio <br />
                                    - Estado de cuenta de banco con clabe <br />
                                  </p>
                                </div>
                                <div className="af-class-faq-question-wrapper">
                                  <div className="af-class-form-sectiontitle af-class-faq-question">
                                    Facturación
                                  </div>
                                  <p
                                    className="af-class-gen-paragraph"
                                    style={{ textAlign: 'justify' }}
                                  >
                                    Si necesitas factura, para obtenerla deberás
                                    enviar un correo electrónico a
                                    facturacion@palcosplus.com con tus datos. En
                                    Palcosplus emitiremos una factura por
                                    nuestros servicios y el dueño del palco
                                    emite el monto de la renta, así que antes de
                                    rentar, asegúrate que esa opción sea de
                                    facturación completa.
                                  </p>
                                </div>
                                <div className="af-class-faq-question-wrapper">
                                  <div className="af-class-form-sectiontitle af-class-faq-question">
                                    Atención al cliente
                                  </div>
                                  <p
                                    className="af-class-gen-paragraph"
                                    style={{ textAlign: 'justify' }}
                                  >
                                    ¡Nos gustaría mucho saber tu opinión! Puedes
                                    escribirnos tus dudas, comentarios,
                                    aclaraciones y agradecimientos al correo
                                    contacto@palcosplus.com, vía WhatsApp a
                                    nuestro número 33-2656-9988 y también
                                    mediante nuestra caja de dudas en la página
                                    web.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            id="sec2"
                            data-w-tab="Sección 2"
                            className={
                              section === 2 ? ' w--tab-active' : 'w-tab-pane'
                            }
                          >
                            <div className="af-class-faq-section-page">
                              <h1 className="af-class-gen-sectiontitle af-class-faq-section-title">
                                Clientes
                              </h1>
                              <div className="af-class-faq-questions-list">
                                <div className="af-class-faq-question-wrapper">
                                  <div className="af-class-form-sectiontitle af-class-faq-question">
                                    ¿Cómo rento una propiedad?
                                  </div>
                                  <p
                                    className="af-class-gen-paragraph"
                                    style={{ textAlign: 'justify' }}
                                  >
                                    Regístrate en nuestro sitio con tu correo
                                    electrónico para generar un usuario. Elige
                                    el partido o evento al que deseas asistir y
                                    la página te arrojará los resultados de
                                    lugares disponibles, selecciona el mejor
                                    para ti, da clik en RENTAR y llena los
                                    campos requeridos.
                                    <br />
                                    ¡Así de fácil y rápido puedes asegurar tus
                                    lugares!
                                  </p>
                                </div>
                                <div className="af-class-faq-question-wrapper">
                                  <div className="af-class-form-sectiontitle af-class-faq-question">
                                    ¿Cuál es la protección de renta?
                                  </div>
                                  <p
                                    className="af-class-gen-paragraph"
                                    style={{ textAlign: 'justify' }}
                                  >
                                    Tu pago se realiza a través del sistema de
                                    pago Stripe, plataforma que permite agilizar
                                    el proceso de compra de los clientes con
                                    métodos de pago electrónico; además
                                    Palcosplus te garantiza los accesos
                                    oficiales y si se presenta alguna situación
                                    con el pago, en Palcosplus nos hacemos
                                    responsables de solucionarlo.
                                  </p>
                                </div>
                                <div className="af-class-faq-question-wrapper">
                                  <div className="af-class-form-sectiontitle af-class-faq-question">
                                    ¿Qué certeza tengo de los accesos?
                                  </div>
                                  <p
                                    className="af-class-gen-paragraph"
                                    style={{ textAlign: 'justify' }}
                                  >
                                    En Palcosplus somos responsables de
                                    confirmar con los dueños de los palcos que
                                    los accesos estén disponibles para su renta
                                    antes de publicarlos en nuestros medios de
                                    difusión, además verificamos que sean 100%
                                    reales para tranquilidad de los clientes.
                                  </p>
                                </div>
                                <div className="af-class-faq-question-wrapper">
                                  <div className="af-class-form-sectiontitle af-class-faq-question">
                                    ¿Cómo recibo mis accesos?
                                  </div>
                                  <p
                                    className="af-class-gen-paragraph"
                                    style={{ textAlign: 'justify' }}
                                  >
                                    En Palcosplus tenemos dos modalidades de
                                    entrega:
                                    <ul>
                                      <li>
                                        - Los accesos pueden ser entregados, sin
                                        costo adicional, en nuestras oficinas
                                        ubicadas frente a Plaza del Sol en
                                        horarios de oficina.
                                      </li>
                                      <li>
                                        {' '}
                                        - Si prefiere recibir los accesos en su
                                        domicilio, tendrá un costo adicional de
                                        $100 y únicamente hacemos entregas
                                        dentro del Área Metropolitana de
                                        Guadalajara.
                                      </li>
                                    </ul>
                                  </p>
                                </div>
                                <div className="af-class-faq-question-wrapper">
                                  <div className="af-class-form-sectiontitle af-class-faq-question">
                                    ¿Qué característica tiene el palco?
                                  </div>
                                  <p
                                    className="af-class-gen-paragraph"
                                    style={{ textAlign: 'justify' }}
                                  >
                                    Cada palco tiene cuenta con una publicación
                                    específica y puede conocer las
                                    características de los espacios en la
                                    sección de detalles, además, todo está
                                    ilustrado con las fotografías más actuales
                                    del lugar.
                                  </p>
                                </div>
                                <div className="af-class-faq-question-wrapper">
                                  <div className="af-class-form-sectiontitle af-class-faq-question">
                                    ¿Qué hago si no encuentro una opción de
                                    acuerdo a lo que busco?
                                  </div>
                                  <p
                                    className="af-class-gen-paragraph"
                                    style={{ textAlign: 'justify' }}
                                  >
                                    Para casos específicos te invitamos a
                                    enviarnos un mensaje a través de nuestro
                                    cuestionario de dudas que se encuentra dando
                                    click en el menú Contacto y nos
                                    comunicaremos contigo lo más pronto posible.
                                  </p>
                                </div>
                                <div className="af-class-faq-question-wrapper">
                                  <div className="af-class-form-sectiontitle af-class-faq-question">
                                    ¿Qué hago si no encuentro el evento que
                                    busco?
                                  </div>
                                  <p
                                    className="af-class-gen-paragraph"
                                    style={{ textAlign: 'justify' }}
                                  >
                                    Para casos específicos te invitamos a
                                    enviarnos un mensaje a través de nuestro
                                    cuestionario de dudas que se encuentra dando
                                    click en el menú Contacto y nos
                                    comunicaremos contigo lo más pronto posible.
                                  </p>
                                </div>
                                <div className="af-class-faq-question-wrapper">
                                  <div className="af-class-form-sectiontitle af-class-faq-question">
                                    Se canceló el evento que iba a asistir, ¿Qué
                                    procede?
                                  </div>
                                  <p
                                    className="af-class-gen-paragraph"
                                    style={{ textAlign: 'justify' }}
                                  >
                                    En caso de que el evento haya sido
                                    cancelado, en Palcosplus te aseguramos el
                                    reintegro total del pago de la renta.
                                  </p>
                                </div>
                                <div className="af-class-faq-question-wrapper">
                                  <div className="af-class-form-sectiontitle af-class-faq-question">
                                    El dueño canceló la renta, ¿Qué procede?
                                  </div>
                                  <p
                                    className="af-class-gen-paragraph"
                                    style={{ textAlign: 'justify' }}
                                  >
                                    En Palcosplus te aseguramos el reintegro
                                    total del pago de la renta, además de buscar
                                    una opción nueva para el beneficio del
                                    cliente.
                                    <br />
                                    Para evitar que vuelva a suceder, al dueño
                                    se le bloqueará de la página por un tiempo
                                    indefinido
                                  </p>
                                </div>
                                <div className="af-class-faq-question-wrapper">
                                  <div className="af-class-form-sectiontitle af-class-faq-question">
                                    Quiero cancelar la renta que ya pagué, ¿Es
                                    posible??
                                  </div>
                                  <p
                                    className="af-class-gen-paragraph"
                                    style={{ textAlign: 'justify' }}
                                  >
                                    No, una vez realizado el pago de la renta,
                                    ya no hay devolución.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            id="sec3"
                            data-w-tab="Sección 3"
                            className={
                              section === 3 ? ' w--tab-active' : 'w-tab-pane'
                            }
                          >
                            <div className="af-class-faq-section-page">
                              <h1 className="af-class-gen-sectiontitle af-class-faq-section-title">
                                Propietarios
                              </h1>
                              <div className="af-class-faq-questions-list">
                                <div className="af-class-faq-question-wrapper">
                                  <div className="af-class-form-sectiontitle af-class-faq-question">
                                    ¿Qué beneficios obtienes con nosotros?
                                  </div>
                                  <p
                                    className="af-class-gen-paragraph"
                                    style={{ textAlign: 'justify' }}
                                  >
                                    En caso de ser dueño, Palcosplus te acercará
                                    a los clientes potenciales para tu
                                    propiedad, si tú no asistes a todos los
                                    partidos y eventos, dar de alta tu propiedad
                                    con nosotros te permite generar rendimiento
                                    para aquellas fechas que tú no lo utilices.
                                    <br />
                                    En caso de ser cliente, Palcosplus tiene las
                                    mejores opciones para ti, palcos en todas
                                    las zonas y butacas en los estadios a tu
                                    elección. ¡No te pierdas ningún evento!
                                  </p>
                                </div>
                                <div className="af-class-faq-question-wrapper">
                                  <div className="af-class-form-sectiontitle af-class-faq-question">
                                    ¿Cómo registro mi propiedad?
                                  </div>
                                  <p
                                    className="af-class-gen-paragraph"
                                    style={{ textAlign: 'justify' }}
                                  >
                                    Envía un correo electrónico a
                                    palcosplus@gmail.com con el asunto: QUIERO
                                    REGISTRARME, necesitas tener a la mano los
                                    siguientes documentos:
                                    <ul>
                                      <li>
                                        - ¿Qué propiedad tienes y el número?
                                        (Palco o butaca)
                                      </li>
                                      <li>- ¿En qué estadio?</li>
                                      <li>
                                        - ¿En qué zona del estadio está ubicada?
                                      </li>
                                      <li>
                                        - ¿Cuántos boletos tienes disponible?
                                      </li>
                                      <li>- Nombre del propietario </li>
                                      <li>
                                        - Amenidades: Refrigerador, sala,
                                        cocina, baño, televisión, otro.
                                      </li>
                                      <li>
                                        - ¿Qué modalidad te interesa: por evento
                                        o por temporada?
                                      </li>
                                    </ul>
                                  </p>
                                </div>
                                <div className="af-class-faq-question-wrapper">
                                  <div className="af-class-form-sectiontitle af-class-faq-question">
                                    ¿Qué documentos se necesitan para registrar
                                    mi propiedad?
                                  </div>
                                  <p
                                    className="af-class-gen-paragraph"
                                    style={{ textAlign: 'justify' }}
                                  >
                                    - Título de propiedad de tu palco <br />
                                    - Comprobante de domiclio <br />
                                    - Estado de cuenta de banco con clabe
                                    interbancaria
                                    <br />
                                  </p>
                                </div>
                                <div className="af-class-faq-question-wrapper">
                                  <div className="af-class-form-sectiontitle af-class-faq-question">
                                    ¿Cómo rento mi propiedad?
                                  </div>
                                  <p
                                    className="af-class-gen-paragraph"
                                    style={{ textAlign: 'justify' }}
                                  >
                                    Envíanos un correo solicitando los
                                    requisitos, en la mayor brevedad posible uno
                                    de nuestros agentes se pondrá en contacto
                                    contigo para recabar la información
                                    necesaria.
                                  </p>
                                </div>
                                <div className="af-class-faq-question-wrapper">
                                  <div className="af-class-form-sectiontitle af-class-faq-question">
                                    ¿Qué pasa si pierden mis accesos?
                                  </div>
                                  <p
                                    className="af-class-gen-paragraph"
                                    style={{ textAlign: 'justify' }}
                                  >
                                    En Palcosplus aseguramos al 100% la
                                    recuperación de los accesos, en dado caso de
                                    que se extravíe alguno, cubriremos el costo
                                    total, pagado por el cliente, para efectuar
                                    la recuperación del acceso.
                                  </p>
                                </div>
                                <div className="af-class-faq-question-wrapper">
                                  <div className="af-class-form-sectiontitle af-class-faq-question">
                                    ¿Cómo establezco el precio de mi propiedad?
                                  </div>
                                  <p
                                    className="af-class-gen-paragraph"
                                    style={{ textAlign: 'justify' }}
                                  >
                                    Palcosplus te sugerirá un precio de renta
                                    para cada evento y en base a esto el dueño
                                    puede sugerir a Palcosplus el precio para el
                                    evento en el que desea rentar su palco y el
                                    precio de renta será el que cada dueño
                                    quiera pedir por él.
                                  </p>
                                </div>
                                <div className="af-class-faq-question-wrapper">
                                  <div className="af-class-form-sectiontitle af-class-faq-question">
                                    ¿Qué hago si no encuentro el
                                    estadio/auditorio de mi propiedad?
                                  </div>
                                  <p
                                    className="af-class-gen-paragraph"
                                    style={{ textAlign: 'justify' }}
                                  >
                                    Para casos específicos te invitamos a
                                    enviarnos un mensaje a través de nuestro
                                    cuestionario de dudas que se encuentra dando
                                    clic en el menú Contacto y nos comunicaremos
                                    contigo lo más pronto posible.
                                  </p>
                                </div>
                                <div className="af-class-faq-question-wrapper">
                                  <div className="af-class-form-sectiontitle af-class-faq-question">
                                    ¿Qué hago si no encuentro el evento que
                                    busco rentar?
                                  </div>
                                  <p
                                    className="af-class-gen-paragraph"
                                    style={{ textAlign: 'justify' }}
                                  >
                                    Para casos específicos te invitamos a
                                    enviarnos un mensaje a través de nuestro
                                    cuestionario de dudas que se encuentra dando
                                    clic en el menú Contacto y nos comunicaremos
                                    contigo lo más pronto posible.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-gen-section">
              <div className="af-class-gen-container">
                <img
                  src={
                    window.location.origin +
                    '/images/bigIcon_questions_faqs.svg'
                  }
                  loading="lazy"
                  alt=""
                  className="af-class-prop-goback-graphic"
                />
                <div className="af-class-gen-paragraph af-class-prop-goback-text">
                  ¿Alguna de tus dudas no fue aclarada aquí? No te preocupes,
                  envíanos un mensaje.
                </div>
                <Link
                  to="/contacto"
                  className="af-class-gen-button af-class-gen-lastbutton w-button"
                >
                  Ir a contacto
                </Link>
              </div>
            </div>
          </div>
        </span>
      </span>
    );
  } else {
    return <Loader />;
  }
};

export default PreguntasView;

/* eslint-enable */
