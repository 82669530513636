import React, { forwardRef } from "react";
import Modal from "components/Modal";
import Button from "components/Button";

const ConfirmationModal = forwardRef((props, ref) => {
  return (
    <Modal isOpen={props.openModal} onDimiss={props.onDimiss}>
      <div {...props} className={props.className}>
        <div className="flex flex-col justify-between h-full p-4">
          <div className="text-center pb-2">
            <div className="font-bold text-lg">{props.message}</div>
          </div>
          <div className="pb-2">{props.children}</div>
          <div className="flex flex-row justify-center">
            <button
              onClick={props.onDimiss}
              className={`w-1/3 h-10 mx-auto text-white font-bold rounded-md bg-rojo-error focus:outline-none hover:bg-red-400`}
            >
              Cancelar
            </button>
            <button
              onClick={props.onUpdate}
              className=" w-1/3 mx-auto text-white font-bold bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight rounded-md focus:outline-none"
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default ConfirmationModal;
