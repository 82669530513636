import React, { forwardRef, useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useClickAway } from 'react-use';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import XLSX from 'xlsx';
import configData from 'config.json';

import Tables, { DataCell } from 'components/Tables';
import Label from 'components/Label';
import Input from 'components/Input';
import Button from 'components/Button';
import Home from 'components/Icons/Home';
import ConfirmationModal from 'components/ConfirmationModal';

const ManagerEventos = forwardRef((props, ref) => {
  const [arrayDelete, setArrayDelete] = useState([]);
  const [confirmationMessage, setConfirmationMessage] = useState(false);
  const [filters, setFilters] = useState(null);
  const [notfilter, setNotfilter] = useState(false);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const refConfirmation = useRef(null);
  const router = useHistory();
  useClickAway(refConfirmation, () => {
    setConfirmationMessage(false);
  });
  const { control, handleSubmit, register, formState, errors } = useForm();
  const [data, setdata] = useState({
    events: [],
    stadiums: [],
    competitions: [],
    teams: [],
  });

  const getData = async () => {
    try {
      const events = await axios
        .get(`${configData.SERVER_URL}/events`)
        .then((res) => {
          return res.data.events;
        });
      const stadiums = await axios
        .get(`${configData.SERVER_URL}/stadiums`)
        .then((res) => {
          return res.data.stadium;
        });
      const competitions = await axios
        .get(`${configData.SERVER_URL}/competition`)
        .then((res) => {
          return res.data.competition;
        });
      const teams = await axios
        .get(`${configData.SERVER_URL}/teams`)
        .then((res) => {
          return res.data.teams;
        });
      setdata({ events, stadiums, competitions, teams });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  data.events &&
    data.events.sort((a, b) =>
      a.date > b.date ? 1 : b.date > a.date ? -1 : 0
    );

  const getStadiumById = (id) => {
    if (data.stadiums) {
      return data.stadiums.find((stadium) => stadium.id === id);
    }
  };

  const getCompetitionById = (id) => {
    if (data.competitions) {
      const result = data.competitions.find(
        (competition) => competition.id === id
      );
      return result;
    }
  };

  const getTeamById = (id) => {
    if (data.teams) {
      return data.teams.find((team) => team.id === id);
    }
  };

  const onsubmit = (data, e) => {
    e.preventDefault();
    setFilters(data);
  };

  const Filter = (events, filters) => {
    const filterCompetition = (event, competition_id) =>
      competition_id === '' || competition_id === 'all'
        ? events
        : event?.competition_id === competition_id;
    const filterStadium = (event, stadium_id) =>
      stadium_id === '' || stadium_id === 'all'
        ? events
        : event?.stadium_id === stadium_id;
    const filterType = (event, type) =>
      type === '' || type === 'all' ? events : event?.type === type;
    const filterCreationDate = (event, date) =>
      date === '' ? events : event?.date >= date;
    const filterEndDate = (event, date) =>
      date === '' ? events : event?.date <= date;
    const eventsFiltered = events?.filter(
      (event) =>
        filterCompetition(event, filters?.competition) &&
        filterStadium(event, filters?.stadium) &&
        filterType(event, filters?.type) &&
        filterCreationDate(event, filters?.creation_date) &&
        filterEndDate(event, filters?.end_date)
    );
    if (eventsFiltered.length > 0) {
      setNotfilter(false);
      setFilteredEvents(eventsFiltered);
    } else {
      setNotfilter(true);
    }
  };

  useEffect(() => {
    filters && Filter(data.events, filters);
  }, [filters]);

  const titleHeaders = [
    'Sel.',
    'Editar',
    // "Lug. rentados",
    // "Disponibilidad",
    'Tipo',
    'Nombre del evento',
    'Fecha evento',
    'Hora evento',
    'Jornada',
    'Competición/Tour',
    'Estadio',
    'Creación',
    'Ultima modif.',
  ];

  const titleHeadersConfirmation = [
    'Tipo',
    'Jornada',
    'Nombre del evento',
    'Competición/Tour',
    'Estadio',
  ];

  const exportData = (events) => {
    let req = [];
    events.map((event) => {
      const nombre =
        event.type === 'concierto'
          ? event?.name
          : getTeamById(event.home).name +
          ' vs ' +
          getTeamById(event.visitor).name;
      const competition =
        event.type === 'concierto'
          ? event?.tour_name
          : getCompetitionById(event.competition_id).name;
      const xlsData = {
        Tipo: event.type,
        Nombre: nombre,
        Fecha: event.date,
        Hora: event.time,
        Jornada: event?.jornada,
        'Competicion/Tour': competition,
        Estadio: getStadiumById(event?.stadium_id).name,
        Creacion: event.inserted_date,
        Modificacion: event?.modified_date,
      };
      req.push(xlsData);
    });
    convertJSONtoXLSX(req);
  };

  const convertJSONtoXLSX = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();

    // Cambiar el nombre, no se si sea necesario especificar que es o se puede poner la fecha
    XLSX.utils.book_append_sheet(workbook, worksheet, 'PalcosplusData'); //PalcosPlusData es el nombre de la hoja dentro del libro xlsx
    // Buffer
    XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    // Binary String
    XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    // Create a file
    XLSX.writeFile(workbook, 'eventos.xlsx'); //data.xlsx es el nombre del archivo, seria cambiarlo o buscar uno mas adoc
  };

  function displayRows(event, idx) {
    return (
      <tr className="hover:bg-gray-100">
        <DataCell className="">
          <Input
            id=""
            className="ring-2 ring-gris-oscuro cursor-pointer"
            height="h-5"
            width="w-5"
            name="checkbox"
            type="checkbox"
            onClick={() => clickChecbox(idx)}
          />
        </DataCell>
        <DataCell className="">
          {event.type === 'partido' ? (
            <Link
              to={`/updatePartido/${event.id}`}
              className="text-blue-600 underline"
            >
              Editar registro
            </Link>
          ) : (
            <Link
              to={`/updateConcierto/${event.id}`}
              className="text-blue-600 underline"
            >
              Editar registro
            </Link>
          )}
        </DataCell>
        {/* <DataCell className="space-x-4">{ }</DataCell>
      <DataCell>{ }</DataCell> */}
        <DataCell>{event.type}</DataCell>
        <DataCell>
          {event.type === 'concierto'
            ? event?.name
            : getTeamById(event.home).name +
            ' vs ' +
            getTeamById(event.visitor).name}
        </DataCell>
        <DataCell>{event.date}</DataCell>
        <DataCell>{event.time}</DataCell>
        <DataCell>{event?.jornada}</DataCell>
        <DataCell>
          {event.type === 'concierto'
            ? event?.tour_name
            : getCompetitionById(event.competition_id).name}
        </DataCell>
        <DataCell>{getStadiumById(event?.stadium_id).name}</DataCell>
        <DataCell>{event.inserted_date}</DataCell>
        <DataCell>{event.modified_date}</DataCell>
      </tr>
    );
  }

  const clickChecbox = (idx) => {
    const found = arrayDelete.findIndex((number) => number === idx);
    if (found === -1) {
      setArrayDelete([...arrayDelete, idx]);
    } else {
      let newArrray = arrayDelete;
      newArrray.splice(found, 1);
      if (newArrray.length === 0) {
        setArrayDelete([]);
      } else {
        setArrayDelete(newArrray);
      }
    }
  };

  const confirmDelete = async () => {
    for (let i = 0; i < data.events.length; i++) {
      const found = arrayDelete.findIndex((number) => number === i);
      if (found !== -1) {
        try {
          await axios.delete(
            `${configData.SERVER_URL}/events/${data.events[i].id}`
          );
          router.go(0);
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  return (
    <form className="w-full pt-28" onSubmit={handleSubmit(onsubmit)}>
      <div className="mx-auto pb-24" style={{ maxWidth: '1080px' }}>
        <div className="flex flex-col-reverse lg:flex-row justify-between pb-10">
          <div className="flex flex-col md:pt-10 lg:pt-0">
            <div id="tracking" className="pb-4">
              <h3 className="text-gris-oscuro">
                <p>Panel de Administrador / Estadios</p>
              </h3>
            </div>
            <div id="title" className="pb-2">
              <h1 className="text-sport-blue font-bold text-6xl">
                Gestionar Eventos
              </h1>
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="pb-14 md:pb-0">
              <Link to="/panel">
                <Button className="w-56 md:w-40 h-14 bg-green-field hover:bg-green-highlight focus:ring-green-highlight ">
                  PANEL ADMIN.
                </Button>
              </Link>
            </div>
            <div className="hidden lg:hidden md:flex flex-row">
              <div className="px-4">
                <Link to="/createPartido">
                  <Button className="w-full sm:w-36 h-14 bg-green-field hover:bg-green-highlight focus:ring-green-highlight ">
                    NUEVO
                  </Button>
                </Link>
              </div>
              <div>
                <Button
                  onClick={() =>
                    exportData(
                      filteredEvents.length > 0 ? filteredEvents : data.events
                    )
                  }
                  className="w-full sm:w-36 h-14 text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight"
                >
                  DESCARGAR
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="md:hidden lg:flex w-full md:w-80 flex flex-col md:flex-row md:justify-between">
          <div className="pb-5 md:pb-0">
            <Link to="/createPartido">
              <Button className="w-full md:w-36 h-14 bg-green-field hover:bg-green-highlight focus:ring-green-highlight ">
                NUEVO
              </Button>
            </Link>
          </div>
          <div>
            <Button
              onClick={() =>
                exportData(
                  filteredEvents.length > 0 ? filteredEvents : data.events
                )
              }
              className="w-full md:w-36 h-14 text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight"
            >
              DESCARGAR
            </Button>
          </div>
        </div>
        <div className="grid grid-flow-row pt-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-12 gap-y-8 pb-8">
          <div id="col">
            <div className="pb-1">
              <Label className="text-deep-blue">Tipo de Evento</Label>
            </div>
            <Controller
              name="type"
              control={control}
              defaultValue={'all'}
              as={
                <Input
                  name="type"
                  input="select"
                  type="text"
                  className="w-full h-10"
                  value="partido"
                  options={[
                    { value: 'partido', label: 'Partido' },
                    { value: 'concierto', label: 'General' },
                    { value: 'all', label: 'Todos' },
                  ]}
                />
              }
            />
          </div>
          <div id="col">
            <div className="pb-1">
              <Label className="text-deep-blue">Competición/Tour</Label>
            </div>
            <Controller
              name="competition"
              control={control}
              defaultValue={''}
              as={
                <select
                  ref={register}
                  id="competition"
                  name="competition"
                  className="border rounded-sm focus:outline-none focus:ring-2 focus:ring-gris-oscuro text-gris-oscuro bg-gris-auxiliar w-full h-10"
                >
                  <option value="all">Competición</option>
                  {data.competitions &&
                    data.competitions.map((competition) => {
                      return (
                        <option value={competition.id}>
                          {competition.name}
                        </option>
                      );
                    })}
                  {data.events &&
                    data.events.map((event) => {
                      return (
                        event.type === 'concierto' && (
                          <option value={event.id}>{event.tour_name}</option>
                        )
                      );
                    })}
                </select>
                // <Input
                //   name="competition"
                //   input="select"
                //   className="w-full h-10"
                //   options={data.competitions && data.competitions.map(competition => {
                //     return <option value={competition.id}>{competition.name}</option>
                //   })}
                // />
              }
            />
          </div>
          <div id="col">
            <div className="pb-1">
              <Label className="text-deep-blue">Estadio</Label>
            </div>
            <Controller
              name="stadium"
              control={control}
              defaultValue={''}
              as={
                <select
                  ref={register}
                  id="stadium"
                  name="stadium"
                  className="border rounded-sm focus:outline-none focus:ring-2 focus:ring-gris-oscuro text-gris-oscuro bg-gris-auxiliar w-full h-10"
                >
                  <option value="all">Estadio</option>
                  {data.stadiums &&
                    data.stadiums.map((stadium) => {
                      return <option value={stadium.id}>{stadium.name}</option>;
                    })}
                </select>
              }
            />
          </div>
          <div id="col">
            <div className="pb-1">
              <Label className="text-deep-blue">Desde (Fecha Evento)</Label>
            </div>
            <Controller
              name="creation_date"
              control={control}
              defaultValue={''}
              as={
                <Input
                  name="creation_date"
                  input="input"
                  type="date"
                  className="w-full h-10"
                />
              }
            />
            {errors.creation_date && (
              <span className="text-rojo-error">
                {errors.creation_date.message}
              </span>
            )}
          </div>
          <div id="col">
            <div className="pb-1">
              <Label className="text-deep-blue">Hasta (Fecha Evento)</Label>
            </div>
            <Controller
              name="end_date"
              control={control}
              defaultValue={''}
              as={
                <Input
                  name="end_date"
                  input="input"
                  type="date"
                  className="w-full h-10"
                />
              }
            />
            {errors.end_date && (
              <span className="text-rojo-error">{errors.end_date.message}</span>
            )}
          </div>
          <div id="col">
            <div className="md:pt-4">
              <Button
                type="submit"
                className="w-full md:w-36 h-14 bg-green-field hover:bg-green-highlight focus:ring-green-highlight "
              >
                APLICAR
              </Button>
            </div>
          </div>
        </div>
        <div>
          <div className="hidden md:flex flex-row-reverse">
            <Button
              className="w-32 h-10 bg-rojo-error text-white p-2"
              onClick={() => setConfirmationMessage(true)}
              disable={arrayDelete.length > 0 ? false : true}
            >
              ELIMINAR
            </Button>
          </div>
          <div className="pt-10 md:pt-2 overflow-x-auto">
            <Tables className="w-auto" headers={titleHeaders}>
              {filteredEvents.length > 0
                ? filteredEvents.map((event, idx) => displayRows(event, idx))
                : data.events.map((event, idx) => displayRows(event, idx))}
            </Tables>
          </div>
          <div className="pt-8">
            <Button className="w-56 h-14 bg-gris-auxiliar hover:bg-gris-medio focus:ring-gris-medio ">
              CARGAR MÁS
            </Button>
          </div>
        </div>
      </div>
      <ConfirmationModal
        openModal={confirmationMessage}
        onUpdate={() => confirmDelete()}
        onDimiss={() => setConfirmationMessage(false)}
        ref={refConfirmation}
        className={`${confirmationMessage
            ? 'z-50 transform transition-all ease-in border absolute bg-white rounded-lg shadow-md left-0 right-0 w-6/12 h-auto mx-auto'
            : 'hidden'
          }`}
        message={'Estas seguro de eliminar los siguientes datos?'}
      >
        <Tables
          className="w-full overflow-x-auto"
          classHead={'w-full'}
          headers={titleHeadersConfirmation}
        >
          {data.events &&
            data.events.map((event, idx) => {
              const found = arrayDelete.findIndex((number) => number === idx);
              if (found !== -1) {
                return (
                  <tr>
                    <DataCell className="truncate">{event.type}</DataCell>
                    <DataCell className="truncate">{event.jornada}</DataCell>
                    <DataCell className="truncate">{event.name}</DataCell>
                    <DataCell className="truncate">{event.tour_name}</DataCell>
                    <DataCell className="truncate">
                      {getStadiumById(event.stadium_id).name}
                    </DataCell>
                  </tr>
                );
              }
            })}
        </Tables>
      </ConfirmationModal>
    </form>
  );
});

export default ManagerEventos;
