import React from 'react'
import { DialogOverlay, DialogContent } from '@reach/dialog';
import { Link } from 'react-router-dom';

const CheckoutSuccess =({ isOpen, onDimiss, id })=> {

  return (
      <DialogOverlay
            aria-label="modal"
            aria-labelledby="dialog-title"
            isOpen={isOpen}
            className="z-40 border"
        >
          <DialogContent
                className="container rounded-lg"
                aria-labelledby="dialog-content"
            >
        <style dangerouslySetInnerHTML={{
          __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/front-end-palcosplus.webflow.css);


            html {
              -webkit-tap-highlight-color: rgba(0,0,0,0);
            }
            textarea {
              resize: vertical;
            }
            .w-lightbox-backdrop {
              background: rgba(0,1,13,.85);
            }
        ` }} />
        <span className="af-view">
            <div className="af-class-popup-wrapper">
              <div className="af-class-popup-container af-class-gen-alert-container">
                <div className="af-class-form-sectiontitle-wrapper">
                  <h2 className="af-class-form-sectiontitle">Gooooooooooooooool!!!!!</h2>
                </div>
                <p className="af-class-form-note">Felicitaciones! Tu renta ha sido exitosa.</p>
                <Link to={`/confirmacion/${id}`} className="af-class-gen-button af-class-cart-gen-button w-button">entendido</Link>
              </div>
            </div>
            {/* <div className="af-class-popup-wrapper">
              <div className="af-class-popup-container af-class-gen-alert-container">
                <div className="af-class-popup-close-wrapper">
                <img src="images/close-sp-gray.svg" loading="lazy" alt="" className="af-class-popup-closeicon" />
                </div>
                <div className="af-class-form-sectiontitle-wrapper">
                  <h2 className="af-class-form-sectiontitle">Ha ocurrido un error</h2>
                </div>
                <p className="af-class-form-note">Al parecer hay un error con la solicitud. Verifica que todos los campos obligatorios (*) estén completos e inténtalo de nuevo. Si los problemas persisten, por favor <a href="#" className="af-class-form-note-link">contacta a soporte</a>.</p>
                <a href="#" className="af-class-gen-button af-class-cart-gen-button w-button">entendido</a>
              </div>
            </div> */}
        </span>
          </DialogContent>
      </DialogOverlay>
    )
}

export default CheckoutSuccess
