import React, { forwardRef } from 'react';
import Modal from 'components/Modal';

const ImgModal = forwardRef((props, ref) => {
  return (
    <Modal isOpen={props.openModal} onDimiss={props.onDimiss}>
      <div className="z-50 pt-0 absolute left-0 right-0 w-3/4 h-3/4 mx-auto">
        <img className="w-full h-full" src={props.url} alt="" />
      </div>
    </Modal>
  );
});

export default ImgModal;
