import React, { forwardRef, useState, useEffect, FormEvent } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import ImageUploading from 'react-images-uploading';
import axios from 'axios';
import configData from 'config.json';

import Tables, { DataCell } from 'components/Tables';
import Label from 'components/Label';
import Input from 'components/Input';
import Button from 'components/Button';
import Home from 'components/Icons/Home';
import MultiSelect from 'react-multi-select-component';
import Carousel from 'components/Carousel';

import './Estadio.css';

const teamsLigamx = [
  { value: 'América', label: 'América' },
  { value: 'Atlas', label: 'Atlas' },
  { value: 'Toluca', label: 'Toluca' },
  { value: 'Monterrey', label: 'Monterrey' },
  { value: 'Tigres', label: 'Tigres' },
  { value: 'Cruz', label: 'Cruz' },
  { value: 'Atlético de San Luis', label: 'Atlético de San Luis' },
  { value: 'León', label: 'León' },
  { value: 'Santos', label: 'Santos' },
  { value: 'Pachuca', label: 'Pachuca' },
  { value: 'Guadalajara', label: 'Guadalajara' },
  { value: 'Mazatlán', label: 'Mazatlán' },
  { value: 'Juárez', label: 'Juárez' },
  { value: 'Necaxa', label: 'Necaxa' },
  { value: 'Puebla', label: 'Puebla' },
  { value: 'Querétaro', label: 'Querétaro' },
  { value: 'UNAM', label: 'UNAM' },
  { value: 'Tijuana', label: 'Tijuana' },
];

const CreateEstadio = forwardRef((props, ref) => {
  const [selected, setSelected] = useState([]);
  const [rows, setRows] = useState([]);
  const [postalCode, setPostalCode] = useState([]);
  const [tarifa, setTarifa] = useState([]);
  const [imagesMain, setImagesMain] = useState([]);
  const [imagesBanner, setImgBanner] = useState([]);
  const [imagesMap, setImagesMap] = useState([]);
  const [currentImageMain, setCurrentImageMain] = useState(0);
  const [currentImageBanner, setCurrentImageBanner] = useState(0);
  const [currentImageMap, setCurrentImageMap] = useState(0);
  const [teams, setTeams] = useState([]);
  const history = useHistory();
  const maxNumber = 5;

  useEffect(() => {
    axios
      .get(`${configData.SERVER_URL}/teams`)
      .then((res) => {
        const teams = res.data;
        setTeams(teams.teams);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onChangesetImgMain = (imageList, addUpdateIndex) => {
    setImagesMain(imageList);
  };
  const onChangesetImgBanner = (imageList, addUpdateIndex) => {
    setImgBanner(imageList);
  };
  const onChangesetMap = (imageList, addUpdateIndex) => {
    setImagesMap(imageList);
  };

  const { control, handleSubmit, register, formState, errors } = useForm();

  const saveTarifa = async (stadiumId) => {
    let tarifas = [];
    for (let i = 0; i < rows.length; i++) {
      if (tarifa[i] !== null && postalCode[i]) {
        tarifas.push({ tarifa: tarifa[i], postalCode: postalCode[i] });
      }
    }
    const req = { tarifas: tarifas, stadium_id: stadiumId };
    const res = await axios.post(`${configData.SERVER_URL}/tarifas`, req);
    const response = res.data.tarifa;
  };

  const sendDataRequest = async (stadium) => {
    try {
      const formData = new FormData();
      formData.append('name', stadium.name);
      formData.append('country', stadium.country);
      formData.append('city', stadium.city);
      formData.append('capacity', stadium.capacity);
      formData.append('fundation_date', stadium.fundation_date);
      formData.append('local_teams', stadium.local_teams);
      formData.append('description', stadium.description);
      formData.append('location', stadium.location);
      formData.append('delivery_zone', stadium.delivery_zone);
      formData.append('zones', stadium.zones);
      formData.append('access', stadium.access);
      stadium.images.map((img) => {
        formData.append('images', img);
      });
      const res = await axios.post(
        `${configData.SERVER_URL}/stadiums`,
        formData
      );
      const response = res.data.stadium;
      const tarifa = await saveTarifa(response._id);
      history.push('/managerEstadio');
      history.go(0);
    } catch (err) {
      console.log(err);
    }
  };

  const onsubmit = (data, e) => {
    e.preventDefault();
    data.teams = selected;
    // let tarifas = [];
    let teams = [];
    const imgMain = imagesMain.map((img, idx) => {
      const type = img.file.type.split('/');
      return Object.defineProperty(img.file, 'name', {
        writable: 'true',
        value: 'principal-' + data.name + '-' + (idx + 1) + '.' + type[1],
      });
    });
    const imgBanner = imagesBanner.map((img, idx) => {
      const type = img.file.type.split('/');
      return Object.defineProperty(img.file, 'name', {
        writable: 'true',
        value: 'banner-' + data.name + '-' + (idx + 1) + '.' + type[1],
      });
    });
    const imgMap = imagesMap.map((img, idx) => {
      const type = img.file.type.split('/');
      return Object.defineProperty(img.file, 'name', {
        writable: 'true',
        value: 'map-' + data.name + '-' + (idx + 1) + '.' + type[1],
      });
    });
    data.teams.map((team) => {
      teams.push(team.value);
    });
    // for (let i = 1; i <= rows.length; i++) {
    //   tarifas.push({ tarifa: data.tarifa1, postal_code: data.postal_code1 })
    //   console.log(data.tarifa + i)
    // }
    const stadium = {
      name: data.name,
      country: data.country,
      city: data.city,
      capacity: data.capacidad,
      fundation_date: data.fundation,
      local_teams: teams,
      description: data.description,
      location: data.location,
      zones: data.zone,
      delivery_zone: data.delivery_zone,
      access: data.access,
      images: [...imgMain, ...imgBanner, ...imgMap],
    };
    sendDataRequest(stadium);
  };

  const titleHeaders = ['Tarifa', 'Códigos postales'];

  function displayRows() {
    <tr className="">
      <DataCell className="px-4 py-2">Gratis</DataCell>
      <DataCell className="px-4 py-2">
        <Input
          name="type"
          input="text"
          className="w-full h-10 bg-gris-auxiliar"
          value={'45030, 45031, 45033, 45035, 45036'}
        />
      </DataCell>
    </tr>;
  }

  const getTeams = () => {
    return teams.map((stadium) => {
      return { value: stadium.id, label: stadium.name };
    });
  };

  return (
    <form className="w-full pt-28" onSubmit={handleSubmit(onsubmit)}>
      <div className="mx-auto pb-24" style={{ maxWidth: '1080px' }}>
        <div className="flex flex-col-reverse md:flex-row justify-between pb-10">
          <div className="flex flex-col">
            <div id="tracking" className="pb-4">
              <h3 className="text-gris-oscuro">
                Panel de Administrador / Estadios / Agregar Estadio
              </h3>
            </div>
            <div id="title" className="pb-2">
              <h1 className="text-sport-blue font-bold text-5xl">
                Agregar Nuevo Estadio
              </h1>
            </div>
          </div>
          <div className="flex flex-row pb-14 md:pb-0">
            <div className="">
              <Link to="/managerEstadio">
                <Button className="w-40 h-14 bg-green-field rounded-lg shadow-sm hover:bg-green-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight ">
                  Listado
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="grid grid-flow-row pt-8 md:grid-cols-2 lg:grid-cols-3 gap-12">
          <div id="col1">
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Nombre del Estadio</Label>
              </div>
              <Controller
                name="name"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                as={
                  <Input
                    name="name"
                    input="input"
                    type="text"
                    className="w-full h-10"
                    placeholder="Estadio Jalisco"
                  />
                }
              />
              {errors.name && (
                <span className="text-rojo-error">{errors.name.message}</span>
              )}
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Ciudad</Label>
              </div>
              <Controller
                name="city"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={''}
                as={
                  <Input
                    name="city"
                    input="input"
                    type="text"
                    className="w-full h-10"
                    placeholder="Guadalajara"
                  />
                }
              />
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Capacidad</Label>
              </div>
              <Controller
                name="capacidad"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={''}
                as={
                  <Input
                    name="capacidad"
                    input="input"
                    type="number"
                    className="w-full h-10"
                    placeholder="10000"
                  />
                }
              />
              {errors.name && (
                <span className="text-rojo-error">{errors.name.message}</span>
              )}
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Equipos locales</Label>
              </div>
              <Controller
                name="teams"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={selected}
                render={({ onChange }) => (
                  <MultiSelect
                    name="teams"
                    input="select"
                    value={selected}
                    onChange={setSelected}
                    hasSelectAll={false}
                    options={teamsLigamx}
                  />
                )}
              />
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Descripción</Label>
              </div>
              <Controller
                name="description"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={''}
                as={
                  <Input
                    name="description"
                    input="textarea"
                    className="w-full h-48"
                  />
                }
              />
              {errors.details && (
                <span className="text-rojo-error">
                  {errors.details.message}
                </span>
              )}
            </div>
          </div>
          <div id="col2">
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">País</Label>
              </div>
              <Controller
                name="country"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={'Mexico'}
                as={
                  <Input
                    name="country"
                    input="select"
                    className="w-full h-10"
                    options={[{ value: 'Mexico', label: 'México' }]}
                  />
                }
              />
              {errors.name && (
                <span className="text-rojo-error">{errors.name.message}</span>
              )}
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">
                  Ubicación (URL Google Maps)
                </Label>
              </div>
              <Controller
                name="location"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={''}
                as={
                  <Input
                    name="location"
                    input="input"
                    type="text"
                    className="w-full h-10"
                    placeholder="https://www.google.com/maps/place/Jalisco+Stadium∫"
                  />
                }
              />
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Fundación</Label>
              </div>
              <Controller
                name="fundation"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={''}
                as={
                  <Input
                    name="fundation"
                    input="input"
                    type="date"
                    className="w-full h-10"
                  />
                }
              />
              {errors.name && (
                <span className="text-rojo-error">{errors.name.message}</span>
              )}
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Zonas</Label>
              </div>
              <Controller
                name="zone"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={''}
                as={
                  <Input
                    name="zone"
                    input="text"
                    className="w-full h-10"
                    placeholder="poniente-#034AA6, oriente-#034AA6"
                  />
                }
              />
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Puertas de acceso</Label>
              </div>
              <Controller
                name="access"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={''}
                as={
                  <Input
                    name="access"
                    input="text"
                    className="w-full h-10"
                    placeholder="1,2,3,4,5"
                  />
                }
              />
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Zona de entrega</Label>
              </div>
              <Controller
                name="delivery_zone"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={''}
                as={
                  <Input
                    name="delivery_zone"
                    input="text"
                    className="w-full h-10"
                    placeholder="zona oriente puerta 15"
                  />
                }
              />
            </div>
          </div>
        </div>
        <div>
          <div id="subtitle">
            <h1 className="text-sport-blue font-bold text-4xl">Media</h1>
          </div>
          <div className="grid grid-flow-row pt-8 grid-cols-1 md:grid-cols-2 gap-12 pb-20">
            <ImageUploading
              multiple
              value={imagesMain}
              onChange={onChangesetImgMain}
              maxNumber={1}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <div className="">
                  <h1 className="text-ocean-black font-bold text-xl">
                    Imagen principal
                  </h1>
                  <div className={imagesMain.length === 0 && 'hidden'}>
                    {imagesMain.length !== 0 && (
                      <Carousel current={(e) => setCurrentImageMain(e)}>
                        {imagesMain}
                      </Carousel>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <div className="flex flex-row">
                      <div className="">
                        <Button
                          type="button"
                          className={
                            'w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight'
                          }
                          style={isDragging ? { color: 'red' } : undefined}
                          onClick={onImageUpload}
                          disable={imagesMain.length === 5 && true}
                          {...dragProps}
                        >
                          CARGAR
                        </Button>
                      </div>
                      {imagesMain.length > 0 && (
                        <div className="pl-3">
                          <Button
                            type="button"
                            className={
                              'w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight '
                            }
                            disable={imagesMain.length === 0 && true}
                            onClick={() => onImageRemove(currentImageMain)}
                          >
                            Borrar
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </ImageUploading>
            <ImageUploading
              multiple
              value={imagesBanner}
              onChange={onChangesetImgBanner}
              maxNumber={maxNumber}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <div className="">
                  <h1 className="text-ocean-black font-bold text-xl">
                    Imagen Banner
                  </h1>
                  <div className={imagesBanner.length === 0 && 'hidden'}>
                    {imagesBanner.length !== 0 && (
                      <Carousel current={(e) => setCurrentImageBanner(e)}>
                        {imagesBanner}
                      </Carousel>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <div className="flex flex-row">
                      <div className="">
                        <Button
                          type="button"
                          className={
                            'w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight'
                          }
                          style={isDragging ? { color: 'red' } : undefined}
                          onClick={onImageUpload}
                          disable={imagesBanner.length === 5 && true}
                          {...dragProps}
                        >
                          CARGAR
                        </Button>
                      </div>
                      {imagesBanner.length > 0 && (
                        <div className="pl-3">
                          <Button
                            type="button"
                            className={
                              'w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight '
                            }
                            disable={imagesBanner.length === 0 && true}
                            onClick={() => onImageRemove(currentImageBanner)}
                          >
                            Borrar
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </ImageUploading>
            <ImageUploading
              multiple
              value={imagesMap}
              onChange={onChangesetMap}
              maxNumber={1}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <div className="">
                  <h1 className="text-ocean-black font-bold text-xl">
                    Imagen Mapa
                  </h1>
                  <div className={imagesMap.length === 0 && 'hidden'}>
                    {imagesMap.length !== 0 && (
                      <Carousel current={(e) => setCurrentImageMap(e)}>
                        {imagesMap}
                      </Carousel>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <div className="flex flex-row">
                      <div className="">
                        <Button
                          type="button"
                          className={
                            'w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight'
                          }
                          style={isDragging ? { color: 'red' } : undefined}
                          onClick={onImageUpload}
                          disable={imagesMap.length === 5 && true}
                          {...dragProps}
                        >
                          CARGAR
                        </Button>
                      </div>
                      {imagesMap.length > 0 && (
                        <div className="pl-3">
                          <Button
                            type="button"
                            className={
                              'w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight '
                            }
                            disable={imagesMap.length === 0 && true}
                            onClick={() => onImageRemove(currentImageMap)}
                          >
                            Borrar
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </ImageUploading>
          </div>
        </div>
        <div className="pb-24">
          <h1 className="text-sport-blue font-bold text-4xl">
            Tarifas de entrega
          </h1>
          <div className="pt-2 pb-5" style={{ width: '750px' }}>
            <Tables idheader={0} classHead="w-36" headers={titleHeaders}>
              {
                rows.length !== 0 && rows.map((row) => row)
                // : (
                //   <tr>
                //     <DataCell className="px-4 py-2">
                //       <Input
                //         name={("tarifa" + (rows.length + 1)).toString()}
                //         input="text"
                //         className="w-full h-10 bg-gris-auxiliar"
                //         onBlur={e => setTarifa([...tarifa, e.target.value])}
                //       />
                //     </DataCell>
                //     <DataCell className="px-4 py-2">
                //       <Input
                //         name={("postal_code" + (rows.length + 1)).toString}
                //         input="text"
                //         className="w-full h-10 bg-gris-auxiliar"
                //         onBlur={e => setPostalCode([...postalCode, e.target.value])}
                //       />
                //     </DataCell>
                //   </tr>
                // )
              }
            </Tables>
          </div>
          <div>
            <Button
              type="button"
              className="text-white p-2 bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight"
              onClick={() => {
                setRows([
                  ...rows,
                  <tr>
                    <DataCell className="px-4 py-2">
                      <Input
                        name={('tarifa' + (rows.length + 1)).toString()}
                        input="text"
                        className="w-full h-10 bg-gris-auxiliar"
                        placeholder="100"
                        onBlur={(e) => (tarifa[rows.length] = e.target.value)}
                      />
                    </DataCell>
                    <DataCell className="px-4 py-2">
                      <Input
                        name={('postal_code' + (rows.length + 1)).toString}
                        input="text"
                        placeholder="49018,27212,12321"
                        className="w-full h-10 bg-gris-auxiliar"
                        onBlur={(e) =>
                          (postalCode[rows.length] = e.target.value)
                        }
                      />
                    </DataCell>
                  </tr>,
                ]);
              }}
            >
              AGREGAR NUEVA TARIFA
            </Button>
          </div>
        </div>
        {/* <div className="pb-24">
          <h1 className="text-sport-blue font-bold text-4xl">
            Registro de Actividad
          </h1>
          <div className="w-3/4 grid grid-flow-row grid-cols-2">
            <div className="flex flex-col">
              <h4 className="text-ocean-black font-bold text-xl pt-4">
                Fecha de creacion
              </h4>
              <p>18/09/20 - 15:00</p>
            </div>
            <div className="flex flex-col">
              <h4 className="text-ocean-black font-bold text-xl pt-4">
                Fecha de última modificacion
              </h4>
              <p>27/12/20 - 10:00</p>
            </div>
          </div>
        </div> */}
        <div className="flex flex-row">
          <div className="pr-5">
            <Button
              type="submit"
              className="w-44 h-14 font-bold text-xl text-ocean-black bg-green-field rounded-lg shadow-sm hover:bg-green-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight "
              disable={
                imagesMain.length === 0 ||
                  imagesBanner.length === 0 ||
                  imagesMap.length === 0
                  ? true
                  : false
              }
            >
              GUARDAR
            </Button>
          </div>
          <div className="pr-5">
            <Button
              onClick={() => history.goBack()}
              className="w-44 h-14 font-bold text-xl text-white bg-aqua-grass rounded-lg shadow-sm hover:bg-aqua-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight "
              type="button"
            >
              CANCELAR
            </Button>
          </div>
          {/* <div className="pr-5">
            <Button className="w-44 h-14 font-bold text-xl text-white bg-aqua-grass rounded-lg shadow-sm hover:bg-aqua-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight ">
              ELIMINAR
            </Button>
          </div> */}
        </div>
      </div>
    </form>
  );
});

export default CreateEstadio;
