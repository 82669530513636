import React, { forwardRef, useState, useEffect, FormEvent } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import ImageUploading from 'react-images-uploading';
import axios from 'axios';
import configData from 'config.json';

import Label from 'components/Label';
import Input from 'components/Input';
import Button from 'components/Button';
import Home from 'components/Icons/Home';
import Carousel from 'components/Carousel';
import Loader from 'components/Loader';

const CreateEquipo = forwardRef((props, ref) => {
  const history = useHistory();
  const params = useParams();
  const [loader, setLoader] = useState(true);
  const [colorPrincipal, setColorPrincipal] = useState();
  const [colorSecundario, setColorSecundario] = useState();
  const [imagesVista, setImagesVista] = useState([]);
  const [imagesGaleria, setImagesGaleria] = useState([]);
  const [stadiums, setStadiums] = useState([]);
  const [team, setTeam] = useState('');
  const [currentImageVista, setCurrentImageVista] = useState(0);
  const [currentImageGaleria, setCurrentImageGaleria] = useState(0);
  const maxNumber = 5;

  const getData = async () => {
    try {
      const stadium = await axios
        .get(`${configData.SERVER_URL}/stadiums`)
        .then((res) => {
          return res.data.stadium;
        });
      const team = await axios
        .get(`${configData.SERVER_URL}/teams/${params.id}`)
        .then(
          (res) => {
            return res.data.team;
          },
          (err) => {
            console.log(err);
          }
        );
      setStadiums(stadium);
      setTeam(team);
      setColorPrincipal(team.principal_color);
      setColorSecundario(team.secundary_color);
      let galeria = [];
      for (let i = 1; i < team.images.length; i++) {
        galeria.push({
          data_url: team.images[i],
          file: {
            name: team.images[i].split(
              'https://upload-images-palcosplus.s3.amazonaws.com/'
            )[1],
          },
        });
      }
      setImagesGaleria(galeria);
      setImagesVista([
        {
          data_url: team.images[0],
          file: {
            name: team.images[0].split(
              'https://upload-images-palcosplus.s3.amazonaws.com/'
            )[1],
          },
        },
      ]);
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setLoader(true);
    getData();
  }, []);

  const onChangesetImagesVista = (imageList, addUpdateIndex) => {
    setImagesVista(imageList);
  };
  const onChangesetImagesGaleria = (imageList, addUpdateIndex) => {
    setImagesGaleria(imageList);
  };

  const { control, handleSubmit, register, formState, errors } = useForm();

  const sendDataRequest = async (team) => {
    try {
      const formData = new FormData();
      formData.append('name', team.name);
      formData.append('country', team.country);
      formData.append('stadium_id', team.stadium_id);
      formData.append('principal_color', team.principal_color);
      formData.append('secundary_color', team.secundary_color);
      team.imagesName.map((img) => {
        const url = img.data_url.split(
          'https://upload-images-palcosplus.s3.amazonaws.com/'
        );
        if (url[0] === '') {
          formData.append('imagesName', img.data_url);
        } else {
          formData.append('imagesName', '');
        }
      });
      team.images.map((img) => {
        formData.append('images', img);
      });
      const res = await axios.patch(
        `${configData.SERVER_URL}/teams/${params.id}`,
        formData
      );
      const response = res.data.team;
      history.push('/managerEquipos');
      history.go(0);
    } catch (err) {
      console.log(err);
    }
  };

  const onsubmit = (data, e) => {
    e.preventDefault();
    const imgVista = imagesVista.map((img, idx) => {
      const url = img.data_url.split(
        'https://upload-images-palcosplus.s3.amazonaws.com/'
      );
      if (url[0] === '') {
        return Object.defineProperty(img.file, 'name', {
          writable: 'true',
          value: img.data_url,
        });
      } else {
        const type = img.file.type.split('/');
        return Object.defineProperty(img.file, 'name', {
          writable: 'true',
          value: 'vista-' + data.name + '-' + (idx + 1) + '.' + type[1],
        });
      }
    });
    const imgGaleria = imagesGaleria.map((img, idx) => {
      const url = img.data_url.split(
        'https://upload-images-palcosplus.s3.amazonaws.com/'
      );
      if (url[0] === '') {
        return Object.defineProperty(img.file, 'name', {
          writable: 'true',
          value: img.data_url,
        });
      } else {
        const type = img.file.type.split('/');
        return Object.defineProperty(img.file, 'name', {
          writable: 'true',
          value: 'galeria-' + data.name + '-' + (idx + 1) + '.' + type[1],
        });
      }
    });
    const team = {
      name: data.name,
      country: data.country,
      stadium_id: data.stadium,
      principal_color: colorPrincipal,
      secundary_color: colorSecundario,
      images: [...imgVista, ...imgGaleria],
      imagesName: [...imagesVista, ...imagesGaleria],
    };
    sendDataRequest(team);
  };

  const getStadiums = () => {
    return stadiums.map((stadium) => {
      return { value: stadium.id, label: stadium.name };
    });
  };

  if (!loader) {
    return (
      <form className="w-full pt-28" onSubmit={handleSubmit(onsubmit)}>
        <div className="container-pp mx-auto pb-24">
          <div className="flex flex-col-reverse md:flex-row justify-between pb-10">
            <div className="flex flex-col">
              <div id="tracking" className="pb-4">
                <h3 className="text-gris-oscuro">
                  Panel de Administrador / Equipos / Modificar Equipo
                </h3>
              </div>
              <div id="title" className="pb-2">
                <h1 className="text-sport-blue font-bold text-5xl">
                  Modificar {team.name}
                </h1>
              </div>
            </div>
            <div className="flex flex-row pb-14 md:pb-0">
              <div className="">
                <Link to="/managerEquipos">
                  <Button className="w-40 h-14 bg-green-field rounded-lg shadow-sm hover:bg-green-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight ">
                    Listado
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="grid grid-flow-row pt-8 md:grid-cols-2 lg:grid-cols-3 gap-12">
            <div id="col1">
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">País</Label>
                </div>
                <Controller
                  name="country"
                  control={control}
                  rules={{ required: 'Este campo no debe estar vacío' }}
                  defaultValue={team.country}
                  as={
                    <Input
                      name="country"
                      input="select"
                      type="text"
                      className="w-full h-10"
                      options={[{ value: 'mexico', label: 'México' }]}
                    />
                  }
                />
              </div>
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">Estadio cede</Label>
                </div>
                <Controller
                  name="stadium"
                  control={control}
                  rules={{ required: 'Este campo no debe estar vacío' }}
                  defaultValue={team.stadium_id}
                  as={
                    <Input
                      name="stadium"
                      input="select"
                      className="w-full h-10"
                      options={[
                        { value: '', label: 'Selecciona un estadio' },
                        ...getStadiums(),
                      ]}
                    />
                  }
                />
                {errors.stadium && (
                  <span className="text-rojo-error">
                    {errors.stadium.message}
                  </span>
                )}
              </div>
              <div id="data" className="pb-8">
                <div className="pb-1 flex flex-row justify-between">
                  <Label className="text-deep-blue">
                    Color secundario del equipo
                  </Label>
                  <div
                    className="w-10"
                    style={{ backgroundColor: colorSecundario }}
                  ></div>
                </div>
                <Input
                  ref={register}
                  defaultValue={colorSecundario}
                  placeholder="#FF5733"
                  name="color_secundario"
                  input="text"
                  className="w-full h-10"
                  onChange={(e) => setColorSecundario(e.target.value)}
                />
              </div>
            </div>
            <div id="col2">
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">Nombre del equipo</Label>
                </div>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: 'Este campo no debe estar vacío' }}
                  defaultValue={team.name}
                  as={
                    <Input
                      name="name"
                      input="input"
                      type="text"
                      className="w-full h-10"
                    />
                  }
                />
                {errors.name && (
                  <span className="text-rojo-error">{errors.name.message}</span>
                )}
              </div>
              <div id="data" className="pb-8">
                <div className="pb-1 flex flex-row justify-between">
                  <Label className="text-deep-blue">
                    Color principal del equipo
                  </Label>
                  <div
                    className="w-10"
                    style={{ backgroundColor: colorPrincipal }}
                  ></div>
                </div>
                <Input
                  ref={register}
                  defaultValue={colorPrincipal}
                  placeholder="#FF5733"
                  name="color_principal"
                  input="text"
                  className="w-full h-10"
                  onChange={(e) => setColorPrincipal(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div>
            <div id="subtitle">
              <h1 className="text-sport-blue font-bold text-4xl">Media</h1>
            </div>
            <div className="grid grid-flow-row pt-8 md:grid-cols-2 gap-12 pb-20">
              <ImageUploading
                multiple
                value={imagesVista}
                onChange={onChangesetImagesVista}
                maxNumber={1}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <div className="">
                    <h1 className="text-ocean-black font-bold text-xl">
                      Vista
                    </h1>
                    <div className={imagesVista.length === 0 && 'hidden'}>
                      {imagesVista.length !== 0 && (
                        <Carousel current={(e) => setCurrentImageVista(e)}>
                          {imagesVista}
                        </Carousel>
                      )}
                    </div>
                    <div className="flex flex-col">
                      <div className="flex flex-row">
                        <div className="">
                          <Button
                            type="button"
                            className={
                              'w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight'
                            }
                            style={isDragging ? { color: 'red' } : undefined}
                            onClick={onImageUpload}
                            disable={imagesVista.length === 5 && true}
                            {...dragProps}
                          >
                            CARGAR
                          </Button>
                        </div>
                        {imagesVista.length > 0 && (
                          <div className="pl-3">
                            <Button
                              type="button"
                              className={
                                'w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight '
                              }
                              disable={imagesVista.length === 0 && true}
                              onClick={() => onImageRemove(currentImageVista)}
                            >
                              Borrar
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </ImageUploading>
              <ImageUploading
                multiple
                value={imagesGaleria}
                onChange={onChangesetImagesGaleria}
                maxNumber={maxNumber}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <div className="">
                    <h1 className="text-ocean-black font-bold text-xl">
                      Galería
                    </h1>
                    <div className={imagesGaleria.length === 0 && 'hidden'}>
                      {imagesGaleria.length !== 0 && (
                        <Carousel current={(e) => setCurrentImageGaleria(e)}>
                          {imagesGaleria}
                        </Carousel>
                      )}
                    </div>
                    <div className="flex flex-col">
                      <div className="flex flex-row">
                        <div className="">
                          <Button
                            type="button"
                            className={
                              'w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight'
                            }
                            style={isDragging ? { color: 'red' } : undefined}
                            onClick={onImageUpload}
                            disable={imagesGaleria.length === 5 && true}
                            {...dragProps}
                          >
                            CARGAR
                          </Button>
                        </div>
                        {imagesGaleria.length > 0 && (
                          <div className="pl-3">
                            <Button
                              type="button"
                              className={
                                'w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight '
                              }
                              disable={imagesGaleria.length === 0 && true}
                              onClick={() => onImageRemove(currentImageGaleria)}
                            >
                              Borrar
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </ImageUploading>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="pr-5">
              <Button
                type="submit"
                className="w-44 h-14 font-bold text-xl text-ocean-black bg-green-field rounded-lg shadow-sm hover:bg-green-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight "
                disable={
                  imagesGaleria.length === 0 || imagesVista.length === 0
                    ? true
                    : false
                }
              >
                GUARDAR
              </Button>
            </div>
            <div className="pr-5">
              <Button
                onClick={() => history.goBack()}
                className="w-44 h-14 font-bold text-xl text-white bg-aqua-grass rounded-lg shadow-sm hover:bg-aqua-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight "
                type="button"
              >
                CANCELAR
              </Button>
            </div>
            <div className="pr-5">
              <Button className="w-44 h-14 font-bold text-xl text-white bg-aqua-grass rounded-lg shadow-sm hover:bg-aqua-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight ">
                ELIMINAR
              </Button>
            </div>
          </div>
        </div>
      </form>
    );
  } else {
    return <Loader />;
  }
});

export default CreateEquipo;
