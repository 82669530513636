import React, { useEffect, useState, useContext } from 'react';
import { createScope, map, transformProxies } from './helpers';
import { Link, useHistory } from 'react-router-dom';
import ModalInicioSesion from './ModalInicioSesion';
import { Helmet } from 'react-helmet';
import { AuthContext } from './auth-context';
import axios from 'axios';
import configData from 'config.json';
import ModalUpdateUser from './ModalUpdateUser';

const NavbarView = () => {
  const auth = useContext(AuthContext);
  const [openModal, setOpenModal] = useState(false);
  const [updateUser, setUpdateUser] = useState(false);
  const [user, setUser] = useState(null);
  const router = useHistory();

  const getUser = async () => {
    try {
      const user = await axios
        .get(`${configData.SERVER_URL}/user/${auth.userId}`)
        .then((res) => {
          return res.data.user;
        });
      setUser(user);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getUser();
  }, [auth.userId]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'js/webflow.js';
    script.async = true;
    document.body.appendChild(script);
    script.src =
      'https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=60e399f8a778918b4c4c009b';
    script.async = true;
    document.body.appendChild(script);
    window.addEventListener('scroll', handleScroll);
  });

  const handleScroll = () => {
    if (window.scrollY < 100) {
      document.getElementById('navbar').style.backgroundColor = 'transparent';
    } else {
      document.getElementById('navbar').style.backgroundColor = '#e6e6e6';
    }
  };

  const onDimiss = () => {
    openModal && setOpenModal(false);
    updateUser && setUpdateUser(false);
    // router.goBack();
  };

  const logout = () => {
    auth.logout();
    auth.role === 'admin' && router.push('/');
    router.go(0);
  };

  return (
    <span>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/front-end-palcosplus.webflow.css);


              html {
              	-webkit-tap-highlight-color: rgba(0,0,0,0);
              }
            	textarea {
              	resize: vertical;
          	}
        `,
        }}
      />
      <span className="af-view">
        <div className="af-class-stylepag-body">
          <div id="navbar" className="af-class-navbar" style={{ zIndex: 8 }}>
            <Link to="/" className="af-class-navbar-logo-wrapper w-nav-brand">
              <img
                src={window.location.origin + '/images/noTag-Estandar.svg'}
                alt=""
                className="af-class-navbar-logo"
              />
              <img
                src={window.location.origin + '/images/Icono-Estandar.svg'}
                alt=""
                className="af-class-navbar-logoicon"
              />
            </Link>
            <div className="af-class-navbar-rightcontent-wrapper">
              <div className="af-class-navbar-mobbg" />
              <div className="af-class-navbar-navmenu">
                <div className="af-class-navbar-navmain-wrapper">
                  <Link
                    to="/nosotros"
                    className="af-class-navbar-navlink w-inline-block"
                  >
                    <div className="af-class-navbar-navlink-text">Nosotros</div>
                    <div className="af-class-navbar-navlink-hoverbg" />
                  </Link>
                  <Link
                    to="/estadios"
                    className="af-class-navbar-navlink w-inline-block"
                  >
                    <div className="af-class-navbar-navlink-text">Estadios</div>
                    <div className="af-class-navbar-navlink-hoverbg" />
                  </Link>
                  <Link
                    to="/contacto"
                    className="af-class-navbar-navlink w-inline-block"
                  >
                    <div className="af-class-navbar-navlink-text">Contacto</div>
                    <div className="af-class-navbar-navlink-hoverbg" />
                  </Link>

                  <Link
                    to="/contacto#application"
                    className="af-class-navbar-navlink w-inline-block"
                  >
                    <div className="af-class-navbar-navlink-text">
                      Registrar propiedad
                    </div>
                    <div className="af-class-navbar-navlink-hoverbg" />
                  </Link>
                  {!user && (
                    <div className="af-class-navbar-access-wrapper">
                      <img
                        src={
                          window.location.origin +
                          '/images/icon-user_mobLogin_white.svg'
                        }
                        alt=""
                        className="af-class-navbar-mob-loginicon"
                      />
                      <button
                        onClick={() => setOpenModal(true)}
                        className="af-class-navbar-accessbutton w-button"
                      >
                        Iniciar sesión
                      </button>
                    </div>
                  )}
                  {user &&
                    (user.role !== 'admin' ? (
                      <div className="af-class-navbar-access-wrapper">
                        <img
                          src={
                            user.origin === 'web' ? `${user.image}` : user.image
                          }
                          sizes="(max-width: 991px) 75px, 40px"
                          srcSet={
                            user.origin === 'web'
                              ? `${user.image} 500w, ${user.image} 800w`
                              : user.image
                          }
                          alt=""
                          className="af-class-navbar-profilepic"
                          style={{
                            backgroundImage:
                              user.origin === 'web'
                                ? `${user.image}`
                                : user.image,
                          }}
                        />
                        <div className="af-class-navbar-mob-username">
                          {user && user.name}
                        </div>
                        <div
                          onClick={() => setUpdateUser(true)}
                          className="af-class-navbar-accessbutton af-class-loggedin w-button"
                        >
                          Mi cuenta
                        </div>
                        <button
                          style={{ marginLeft: '10px' }}
                          onClick={() => logout()}
                          className="af-class-navbar-accessbutton w-button"
                        >
                          Cerrar sesión
                        </button>
                      </div>
                    ) : (
                      <div className="af-class-navbar-access-wrapper">
                        <Link
                          to="/panel"
                          className="af-class-navbar-accessbutton w-button"
                        >
                          Panel Admin.
                        </Link>
                        <button
                          style={{ marginLeft: '10px' }}
                          onClick={() => logout()}
                          className="af-class-navbar-accessbutton w-button"
                        >
                          Cerrar sesión
                        </button>
                        <img
                          src={
                            window.location.origin +
                            '/images/admin-deepBlue.svg'
                          }
                          alt=""
                          className="af-class-navbar-adminicon"
                        />
                        <img
                          src={
                            window.location.origin + '/images/admin-white.svg'
                          }
                          alt=""
                          className="af-class-navbar-mob-adminicon"
                        />
                      </div>
                    ))}
                  <div className="af-class-navbar-mob-closebutton">
                    <img
                      src={window.location.origin + '/images/close-white.svg'}
                      alt=""
                      className="af-class-navbar-mob-closeicon"
                    />
                  </div>
                </div>
                <div className="af-class-navbar-mobsocial-wrapper">
                  <div className="af-class-navbar-socialicons-wrapper">
                    <Link
                      to="#"
                      className="af-class-navbar-sociallink w-inline-block"
                    >
                      <img
                        src={
                          window.location.origin + '/images/Facebook-white.svg'
                        }
                        alt=""
                        className="af-class-navbar-facebookicon"
                      />
                    </Link>
                    <Link
                      to="#"
                      className="af-class-navbar-sociallink w-inline-block"
                    >
                      <img
                        src={
                          window.location.origin + '/images/Instagram-white.svg'
                        }
                        alt=""
                        className="af-class-navbar-facebookicon"
                      />
                    </Link>
                  </div>
                  <p className="af-class-navbar-sociallabel">
                    ¡Síguenos en redes sociales!
                  </p>
                </div>
              </div>
              {/* <div className="af-class-navbar-cart-wrapper">
                <p id="cart-num" className="af-class-navbar-cartnum">0</p>
                <div className="af-class-navbar-carticon-wrapper">
                  <img src={window.location.origin + "/images/icon-cart_deepBlue.svg"}  alt="" className="af-class-navbar-carticon" />
                </div>
              </div> */}
              <div className="af-class-navbar-mobadmin-wrapper">
                <img
                  src={window.location.origin + '/images/admin-deepBlue.svg'}
                  alt=""
                  className="af-class-navbar-mob-adminicontop"
                />
              </div>
              <div className="af-class-navbar-mob-menubutton">
                <img
                  src={window.location.origin + '/images/menu-deepBlue.svg'}
                  alt=""
                  className="af-class-navbar-burger"
                />
              </div>
            </div>
            <div className="af-class-navbar-bganimscript w-embed w-script"></div>
          </div>
          {openModal && (
            <ModalInicioSesion openModal={openModal} onDimiss={onDimiss} />
          )}
          {updateUser && (
            <ModalUpdateUser
              openModal={updateUser}
              onDimiss={onDimiss}
              id={user.id}
            />
          )}
          {/* <div className="af-class-stylepag-container af-class-firstcont">
                <div className="af-class-navbar-access-wrapper">
                  <img src={window.location.origin+"images/dum_User_sq.jpg"}  sizes="(max-width: 991px) 75px, 40px" srcSet="images/dum_User_sq-p-500.jpeg 500w, images/dum_User_sq.jpg 800w" alt="" className="af-class-navbar-profilepic" />
                  <div className="af-class-navbar-mob-username">Jesús Daniel González Torres</div>
                  <Link to="#" className="af-class-navbar-accessbutton af-class-loggedin w-button">Mi cuenta</Link>
                </div>
                <div className="af-class-navbar-access-wrapper">
                  <Link to="#" className="af-class-navbar-accessbutton w-button">Panel Admin.</Link>
                  <img src={window.location.origin+"images/admin-deepBlue.svg"}  alt="" className="af-class-navbar-adminicon" />
                  <img src={window.location.origin+"images/admin-white.svg"}  alt="" className="af-class-navbar-mob-adminicon" />
                </div>
            </div> */}
          {/* [if lte IE 9]>
            <![endif] */}
        </div>
      </span>
    </span>
  );
};

export default NavbarView;

/* eslint-enable */
