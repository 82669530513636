import React, { forwardRef, useState, useEffect, FormEvent } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import TimeField from 'react-simple-timefield';
import ImageUploading from 'react-images-uploading';
import Switch from 'react-switch';
import configData from 'config.json';

import Tables, { DataCell } from 'components/Tables';
import Label from 'components/Label';
import Input from 'components/Input';
import Button from 'components/Button';
import Home from 'components/Icons/Home';
import Carousel from 'components/Carousel';

const CreateConcierto = forwardRef((props, ref) => {
  const [color, setColor] = useState();
  const [rows, setRows] = useState([]);
  const [isImportant, setIsImportant] = useState(false);
  const [imagesMain, setImagesMain] = useState([]);
  const [imagesBanner, setImgBanner] = useState([]);
  const [imagesAux, setImagesAux] = useState([]);
  const [currentImageMain, setCurrentImageMain] = useState(0);
  const [currentImageBanner, setCurrentImageBanner] = useState(0);
  const [currentImageAux, setCurrentImageAux] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [stadiums, setStadiums] = useState([]);
  const history = useHistory();
  const maxNumber = 5;

  const redirect = () => {
    history.push('/createPartido');
  };

  useEffect(() => {
    axios.get(`${configData.SERVER_URL}/stadiums`).then((res) => {
      const stadium = res.data;
      setStadiums(stadium.stadium);
    });
  }, []);

  const onChangesetImgMain = (imageList, addUpdateIndex) => {
    setImagesMain(imageList);
  };
  const onChangesetImgBanner = (imageList, addUpdateIndex) => {
    setImgBanner(imageList);
  };
  const onChangesetAux = (imageList, addUpdateIndex) => {
    setImagesAux(imageList);
  };

  const { control, handleSubmit, register, formState, errors } = useForm();

  const sendDataRequest = async (event) => {
    try {
      const formData = new FormData();
      formData.append('name', event.name);
      formData.append('tour_name', event.tour_name);
      formData.append('event_color', event.event_color);
      formData.append('type', event.type);
      formData.append('date', event.date);
      formData.append('time', event.time);
      formData.append('stadium_id', event.stadium_id);
      formData.append('isImportant', event.isImportant);
      formData.append('active', event.active);
      event.images.map((img) => {
        formData.append('images', img);
      });
      const res = await axios.post(`${configData.SERVER_URL}/events`, formData);
      const response = res.data.event;
      history.go(0);
    } catch (err) {
      console.log(err);
    }
  };

  const onsubmit = (data, e) => {
    e.preventDefault();
    const imgMain = imagesMain.map((img, idx) => {
      const type = img.file.type.split('/');
      return Object.defineProperty(img.file, 'name', {
        writable: 'true',
        value: 'principal-' + data.name + '-' + (idx + 1) + '.' + type[1],
      });
    });
    const imgBanner = imagesBanner.map((img, idx) => {
      const type = img.file.type.split('/');
      return Object.defineProperty(img.file, 'name', {
        writable: 'true',
        value: 'banner-' + data.name + '-' + (idx + 1) + '.' + type[1],
      });
    });
    const imgAux = imagesAux.map((img, idx) => {
      const type = img.file.type.split('/');
      return Object.defineProperty(img.file, 'name', {
        writable: 'true',
        value: 'map-' + data.name + '-' + (idx + 1) + '.' + type[1],
      });
    });
    const time = data.time.split(':');
    if (time[0].length === 3) {
      time[0] = time[0].slice(0, -1);
      data.time = time[0] + ':' + time[1];
    } else if (time[1].length === 3) {
      time[1] = time[1].slice(0, -1);
      data.time = time[0] + ':' + time[1];
    }
    const event = {
      name: data.name,
      tour_name: data.tour_name,
      event_color: color,
      type: data.type,
      date: data.date,
      time: data.time,
      stadium_id: data.stadium,
      isImportant: isImportant,
      active: isActive,
      images: [...imgMain, ...imgBanner, ...imgAux],
    };
    sendDataRequest(event);
  };

  const titleHeaders = ['Tarifa', 'Códigos postales'];

  function displayRows() {
    <tr className="">
      <DataCell className="px-4 py-2">Gratis</DataCell>
      <DataCell className="px-4 py-2">
        <Input
          name="type"
          input="text"
          className="w-full h-10 bg-gris-auxiliar"
          value={'45030, 45031, 45033, 45035, 45036'}
        />
      </DataCell>
    </tr>;
  }

  const getStadium = () => {
    return stadiums.map((stadium) => {
      return { value: stadium.id, label: stadium.name };
    });
  };

  return (
    <form className="w-full pt-28" onSubmit={handleSubmit(onsubmit)}>
      <div className="mx-auto pb-24" style={{ maxWidth: '1080px' }}>
        <div className="flex flex-col-reverse md:flex-row justify-between pb-10">
          <div className="flex flex-col">
            <div id="tracking" className="pb-4">
              <h3 className="text-gris-oscuro">
                Panel de Administrador / Estadios / Agregar Evento
              </h3>
            </div>
            <div id="title" className="pb-2">
              <h1 className="text-sport-blue font-bold text-5xl">
                Agregar Nuevo Evento
              </h1>
            </div>
          </div>
          <div className="flex flex-row pb-14 md:pb-0">
            <div className="">
              <Link to="/managerEventos">
                <Button className="w-40 h-14 bg-green-field rounded-lg shadow-sm hover:bg-green-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight ">
                  Listado
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="grid grid-flow-row pt-8 md:grid-cols-2 lg:grid-cols-3 gap-12">
          <div id="col1">
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Tipo de Evento</Label>
              </div>
              <Controller
                name="type"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={'concierto'}
                render={({ onChange }) => (
                  <Input
                    name="type"
                    input="select"
                    type="text"
                    className="w-full h-10"
                    options={[
                      { value: 'concierto', label: 'General' },
                      { value: 'partido', label: 'Partido' },
                    ]}
                    onChange={() => redirect()}
                  />
                )}
              />
              {errors.type && (
                <span className="text-rojo-error">{errors.type.message}</span>
              )}
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Hora local</Label>
              </div>
              <Controller
                name="time"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={'00:00'}
                as={
                  <div className="flex relative w-full">
                    <TimeField
                      name="time"
                      style={{ width: '100%' }}
                      className=" h-10 border rounded-sm focus:outline-none focus:ring-2 focus:ring-gris-oscuro text-gris-oscuro bg-gris-auxiliar "
                    />
                  </div>
                }
              />
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">
                  Nombre del tour/festival
                </Label>
              </div>
              <Controller
                name="tour_name"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={''}
                as={
                  <Input
                    name="tour_name"
                    input="input"
                    type="text"
                    className="w-full h-10"
                    placeholder="Última gira"
                  />
                }
              />
              {errors.tour_name && (
                <span className="text-rojo-error">
                  {errors.tour_name.message}
                </span>
              )}
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1 flex flex-row justify-between">
                <Label className="text-deep-blue">Color de evento</Label>
                <div className="w-10" style={{ backgroundColor: color }}></div>
              </div>
              <Controller
                name="color"
                control={control}
                defaultValue={''}
                render={({ onChange }) => (
                  <Input
                    name="color"
                    input="text"
                    placeholder="#034AA6"
                    className="w-full h-10"
                    onChange={(e) => setColor(e.target.value)}
                  />
                )}
              />
              {errors.color && (
                <span className="text-rojo-error">{errors.color.message}</span>
              )}
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Activo</Label>
              </div>
              <Switch
                onColor={'#034AA6'}
                onChange={() => setIsActive(!isActive)}
                checked={isActive}
                checkedIcon={false}
                uncheckedIcon={false}
              />
            </div>
          </div>
          <div id="col2">
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Fecha</Label>
              </div>
              <Controller
                name="date"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={''}
                as={
                  <Input
                    name="date"
                    input="input"
                    type="date"
                    className="w-full h-10"
                  />
                }
              />
              {errors.date && (
                <span className="text-rojo-error">{errors.date.message}</span>
              )}
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Nombre del evento</Label>
              </div>
              <Controller
                name="name"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={''}
                as={
                  <Input
                    name="name"
                    input="input"
                    type="text"
                    className="w-full h-10"
                    placeholder="Guns N' Roses"
                  />
                }
              />
              {errors.name && (
                <span className="text-rojo-error">{errors.name.message}</span>
              )}
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Estadio</Label>
              </div>
              <Controller
                name="stadium"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={''}
                as={
                  <Input
                    name="stadium"
                    input="select"
                    type="text"
                    className="w-full h-10"
                    options={[
                      { value: '', label: 'Selecciona un estadio' },
                      ...getStadium(),
                    ]}
                  />
                }
              />
              {errors.stadium && (
                <span className="text-rojo-error">
                  {errors.stadium.message}
                </span>
              )}
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Evento destacado</Label>
              </div>
              <Switch
                onColor={'#034AA6'}
                onChange={() => setIsImportant(!isImportant)}
                checked={isImportant}
                checkedIcon={false}
                uncheckedIcon={false}
              />
            </div>
          </div>
        </div>
        <div>
          <div id="subtitle">
            <h1 className="text-sport-blue font-bold text-4xl">Media</h1>
          </div>
          <div className="grid grid-flow-row pt-8 grid-cols-1 md:grid-cols-2 gap-12 pb-20">
            <ImageUploading
              multiple
              value={imagesMain}
              onChange={onChangesetImgMain}
              maxNumber={1}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <div className="">
                  <h1 className="text-ocean-black font-bold text-xl">
                    Logotipo
                  </h1>
                  <div className={imagesMain.length === 0 && 'hidden'}>
                    {imagesMain.length !== 0 && (
                      <Carousel current={(e) => setCurrentImageMain(e)}>
                        {imagesMain}
                      </Carousel>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <div className="flex flex-row">
                      <div className="">
                        <Button
                          type="button"
                          className={
                            'w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight'
                          }
                          style={isDragging ? { color: 'red' } : undefined}
                          onClick={onImageUpload}
                          disable={imagesMain.length === 5 && true}
                          {...dragProps}
                        >
                          CARGAR
                        </Button>
                      </div>
                      {imagesMain.length > 0 && (
                        <div className="pl-3">
                          <Button
                            type="button"
                            className={
                              'w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight '
                            }
                            disable={imagesMain.length === 0 && true}
                            onClick={() => onImageRemove(currentImageMain)}
                          >
                            Borrar
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </ImageUploading>
            <ImageUploading
              multiple
              value={imagesBanner}
              onChange={onChangesetImgBanner}
              maxNumber={maxNumber}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <div className="">
                  <h1 className="text-ocean-black font-bold text-xl">Banner</h1>
                  <div className={imagesBanner.length === 0 && 'hidden'}>
                    {imagesBanner.length !== 0 && (
                      <Carousel current={(e) => setCurrentImageBanner(e)}>
                        {imagesBanner}
                      </Carousel>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <div className="flex flex-row">
                      <div className="">
                        <Button
                          type="button"
                          className={
                            'w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight'
                          }
                          style={isDragging ? { color: 'red' } : undefined}
                          onClick={onImageUpload}
                          disable={imagesBanner.length === 5 && true}
                          {...dragProps}
                        >
                          CARGAR
                        </Button>
                      </div>
                      {imagesBanner.length > 0 && (
                        <div className="pl-3">
                          <Button
                            type="button"
                            className={
                              'w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight '
                            }
                            disable={imagesBanner.length === 0 && true}
                            onClick={() => onImageRemove(currentImageBanner)}
                          >
                            Borrar
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </ImageUploading>
            <ImageUploading
              multiple
              value={imagesAux}
              onChange={onChangesetAux}
              maxNumber={1}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <div className="">
                  <h1 className="text-ocean-black font-bold text-xl">
                    Imagen Auxiliar
                  </h1>
                  <div className={imagesAux.length === 0 && 'hidden'}>
                    {imagesAux.length !== 0 && (
                      <Carousel current={(e) => setCurrentImageAux(e)}>
                        {imagesAux}
                      </Carousel>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <div className="flex flex-row">
                      <div className="">
                        <Button
                          type="button"
                          className={
                            'w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight'
                          }
                          style={isDragging ? { color: 'red' } : undefined}
                          onClick={onImageUpload}
                          disable={imagesAux.length === 5 && true}
                          {...dragProps}
                        >
                          CARGAR
                        </Button>
                      </div>
                      {imagesAux.length > 0 && (
                        <div className="pl-3">
                          <Button
                            type="button"
                            className={
                              'w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight '
                            }
                            disable={imagesAux.length === 0 && true}
                            onClick={() => onImageRemove(currentImageAux)}
                          >
                            Borrar
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </ImageUploading>
          </div>
        </div>
        {/* <div className="pb-24">
          <h1 className="text-sport-blue font-bold text-4xl">
            Disponibilidad de propiedades
          </h1>
          <div className="pt-2 pb-5" style={{ width: "750px" }}>
            <Tables idheader={0} classHead="w-36" headers={titleHeaders}>
              <tr>
                <DataCell className="px-4 py-2">Butacas</DataCell>
                <DataCell className="px-4 py-2">
                  <Input
                    name="butacas"
                    input="text"
                    className="w-full h-10 bg-gris-auxiliar"
                  />
                </DataCell>
              </tr>
              <tr>
                <DataCell className="px-4 py-2">Plateas</DataCell>
                <DataCell className="px-4 py-2">
                  <Input
                    name="plateas"
                    input="text"
                    className="w-full h-10 bg-gris-auxiliar"
                  />
                </DataCell>
              </tr>
              <tr>
                <DataCell className="px-4 py-2">Palcos</DataCell>
                <DataCell className="px-4 py-2">
                  <Input
                    name="palcos"
                    input="text"
                    className="w-full h-10 bg-gris-auxiliar"
                  />
                </DataCell>
              </tr>
            </Tables>
          </div>
        </div> */}
        {/* <div className="pb-24">
          <h1 className="text-sport-blue font-bold text-4xl">
            Registro de Actividad
          </h1>
          <div className="w-3/4 grid grid-flow-row grid-cols-2">
            <div className="flex flex-col">
              <h4 className="text-ocean-black font-bold text-xl pt-4">
                Fecha de creacion
              </h4>
              <p>18/09/20 - 15:00</p>
            </div>
            <div className="flex flex-col">
              <h4 className="text-ocean-black font-bold text-xl pt-4">
                Fecha de última modificacion
              </h4>
              <p>27/12/20 - 10:00</p>
            </div>
          </div>
        </div> */}
        <div className="flex flex-row">
          <div className="pr-5">
            <Button
              type="submit"
              className="w-44 h-14 font-bold text-xl text-ocean-black bg-green-field rounded-lg shadow-sm hover:bg-green-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight "
              disable={
                imagesMain.length === 0 ||
                  imagesBanner.length === 0 ||
                  imagesAux.length === 0
                  ? true
                  : false
              }
            >
              GUARDAR
            </Button>
          </div>
          <div className="pr-5">
            <Button
              onClick={() => history.goBack()}
              className="w-44 h-14 font-bold text-xl text-white bg-aqua-grass rounded-lg shadow-sm hover:bg-aqua-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight "
              type="button"
            >
              CANCELAR
            </Button>
          </div>
          {/* <div className="pr-5">
            <Button className="w-44 h-14 font-bold text-xl text-white bg-aqua-grass rounded-lg shadow-sm hover:bg-aqua-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight ">
              ELIMINAR
            </Button>
          </div> */}
        </div>
      </div>
    </form>
  );
});

export default CreateConcierto;
