import React, { forwardRef, useState, useRef, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useClickAway } from 'react-use';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import XLSX from 'xlsx';
import configData from 'config.json';

import Tables, { DataCell } from 'components/Tables';
import Label from 'components/Label';
import Input from 'components/Input';
import Button from 'components/Button';
import Home from 'components/Icons/Home';
import ConfirmationModal from 'components/ConfirmationModal';

const ManagerManual = forwardRef((props, ref) => {
  const [arrayDelete, setArrayDelete] = useState([]);
  const [confirmationMessage, setConfirmationMessage] = useState(false);
  const refConfirmation = useRef(null);
  const router = useHistory();
  useClickAway(refConfirmation, () => {
    setConfirmationMessage(false);
  });
  const [manuals, setmanuals] = useState();

  const getData = async () => {
    try {
      const manuals = await axios
        .get(`${configData.SERVER_URL}/manual`)
        .then((res) => {
          return res.data.manuals;
        });
      setmanuals(manuals);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  function downloadBlob(blob, name) {
    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    const blobUrl = URL.createObjectURL(blob);
    // Create a link element
    const link = document.createElement('a');
    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = name;
    // Append link to the body
    document.body.appendChild(link);
    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    // Remove link from body
    document.body.removeChild(link);
  }

  const downloadFile = (filename) => {
    axios
      .get(`${configData.SERVER_URL}/manual/download/${filename}`)
      .then((res) => {
        let csvBlob = new Blob([res.data], {
          type: 'text/csv;charset=utf-8;',
        });
        downloadBlob(csvBlob, filename);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const titleHeaders = ['Sel.', 'Nombre', 'Download', 'Creación'];

  const titleHeadersConfirmation = ['Nombre'];

  function displayRows(manual, idx) {
    return (
      <tr className="hover:bg-gray-100">
        <DataCell className="">
          <Input
            id=""
            className="ring-2 ring-gris-oscuro cursor-pointer"
            height="h-5"
            width="w-5"
            name="checkbox"
            type="checkbox"
            onClick={() => clickChecbox(idx)}
          />
        </DataCell>
        <DataCell className="">{manual.name}</DataCell>
        <DataCell className="">
          <button
            className="text-blue-600 underline"
            onClick={() => downloadFile(manual.name)}
          >
            Descargar
          </button>
        </DataCell>
        <DataCell>{manual.inserted_date}</DataCell>
      </tr>
    );
  }

  const clickChecbox = (idx) => {
    const found = arrayDelete.findIndex((number) => number === idx);
    if (found === -1) {
      setArrayDelete([...arrayDelete, idx]);
    } else {
      let newArrray = arrayDelete;
      newArrray.splice(found, 1);
      if (newArrray.length === 0) {
        setArrayDelete([]);
      } else {
        setArrayDelete(newArrray);
      }
    }
  };

  const confirmDelete = async () => {
    for (let i = 0; i < manuals.length; i++) {
      const found = arrayDelete.findIndex((number) => number === i);
      if (found !== -1) {
        try {
          await axios.delete(
            `${configData.SERVER_URL}/events/competition/${manuals[i].id}`
          );
          await axios.delete(
            `${configData.SERVER_URL}/competition/${manuals[i].id}`
          );
          router.go(0);
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  return (
    <div className="flex w-full pt-28">
      <div className="mx-auto pb-24" style={{ maxWidth: '1080px' }}>
        <div className="flex flex-col-reverse md:flex-row justify-between pb-10">
          <div className="flex flex-col">
            <div id="tracking" className="pb-4">
              <h3 className="text-gris-oscuro">
                Panel de Administrador / Manuales
              </h3>
            </div>
            <div id="title" className="pb-2">
              <h1 className="text-sport-blue font-bold text-6xl">
                Gestionar Manuales
              </h1>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="pb-14 md:pb-0">
              <Link to="/panel">
                <Button className="w-40 h-14 bg-green-field hover:bg-green-highlight focus:ring-green-highlight ">
                  PANEL ADMIN.
                </Button>
              </Link>
            </div>
            <div className="hidden lg:hidden md:flex flex-row">
              <div className="px-4">
                <Link to="/createManual">
                  <Button className="w-full sm:w-36 h-14 bg-green-field hover:bg-green-highlight focus:ring-green-highlight ">
                    NUEVO
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="md:hidden lg:flex w-full md:w-80 flex flex-col md:flex-row md:justify-between">
          <div className="pb-5 md:pb-0">
            <Link to="/createManual">
              <Button className="w-full md:w-36 h-14 bg-green-field hover:bg-green-highlight focus:ring-green-highlight ">
                NUEVO
              </Button>
            </Link>
          </div>
        </div>
        <div>
          {/* <div className="flex flex-row-reverse">
            <Button
              className="w-32 h-10 bg-rojo-error text-white p-2"
              onClick={() => setConfirmationMessage(true)}
              disable={arrayDelete.length > 0 ? false : true}
            >
              ELIMINAR
            </Button>
          </div> */}
          <div className="pt-2 overflow-x-auto">
            <Tables
              className="w-auto"
              idheader={0}
              classHead="w-20"
              headers={titleHeaders}
            >
              {manuals &&
                manuals.map((manual, idx) => displayRows(manual, idx))}
            </Tables>
          </div>
        </div>
      </div>
      <ConfirmationModal
        openModal={confirmationMessage}
        onUpdate={() => confirmDelete()}
        onDimiss={() => setConfirmationMessage(false)}
        ref={refConfirmation}
        className={`${confirmationMessage
            ? 'z-50 transform transition-all ease-in border absolute bg-white rounded-lg shadow-md left-0 right-0 w-6/12 h-auto mx-auto'
            : 'hidden'
          }`}
        message={'Estas seguro de eliminar los siguientes datos?'}
      >
        <Tables
          className="w-full overflow-x-auto"
          headers={titleHeadersConfirmation}
        >
          {manuals &&
            manuals.map((manual, idx) => {
              const found = arrayDelete.findIndex((number) => number === idx);
              if (found !== -1) {
                return (
                  <tr>
                    <DataCell className="truncate">{manual.name}</DataCell>
                  </tr>
                );
              }
            })}
        </Tables>
      </ConfirmationModal>
    </div>
  );
});

export default ManagerManual;
