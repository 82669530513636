/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=60e399f8a778918b4c4c009b").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class CarritoView extends React.Component {

  render() {
    const proxies = CarritoView.Controller !== CarritoView ? transformProxies(this.props.children) : {

    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{
          __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/front-end-palcosplus.webflow.css);


            html {
              -webkit-tap-highlight-color: rgba(0,0,0,0);
            }
            textarea {
              resize: vertical;
            }
            .w-lightbox-backdrop {
              background: rgba(0,1,13,.85);
            }
        ` }} />
        <span className="af-view">
          <div>
            <div className="af-class-popup-wrapper">
              <div className="af-class-popup-container af-class-cart-container">
                <div className="af-class-form-sectiontitle-wrapper"><img src="images/icon-cart_blue.svg" loading="lazy" alt className="af-class-form-sectiontitle-icon" />
                  <h2 className="af-class-form-sectiontitle">Carrito</h2>
                </div>
                <div className="af-class-popup-close-wrapper"><img src="images/close-sp-gray.svg" loading="lazy" alt className="af-class-popup-closeicon" /></div>
                <div className="af-class-cart-rents-list-wrapper">
                  <div className="af-class-cart-rent">
                    <div className="af-class-cart-rent-pics-wrapper">
                      <div className="af-class-car-rent-gallery-wrapper">
                        <div data-delay={2500} data-animation="slide" data-autoplay={1} data-duration={800} data-infinite={1} className="af-class-cart-rent-gallery w-slider">
                          <div className="af-class-cart-rent-gallery-mask w-slider-mask">
                            <div className="af-class-cart-rent-gallery-slide w-slide"><img src="images/Palco4.jpg" loading="lazy" sizes="(max-width: 479px) 48vw, (max-width: 767px) 254.328125px, 225px" srcSet="images/Palco4-p-500.jpeg 500w, images/Palco4-p-1080.jpeg 1080w, images/Palco4.jpg 1200w" alt className="af-class-cart-rent-gallery-img" /></div>
                            <div className="af-class-cart-rent-gallery-slide w-slide"><img src="images/Palco3.jpeg" loading="lazy" sizes="(max-width: 479px) 48vw, (max-width: 767px) 254.328125px, 225px" srcSet="images/Palco3-p-1080.jpeg 1080w, images/Palco3.jpeg 1200w" alt className="af-class-cart-rent-gallery-img" /></div>
                            <div className="af-class-cart-rent-gallery-slide w-slide"><img src="images/Palco1.jpeg" loading="lazy" sizes="(max-width: 479px) 48vw, (max-width: 767px) 254.328125px, 225px" srcSet="images/Palco1-p-1080.jpeg 1080w, images/Palco1.jpeg 1200w" alt className="af-class-cart-rent-gallery-img" /></div>
                            <div className="af-class-cart-rent-gallery-slide w-slide"><img src="images/Palco2.jpeg" loading="lazy" sizes="(max-width: 479px) 48vw, (max-width: 767px) 254.328125px, 225px" srcSet="images/Palco2-p-1080.jpeg 1080w, images/Palco2.jpeg 1200w" alt className="af-class-cart-rent-gallery-img" /></div>
                            <div className="af-class-cart-rent-gallery-slide w-slide"><img src="images/Palco6.jpeg" loading="lazy" sizes="(max-width: 479px) 48vw, (max-width: 767px) 254.328125px, 225px" srcSet="images/Palco6-p-1080.jpeg 1080w, images/Palco6.jpeg 1200w" alt className="af-class-cart-rent-gallery-img" /></div>
                          </div>
                          <div className="af-class-cart-rent-gallery-arrow w-slider-arrow-left">
                            <div className="w-icon-slider-left" />
                          </div>
                          <div className="af-class-cart-rent-gallery-arrow w-slider-arrow-right">
                            <div className="w-icon-slider-right" />
                          </div>
                          <div className="af-class-cart-rent-gallery-nav w-slider-nav w-round" />
                        </div>
                        <div className="af-class-cart-rent-gallery-disablecontrol" />
                      </div>
                      <div className="af-class-cart-rent-buttons-wrapper">
                        <a href="#" className="af-class-cart-auxbutton w-button">Detalles</a>
                        <a href="#" className="af-class-cart-auxbutton af-class-cart-button-remove w-button">Eliminar</a>
                      </div>
                    </div>
                    <div className="af-class-cart-rent-data-wrapper">
                      <div className="af-class-cart-rent-eventname-wrapper">
                        <div>Atlas F.C.</div>
                        <div className="af-class-cart-rent-vs">Vs</div>
                        <div>Guadalajara</div>
                      </div>
                      <div className="af-class-cart-rent-stadium">Estadio Jalisco</div>
                      <div className="af-class-cart-rent-roundtour-wrapper">
                        <div>Jornada 15</div>
                        <div className="af-class-cart-rent-competition">Liga MX Clausura 2021</div>
                      </div>
                      <div className="af-class-cat-eventcard-datetime-wrapper">
                        <div className="af-class-cat-eventcard-data af-class-eventcard-databold">19</div>
                        <div className="af-class-cat-eventcard-data af-class-eventcard-datamedium">Nov</div>
                        <div className="af-class-cat-eventcard-data">2021</div>
                        <div className="af-class-cat-eventcard-data">···</div>
                        <div className="af-class-cat-eventcard-data af-class-eventcard-databold">21:00</div>
                        <div className="af-class-cat-eventcard-data">Hrs</div>
                      </div>
                      <div className="af-class-cart-rent-property-wrapper">
                        <div className="af-class-cart-rent-propname">Palco #000 Nivel 3</div>
                        <div className="af-class-cart-rent-propdata-wrapper">
                          <div className="af-class-cart-rent-accessnum">10 tarjetas</div>
                          <div className="af-class-cart-rent-price-wrapper">
                            <div className="af-class-cart-rent-price">$6,300</div>
                            <div className="af-class-cart-rent-curr">Mxn</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="af-class-cat-contentdivider af-class-cart-divider" />
                  <div className="af-class-cart-rent">
                    <div className="af-class-cart-rent-pics-wrapper">
                      <div className="af-class-car-rent-gallery-wrapper">
                        <div data-delay={2500} data-animation="slide" data-autoplay={1} data-duration={800} data-infinite={1} className="af-class-cart-rent-gallery w-slider">
                          <div className="af-class-cart-rent-gallery-mask w-slider-mask">
                            <div className="af-class-cart-rent-gallery-slide w-slide"><img src="images/Palco4.jpg" loading="lazy" sizes="(max-width: 479px) 48vw, (max-width: 767px) 254.328125px, 225px" srcSet="images/Palco4-p-500.jpeg 500w, images/Palco4-p-1080.jpeg 1080w, images/Palco4.jpg 1200w" alt className="af-class-cart-rent-gallery-img" /></div>
                            <div className="af-class-cart-rent-gallery-slide w-slide"><img src="images/Palco3.jpeg" loading="lazy" sizes="(max-width: 479px) 48vw, (max-width: 767px) 254.328125px, 225px" srcSet="images/Palco3-p-1080.jpeg 1080w, images/Palco3.jpeg 1200w" alt className="af-class-cart-rent-gallery-img" /></div>
                            <div className="af-class-cart-rent-gallery-slide w-slide"><img src="images/Palco1.jpeg" loading="lazy" sizes="(max-width: 479px) 48vw, (max-width: 767px) 254.328125px, 225px" srcSet="images/Palco1-p-1080.jpeg 1080w, images/Palco1.jpeg 1200w" alt className="af-class-cart-rent-gallery-img" /></div>
                            <div className="af-class-cart-rent-gallery-slide w-slide"><img src="images/Palco2.jpeg" loading="lazy" sizes="(max-width: 479px) 48vw, (max-width: 767px) 254.328125px, 225px" srcSet="images/Palco2-p-1080.jpeg 1080w, images/Palco2.jpeg 1200w" alt className="af-class-cart-rent-gallery-img" /></div>
                            <div className="af-class-cart-rent-gallery-slide w-slide"><img src="images/Palco6.jpeg" loading="lazy" sizes="(max-width: 479px) 48vw, (max-width: 767px) 254.328125px, 225px" srcSet="images/Palco6-p-1080.jpeg 1080w, images/Palco6.jpeg 1200w" alt className="af-class-cart-rent-gallery-img" /></div>
                          </div>
                          <div className="af-class-cart-rent-gallery-arrow w-slider-arrow-left">
                            <div className="w-icon-slider-left" />
                          </div>
                          <div className="af-class-cart-rent-gallery-arrow w-slider-arrow-right">
                            <div className="w-icon-slider-right" />
                          </div>
                          <div className="af-class-cart-rent-gallery-nav w-slider-nav w-round" />
                        </div>
                        <div className="af-class-cart-rent-gallery-disablecontrol" />
                      </div>
                      <div className="af-class-cart-rent-buttons-wrapper">
                        <a href="#" className="af-class-cart-auxbutton w-button">Detalles</a>
                        <a href="#" className="af-class-cart-auxbutton af-class-cart-button-remove w-button">Eliminar</a>
                      </div>
                    </div>
                    <div className="af-class-cart-rent-data-wrapper">
                      <div className="af-class-cart-rent-eventname-wrapper">
                        <div>Guns N' Roses en vivo</div>
                      </div>
                      <div className="af-class-cart-rent-stadium">Estadio Jalisco</div>
                      <div className="af-class-cart-rent-roundtour-wrapper">
                        <div>Not in this Lifetime Tour</div>
                      </div>
                      <div className="af-class-cat-eventcard-datetime-wrapper">
                        <div className="af-class-cat-eventcard-data af-class-eventcard-databold">18</div>
                        <div className="af-class-cat-eventcard-data af-class-eventcard-datamedium">Oct</div>
                        <div className="af-class-cat-eventcard-data">2021</div>
                        <div className="af-class-cat-eventcard-data">···</div>
                        <div className="af-class-cat-eventcard-data af-class-eventcard-databold">20:00</div>
                        <div className="af-class-cat-eventcard-data">Hrs</div>
                      </div>
                      <div className="af-class-cart-rent-property-wrapper">
                        <div className="af-class-cart-rent-propname">Palco #000 Nivel 3</div>
                        <div className="af-class-cart-rent-propdata-wrapper">
                          <div className="af-class-cart-rent-accessnum">10 tarjetas</div>
                          <div className="af-class-cart-rent-price-wrapper">
                            <div className="af-class-cart-rent-price">$9,500</div>
                            <div className="af-class-cart-rent-curr">Mxn</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="af-class-cart-pay-wrapper">
                  <div className="af-class-cart-subtotal-wrapper">
                    <div className="af-class-cart-subtotal-label">Subtotal</div>
                    <div className="af-class-cart-subtotal-sum-wrapper">
                      <div className="af-class-cart-subtotal-value">$15,800</div>
                      <div className="af-class-cart-subtotal-curr">Mxn</div>
                    </div>
                  </div>
                  <a href="#" className="af-class-gen-button af-class-cart-gen-button w-button">Proceder al checkout</a>
                  <div className="af-class-form-error-wrapper">
                    <div>Al parecer hay un error con la solicitud. Uno de los lugares que deseas agregar ya ha sido rentado.</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-popup-wrapper">
              <div className="af-class-popup-container af-class-gen-alert-container">
                <div className="af-class-popup-close-wrapper"><img src="images/close-sp-gray.svg" loading="lazy" alt className="af-class-popup-closeicon" /></div>
                <div className="af-class-form-sectiontitle-wrapper">
                  <h2 className="af-class-form-sectiontitle">Antes... Inicia sesión</h2>
                </div>
                <p className="af-class-form-note">Para poder proceder con el checkout de tus rentas es necesario que inicies sesión. Ingresa tus datos e inténtalo de nuevo.</p>
                <div className="af-class-cart-login-buttons-wrapper">
                  <a href="#" className="af-class-gen-button af-class-cart-gen-button w-button">Iniciar sesión</a>
                  <a href="#" className="af-class-gen-button af-class-cart-gen-button w-button">Botón de facebook</a>
                  <a href="#" className="af-class-gen-button af-class-cart-gen-button w-button">botón de google</a>
                </div>
                <div className="af-class-cart-login-register-wrapper">
                  <p className="af-class-form-note"><span className="af-class-form-notebold">¿No tienes cuenta?</span> No hay problema, realizar tu registro te toma sólo 1 minuto.</p>
                  <a href="#" className="af-class-gen-button af-class-cart-gen-button w-button">Registrarme</a>
                </div>
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default CarritoView

/* eslint-enable */