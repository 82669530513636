import React, { forwardRef, useState, useRef, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useClickAway } from 'react-use';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import XLSX from 'xlsx';
import configData from 'config.json';

import Tables, { DataCell } from 'components/Tables';
import Label from 'components/Label';
import Input from 'components/Input';
import Button from 'components/Button';
import Home from 'components/Icons/Home';
import ConfirmationModal from 'components/ConfirmationModal';

import './CreatePalco.css';

const ManagerPalco = forwardRef((props, ref) => {
  const [arrayDelete, setArrayDelete] = useState([]);
  const [confirmationMessage, setConfirmationMessage] = useState(false);
  const [filters, setFilters] = useState(null);
  const [notfilter, setNotfilter] = useState(false);
  const [filteredPalcos, setFilteredPalcos] = useState([]);
  const refConfirmation = useRef(null);
  const router = useHistory();
  useClickAway(refConfirmation, () => {
    setConfirmationMessage(false);
  });
  const { control, handleSubmit, register, formState, errors } = useForm();
  const [data, setdata] = useState({
    palcos: [],
    stadiums: [],
  });

  const getData = async () => {
    try {
      const propiedades = await axios
        .get(`${configData.SERVER_URL}/palcos`)
        .then((res) => {
          return res.data.palcos;
        });
      const stadiums = await axios
        .get(`${configData.SERVER_URL}/stadiums`)
        .then((res) => {
          return res.data.stadium;
        });
      // const palcos = propiedades.filter(
      //   (propiedad) => propiedad.type === 'palco'
      // );
      const palcos = propiedades;
      setdata({ palcos, stadiums });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getStadiumById = (id) => {
    if (data.stadiums) {
      return data.stadiums.find((stadium) => stadium.id === id);
    }
  };

  const onsubmit = (data, e) => {
    e.preventDefault();
    setFilters(data);
  };

  const Filter = (palcos, filters) => {
    const filterStadium = (palco, stadium_id) =>
      stadium_id === '' || stadium_id === 'all'
        ? palcos
        : palco?.stadium_id === stadium_id;
    const filterDate = (palco, cration_date, end_date) =>
      cration_date === ''
        ? palcos
        : palco?.date >= cration_date && palco?.date <= end_date;
    const palcosFiltered = palcos?.filter((palco) =>
      filterStadium(palco, filters?.stadium)
    );
    if (palcosFiltered.length > 0) {
      setNotfilter(false);
      setFilteredPalcos(palcosFiltered);
    } else {
      setNotfilter(true);
    }
  };

  useEffect(() => {
    filters && Filter(data.palcos, filters);
  }, [filters]);

  const titleHeaders = [
    'Sel.',
    'Editar',
    'Tipo',
    'Nombre',
    'Estadio',
    'Zona',
    'Accesos',
    'Tarjetas',
    'Precio Dueño',
    'Comisión',
    'Precio',
    'Creación',
    'Ultima modif.',
  ];

  const titleHeadersConfirmation = ['Tipo', 'Nombre', 'Estadio', 'Zona'];

  function displayRows(palco, idx) {
    return (
      <tr className="hover:bg-gray-100">
        <DataCell className="">
          <Input
            id=""
            className="ring-2 ring-gris-oscuro cursor-pointer"
            height="h-5"
            width="w-5"
            name="checkbox"
            type="checkbox"
            onClick={() => clickChecbox(idx)}
          />
        </DataCell>
        <DataCell className="">
          <Link
            to={`/updatePalco/${palco.id}`}
            className="text-blue-600 underline"
          >
            Editar registro
          </Link>
        </DataCell>
        <DataCell className="space-x-4">{palco.type}</DataCell>
        <DataCell>{palco.name}</DataCell>
        <DataCell>{getStadiumById(palco.stadium_id).name}</DataCell>
        <DataCell>{palco.zone}</DataCell>
        <DataCell>{palco.access}</DataCell>
        <DataCell>{palco.num_cards}</DataCell>
        <DataCell>${palco.owner_price}</DataCell>
        <DataCell>{palco.comision}%</DataCell>
        <DataCell>${palco.price}</DataCell>
        <DataCell>{palco.inserted_date}</DataCell>
        <DataCell>{palco.modified_date}</DataCell>
      </tr>
    );
  }

  const exportData = (palcos) => {
    let req = [];
    palcos.map((palco) => {
      const xlsData = {
        Tipo: palco.type,
        Nombre: palco.name,
        Estadio: getStadiumById(palco.stadium_id).name,
        Zona: palco.zone,
        Accesos: palco.access,
        Tarjetas: palco.num_cards,
        'Precio Dueño': palco.owner_price,
        Comisión: palco.comision,
        Precio: palco.price,
        Creación: palco.inserted_date,
        'Ultima modif.': palco.modified_date,
      };
      req.push(xlsData);
    });
    convertJSONtoXLSX(req);
  };

  const convertJSONtoXLSX = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();

    // Cambiar el nombre, no se si sea necesario especificar que es o se puede poner la fecha
    XLSX.utils.book_append_sheet(workbook, worksheet, 'PalcosplusData'); //PalcosPlusData es el nombre de la hoja dentro del libro xlsx
    // Buffer
    XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    // Binary String
    XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    // Create a file
    XLSX.writeFile(workbook, 'propiedades.xlsx'); //data.xlsx es el nombre del archivo, seria cambiarlo o buscar uno mas adoc
  };

  const clickChecbox = (idx) => {
    const found = arrayDelete.findIndex((number) => number === idx);
    if (found === -1) {
      setArrayDelete([...arrayDelete, idx]);
    } else {
      let newArrray = arrayDelete;
      newArrray.splice(found, 1);
      if (newArrray.length === 0) {
        setArrayDelete([]);
      } else {
        setArrayDelete(newArrray);
      }
    }
  };

  const confirmDelete = async () => {
    for (let i = 0; i < data.palcos.length; i++) {
      const found = arrayDelete.findIndex((number) => number === i);
      if (found !== -1) {
        try {
          await axios.delete(
            `${configData.SERVER_URL}/palcos/${data.palcos[i].id}`
          );
          router.go(0);
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  return (
    <form className="w-full pt-28" onSubmit={handleSubmit(onsubmit)}>
      <div className="container-pp mx-auto pb-24">
        <div className="flex flex-col-reverse md:flex-row justify-between pb-10">
          <div className="flex flex-col">
            <div id="tracking" className="pb-4">
              <h3 className="text-gris-oscuro">
                Panel de Administrador / Propiedades / Palcos
              </h3>
            </div>
            <div id="title" className="pb-2">
              <h1 className="text-sport-blue font-bold text-6xl">
                Gestionar Propiedades
              </h1>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="pb-14 md:pb-0">
              <Link to="/panel">
                <Button className="w-40 h-14 bg-green-field hover:bg-green-highlight focus:ring-green-highlight ">
                  PANEL ADMIN.
                </Button>
              </Link>
            </div>
            <div className="hidden lg:hidden md:flex flex-row">
              <div className="px-4">
                <Link to="/createPalco">
                  <Button className="w-full sm:w-36 h-14 bg-green-field hover:bg-green-highlight focus:ring-green-highlight ">
                    NUEVO
                  </Button>
                </Link>
              </div>
              <div>
                <Button
                  onClick={() =>
                    exportData(
                      filteredPalcos.length > 0 ? filteredPalcos : data.palcos
                    )
                  }
                  className="w-full sm:w-36 h-14 text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight"
                >
                  DESCARGAR
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="md:hidden lg:flex w-full md:w-80 flex flex-col md:flex-row md:justify-between">
          <div className="pb-5 md:pb-0">
            <Link to="/createPalco">
              <Button className="w-full md:w-36 h-14 bg-green-field hover:bg-green-highlight focus:ring-green-highlight ">
                NUEVO
              </Button>
            </Link>
          </div>
          <div>
            <Button
              onClick={() =>
                exportData(
                  filteredPalcos.length > 0 ? filteredPalcos : data.palcos
                )
              }
              className="w-full md:w-36 h-14 text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight"
            >
              DESCARGAR
            </Button>
          </div>
        </div>
        <div className="grid grid-flow-row pt-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-12 gap-y-8 pb-8">
          <div id="col">
            <div className="pb-1">
              <Label className="text-deep-blue">Estadio</Label>
            </div>
            <Controller
              name="stadium"
              control={control}
              as={
                <select
                  ref={register}
                  id="stadium"
                  name="stadium"
                  className="border rounded-sm focus:outline-none focus:ring-2 focus:ring-gris-oscuro text-gris-oscuro bg-gris-auxiliar w-full h-10"
                >
                  <option value="all">Estadio</option>
                  {data.stadiums &&
                    data.stadiums.map((stadium) => {
                      return <option value={stadium.id}>{stadium.name}</option>;
                    })}
                </select>
              }
            />
          </div>
          <div id="col">
            <div className="pb-1">
              <Label className="text-deep-blue">Desde (Fecha creación)</Label>
            </div>
            <Controller
              name="creation_date"
              control={control}
              defaultValue={''}
              as={
                <Input
                  name="creation_date"
                  input="input"
                  type="date"
                  className="w-full h-10"
                />
              }
            />
            {errors.creation_date && (
              <span className="text-rojo-error">
                {errors.creation_date.message}
              </span>
            )}
          </div>
          <div id="col">
            <div className="pb-1">
              <Label className="text-deep-blue">Hasta (Fecha Creación)</Label>
            </div>
            <Controller
              name="end_date"
              control={control}
              defaultValue={''}
              as={
                <Input
                  name="end_date"
                  input="input"
                  type="date"
                  className="w-full h-10"
                />
              }
            />
            {errors.end_date && (
              <span className="text-rojo-error">{errors.end_date.message}</span>
            )}
          </div>
          <div id="col">
            <div>
              <Button
                type="submit"
                className="w-full md:w-36 h-14 bg-green-field hover:bg-green-highlight focus:ring-green-highlight "
              >
                APLICAR
              </Button>
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-row-reverse">
            <Button
              className="w-32 h-10 bg-rojo-error text-white p-2"
              onClick={() => setConfirmationMessage(true)}
              disable={arrayDelete.length > 0 ? false : true}
            >
              ELIMINAR
            </Button>
          </div>
          <div className="pt-2 overflow-x-auto">
            <Tables className="w-auto" headers={titleHeaders}>
              {filteredPalcos.length > 0
                ? filteredPalcos.map((palco, idx) => displayRows(palco, idx))
                : data.palcos.map((palco, idx) => displayRows(palco, idx))}
            </Tables>
          </div>
          <div className="pt-8">
            <Button className="w-56 h-14 bg-gris-auxiliar hover:bg-gris-medio focus:ring-gris-medio ">
              CARGAR MÁS
            </Button>
          </div>
        </div>
      </div>
      <ConfirmationModal
        openModal={confirmationMessage}
        onUpdate={() => confirmDelete()}
        onDimiss={() => setConfirmationMessage(false)}
        ref={refConfirmation}
        className={`${confirmationMessage
            ? 'z-50 transform transition-all ease-in border absolute bg-white rounded-lg shadow-md left-0 right-0 w-6/12 h-auto mx-auto'
            : 'hidden'
          }`}
        message={'Estas seguro de eliminar los siguientes datos?'}
      >
        <Tables
          className="w-full overflow-x-auto"
          headers={titleHeadersConfirmation}
        >
          {data.palcos &&
            data.palcos.map((palco, idx) => {
              const found = arrayDelete.findIndex((number) => number === idx);
              if (found !== -1) {
                return (
                  <tr>
                    <DataCell className="truncate">{palco.type}</DataCell>
                    <DataCell className="truncate">{palco.name}</DataCell>
                    <DataCell className="truncate">
                      {getStadiumById(palco.stadium_id).name}
                    </DataCell>
                    <DataCell className="truncate">{palco.zone}</DataCell>
                  </tr>
                );
              }
            })}
        </Tables>
      </ConfirmationModal>
    </form>
  );
});

export default ManagerPalco;
