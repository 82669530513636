import { DialogOverlay, DialogContent } from '@reach/dialog';
import React, { useState, useRef, useEffect, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import '@reach/dialog/styles.css';
import '../App.css';
import { AuthContext } from './auth-context';
import { useHistory } from 'react-router-dom';
import configData from 'config.json';

const ModalInicioSesion = ({ isOpen, onDimiss }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const auth = useContext(AuthContext);
  const [dimensions, setDimensions] = useState(window.innerWidth);
  const [widthstyle, setWidthstyle] = useState();
  const [image, setImage] = useState();
  const [imageUrl, setImageUrl] = useState();
  const fileInput = useRef();
  const router = useHistory();
  const { handleSubmit, register, control, formState, errors, watch } = useForm(
    {
      mode: 'all',
    }
  );

  useEffect(() => {
    if (!image) {
      return;
    }
    const file = new FileReader();
    file.onload = () => {
      setImageUrl(file.result);
    };
    file.readAsDataURL(image);
  }, [image]);

  const sendDataRequest = async (user) => {
    try {
      const formData = new FormData();
      formData.append('name', user.name);
      formData.append('last_name', user.last_name);
      formData.append('email', user.email);
      formData.append('tel', user.tel);
      formData.append('date_birth', user.date_birth);
      formData.append('password', user.password);
      formData.append('image', user.image);
      formData.append('role', user.role);
      formData.append('origin', user.origin);
      formData.append('alias', user.alias);
      formData.append('country', user.country);
      formData.append('state', user.state);
      formData.append('city', user.city);
      formData.append('suburb', user.suburb);
      formData.append('postal_code', user.postal_code);
      formData.append('address', user.address);
      formData.append('interior_num', user.interior_num);
      const res = await axios.post(
        `${configData.SERVER_URL}/user/signup`,
        formData
      );
      auth.login(res.data.userId, res.data.token);
    } catch (err) {
      console.log(err);
    }
  };

  const onsubmit = async (data, e) => {
    e.preventDefault();
    const user = {
      name: data.name,
      last_name: data.lastname,
      email: data.email,
      tel: data.phone,
      date_birth: data.birthday,
      password: data.password,
      image: image,
      role: 'user',
      origin: 'web',
      alias: data.alias,
      country: data.country,
      state: data.state,
      city: data.city,
      suburb: data.suburb,
      postal_code: data.postal_code,
      address: data.address,
      interior_num: data.interior_num,
    };
    await sendDataRequest(user);
    onDimiss();
  };

  useEffect(() => {
    function handleResize() {
      setDimensions(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  });

  useEffect(() => {
    if (dimensions > 1153) {
      setWidthstyle('50%');
    } else {
      setWidthstyle('80%');
    }
  }, [dimensions]);

  return (
    <DialogOverlay
      aria-label="modal"
      aria-labelledby="dialog-title"
      isOpen={isOpen}
      onDismiss={onDimiss}
      className="z-40 border"
    >
      <DialogContent
        className="container rounded-lg"
        aria-labelledby="dialog-content"
        style={{ width: widthstyle }}
      >
        <span className="af-view w-full">
          <div>
            <div className="af-class-popup-wrapper">
              <div className="af-class-popup-container">
                <div className="af-class-form-block w-form">
                  <form
                    id="wf-form-signup"
                    name="wf-form-signup"
                    data-name="signup"
                    className="af-class-form"
                    onSubmit={handleSubmit(onsubmit)}
                  >
                    <div className="af-class-form-section">
                      <div className="af-class-form-sectiontitle-wrapper">
                        <img
                          src="images/icon-userEdit_blue.svg"
                          loading="lazy"
                          alt=""
                          className="af-class-form-sectiontitle-icon"
                        />
                        <h2 className="af-class-form-sectiontitle">
                          Crear nueva cuenta
                        </h2>
                      </div>
                      <div className="af-class-form-content-wrapper">
                        <div className="af-class-form-field-wrapper">
                          <label
                            htmlFor="name-2"
                            className="af-class-form-label"
                          >
                            Nombre(s) *
                          </label>
                          <input
                            ref={register}
                            type="text"
                            className="af-class-form-textinput w-input"
                            maxLength={256}
                            name="name"
                            data-name="name"
                            placeholder="....."
                            id="name"
                            required
                          />
                        </div>
                        <div className="af-class-form-field-wrapper">
                          <label
                            htmlFor="lastname"
                            className="af-class-form-label"
                          >
                            Apellidos *
                          </label>
                          <input
                            ref={register}
                            type="text"
                            className="af-class-form-textinput w-input"
                            maxLength={256}
                            name="lastname"
                            data-name="lastname"
                            placeholder="....."
                            id="lastname"
                            required
                          />
                        </div>
                        <div className="af-class-form-field-wrapper">
                          <label
                            htmlFor="email-2"
                            className="af-class-form-label"
                          >
                            Correo electrónico *
                          </label>
                          <input
                            ref={register}
                            type="email"
                            className="af-class-form-textinput w-input"
                            maxLength={256}
                            name="email"
                            data-name="email"
                            placeholder="....."
                            id="email"
                            required
                          />
                        </div>
                        <div className="af-class-form-field-wrapper">
                          <label
                            htmlFor="phone"
                            className="af-class-form-label"
                          >
                            Teléfono *
                          </label>
                          <input
                            ref={register}
                            type="tel"
                            className="af-class-form-textinput w-input"
                            maxLength={10}
                            name="phone"
                            data-name="phone"
                            placeholder="....."
                            id="phone"
                            required
                          />
                        </div>
                        <div className="af-class-form-field-wrapper">
                          <label
                            htmlFor="birthday"
                            className="af-class-form-label"
                          >
                            Fecha de nacimiento *
                          </label>
                          <input
                            ref={register}
                            type="date"
                            className="af-class-form-textinput w-input"
                            maxLength={256}
                            name="birthday"
                            data-name="birthday"
                            placeholder="....."
                            id="birthday"
                            required
                          />
                        </div>
                        <div className="af-class-form-field-wrapper af-class-form-imgfield-wrapper">
                          {image && (
                            <img
                              src={imageUrl}
                              loading="lazy"
                              sizes="100px"
                              srcSet={`${imageUrl} 500w, ${imageUrl} 800w`}
                              alt=""
                              className="af-class-form-imgpreview"
                            />
                          )}
                          <div className="af-class-form-imgfield-content">
                            <label
                              htmlFor="name-2"
                              className="af-class-form-label af-class-form-imglabel"
                            >
                              Imagen de perfil
                            </label>
                            <div className="af-class-form-imgfield-buttonwrapper">
                              <div className="af-class-form-filename">
                                {image && image.name}
                              </div>
                              <button
                                type="button"
                                onClick={() => fileInput.current.click()}
                                className="af-class-form-uploadbutton w-button"
                              >
                                Cargar
                              </button>
                              <input
                                ref={fileInput}
                                accept=".jpg, .png, .jpeg"
                                type="file"
                                className="hidden"
                                onChange={(e) => setImage(e.target.files[0])}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="af-class-form-section">
                      <div className="af-class-form-sectiontitle-wrapper">
                        <img
                          src="images/icon-passwordEdit_blue.svg"
                          loading="lazy"
                          alt=""
                          className="af-class-form-sectiontitle-icon"
                        />
                        <h2 className="af-class-form-sectiontitle">
                          Establecer contraseña
                        </h2>
                      </div>
                      <div className="af-class-form-content-wrapper">
                        <div className="af-class-form-field-wrapper">
                          <label
                            htmlFor="password"
                            className="af-class-form-label"
                          >
                            Contraseña *
                          </label>
                          <input
                            ref={register}
                            type={showPassword ? 'text' : 'password'}
                            className="af-class-form-textinput w-input"
                            maxLength={256}
                            name="password"
                            data-name="password"
                            placeholder="....."
                            id="password"
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                          <div className="pt-4">
                            <input
                              type="checkbox"
                              id="show_password"
                              name="show_password"
                              className="w-checkbox-input w-checkbox-input--inputType-custom af-class-form-checkbox-box"
                              onClick={() => setShowPassword(!showPassword)}
                            />{' '}
                            <span className="af-class-form-checkbox-label">
                              Mostrar contraseña
                            </span>
                          </div>
                        </div>
                        <div className="af-class-form-field-wrapper">
                          <label
                            htmlFor="repeat-password"
                            className="af-class-form-label"
                          >
                            Repetir contraseña *
                          </label>
                          <input
                            ref={register}
                            type={showConfirmPassword ? 'text' : 'password'}
                            className="af-class-form-textinput w-input"
                            maxLength={256}
                            name="repeat-password"
                            data-name="repeat-password"
                            placeholder="....."
                            id="repeat-password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                          />
                          <div className="pt-4">
                            <input
                              type="checkbox"
                              id="show_repeat_password"
                              name="show_password"
                              className="w-checkbox-input w-checkbox-input--inputType-custom af-class-form-checkbox-box"
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                            />{' '}
                            <span className="af-class-form-checkbox-label">
                              Mostrar contraseña
                            </span>
                          </div>
                          {password && password !== confirmPassword && (
                            <div className="af-class-form-error-wrapper">
                              <div>Las contraseñas no coinciden.</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="af-class-form-section">
                      <div className="af-class-form-sectiontitle-wrapper">
                        <img
                          src="images/icon-fav_location_blue.svg"
                          loading="lazy"
                          alt=""
                          className="af-class-form-sectiontitle-icon"
                        />
                        <h2 className="af-class-form-sectiontitle">
                          Dirección favorita de entrega
                        </h2>
                      </div>
                      <p className="af-class-form-note">
                        Aquí te entregaremos tus entradas cuando rentes algún
                        palco, platea o butaca. Si no estás seguro, no te
                        preocupes, podrás agregarla o modificarla más tarde.
                        También, si prefieres, podrás recoger las tarjetas en
                        nuestras oficinas o en el estadio, el día del evento.
                      </p>
                      <div className="af-class-form-content-wrapper">
                        <div className="af-class-form-field-wrapper">
                          <div className="af-class-form-label-wrapper">
                            <label
                              htmlFor="name-3"
                              className="af-class-form-label"
                            >
                              Alias
                            </label>
                            <label
                              htmlFor="name-3"
                              className="af-class-form-labelnote"
                            >
                              Casa, oficina, etc.
                            </label>
                          </div>
                          <input
                            ref={register}
                            type="text"
                            className="af-class-form-textinput w-input"
                            maxLength={256}
                            name="alias"
                            placeholder="....."
                            id="alias"
                          />
                        </div>
                        <div className="af-class-form-field-wrapper">
                          <label
                            htmlFor="fav-address-country"
                            className="af-class-form-label"
                          >
                            País
                          </label>
                          <select
                            ref={register}
                            id="country"
                            name="country"
                            className="af-class-form-drop w-select"
                          >
                            <option>Seleccionar país</option>
                            <option value="mexico">México</option>
                          </select>
                        </div>
                        <div className="af-class-form-field-wrapper">
                          <label
                            htmlFor="fav-address-state"
                            className="af-class-form-label"
                          >
                            Estado
                          </label>
                          <select
                            ref={register}
                            id="state"
                            name="state"
                            className="af-class-form-drop w-select"
                          >
                            <option>Seleccionar estado</option>
                            <option value="jalisco">Jalisco</option>
                          </select>
                        </div>
                        <div className="af-class-form-field-wrapper">
                          <label
                            htmlFor="fav-address-city"
                            className="af-class-form-label"
                          >
                            Ciudad
                          </label>
                          <input
                            ref={register}
                            type="text"
                            className="af-class-form-textinput w-input"
                            maxLength={256}
                            name="city"
                            placeholder="....."
                            id="city"
                          />
                        </div>
                        <div className="af-class-form-field-wrapper">
                          <label
                            htmlFor="fav-address-suburb"
                            className="af-class-form-label"
                          >
                            Colonia
                          </label>
                          <input
                            ref={register}
                            type="text"
                            className="af-class-form-textinput w-input"
                            maxLength={256}
                            name="suburb"
                            placeholder="....."
                            id="suburb"
                          />
                        </div>
                        <div className="af-class-form-field-wrapper">
                          <label
                            htmlFor="fav-address-zip"
                            className="af-class-form-label"
                          >
                            Código Postal
                          </label>
                          <input
                            ref={register}
                            type="text"
                            className="af-class-form-textinput w-input"
                            maxLength={256}
                            name="postal_code"
                            placeholder="....."
                            id="zip"
                            required
                          />
                        </div>
                        <div className="af-class-form-field-wrapper">
                          <label
                            htmlFor="fav-address-address"
                            className="af-class-form-label"
                          >
                            Dirección
                          </label>
                          <input
                            ref={register}
                            type="text"
                            className="af-class-form-textinput w-input"
                            maxLength={256}
                            name="address"
                            placeholder="....."
                            id="address"
                            required
                          />
                        </div>
                        <div className="af-class-form-field-wrapper">
                          <label
                            htmlFor="fav-address-interior"
                            className="af-class-form-label"
                          >
                            Número interior
                          </label>
                          <input
                            ref={register}
                            type="text"
                            className="af-class-form-textinput w-input"
                            maxLength={256}
                            name="interior_num"
                            placeholder="....."
                            id="interior"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="af-class-form-section af-class-form-lastsection">
                      <div className="af-class-form-content-wrapper">
                        <label className="w-checkbox af-class-form-checkbox-wrapper">
                          <input
                            type="checkbox"
                            id="accept-policies"
                            name="accept-policies"
                            data-name="accept-policies"
                            required
                            className="w-checkbox-input w-checkbox-input--inputType-custom af-class-form-checkbox-box"
                          />
                          <span
                            htmlFor="accept-policies"
                            className="af-class-form-checkbox-label w-form-label"
                          >
                            He leído y acepto los{' '}
                            <a href="#" className="af-class-form-checkbox-link">
                              Términos y Condiciones
                            </a>
                            , así como el{' '}
                            <a href="#" className="af-class-form-checkbox-link">
                              Aviso de Privacidad
                            </a>{' '}
                            de PalcosPlus.com *
                          </span>
                        </label>
                        <button
                          type="submit"
                          defaultValue="Registrarme"
                          data-wait="....."
                          className={`af-class-gen-button af-class-form-sendbutton w-button`}
                          style={
                            password !== confirmPassword
                              ? { cursor: 'not-allowed' }
                              : { cursor: 'pointer' }
                          }
                          disabled={password !== confirmPassword ? true : false}
                        >
                          Registrarme
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="af-class-form-success-wrapper w-form-done">
                    <img
                      src="images/title-confirmRent_blue.svg"
                      loading="lazy"
                      alt=""
                      className="af-class-form-success-icon"
                    />
                    <div>
                      <span className="af-class-form-success-boldtext">
                        ¡Gracias por registrarte en PalcosPlus.com!
                        <br />
                      </span>
                      Para continuar, verifica tu cuenta accediendo al link que
                      recibirás en tu correo de registro.
                    </div>
                  </div>
                  <div className="af-class-form-error-wrapper w-form-fail">
                    <div>
                      Al parecer hay un error con el registro. Verifica que
                      todos los campos obligatorios (*) estén completos e
                      inténtalo de nuevo. Si los problemas persisten por favor
                      contacta a soporte.
                    </div>
                  </div>
                </div>
                <div className="af-class-popup-close-wrapper">
                  <img
                    src="images/close-sp-gray.svg"
                    loading="lazy"
                    alt=""
                    className="af-class-popup-closeicon"
                    onClick={onDimiss}
                  />
                </div>
              </div>
            </div>
          </div>
        </span>
      </DialogContent>
    </DialogOverlay>
  );
};

export default ModalInicioSesion;
