import React from "react";

const Input = (props) => {
  const createInput = (props) => {
    switch (props.input) {
      case "input": {
        return (
          <div className="flex relative">
            <input
              {...props}
              id={props?.id}
              name={props?.name}
              type={props?.type}
              className={
                "border rounded-sm focus:outline-none focus:ring-2 focus:ring-gris-oscuro text-gris-oscuro bg-gris-auxiliar " +
                props.className
              }
              placeholder={props?.placeholder}
              icon={props?.icon}
              value={props?.value}
            />
            {props.icon && (
              <span
                className={`absolute inset-y-0  flex items-center pl-2 focus p-2 rounded-md ${
                  props.iconclass
                } ${props.dir === "left" ? " left-0 " : "right-0 "}`}
              >
                  {props?.icon}
              </span>
            )}
          </div>
        );
      }
      case "select": {
        return (
          <div className="flex relative">
            <select
              {...props}
              className={
                "border rounded-sm focus:outline-none focus:ring-2 focus:ring-gris-oscuro text-gris-oscuro bg-gris-auxiliar " +
                props.className
              }
              id={props.id}
              name={props.name}
              value={props?.value}
            >
              {props.options.map((type) => (
                <option {...type} key={type.value} value={type.value}>
                  {type.label}
                </option>
              ))}
            </select>
            {props.icon && (
              <span
                className={`absolute inset-y-0  flex items-center pl-2 focus p-2 rounded-md ${
                  props.iconclass
                } ${props.dir === "left" ? " left-0 " : "right-0 "}`}
              >
                  {props?.icon}
              </span>
            )}
          </div>
        );
      }
      case "textarea": {
        return (
          <textarea
            {...props}
            className={
              "border rounded-sm focus:outline-none focus:ring-2 focus:ring-gris-oscuro text-gris-oscuro bg-gris-auxiliar " +
              props.className
            }
            id={props.id}
            rows={props.rows || 3}
            cols={props.cols || 40}
            value={props.value}
          />
        );
      }
      default: {
        return (
          <div className="flex relative">
            <input
              {...props}
              className={
                "border rounded-sm focus:outline-none focus:ring-2 focus:ring-gris-oscuro text-gris-oscuro bg-gris-auxiliar " +
                props.className
              }
              id={props?.id}
              name={props?.name}
              type={props?.type}
              placeholder={props?.placeholder}
              icon={props?.icon}
              value={props?.value}
            />
            {props.icon && (
              <span
                className={`absolute inset-y-0  flex items-center pl-2 focus p-2 rounded-md ${
                  props.iconclass
                } ${props.dir === "left" ? " left-0 " : "right-0 "}`}
              >
                  {props?.icon}
              </span>
            )}
          </div>
        );
      }
    }
  };

  return createInput(props);
};

export default Input;
