/* eslint-disable */

import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { createScope, map, transformProxies } from './helpers';

const scripts = [
  {
    loading: Promise.resolve(
      'document.addEventListener("scroll",function(){const e=document.getElementById("navbar");var n=document.scrollingElement.scrollTop;n<200?e.style.backgroundColor="transparent":200<n&&(e.style.backgroundColor="#e6e6e6")});'
    ),
    isAsync: false,
  },
  {
    loading: fetch(
      'https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=60e399f8a778918b4c4c009b'
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch('js/webflow.js').then((body) => body.text()),
    isAsync: false,
  },
];

let Controller;

class NosotrosView extends React.Component {
  componentDidMount() {
    const htmlEl = document.querySelector('html');
    htmlEl.dataset['wfPage'] = '60ee48cd738e896b0322f00e';
    htmlEl.dataset['wfSite'] = '60e399f8a778918b4c4c009b';

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
    const proxies =
      NosotrosView.Controller !== NosotrosView
        ? transformProxies(this.props.children)
        : {};

    return (
      <span>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/front-end-palcosplus.webflow.css);


              html {
              	-webkit-tap-highlight-color: rgba(0,0,0,0);
              }
            	textarea {
              	resize: vertical;
          	}
        `,
          }}
        />
        <span className="af-view">
          <div>
            <div className="af-class-gen-herosection">
              <h1 className="af-class-gen-pagetitle">
                La mejor opción para rentar palcos, butacas y plateas
              </h1>
              <div
                data-poster-url="videos/topPage_nosotros_comp-poster-00001.jpg"
                data-video-urls="videos/topPage_nosotros_comp-transcode.mp4,videos/topPage_nosotros_comp-transcode.webm"
                data-autoplay="true"
                data-loop="true"
                data-wf-ignore="true"
                className="af-class-gen-pagetitle-video w-background-video w-background-video-atom"
              >
                <video
                  autoPlay
                  loop
                  style={{
                    backgroundImage:
                      'url("videos/topPage_nosotros_comp-poster-00001.jpg")',
                  }}
                  muted
                  playsInline
                  data-wf-ignore="true"
                  data-object-fit="cover"
                >
                  <source
                    src="videos/topPage_nosotros_comp-transcode.mp4"
                    data-wf-ignore="true"
                  />
                  <source
                    src="videos/topPage_nosotros_comp-transcode.webm"
                    data-wf-ignore="true"
                  />
                </video>
              </div>
            </div>
            <div className="af-class-gen-section af-class-aboutus-gal-section">
              <div className="af-class-gen-container af-class-aboutus-galcontainer">
                <div className="af-class-aboutus-leftcolumn">
                  <div className="af-class-aboutus-gal-wrapper">
                    <div className="af-class-aboutus-gal-mask">
                      <img
                        src="images/graph_gallery-Frame.svg"
                        loading="lazy"
                        alt
                        className="af-class-aboutus-gal-frame"
                      />
                      <img
                        src="images/galeria1.jpg"
                        loading="lazy"
                        sizes="(max-width: 991px) 100vw, 445.5px"
                        srcSet="images/galeria1-p-500.jpeg 500w, images/galeria1-p-800.jpeg 800w, images/galeria1.jpg 1200w"
                        alt
                        className="af-class-aboutus-gal-img af-class-aboutus-gal-img1"
                      />
                      <img
                        src="images/galeria4.jpg"
                        loading="lazy"
                        sizes="(max-width: 991px) 100vw, 445.5px"
                        srcSet="images/galeria4-p-500.jpeg 500w, images/galeria4-p-800.jpeg 800w, images/galeria4-p-1080.jpeg 1080w, images/galeria4-p-1600.jpeg 1600w, images/galeria4-p-2000.jpeg 2000w, images/galeria4.jpg 2048w"
                        alt
                        className="af-class-aboutus-gal-img af-class-aboutus-gal-img2"
                      />
                      <img
                        src="images/galeria2.jpg"
                        loading="lazy"
                        sizes="(max-width: 991px) 100vw, 445.5px"
                        srcSet="images/galeria2-p-1080.jpeg 1080w, images/galeria2.jpg 1200w"
                        alt
                        className="af-class-aboutus-gal-img af-class-aboutus-gal-img3"
                      />
                      <img
                        src="images/galeria3.jpg"
                        loading="lazy"
                        sizes="(max-width: 991px) 100vw, 445.5px"
                        srcSet="images/galeria3-p-1080.jpeg 1080w, images/galeria3-p-1600.jpeg 1600w, images/galeria3-p-2000.jpeg 2000w, images/galeria3-p-2600.jpeg 2600w, images/galeria3-p-3200.jpeg 3200w, images/galeria3.jpg 3982w"
                        alt
                        className="af-class-aboutus-gal-img"
                      />
                    </div>
                  </div>
                </div>
                <div className="af-class-aboutus-rightcolumn">
                  <div className="af-class-aboutus-theme-wrapper">
                    <p className="af-class-gen-paragraph af-class-gen-paragraph-aboutus">
                      Apoyar en vivo a tu equipo favorito no puede compararse
                      con nada; pero sabemos que la experiencia puede no ser la
                      mejor: malas vistas, asientos incómodos, taquillas llenas…
                      <br />
                      <br />
                      <span className="af-class-gen-boldspan">
                        Todo eso ha quedado atrás.
                      </span>
                    </p>
                  </div>
                  <div className="af-class-aboutus-theme-wrapper">
                    <div className="af-class-aboutus-theme-content">
                      <h2 className="af-class-gen-sectiontitle">
                        A un click de los mejores lugares
                      </h2>
                      <p className="af-class-gen-paragraph af-class-paragraph-space">
                        Selecciona tu evento preferido, elige los lugares más
                        cómodos, paga de forma segura y recibe tus accesos en la
                        entrada de tu domicilio. Así, fácil y rápido.
                      </p>
                      <HashLink
                        to="/#events-cat"
                        className="af-class-gen-button w-button"
                      >
                        Ver eventos
                      </HashLink>
                    </div>
                    <div className="af-class-aboutus-gal-mobmask">
                      <img
                        src="images/galeria1.jpg"
                        loading="lazy"
                        sizes="(max-width: 479px) 247.5px, (max-width: 767px) 297px, (max-width: 991px) 346.5px, 100vw"
                        srcSet="images/galeria1-p-500.jpeg 500w, images/galeria1-p-800.jpeg 800w, images/galeria1.jpg 1200w"
                        alt
                        className="af-class-aboutus-gal-img"
                      />
                      <img
                        src="images/graph_gallery-Frame.svg"
                        loading="lazy"
                        alt
                        className="af-class-aboutus-gal-frame"
                      />
                    </div>
                  </div>
                  <div
                    data-w-id="c9564fc0-d7b1-bf0f-85b4-2a2da81806b9"
                    className="af-class-aboutus-theme-wrapper"
                  >
                    <div className="af-class-aboutus-theme-content af-class-aboutus-theme-contentright">
                      <h2 className="af-class-gen-sectiontitle">
                        Los estadios más populares del deporte
                      </h2>
                      <p className="af-class-gen-paragraph af-class-paragraph-space">
                        Operamos en los estadios más populares del país, donde
                        juegan los equipos con mayor tradición. Queremos llegar
                        a todo el país, y ¡seguimos creciendo!
                      </p>
                      <Link
                        to="/estadios"
                        className="af-class-gen-button w-button"
                      >
                        Ver estadios
                      </Link>
                    </div>
                    <div className="af-class-aboutus-gal-mobmask af-class-aboutus-gal-rightmask">
                      <img
                        src="images/galeria4.jpg"
                        loading="lazy"
                        sizes="(max-width: 479px) 247.5px, (max-width: 767px) 297px, (max-width: 991px) 346.5px, 100vw"
                        srcSet="images/galeria4-p-500.jpeg 500w, images/galeria4-p-800.jpeg 800w, images/galeria4-p-1080.jpeg 1080w, images/galeria4-p-1600.jpeg 1600w, images/galeria4-p-2000.jpeg 2000w, images/galeria4.jpg 2048w"
                        alt
                        className="af-class-aboutus-gal-img"
                      />
                      <img
                        src="images/graph_gallery-Frame.svg"
                        loading="lazy"
                        alt
                        className="af-class-aboutus-gal-frame"
                      />
                    </div>
                  </div>
                  <div
                    data-w-id="f727af49-a184-8580-93bb-c92d824e0798"
                    className="af-class-aboutus-theme-wrapper"
                  >
                    <div className="af-class-aboutus-theme-content">
                      <h2 className="af-class-gen-sectiontitle">
                        Sácale más provecho a tus pases
                      </h2>
                      <p className="af-class-gen-paragraph af-class-paragraph-space">
                        Saca provecho de tus pases cuando no los utilizas, usa
                        nuestra plataforma para monetizar tu propiedad. Envíanos
                        solicitud y nos pondremos en contacto.
                      </p>

                      <HashLink
                        to="/contacto#application"
                        className="af-class-gen-button w-button"
                      >
                        Rentar mis pases
                      </HashLink>
                    </div>
                    <div className="af-class-aboutus-gal-mobmask">
                      <img
                        src="images/galeria2.jpg"
                        loading="lazy"
                        sizes="(max-width: 479px) 247.5px, (max-width: 767px) 297px, (max-width: 991px) 346.5px, 100vw"
                        srcSet="images/galeria2-p-1080.jpeg 1080w, images/galeria2.jpg 1200w"
                        alt
                        className="af-class-aboutus-gal-img"
                      />
                      <img
                        src="images/graph_gallery-Frame.svg"
                        loading="lazy"
                        alt
                        className="af-class-aboutus-gal-frame"
                      />
                    </div>
                  </div>
                  <div
                    data-w-id="682be3a5-b40e-dd0a-0d7a-26b84c3c28ad"
                    className="af-class-aboutus-theme-wrapper"
                  >
                    <div className="af-class-aboutus-theme-content af-class-aboutus-theme-contentright">
                      <h2 className="af-class-gen-sectiontitle">
                        Vende tus pases de forma sencilla
                      </h2>
                      <p className="af-class-gen-paragraph af-class-paragraph-space">
                        ¿Quieres vender los pases de tu palco, platea o butaca?
                        Podemos ayudarte a hacerlo de forma rápida y segura.
                      </p>
                      <HashLink
                        to="/contacto#application"
                        className="af-class-gen-button w-button"
                      >
                        Vender mis pases
                      </HashLink>
                    </div>
                    <div className="af-class-aboutus-gal-mobmask af-class-aboutus-gal-rightmask">
                      <img
                        src="images/galeria3.jpg"
                        loading="lazy"
                        sizes="(max-width: 479px) 247.5px, (max-width: 767px) 297px, (max-width: 991px) 346.5px, 100vw"
                        srcSet="images/galeria3-p-1080.jpeg 1080w, images/galeria3-p-1600.jpeg 1600w, images/galeria3-p-2000.jpeg 2000w, images/galeria3-p-2600.jpeg 2600w, images/galeria3-p-3200.jpeg 3200w, images/galeria3.jpg 3982w"
                        alt
                        className="af-class-aboutus-gal-img"
                      />
                      <img
                        src="images/graph_gallery-Frame.svg"
                        loading="lazy"
                        alt
                        className="af-class-aboutus-gal-frame"
                      />
                    </div>
                  </div>
                  <div className="af-class-aboutus-theme-wrapper af-class-aboutus-theme-lastwrapper">
                    <p className="af-class-gen-paragraph af-class-gen-paragraph-aboutus">
                      Queremos que todos puedan disfrutar de su equipo favorito
                      con los lugares más cómodos y exclusivos, de forma rápida
                      y segura.
                      <br />
                      <br />‍
                      <span className="af-class-gen-boldspan">
                        La experiencia de los eventos deportivos ya no será la
                        misma.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-gen-section">
              <div className="af-class-gen-container">
                <h2 className="af-class-gen-sectiontitle af-class-aboutus-stepstitle">
                  Sólo 4 pasos para conseguir los mejores lugares
                </h2>
                <div className="af-class-aboutus-steplist-wrapper">
                  <div className="af-class-aboutus-step-wrapper">
                    <div className="af-class-aboutus-stepnumber-wrapper">
                      <div className="af-class-aboutus-stepnumber">1</div>
                      <img
                        src="images/bigIcon-PP_steps_s1_deepBlue.svg"
                        loading="lazy"
                        alt
                        className="af-class-aboutus-stepicon"
                      />
                    </div>
                    <div className="af-class-gen-paragraph af-class-aboutus-step">
                      Selecciona el evento al que quieres asistir.
                    </div>
                  </div>
                  <div className="af-class-aboutus-step-wrapper">
                    <div className="af-class-aboutus-stepnumber-wrapper">
                      <div className="af-class-aboutus-stepnumber">2</div>
                      <img
                        src="images/bigIcon-PP_steps_s2_deepBlue.svg"
                        loading="lazy"
                        alt
                        className="af-class-aboutus-stepicon"
                      />
                    </div>
                    <div className="af-class-gen-paragraph af-class-aboutus-step">
                      Escoge los mejores lugares para ti y tus acompañantes.
                    </div>
                  </div>
                  <div className="af-class-aboutus-step-wrapper">
                    <div className="af-class-aboutus-stepnumber-wrapper">
                      <div className="af-class-aboutus-stepnumber">3</div>
                      <img
                        src="images/bigIcon-PP_steps_s3_deepBlue.svg"
                        loading="lazy"
                        alt
                        className="af-class-aboutus-stepicon"
                      />
                    </div>
                    <div className="af-class-gen-paragraph af-class-aboutus-step">
                      Registra tu información y realiza tu pago de forma rápida
                      y 100% segura.
                    </div>
                  </div>
                  <div className="af-class-aboutus-step-wrapper">
                    <div className="af-class-aboutus-stepnumber-wrapper">
                      <div className="af-class-aboutus-stepnumber">4</div>
                      <img
                        src="images/bigIcon-PP_steps_s4_deepBlue.svg"
                        loading="lazy"
                        alt
                        className="af-class-aboutus-stepicon"
                      />
                    </div>
                    <div className="af-class-gen-paragraph af-class-aboutus-step">
                      Recibe tus entradas en la puerta de tu domicilio o en el
                      estadio y ¡disfruta del evento!
                    </div>
                  </div>
                </div>
                <HashLink
                  to="/#events-cat"
                  className="af-class-gen-button w-button"
                >
                  Ver eventos
                </HashLink>
              </div>
            </div>
            <div className="af-class-gen-section">
              <div className="af-class-gen-container">
                <div className="af-class-socialinvite-wrapper">
                  <div className="af-class-socialinvite-img-wrapper">
                    <img
                      src="images/socialMedia-invite_comp.png"
                      loading="lazy"
                      sizes="100vw"
                      srcSet="images/socialMedia-invite_comp-p-500.png 500w, images/socialMedia-invite_comp-p-800.png 800w, images/socialMedia-invite_comp.png 1920w"
                      alt
                      className="af-class-socialinvite-image"
                    />
                    <img
                      src="images/socialMedia-invite_movil_comp.png"
                      loading="lazy"
                      sizes="(max-width: 479px) 76vw, (max-width: 767px) 350px, 100vw"
                      srcSet="images/socialMedia-invite_movil_comp-p-500.png 500w, images/socialMedia-invite_movil_comp-p-800.png 800w, images/socialMedia-invite_movil_comp.png 1786w"
                      alt
                      className="af-class-socialinvite-imgmob"
                    />
                    <img
                      src="images/sombreBloque.png"
                      loading="lazy"
                      alt
                      className="af-class-socialinvite-mobshadow"
                    />
                  </div>
                  <div className="af-class-socialinvite-content-wrapper">
                    <div className="af-class-socialinvite-graphic-wrapper">
                      <img
                        src="images/bigIcon-social_media_vertical.svg"
                        loading="lazy"
                        alt
                        className="af-class-socialinvite-graphicver"
                      />
                      <img
                        src="images/bigIcon-social_media_horiz.svg"
                        loading="lazy"
                        alt
                        className="af-class-socialinvite-graphichor"
                      />
                    </div>
                    <h2 className="af-class-gen-sectiontitle">
                      Mantente al día con lo último
                    </h2>
                    <p className="af-class-gen-paragraph">
                      Síguenos en redes sociales y mantente actualizado con las
                      últimas noticias y promociones.
                    </p>
                    <div className="af-class-socialinvite-platforms-wrapper">
                      <img
                        src="images/bigIcon-social_media_mobile.svg"
                        loading="lazy"
                        alt
                        className="af-class-socialinvite-graphicmob"
                      />
                      <div className="af-class-socialinvite-buttons-wrapper">
                        <a
                          href="https://www.facebook.com/palcosplus"
                          target="_blank"
                          alt=""
                          className="af-class-socialinvite-button af-class-socialinvite-fb w-inline-block"
                        >
                          <img
                            src="images/Facebook-white.svg"
                            loading="lazy"
                            alt=""
                            className="af-class-socialinvite-buttonicon"
                          />
                          <div>Me gusta</div>
                        </a>
                        <a
                          href="https://instagram.com/palcosplus?utm_medium=copy_link"
                          target="_blank"
                          className="af-class-socialinvite-button af-class-socialinvite-ig w-inline-block"
                        >
                          <img
                            src="images/Instagram-white.svg"
                            loading="lazy"
                            alt=""
                            className="af-class-socialinvite-buttonicon"
                          />
                          <div>Seguir</div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="af-class-socialinvite-grayblock" />
                </div>
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    );
  }
}

export default NosotrosView;

/* eslint-enable */
