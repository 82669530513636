import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, Link } from 'react-router-dom';
import configData from 'config.json';

const EstadiosView = () => {
  const [data, setData] = useState({
    stadiums: [],
    teams: [],
  });

  const getData = async () => {
    try {
      const stadiums = await axios
        .get(`${configData.SERVER_URL}/stadiums`)
        .then((res) => {
          return res.data.stadium;
        });
      const teams = await axios
        .get(`${configData.SERVER_URL}/teams`)
        .then((res) => {
          return res.data.teams;
        });
      setData({
        stadiums,
        teams,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getTeamById = (id) => {
    return data.teams.find((team) => team.id === id);
  };

  return (
    <span className="af-view">
      <div>
        <div className="af-class-gen-herosection">
          <h1 className="af-class-gen-pagetitle">
            Donde tu pasión vive, semana a semana
          </h1>
          <div
            data-poster-url="videos/topPage_estadios_comp-poster-00001.jpg"
            data-video-urls="videos/topPage_estadios_comp-transcode.mp4,videos/topPage_estadios_comp-transcode.webm"
            data-autoplay="true"
            data-loop="true"
            data-wf-ignore="true"
            className="af-class-gen-pagetitle-video w-background-video w-background-video-atom"
          >
            <video
              autoPlay
              loop
              style={{
                backgroundImage:
                  'url("videos/topPage_estadios_comp-poster-00001.jpg")',
              }}
              muted
              playsInline
              data-wf-ignore="true"
              data-object-fit="cover"
            >
              <source
                src="videos/topPage_estadios_comp-transcode.mp4"
                data-wf-ignore="true"
              />
              <source
                src="videos/topPage_estadios_comp-transcode.webm"
                data-wf-ignore="true"
              />
            </video>
          </div>
        </div>
        <div className="af-class-gen-section">
          <div className="af-class-gen-container af-class-catstd-stdcards-wrapper">
            <div className="af-class-catstd-intro-wrapper">
              <img
                alt=""
                src="images/bigIcon_intro_stadiums_blue.svg"
                loading="lazy"
                className="af-class-catstd-intro-graphic"
              />
              <p className="af-class-gen-paragraph af-class-catstd-introparagraph">
                Nuestra cobertura de estadios crece día a día. Queremos que
                disfrutes a tu equipo favorito, a donde sea que vaya.
              </p>
            </div>
            {data.stadiums &&
              data.stadiums.map((stadium) => {
                const teams = stadium?.local_teams.split(',');
                return (
                  <Link
                    to={`/estadio/${stadium?.id}`}
                    className="af-class-catstd-stdcard w-inline-block"
                  >
                    <div className="af-class-catstd-stdcard-teams-wrapper">
                      {/* {teams.map(team => {
                                            return (
                                                <img alt="" src={`${getTeamById(team).images[0]}`} loading="lazy" sizes="(max-width: 479px) 35.671875px, (max-width: 767px) 40.125px, 44.578125px" srcSet={`${getTeamById(team).images[0]} 500w, ${getTeamById(team).images[0]} 535w`} className="af-class-catstd-stdcard-teamemblem" />
                                            )
                                        })} */}
                    </div>
                    <img
                      alt=""
                      src={`${stadium.images[1]}`}
                      loading="lazy"
                      sizes="(max-width: 479px) 100vw, (max-width: 767px) 82vw, (max-width: 991px) 550px, 43vw"
                      srcSet={`${stadium.images[1]}`}
                      className="af-class-catstd-stdcard-img"
                    />
                    <div className="af-class-catstd-stdcard-banner">
                      <h3 className="af-class-catstd-stdcard-stadium">
                        {stadium.name}
                      </h3>
                      <div className="af-class-catstd-stdcard-location-wrapper">
                        <div className="af-class-catstd-stdcard-city">
                          {stadium.city}
                        </div>
                        <img
                          alt=""
                          src="images/flag-mexico_1f1f2-1f1fd.png"
                          loading="lazy"
                          className="af-class-catstd-stdcard-flag"
                        />
                      </div>
                      <div className="af-class-catstd-stdcard-city af-class-catstd-stdcard-citymob">
                        {stadium.city}
                      </div>
                    </div>
                  </Link>
                );
              })}
            <Link
              id="w-node-_17871062-5739-ea84-8cbd-25f137f72e4a-d4ec4367"
              to="#"
              className="af-class-catstd-stdcard w-inline-block"
            >
              <div className="af-class-catstd-stdcard-teams-wrapper">
                <img
                  alt=""
                  src="images/defaultTeam-deepBlue.svg"
                  loading="lazy"
                  className="af-class-catstd-stdcard-teamemblem"
                />
                <img
                  alt=""
                  src="images/defaultTeam-deepBlue.svg"
                  loading="lazy"
                  className="af-class-catstd-stdcard-teamemblem"
                />
              </div>
              <img
                alt=""
                src="images/defaultStadium.jpg"
                loading="lazy"
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 82vw, (max-width: 991px) 550px, 43vw"
                srcSet="images/defaultStadium-p-500.jpeg 500w, images/defaultStadium-p-800.jpeg 800w, images/defaultStadium-p-1080.jpeg 1080w, images/defaultStadium.jpg 3213w"
                className="af-class-catstd-stdcard-img"
              />
              <div className="af-class-catstd-stdcard-banner">
                <h3 className="af-class-catstd-stdcard-stadium">
                  Próximamente...
                </h3>
                <div className="af-class-catstd-stdcard-location-wrapper">
                  <div className="af-class-catstd-stdcard-city">?????</div>
                  <img
                    alt=""
                    src="images/defaultFlag_aquaGrass.png"
                    loading="lazy"
                    className="af-class-catstd-stdcard-flag"
                  />
                </div>
              </div>
            </Link>
            <Link to="#" className="af-class-catstd-stdcard w-inline-block">
              <div className="af-class-catstd-stdcard-teams-wrapper">
                <img
                  alt=""
                  src="images/defaultTeam-deepBlue.svg"
                  loading="lazy"
                  className="af-class-catstd-stdcard-teamemblem"
                />
                <img
                  alt=""
                  src="images/defaultTeam-deepBlue.svg"
                  loading="lazy"
                  className="af-class-catstd-stdcard-teamemblem"
                />
              </div>
              <img
                alt=""
                src="images/defaultStadium.jpg"
                loading="lazy"
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 82vw, (max-width: 991px) 550px, 43vw"
                srcSet="images/defaultStadium-p-500.jpeg 500w, images/defaultStadium-p-800.jpeg 800w, images/defaultStadium-p-1080.jpeg 1080w, images/defaultStadium.jpg 3213w"
                className="af-class-catstd-stdcard-img"
              />
              <div className="af-class-catstd-stdcard-banner">
                <h3 className="af-class-catstd-stdcard-stadium">
                  Próximamente...
                </h3>
                <div className="af-class-catstd-stdcard-location-wrapper">
                  <div className="af-class-catstd-stdcard-city">?????</div>
                  <img
                    alt=""
                    src="images/defaultFlag_aquaGrass.png"
                    loading="lazy"
                    className="af-class-catstd-stdcard-flag"
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="af-class-gen-section af-class-deepblue">
          <img
            alt=""
            src="images/triangleGraphicUp-white.svg"
            loading="lazy"
            className="af-class-gen-trianglegraphic af-class-lefttriangle"
          />
          <img
            alt=""
            src="images/triangleGraphicDown-white.svg"
            loading="lazy"
            className="af-class-gen-trianglegraphic"
          />
          <div
            data-w-id="eabc14a1-0a81-1c7b-2d4b-c187a5bb93e5"
            className="af-class-gen-container"
          >
            <div className="af-class-rentinvite-top-wrapper">
              <div className="af-class-rentinvite-icons-wrapper">
                <div className="af-class-rentinvite-icons-subwrapper1">
                  <img
                    alt=""
                    src="images/bigIcon-prop_rent_s1_white.svg"
                    loading="lazy"
                    data-w-id="eabc14a1-0a81-1c7b-2d4b-c187a5bb93e9"
                    className="af-class-rentinvite-icon af-class-rentinvite-icon1"
                  />
                  <img
                    alt=""
                    src="images/rentInviteArrow-aquaGrass.svg"
                    loading="lazy"
                    data-w-id="eabc14a1-0a81-1c7b-2d4b-c187a5bb93ea"
                    className="af-class-rentinvite-arrow af-class-rotatingarrow af-class-rentinvite-arrow1"
                  />
                  <img
                    alt=""
                    src="images/bigIcon-prop_rent_s2_white.svg"
                    loading="lazy"
                    data-w-id="eabc14a1-0a81-1c7b-2d4b-c187a5bb93eb"
                    className="af-class-rentinvite-icon af-class-rentinvite-icon2"
                  />
                  <img
                    alt=""
                    src="images/rentInviteArrow-aquaGrass.svg"
                    loading="lazy"
                    data-w-id="eabc14a1-0a81-1c7b-2d4b-c187a5bb93ec"
                    className="af-class-rentinvite-arrow af-class-rotatingarrow af-class-rentinvite-arrow2"
                  />
                </div>
                <div className="af-class-rentinvite-icons-subwrapper2">
                  <img
                    alt=""
                    src="images/bigIcon-prop_rent_s3_white.svg"
                    loading="lazy"
                    data-w-id="eabc14a1-0a81-1c7b-2d4b-c187a5bb93ee"
                    className="af-class-rentinvite-icon af-class-rentinvite-icon3"
                  />
                  <img
                    alt=""
                    src="images/rentInviteArrow-aquaGrass.svg"
                    loading="lazy"
                    className="af-class-rentinvite-arrow af-class-rentinvite-arrow3"
                  />
                  <img
                    alt=""
                    src="images/bigIcon-prop_rent_s4_white.svg"
                    loading="lazy"
                    data-w-id="eabc14a1-0a81-1c7b-2d4b-c187a5bb93f0"
                    className="af-class-rentinvite-icon af-class-rentinvite-icon4"
                  />
                </div>
              </div>
              <div className="af-class-rentinvite-title-wrapper af-class-rentinvitemobtitlewrapper">
                <div className="af-class-rentinvite-titleremark-wrapper">
                  <div className="af-class-gen-titleremark af-class-leftremark-rentinvite">
                    <h2 className="af-class-gen-sectiontitle af-class-white af-class-titleremarktext">
                      Vuelve
                    </h2>
                  </div>
                  <div className="af-class-gen-titleremark">
                    <h2 className="af-class-gen-sectiontitle af-class-white af-class-titleremarktext">
                      rentable
                    </h2>
                  </div>
                </div>
                <h2 className="af-class-gen-sectiontitle af-class-white af-class-rentinvitetitle">
                  tu palco, platea o butacas
                </h2>
              </div>
            </div>
            <div className="af-class-rentinvite-bottom-wrapper">
              <div className="af-class-rentinvite-title-wrapper">
                <div className="af-class-rentinvite-titleremark-wrapper">
                  <div className="af-class-gen-titleremark af-class-leftremark-rentinvite">
                    <h2 className="af-class-gen-sectiontitle af-class-white af-class-titleremarktext">
                      Vuelve
                    </h2>
                  </div>
                  <div className="af-class-gen-titleremark">
                    <h2 className="af-class-gen-sectiontitle af-class-white af-class-titleremarktext">
                      rentable
                    </h2>
                  </div>
                </div>
                <h2 className="af-class-gen-sectiontitle af-class-white af-class-rentinvitetitle">
                  tu palco, platea o butacas
                </h2>
              </div>
              <div className="af-class-rentinvite-content-wrapper">
                <p className="af-class-gen-paragraph af-class-white af-class-rentinvite">
                  Sácale provecho a tus lugares y pases cuando no los utilizas.
                  Aprovecha nuestra plataforma para monetizar tu propiedad, sin
                  ningún esfuerzo. Por otro lado, si lo que quieres es venderla,
                  nosotros podemos ayudarte.
                </p>
                <div className="af-class-rentinvite-buttons-wrapper">
                  <Link to="/" className="af-class-gen-button w-button">
                    Quiero Rentar
                  </Link>
                  <Link to="/contacto" className="af-class-gen-button w-button">
                    Quiero vender
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};

export default EstadiosView;
