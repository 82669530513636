import React, { forwardRef } from 'react';
import { DialogOverlay, DialogContent } from '@reach/dialog';

import Modal from 'components/Modal';

const PropiedadImageModal = (props) => {
  return (
    <DialogOverlay
      aria-label="modal"
      aria-labelledby="dialog-title"
      isOpen={props.openModal}
      onDismiss={props.onDimiss}
      className="z-40 pt-36"
    >
      <DialogContent
        className="z-50 pt-0 absolute left-0 right-0 w-auto md:w-4/5 h-auto mx-auto bg-transparent"
        aria-labelledby="dialog-content"
      >
        <div className="h-full w-full">
          <div
            className="af-class-prop-slider-arrow w-slider-arrow-left"
            style={{ width: window.innerWidth < 650 ? '20px' : '50px' }}
          >
            <div
              className="flex h-full items-center justify-center"
              onClick={() => props.onChange('previous')}
            >
              <img
                src={
                  window.location.origin +
                  '/images/sliderArrowLeft-deepBlue.svg'
                }
                alt=""
              />
            </div>
          </div>
          <img className="w-full h-full" src={props.url} alt="" />
          <div
            className="af-class-prop-slider-arrow af-class-prop-slider-rightarrow w-slider-arrow-right"
            style={{ width: window.innerWidth < 650 ? '20px' : '50px' }}
          >
            <div
              className="flex h-full items-center justify-center"
              onClick={() => props.onChange('next')}
            >
              <img
                src={
                  window.location.origin +
                  '/images/sliderArrowRight-deepBlue.svg'
                }
                alt=""
              />
            </div>
          </div>
        </div>
      </DialogContent>
    </DialogOverlay>
  );
};

export default PropiedadImageModal;
