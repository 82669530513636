import { DialogOverlay, DialogContent } from "@reach/dialog";
import React from "react";
import "@reach/dialog/styles.css";

const Modal = ({ children, isOpen, onDimiss }) => (
  <DialogOverlay
    aria-label="modal"
    aria-labelledby="dialog-title"
    isOpen={isOpen}
    onDismiss={onDimiss}
    className="z-40 pt-36"
  >
      {children}
  </DialogOverlay>
);

export default Modal;
