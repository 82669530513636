import React, { forwardRef, useState, useRef, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useClickAway } from 'react-use';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import XLSX from 'xlsx';
import configData from 'config.json';

import Tables, { DataCell } from 'components/Tables';
import Label from 'components/Label';
import Input from 'components/Input';
import Button from 'components/Button';
import Home from 'components/Icons/Home';
import ConfirmationModal from 'components/ConfirmationModal';

const ManagerEquipos = forwardRef((props, ref) => {
  const [arrayDelete, setArrayDelete] = useState([]);
  const [confirmationMessage, setConfirmationMessage] = useState(false);
  const refConfirmation = useRef(null);
  const router = useHistory();
  useClickAway(refConfirmation, () => {
    setConfirmationMessage(false);
  });
  const { control, handleSubmit, register, formState, errors } = useForm();
  const [data, setdata] = useState({
    teams: [],
    stadiums: [],
  });

  const getData = async () => {
    try {
      const teams = await axios
        .get(`${configData.SERVER_URL}/teams`)
        .then((res) => {
          return res.data.teams;
        });
      const stadiums = await axios
        .get(`${configData.SERVER_URL}/stadiums`)
        .then((res) => {
          return res.data.stadium;
        });
      setdata({ teams, stadiums });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const onsubmit = (data, e) => {
    e.preventDefault();
  };

  const getStadiumById = (id) => {
    if (data.stadiums) {
      return data.stadiums.find((stadium) => stadium.id === id);
    }
  };

  const getCountries = () => {
    return data.teams.filter((team, idx) => data.teams.indexOf(team) === idx);
  };

  const titleHeaders = [
    'Sel.',
    'Editar',
    'País',
    'Nombre',
    'Estadio sede',
    'Color princ.',
    'Color sec.',
    'Creación',
    'Ultima modif.',
  ];

  const titleHeadersConfirmation = ['País', 'Nombre', 'Estadio sede'];

  function displayRows(team, idx) {
    return (
      <tr className="hover:bg-gray-100">
        <DataCell className="">
          <Input
            id=""
            className="ring-2 ring-gris-oscuro cursor-pointer"
            height="h-5"
            width="w-5"
            name="checkbox"
            type="checkbox"
            onClick={() => clickChecbox(idx)}
          />
        </DataCell>
        <DataCell className="">
          <Link
            to={`/updateEquipo/${team.id}`}
            className="text-blue-600 underline"
          >
            Editar registro
          </Link>
        </DataCell>
        <DataCell className="space-x-4">{team.country}</DataCell>
        <DataCell>{team.name}</DataCell>
        <DataCell>{getStadiumById(team.stadium_id).name}</DataCell>
        <DataCell>{team.principal_color}</DataCell>
        <DataCell>{team.secundary_color}</DataCell>
        <DataCell>{team.inserted_date}</DataCell>
        <DataCell>{team.modified_date}</DataCell>
      </tr>
    );
  }

  const exportData = (teams) => {
    let req = [];
    teams.map((team) => {
      const xlsData = {
        País: team.country,
        Nombre: team.name,
        'Estadio sede': getStadiumById(team.stadium_id).name,
        'Color princ.': team.principal_color,
        'Color sec.': team.secundary_color,
        Creación: team.inserted_date,
        'Ultima modif.': team.modified_date,
      };
      req.push(xlsData);
    });
    convertJSONtoXLSX(req);
  };

  const convertJSONtoXLSX = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();

    // Cambiar el nombre, no se si sea necesario especificar que es o se puede poner la fecha
    XLSX.utils.book_append_sheet(workbook, worksheet, 'PalcosplusData'); //PalcosPlusData es el nombre de la hoja dentro del libro xlsx
    // Buffer
    XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    // Binary String
    XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    // Create a file
    XLSX.writeFile(workbook, 'equipos.xlsx'); //data.xlsx es el nombre del archivo, seria cambiarlo o buscar uno mas adoc
  };

  const clickChecbox = (idx) => {
    const found = arrayDelete.findIndex((number) => number === idx);
    if (found === -1) {
      setArrayDelete([...arrayDelete, idx]);
    } else {
      let newArrray = arrayDelete;
      newArrray.splice(found, 1);
      if (newArrray.length === 0) {
        setArrayDelete([]);
      } else {
        setArrayDelete(newArrray);
      }
    }
  };

  const confirmDelete = async () => {
    for (let i = 0; i < data.teams.length; i++) {
      const found = arrayDelete.findIndex((number) => number === i);
      if (found !== -1) {
        try {
          await axios.delete(
            `${configData.SERVER_URL}/events/team/${data.teams[i].id}`
          );
          await axios.delete(
            `${configData.SERVER_URL}/teams/${data.teams[i].id}`
          );
          router.go(0);
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  return (
    <form className="w-full pt-28" onSubmit={handleSubmit(onsubmit)}>
      <div className="mx-auto pb-24" style={{ maxWidth: '1080px' }}>
        <div className="flex flex-col-reverse md:flex-row justify-between pb-10">
          <div className="flex flex-col">
            <div id="tracking" className="pb-4">
              <h3 className="text-gris-oscuro">
                Panel de Administrador / Equipos
              </h3>
            </div>
            <div id="title" className="pb-2">
              <h1 className="text-sport-blue font-bold text-6xl">
                Gestionar Equipos
              </h1>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="pb-14 md:pb-0">
              <Link to="/panel">
                <Button className="w-40 h-14 bg-green-field hover:bg-green-highlight focus:ring-green-highlight ">
                  PANEL ADMIN.
                </Button>
              </Link>
            </div>
            <div className="hidden lg:hidden md:flex flex-row">
              <div className="px-4">
                <Link to="/createEquipo">
                  <Button className="w-full sm:w-36 h-14 bg-green-field hover:bg-green-highlight focus:ring-green-highlight ">
                    NUEVO
                  </Button>
                </Link>
              </div>
              <div>
                <Button
                  onClick={() => exportData(data.teams)}
                  className="w-full sm:w-36 h-14 text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight"
                >
                  DESCARGAR
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="md:hidden lg:flex w-full md:w-80 flex flex-col md:flex-row md:justify-between">
          <div className="pb-5 md:pb-0">
            <Link to="/createEquipo">
              <Button className="w-full md:w-36 h-14 bg-green-field hover:bg-green-highlight focus:ring-green-highlight ">
                NUEVO
              </Button>
            </Link>
          </div>
          <div>
            <Button
              onClick={() => exportData(data.teams)}
              className="w-full md:w-36 h-14 text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight"
            >
              DESCARGAR
            </Button>
          </div>
        </div>
        <div className="grid grid-flow-row pt-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-12 gap-y-8 pb-8">
          <div id="col">
            <div className="pb-1">
              <Label className="text-deep-blue">País</Label>
            </div>
            <Controller
              name="country"
              control={control}
              rules={{ required: 'Este campo no debe estar vacío' }}
              as={
                <Input
                  name="country"
                  input="select"
                  className="w-full h-10"
                  options={[{ value: 'mexico', label: 'México' }]}
                />
              }
            />
          </div>
          <div id="col">
            <div className="md:pt-4">
              <Button className="w-full md:w-36 h-14 bg-green-field hover:bg-green-highlight focus:ring-green-highlight ">
                APLICAR
              </Button>
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-row-reverse">
            <Button
              className="w-32 h-10 bg-rojo-error text-white p-2"
              onClick={() => setConfirmationMessage(true)}
              disable={arrayDelete.length > 0 ? false : true}
            >
              ELIMINAR
            </Button>
          </div>
          <div className="pt-2 overflow-x-auto">
            <Tables className="w-auto" headers={titleHeaders}>
              {data.teams &&
                data.teams.map((team, idx) => displayRows(team, idx))}
            </Tables>
          </div>
          <div className="pt-8">
            <Button className="w-56 h-14 bg-gris-auxiliar hover:bg-gris-medio focus:ring-gris-medio ">
              CARGAR MÁS
            </Button>
          </div>
        </div>
      </div>
      <ConfirmationModal
        openModal={confirmationMessage}
        onUpdate={() => confirmDelete()}
        onDimiss={() => setConfirmationMessage(false)}
        ref={refConfirmation}
        className={`${confirmationMessage
            ? 'z-50 transform transition-all ease-in border absolute bg-white rounded-lg shadow-md left-0 right-0 w-6/12 h-auto mx-auto'
            : 'hidden'
          }`}
        message={'Estas seguro de eliminar los siguientes datos?'}
      >
        <Tables
          className="w-full overflow-x-auto"
          headers={titleHeadersConfirmation}
        >
          {data.teams &&
            data.teams.map((team, idx) => {
              const found = arrayDelete.findIndex((number) => number === idx);
              if (found !== -1) {
                return (
                  <tr>
                    <DataCell className="truncate">{team.country}</DataCell>
                    <DataCell className="truncate">{team.name}</DataCell>
                    <DataCell className="truncate">
                      {getStadiumById(team.stadium_id).name}
                    </DataCell>
                  </tr>
                );
              }
            })}
        </Tables>
      </ConfirmationModal>
    </form>
  );
});

export default ManagerEquipos;
