import React, {
  forwardRef,
  useState,
  useEffect,
  FormEvent,
  useRef,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useClickAway } from 'react-use';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import XLSX from 'xlsx';
import configData from 'config.json';

import Tables, { DataCell } from 'components/Tables';
import Label from 'components/Label';
import Input from 'components/Input';
import Button from 'components/Button';
import Home from 'components/Icons/Home';
import ConfirmationModal from 'components/ConfirmationModal';

const ManagerEstadio = forwardRef((props, ref) => {
  const { control, handleSubmit, register, formState, errors } = useForm();
  const [stadiums, setstadiums] = useState();
  const [confirmationMessage, setConfirmationMessage] = useState(false);
  const refConfirmation = useRef(null);
  const [arrayDelete, setArrayDelete] = useState([]);
  const router = useHistory();
  useClickAway(refConfirmation, () => {
    setConfirmationMessage(false);
  });

  const getData = async () => {
    try {
      const stadiums = await axios
        .get(`${configData.SERVER_URL}/stadiums`)
        .then((res) => {
          return res.data.stadium;
        });
      setstadiums(stadiums);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const onsubmit = (data, e) => {
    e.preventDefault();
  };

  const titleHeaders = [
    'Sel.',
    'Editar',
    'Nombre del estadio',
    'País',
    'Ciudad',
    'Capacidad',
    'Fundación',
    'Zonas',
    'Puertas de acceso',
    'Equipos locales',
    'Ubicación (Google Maps)',
    'Creación',
    'Ultima modif.',
  ];

  const titleHeadersConfirmation = ['Nombre del estadio', 'País', 'Ciudad'];

  function displayRows(stadium, idx) {
    return (
      <tr className="hover:bg-gray-100">
        <DataCell className="">
          <Input
            id=""
            className="ring-2 ring-gris-oscuro cursor-pointer"
            height="h-5"
            width="w-5"
            name="checkbox"
            type="checkbox"
            onClick={() => clickChecbox(idx)}
          />
        </DataCell>
        <DataCell className="">
          <Link
            to={`/updateEstadio/${stadium.id}`}
            className="text-blue-600 underline"
          >
            Editar registro
          </Link>
        </DataCell>
        <DataCell className="space-x-4">{stadium.name}</DataCell>
        <DataCell>{stadium.country}</DataCell>
        <DataCell>{stadium.city}</DataCell>
        <DataCell>{stadium.capacity}</DataCell>
        <DataCell>{stadium.fundation_date}</DataCell>
        <DataCell>{stadium.zones}</DataCell>
        <DataCell>{stadium.access}</DataCell>
        <DataCell>{stadium.local_teams}</DataCell>
        <DataCell>
          <a
            className="text-blue-600 underline"
            href={stadium.location}
            target="blank"
          >
            {stadium.location}
          </a>
        </DataCell>
        <DataCell>{stadium.inserted_date}</DataCell>
        <DataCell>{stadium.modified_date}</DataCell>
      </tr>
    );
  }

  const exportData = (stadiums) => {
    let req = [];
    stadiums.map((stadium) => {
      const xlsData = {
        'Nombre del estadio': stadium.name,
        País: stadium.country,
        Ciudad: stadium.city,
        Capacidad: stadium.capacity,
        Fundación: stadium.fundation_date,
        Zonas: stadium.zones,
        'Puertas de acceso': stadium.access,
        'Equipos locales': stadium.local_teams,
        'Ubicación (Google Maps)': stadium.location,
        Creación: stadium.inserted_date,
        'Ultima modif.': stadium.modified_date,
      };
      req.push(xlsData);
    });
    convertJSONtoXLSX(req);
  };

  const convertJSONtoXLSX = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();

    // Cambiar el nombre, no se si sea necesario especificar que es o se puede poner la fecha
    XLSX.utils.book_append_sheet(workbook, worksheet, 'PalcosplusData'); //PalcosPlusData es el nombre de la hoja dentro del libro xlsx
    // Buffer
    XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    // Binary String
    XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    // Create a file
    XLSX.writeFile(workbook, 'estadios.xlsx'); //data.xlsx es el nombre del archivo, seria cambiarlo o buscar uno mas adoc
  };

  const clickChecbox = (idx) => {
    const found = arrayDelete.findIndex((number) => number === idx);
    if (found === -1) {
      setArrayDelete([...arrayDelete, idx]);
    } else {
      let newArrray = arrayDelete;
      newArrray.splice(found, 1);
      if (newArrray.length === 0) {
        setArrayDelete([]);
      } else {
        setArrayDelete(newArrray);
      }
    }
  };

  const confirmDelete = async () => {
    for (let i = 0; i < stadiums.length; i++) {
      const found = arrayDelete.findIndex((number) => number === i);
      if (found !== -1) {
        try {
          const teams = await axios
            .get(`${configData.SERVER_URL}/teams/stadium/${stadiums[i].id}`)
            .then((res) => {
              return res.data.teams;
            });
          await axios.delete(
            `${configData.SERVER_URL}/teams/stadium/${stadiums[i].id}`
          );
          for (let i = 0; i < teams.length; i++) {
            await axios.delete(
              `${configData.SERVER_URL}/events/team/${teams[i].id}`
            );
          }
          await axios.delete(
            `${configData.SERVER_URL}/events/stadium/${stadiums[i].id}`
          );
          await axios.delete(
            `${configData.SERVER_URL}/events/stadium/${stadiums[i].id}`
          );
          await axios.delete(
            `${configData.SERVER_URL}/palcos/stadium/${stadiums[i].id}`
          );
          await axios.delete(
            `${configData.SERVER_URL}/tarifas/${stadiums[i].id}`
          );
          await axios.delete(
            `${configData.SERVER_URL}/stadiums/${stadiums[i].id}`
          );
          router.go(0);
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  return (
    <form className="w-full pt-28" onSubmit={handleSubmit(onsubmit)}>
      <div className="mx-auto pb-24" style={{ maxWidth: '1080px' }}>
        <div className="flex flex-col-reverse lg:flex-row justify-between pb-10">
          <div className="flex flex-col md:pt-10 lg:pt-0">
            <div id="tracking" className="pb-4">
              <h3 className="text-gris-oscuro">
                <p>Panel de Administrador / Estadios</p>
              </h3>
            </div>
            <div id="title" className="pb-2">
              <h1 className="text-sport-blue font-bold text-6xl">
                Gestionar Estadios
              </h1>
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="pb-14 md:pb-0">
              <Link to="/panel">
                <Button className="w-56 md:w-40 h-14 bg-green-field hover:bg-green-highlight focus:ring-green-highlight ">
                  PANEL ADMIN.
                </Button>
              </Link>
            </div>
            <div className="hidden lg:hidden md:flex flex-row">
              <div className="px-4">
                <Link to="/createEstadio">
                  <Button className="w-full sm:w-36 h-14 bg-green-field hover:bg-green-highlight focus:ring-green-highlight ">
                    NUEVO
                  </Button>
                </Link>
              </div>
              <div>
                <Button
                  onClick={() => exportData(stadiums)}
                  className="w-full sm:w-36 h-14 text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight"
                >
                  DESCARGAR
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="md:hidden lg:flex w-full md:w-80 flex flex-col md:flex-row md:justify-between">
          <div className="pb-5 md:pb-0">
            <Link to="/createEstadio">
              <Button className="w-full md:w-36 h-14 bg-green-field hover:bg-green-highlight focus:ring-green-highlight ">
                NUEVO
              </Button>
            </Link>
          </div>
          <div>
            <Button
              onClick={() => exportData(stadiums)}
              className="w-full md:w-36 h-14 text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight"
            >
              DESCARGAR
            </Button>
          </div>
        </div>
        <div>
          <div className="hidden md:flex flex-row-reverse">
            <Button
              className="w-32 h-10 bg-rojo-error text-white p-2"
              onClick={() => setConfirmationMessage(true)}
              disable={arrayDelete.length > 0 ? false : true}
            >
              ELIMINAR
            </Button>
          </div>
          <div className="pt-10 md:pt-2 overflow-x-auto">
            <Tables className="w-auto" headers={titleHeaders}>
              {stadiums &&
                stadiums.map((stadium, idx) => displayRows(stadium, idx))}
            </Tables>
          </div>
          <div className="pt-8">
            <Button className="w-56 h-14 bg-gris-auxiliar hover:bg-gris-medio focus:ring-gris-medio ">
              CARGAR MÁS
            </Button>
          </div>
        </div>
      </div>
      <ConfirmationModal
        openModal={confirmationMessage}
        onUpdate={() => confirmDelete()}
        onDimiss={() => setConfirmationMessage(false)}
        ref={refConfirmation}
        className={`${confirmationMessage
          ? 'z-50 transform transition-all ease-in border absolute bg-white rounded-lg shadow-md left-0 right-0 w-6/12 h-auto mx-auto'
          : 'hidden'
          }`}
        message={'Estas seguro de eliminar los siguientes datos?'}
      >
        <Tables
          className="w-full overflow-x-auto"
          headers={titleHeadersConfirmation}
        >
          {stadiums &&
            stadiums.map((stadium, idx) => {
              const found = arrayDelete.findIndex((number) => number === idx);
              if (found !== -1) {
                return (
                  <tr>
                    <DataCell className="truncate">{stadium.name}</DataCell>
                    <DataCell className="truncate">{stadium.country}</DataCell>
                    <DataCell className="truncate">{stadium.city}</DataCell>
                  </tr>
                );
              }
            })}
        </Tables>
      </ConfirmationModal>
    </form>
  );
});

export default ManagerEstadio;
