import React, { forwardRef, useState, useEffect, FormEvent } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import TimeField from 'react-simple-timefield';
import Switch from 'react-switch';
import configData from 'config.json';

import Tables, { DataCell } from 'components/Tables';
import Label from 'components/Label';
import Input from 'components/Input';
import Button from 'components/Button';
import Home from 'components/Icons/Home';
import Loader from 'components/Loader';

const CreatePartido = forwardRef((props, ref) => {
  const [isImportant, setIsImportant] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const history = useHistory();
  const params = useParams();
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState({
    competitions: [],
    stadiums: [],
    event: '',
    teams: [],
  });

  const getData = async () => {
    try {
      const event = await axios
        .get(`${configData.SERVER_URL}/events/${params.id}`)
        .then((res) => {
          return res.data.event;
        });
      const competitions = await axios
        .get(`${configData.SERVER_URL}/competition`)
        .then((res) => {
          return res.data.competition;
        });
      const stadiums = await axios
        .get(`${configData.SERVER_URL}/stadiums`)
        .then((res) => {
          return res.data.stadium;
        });
      const teams = await axios
        .get(`${configData.SERVER_URL}/teams`)
        .then((res) => {
          return res.data.teams;
        });
      event.isImportant === true && setIsImportant(true);
      event.active === true && setIsActive(true);
      setData({
        competitions,
        stadiums,
        event,
        teams,
      });
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setLoader(true);
    getData();
  }, []);

  const { control, handleSubmit, register, formState, errors } = useForm();

  const onsubmit = async (data, e) => {
    try {
      e.preventDefault();
      const time = data.time.split(':');
      if (time[0].length === 3) {
        time[0] = time[0].slice(0, -1);
        data.time = time[0] + ':' + time[1];
      } else if (time[1].length === 3) {
        time[1] = time[1].slice(0, -1);
        data.time = time[0] + ':' + time[1];
      }
      const event = {
        type: data.type,
        home: data.local,
        visitor: data.visitante,
        date: data.date,
        time: data.time,
        jornada: data.jornada,
        competition_id: data.competition,
        stadium_id: data.stadium,
        isImportant: isImportant,
        active: isActive,
      };
      const res = await axios.patch(
        `${configData.SERVER_URL}/events/${params.id}`,
        event
      );
      const response = res.data.event;
      history.push('/managerEventos');
      history.go(0);
    } catch (err) {
      console.log(err);
    }
  };

  const getTeams = (defaultValue) => {
    if (defaultValue) {
      const tema = data.teams.find((team) => team.id === defaultValue);
      return [{ value: tema.id, label: tema.name }];
    } else {
      return data.teams.map((team) => {
        return { value: team.id, label: team.name };
      });
    }
  };

  const getStadium = (defaultValue) => {
    if (defaultValue) {
      const stadium = data.stadiums.find(
        (stadium) => stadium.id === defaultValue
      );
      return [{ value: stadium.id, label: stadium.name }];
    } else {
      return data.stadiums.map((stadium) => {
        return { value: stadium.id, label: stadium.name };
      });
    }
  };

  const getCompetition = (defaultValue) => {
    if (defaultValue) {
      const competition = data.competitions.find(
        (com) => com.id === defaultValue
      );
      return [{ value: competition.id, label: competition.name }];
    } else {
      return data.competitions.map((competitions) => {
        return { value: competitions.id, label: competitions.name };
      });
    }
  };

  const getTeamById = (id) => {
    return data.teams.find((team) => team.id === id);
  };

  if (!loader) {
    return (
      <form className="w-full pt-28" onSubmit={handleSubmit(onsubmit)}>
        <div className="mx-auto pb-24" style={{ maxWidth: '1080px' }}>
          <div className="flex flex-col-reverse md:flex-row justify-between pb-10">
            <div className="flex flex-col">
              <div id="tracking" className="pb-4">
                <h3 className="text-gris-oscuro">
                  Panel de Administrador / Eventos / Modificar Partido
                </h3>
              </div>
              <div id="title" className="pb-2">
                <h1 className="text-sport-blue font-bold text-5xl">
                  Modificar {getTeamById(data.event.home).name} vs{' '}
                  {getTeamById(data.event.visitor).name}
                </h1>
              </div>
            </div>
            <div className="flex flex-row pb-14 md:pb-0">
              <div className="">
                <Link to="/managerEventos">
                  <Button className="w-40 h-14 bg-green-field rounded-lg shadow-sm hover:bg-green-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight ">
                    Listado
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="grid grid-flow-row pt-8 md:grid-cols-2 lg:grid-cols-3 gap-12">
            <div id="col1">
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">Tipo de Evento</Label>
                </div>
                <Controller
                  name="type"
                  control={control}
                  rules={{ required: 'Este campo no debe estar vacío' }}
                  defaultValue={data.event.type}
                  render={({ onChange }) => (
                    <Input
                      name="type"
                      input="input"
                      type="text"
                      className="w-full h-10"
                      value={data.event.type}
                      disabled={true}
                    />
                  )}
                />
                {errors.type && (
                  <span className="text-rojo-error">{errors.type.message}</span>
                )}
              </div>
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">Hora local</Label>
                </div>
                <Controller
                  name="time"
                  control={control}
                  rules={{ required: 'Este campo no debe estar vacío' }}
                  defaultValue={data.event.time}
                  as={
                    <div className="flex relative w-full">
                      <TimeField
                        name="time"
                        style={{ width: '100%' }}
                        className=" h-10 border rounded-sm focus:outline-none focus:ring-2 focus:ring-gris-oscuro text-gris-oscuro bg-gris-auxiliar "
                        value={data.event.time}
                      />
                    </div>
                  }
                />
              </div>
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">Equipo Visitante</Label>
                </div>
                <Controller
                  name="visitante"
                  control={control}
                  rules={{ required: 'Este campo no debe estar vacío' }}
                  defaultValue={data.event.visitor}
                  as={
                    <Input
                      name="visitante"
                      input="select"
                      type="text"
                      className="w-full h-10"
                      options={[...getTeams(data.event.visitor), ...getTeams()]}
                    />
                  }
                />
                {errors.visitante && (
                  <span className="text-rojo-error">
                    {errors.visitante.message}
                  </span>
                )}
              </div>
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">Jornada</Label>
                </div>
                <Controller
                  name="jornada"
                  control={control}
                  rules={{ required: 'Este campo no debe estar vacío' }}
                  defaultValue={data.event.jornada}
                  as={
                    <Input
                      name="jornada"
                      input="input"
                      type="text"
                      className="w-full h-10"
                    />
                  }
                />
                {errors.jornada && (
                  <span className="text-rojo-error">
                    {errors.jornada.message}
                  </span>
                )}
              </div>
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">Evento destacado</Label>
                </div>
                <Switch
                  onColor={'#034AA6'}
                  onChange={() => setIsImportant(!isImportant)}
                  checked={isImportant}
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
              </div>
            </div>
            <div id="col2">
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">Fecha</Label>
                </div>
                <Controller
                  name="date"
                  control={control}
                  rules={{ required: 'Este campo no debe estar vacío' }}
                  defaultValue={data.event.date}
                  as={
                    <Input
                      name="date"
                      input="input"
                      className="w-full h-10"
                      type="date"
                    />
                  }
                />
                {errors.date && (
                  <span className="text-rojo-error">{errors.date.message}</span>
                )}
              </div>
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">Equipo Local</Label>
                </div>
                <Controller
                  name="local"
                  control={control}
                  rules={{ required: 'Este campo no debe estar vacío' }}
                  defaultValue={data.event.home}
                  as={
                    <Input
                      name="local"
                      input="select"
                      type="text"
                      className="w-full h-10"
                      options={[...getTeams(data.event.home), ...getTeams()]}
                    />
                  }
                />
                {errors.local && (
                  <span className="text-rojo-error">
                    {errors.local.message}
                  </span>
                )}
              </div>
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">Competición</Label>
                </div>
                <Controller
                  name="competition"
                  control={control}
                  rules={{ required: 'Este campo no debe estar vacío' }}
                  defaultValue={data.event.competition_id}
                  as={
                    <Input
                      name="competition"
                      input="select"
                      type="text"
                      className="w-full h-10"
                      options={[
                        ...getCompetition(data.event.competition_id),
                        ...getCompetition(),
                      ]}
                    />
                  }
                />
                {errors.competition && (
                  <span className="text-rojo-error">
                    {errors.competition.message}
                  </span>
                )}
              </div>
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">Estadio</Label>
                </div>
                <Controller
                  name="stadium"
                  control={control}
                  rules={{ required: 'Este campo no debe estar vacío' }}
                  defaultValue={data.event.stadium_id}
                  as={
                    <Input
                      name="stadium"
                      input="select"
                      type="text"
                      className="w-full h-10"
                      options={[
                        ...getStadium(data.event.stadium_id),
                        ...getStadium(),
                      ]}
                    />
                  }
                />
                {errors.stadium && (
                  <span className="text-rojo-error">
                    {errors.stadium.message}
                  </span>
                )}
              </div>
              <div id="data" className="pb-8">
                <div className="pb-1">
                  <Label className="text-deep-blue">Activo</Label>
                </div>
                <Switch
                  onColor={'#034AA6'}
                  onChange={() => setIsActive(!isActive)}
                  checked={isActive}
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
              </div>
            </div>
          </div>
          {/* <div className="pb-24 pt-10">
            <h1 className="text-sport-blue font-bold text-4xl">
              Disponibilidad de Propiedades
            </h1>
            <div className="pt-2 pb-5" style={{ width: "750px" }}>
              <Tables idheader={0} classHead="w-36" headers={titleHeaders}>
                <tr>
                  <DataCell className="px-4 py-2">Butacas</DataCell>
                  <DataCell className="px-4 py-2">
                    <Input
                      name="butacas"
                      input="text"
                      className="w-full h-10 bg-gris-auxiliar"
                    />
                  </DataCell>
                </tr>
                <tr>
                  <DataCell className="px-4 py-2">Plateas</DataCell>
                  <DataCell className="px-4 py-2">
                    <Input
                      name="plateas"
                      input="text"
                      className="w-full h-10 bg-gris-auxiliar"
                    />
                  </DataCell>
                </tr>
                <tr>
                  <DataCell className="px-4 py-2">Palcos</DataCell>
                  <DataCell className="px-4 py-2">
                    <Input
                      name="palcos"
                      input="text"
                      className="w-full h-10 bg-gris-auxiliar"
                    />
                  </DataCell>
                </tr>
              </Tables>
            </div>
          </div> */}
          {/* <div className="pb-24">
            <h1 className="text-sport-blue font-bold text-4xl">
              Registro de Actividad
            </h1>
            <div className="w-3/4 grid grid-flow-row grid-cols-2">
              <div className="flex flex-col">
                <h4 className="text-ocean-black font-bold text-xl pt-4">
                  Fecha de creacion
                </h4>
                <p>18/09/20 - 15:00</p>
              </div>
              <div className="flex flex-col">
                <h4 className="text-ocean-black font-bold text-xl pt-4">
                  Fecha de última modificacion
                </h4>
                <p>27/12/20 - 10:00</p>
              </div>
            </div>
          </div> */}
          <div className="flex flex-row">
            <div className="pr-5">
              <Button
                type="submit"
                className="w-44 h-14 font-bold text-xl text-ocean-black bg-green-field rounded-lg shadow-sm hover:bg-green-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight "
              >
                GUARDAR
              </Button>
            </div>
            <div className="pr-5">
              <Button
                onClick={() => history.goBack()}
                className="w-44 h-14 font-bold text-xl text-white bg-aqua-grass rounded-lg shadow-sm hover:bg-aqua-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight "
                type="button"
              >
                CANCELAR
              </Button>
            </div>
            {/* <div className="pr-5">
              <Button className="w-44 h-14 font-bold text-xl text-white bg-aqua-grass rounded-lg shadow-sm hover:bg-aqua-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight ">
                ELIMINAR
              </Button>
            </div> */}
          </div>
        </div>
      </form>
    );
  } else {
    return <Loader />;
  }
});

export default CreatePartido;
