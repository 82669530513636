import React, { forwardRef, useState, useRef, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useClickAway } from 'react-use';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import configData from 'config.json';

import Tables, { DataCell } from 'components/Tables';
import Label from 'components/Label';
import Input from 'components/Input';
import Button from 'components/Button';
import Home from 'components/Icons/Home';
import ConfirmationModal from 'components/ConfirmationModal';

const AllReservation = forwardRef((props, ref) => {
  const [arrayDelete, setArrayDelete] = useState([]);
  const [confirmationMessage, setConfirmationMessage] = useState(false);
  const refConfirmation = useRef(null);
  const router = useHistory();
  useClickAway(refConfirmation, () => {
    setConfirmationMessage(false);
  });
  const { control, handleSubmit, register, formState, errors } = useForm();
  const [data, setdata] = useState({
    rents: [],
    palcos: [],
  });

  const getData = async () => {
    try {
      const rents = await axios
        .get(`${configData.SERVER_URL}/rents`)
        .then((res) => {
          return res.data.rents;
        });
      const palcos = await axios
        .get(`${configData.SERVER_URL}/palcos`)
        .then((res) => {
          return res.data.palcos;
        });
      setdata({ rents, palcos });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
    orderRents();
  }, []);

  const orderRents = () => {
    function compare(a, b) {
      const date1 = a.event_date;
      const date2 = b.event_date;

      let comparison = 0;
      if (date1 > date2) {
        comparison = 1;
      } else if (date1 < date2) {
        comparison = -1;
      }
      return comparison;
    }

    data.rents.sort(compare);
  };

  const onsubmit = (data, e) => {
    e.preventDefault();
  };

  const titleHeaders = [
    'Sel.',
    'Activo',
    'Evento',
    'Fecha del evento',
    'Estadio',
    'Palco',
    'Nombre',
    'Correo',
    'Telefono',
    'Tipo de entrega',
    'Pais',
    'Estado',
    'Ciudad',
    'Colonia',
    'C.P.',
    'Direccion',
    'Numero interior',
  ];

  const titleHeadersConfirmation = [
    'Activo',
    'Fecha',
    'Estadio',
    'Palco',
    'Nombre',
    'Entrega',
  ];

  function displayRows(rent, idx) {
    return (
      <tr className="hover:bg-gray-100">
        <DataCell className="">
          <Input
            id=""
            className="ring-2 ring-gris-oscuro cursor-pointer"
            height="h-5"
            width="w-5"
            name="checkbox"
            type="checkbox"
            onClick={() => clickChecbox(idx)}
          />
        </DataCell>
        <DataCell className="space-x-4">
          {rent.active ? 'Activo' : 'Finalizado'}
        </DataCell>
        <DataCell className="space-x-4 truncate">{rent.event_name}</DataCell>
        <DataCell className="space-x-4">{rent.event_date}</DataCell>
        <DataCell className="space-x-4">{rent.stadium_name}</DataCell>
        <DataCell className="space-x-4">{rent.palco_name}</DataCell>
        <DataCell className="space-x-4">{`${rent.name} ${rent.last_name}`}</DataCell>
        <DataCell className="space-x-4">{rent.email}</DataCell>
        <DataCell className="space-x-4">{rent.tel}</DataCell>
        <DataCell className="space-x-4">
          {rent.alias === 'office'
            ? 'Recoger en oficina'
            : rent.alias === 'stadium'
              ? 'Recoger en estadio'
              : 'Envío a domicilio'}
        </DataCell>
        <DataCell className="space-x-4">
          {rent.country ? rent.country : ''}
        </DataCell>
        <DataCell className="space-x-4">
          {rent.state ? rent.state : ''}
        </DataCell>
        <DataCell className="space-x-4">{rent.city ? rent.city : ''}</DataCell>
        <DataCell className="space-x-4">
          {rent.suburb ? rent.suburb : ''}
        </DataCell>
        <DataCell className="space-x-4">
          {rent.postal_code ? rent.postal_code : ''}
        </DataCell>
        <DataCell className="space-x-4">
          {rent.address ? rent.address : ''}
        </DataCell>
        <DataCell className="space-x-4">
          {rent.interior_num ? rent.interior_num : ''}
        </DataCell>
      </tr>
    );
  }

  const clickChecbox = (idx) => {
    const found = arrayDelete.findIndex((number) => number === idx);
    if (found === -1) {
      setArrayDelete([...arrayDelete, idx]);
    } else {
      let newArrray = arrayDelete;
      newArrray.splice(found, 1);
      if (newArrray.length === 0) {
        setArrayDelete([]);
      } else {
        setArrayDelete(newArrray);
      }
    }
  };

  const confirmDelete = async () => {
    for (let i = 0; i < data.rents.length; i++) {
      const found = arrayDelete.findIndex((number) => number === i);
      if (found !== -1) {
        try {
          const res = await axios.patch(
            `${configData.SERVER_URL}/rents/${data.rents[i].id}`,
            { active: data.rents[i].active }
          );
          console.log(res);
          router.go(0);
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  return (
    <form className="w-full pt-28" onSubmit={handleSubmit(onsubmit)}>
      <div className="container-pp mx-auto pb-24">
        <div className="flex flex-col-reverse md:flex-row justify-between pb-10">
          <div className="flex flex-col">
            <div id="tracking" className="pb-4">
              <h3 className="text-gris-oscuro">
                Panel de Administrador / Historial de Reservas
              </h3>
            </div>
            <div id="title" className="pb-2">
              <h1 className="text-sport-blue font-bold text-6xl">
                Historial de Reservas
              </h1>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="pb-14 md:pb-0">
              <Link to="/panel">
                <Button className="w-40 h-14 bg-green-field hover:bg-green-highlight focus:ring-green-highlight ">
                  PANEL ADMIN.
                </Button>
              </Link>
            </div>
            {/* <div className="hidden lg:hidden md:flex flex-row">
              <div>
                <Button className="w-full sm:w-36 h-14 text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight">
                  DESCARGAR
                </Button>
              </div>
            </div> */}
          </div>
        </div>
        {/* <div className="md:hidden lg:flex w-full md:w-80 flex flex-col md:flex-row md:justify-between">
          <div>
            <Button className="w-full md:w-36 h-14 text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight">
              DESCARGAR
            </Button>
          </div>
        </div> */}
        {/* <div className="grid grid-flow-row pt-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-12 gap-y-8 pb-8">
          <div id="col">
            <div className="pb-1">
              <Label className="text-deep-blue">Estadio</Label>
            </div>
            <Controller
              name="stadium"
              control={control}
              as={
                <Input
                  name="stadium"
                  input="select"
                  className="w-full h-10"
                  options={[{ value: "jalisco", label: "Estadio Jalisco" }]}
                />
              }
            />
          </div>
          <div id="col">
            <div className="pb-1">
              <Label className="text-deep-blue">Desde (Fecha creación)</Label>
            </div>
            <Controller
              name="access"
              control={control}
              defaultValue={""}
              as={
                <Input
                  name="access"
                  input="input"
                  type="date"
                  className="w-full h-10"
                />
              }
            />
            {errors.access && (
              <span className="text-rojo-error">{errors.access.message}</span>
            )}
          </div>
          <div id="col">
            <div className="pb-1">
              <Label className="text-deep-blue">Hasta (Fecha Creación)</Label>
            </div>
            <Controller
              name="access"
              control={control}
              defaultValue={""}
              as={
                <Input
                  name="access"
                  input="input"
                  type="date"
                  className="w-full h-10"
                />
              }
            />
            {errors.access && (
              <span className="text-rojo-error">{errors.access.message}</span>
            )}
          </div>
          <div id="col">
            <div>
              <Button className="w-full md:w-36 h-14 bg-green-field hover:bg-green-highlight focus:ring-green-highlight ">
                APLICAR
              </Button>
            </div>
          </div>
        </div> */}
        <div>
          <div className="flex flex-row-reverse">
            <Button
              className="w-32 h-10 bg-rojo-error text-white p-2"
              onClick={() => setConfirmationMessage(true)}
              disable={arrayDelete.length > 0 ? false : true}
            >
              Cambiar Estatus
            </Button>
          </div>
          <div className="pt-2 overflow-x-auto">
            <Tables className="w-auto" headers={titleHeaders}>
              {data.rents &&
                data.rents.map((rent, idx) => displayRows(rent, idx))}
            </Tables>
          </div>
          <div className="pt-8">
            <Button className="w-56 h-14 bg-gris-auxiliar hover:bg-gris-medio focus:ring-gris-medio ">
              CARGAR MÁS
            </Button>
          </div>
        </div>
      </div>
      <ConfirmationModal
        openModal={confirmationMessage}
        onUpdate={() => confirmDelete()}
        onDimiss={() => setConfirmationMessage(false)}
        ref={refConfirmation}
        className={`${confirmationMessage
            ? 'z-50 transform transition-all ease-in border absolute bg-white rounded-lg shadow-md left-0 right-0 w-6/12 h-auto mx-auto'
            : 'hidden'
          }`}
        message={'Estas seguro de terminar las siguientes reservas?'}
      >
        <Tables
          className="w-full overflow-x-auto"
          headers={titleHeadersConfirmation}
        >
          {data.rents &&
            data.rents.map((rent, idx) => {
              const found = arrayDelete.findIndex((number) => number === idx);
              if (found !== -1) {
                return (
                  <tr>
                    <DataCell className="truncate">
                      {rent.active ? 'Activo' : 'Finalizado'}
                    </DataCell>
                    <DataCell className="truncate">{rent.event_date}</DataCell>
                    <DataCell className="truncate">
                      {rent.stadium_name}
                    </DataCell>
                    <DataCell className="truncate">{rent.palco_name}</DataCell>
                    <DataCell className="truncate">{`${rent.name} ${rent.last_name}`}</DataCell>
                    <DataCell className="truncate">
                      {rent.alias === 'office'
                        ? 'Recoger en oficina'
                        : rent.alias === 'stadium'
                          ? 'Recoger en estadio'
                          : 'Envío a domicilio'}
                    </DataCell>
                  </tr>
                );
              }
            })}
        </Tables>
      </ConfirmationModal>
    </form>
  );
});

export default AllReservation;
