import React from 'react';
import { createScope, map, transformProxies } from './helpers';
import { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, Link, useParams } from 'react-router-dom';
import { useClickAway } from 'react-use';
import { HashLink } from 'react-router-hash-link';
import configData from 'config.json';
import Loader from 'components/Loader';
import PropiedadImageModal from 'components/PropiedadImageModal';

const month = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const amenityClasses = {
  'Refrigerador': 'fridge',
  'TV': 'tv',
  'Estacionamiento': 'parking',
  'Baño': 'wc',
  'Horno': 'oven',
  'Mesero': 'waiter',
  'Derecho a ingresar bebidas': 'drinks',
  'Derecho a ingresar bebidas y alimentos': 'food'
};

const getAmenityClass = (amenity) => {
  return amenityClasses[amenity] || '';
};

const PagPropiedadView = () => {
  const [data, setData] = useState({
    allEvents: [],
    palcos: [],
    stadiums: [],
    events: [],
    teams: [],
    competitions: [],
    relationPE: '',
  });
  const [currentEvent, setCurrentEvent] = useState([0, 4]);
  const [currentImage, setCurrentImage] = useState(1);
  const location = useLocation();
  const router = useHistory();
  const params = useParams();
  const [loader, setLoader] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setShowModal(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);
  const refShowModal = useRef(null);
  useClickAway(refShowModal, () => {
    setShowModal(false);
  });

  const getData = async () => {
    try {
      const allEvents = await axios
        .get(`${configData.SERVER_URL}/events`)
        .then((res) => {
          return res.data.events;
        });
      const events = await axios
        .get(`${configData.SERVER_URL}/events/${params.event}`)
        .then((res) => {
          return res.data.event;
        });
      const stadiums = await axios
        .get(`${configData.SERVER_URL}/stadiums`)
        .then((res) => {
          return res.data.stadium;
        });
      const teams = await axios
        .get(`${configData.SERVER_URL}/teams`)
        .then((res) => {
          return res.data.teams;
        });
      const palcos = await axios
        .get(`${configData.SERVER_URL}/palcos/${params.id}`)
        .then((res) => {
          return res.data.palco;
        });
      const competitions = await axios
        .get(`${configData.SERVER_URL}/competition`)
        .then((res) => {
          return res.data.competition;
        });
      const relationPE = await axios
        .get(`${configData.SERVER_URL}/eventPalco/${params.id}/${params.event}`)
        .then((res) => {
          return res.data.relation;
        })
        .catch((err) => {
          return '';
        });
      setData({
        allEvents,
        palcos,
        stadiums,
        events,
        teams,
        competitions,
        relationPE,
      });
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  data.allEvents &&
    data.allEvents.sort((a, b) =>
      a.date > b.date ? 1 : b.date > a.date ? -1 : 0
    );

  const getTeamById = (id) => {
    if (data.teams) {
      return data.teams.find((team) => team.id === id);
    }
  };

  const getStadiumById = (id) => {
    if (data.stadiums) {
      return data.stadiums.find((stadium) => stadium.id === id);
    }
  };

  const getCompetitionById = (id) => {
    if (data.competitions) {
      const result = data.competitions.find(
        (competition) => competition.id === id
      );
      return result;
    }
  };

  const getDate = (param) => {
    if (data.events.date) {
      const date = data.events.date.split('-');
      switch (param) {
        case 'day':
          return date[2];
        case 'month':
          return date[1] === '0'
            ? month[date[1].substr(-1)]
            : month[date[1] - 1];
        case 'year':
          return date[0];
        default:
          break;
      }
    }
  };

  const getDateCard = (param, event) => {
    if (event) {
      const date = event.date.split('-');
      switch (param) {
        case 'day':
          return date[2];
        case 'month':
          return date[1] === '0'
            ? month[date[1].substr(-1)]
            : month[date[1] - 1];
        case 'year':
          return date[0];
        default:
          break;
      }
    }
  };

  const getEventsByDateStadium = (event) => {
    return data.allEvents.filter((e) => {
      const date1 = new Date(e.date);
      const date2 = new Date(event.date);
      if (
        date1 >= date2 &&
        e.stadium_id === event.stadium_id &&
        e.id !== event.id
      ) {
        return e;
      }
    });
  };

  const changeImage = (value) => {
    if (value === 'next') {
      currentImage < data.palcos.images.length - 1
        ? setCurrentImage(currentImage + 1)
        : setCurrentImage(1);
    } else if (value === 'previous') {
      currentImage > 1
        ? setCurrentImage(currentImage - 1)
        : setCurrentImage(data.palcos.images.length - 1);
    }
  };

  const getColorZone = (zone) => {
    let color = '#4c8080';
    const satdium_zones = getStadiumById(data.events.stadium_id).zones;
    const zones = satdium_zones.split(',');
    for (let i = 0; i < zones.length; i++) {
      const zone_color = zones[i].split('-');
      if (zone_color[0] === zone) {
        if (zone_color.length > 1) color = zone_color[1];
      }
    }
    return color;
  };

  if (!loader) {
    return (
      <span className="af-view">
        <div>
          <div className="af-class-bighero af-class-prop-hero af-class-prop-herobg">
            <img
              src={`${data.palcos.images[0]}`}
              loading="lazy"
              sizes="100vw"
              srcSet={`${data.palcos.images[0]} 500w, ${data.palcos.images[0]} 800w, ${data.palcos.images[0]} 1080w, ${data.palcos.images[0]} 1600w, ${data.palcos.images[0]} 2000w, ${data.palcos.images[0]} 2048w`}
              alt=""
              className="af-class-prop-hero-bgimg"
            />
          </div>
          <div className="af-class-bighero af-class-prop-hero">
            <div className="af-class-prop-bggrad" />
            <div className="af-class-gen-container af-class-prop-hero-container">
              <div className="af-class-prop-hero-leftcolumn">
                <div
                  id="w-node-ffab34fc-8457-7888-9f59-c91ac3e01969-47180e5f"
                  className="af-class-prop-hero-event-wrapper"
                >
                  {/* por que se va a sustituir esto al ser evento */}
                  {data.events.type === 'partido' ? (
                    <div>
                      <div
                        id="w-node-ecd36ce4-2505-d1bd-9ab1-bf395dde2c8b-47180e5f"
                        className="af-class-prop-event-img-wrapper"
                      >
                        <img
                          src={`${getTeamById(data.events.home).images[0]}`}
                          loading="lazy"
                          sizes="100vw"
                          srcSet={`${getTeamById(data.events.home).images[0]
                            } 500w, ${getTeamById(data.events.home).images[0]
                            } 535w`}
                          alt=""
                          className="af-class-prop-event-img"
                        />
                        <div className="af-class-prop-event-vs">Vs</div>
                        <img
                          src={`${getTeamById(data.events.visitor).images[0]}`}
                          loading="lazy"
                          alt=""
                          className="af-class-prop-event-img"
                        />
                      </div>
                      <div
                        id="w-node-_860e93c9-28a7-96d3-9b1f-dd0df13d7729-47180e5f"
                        className="af-class-prop-event-name-wrapper"
                      >
                        <div>{getTeamById(data.events.home).name}</div>
                        <div className="af-class-prop-event-vs">Vs</div>
                        <div>{getTeamById(data.events.visitor).name}</div>
                      </div>
                    </div>
                  ) : (
                    <div
                      id="w-node-_860e93c9-28a7-96d3-9b1f-dd0df13d7729-47180e5f"
                      className="af-class-prop-event-name-wrapper"
                    >
                      <div>{data.events.name}</div>
                    </div>
                  )}
                  {/* -- */}
                  <div
                    id="w-node-_3ea142a5-8b33-0521-85b7-848f60660949-47180e5f"
                    className="af-class-prop-event-datetime-wrapper"
                  >
                    <div className="af-class-prop-event-data af-class-prop-event-databold">
                      {getDate('day')}
                    </div>
                    <div className="af-class-prop-event-data af-class-prop-event-datamedium">
                      {getDate('month')}
                    </div>
                    <div className="af-class-prop-event-data">
                      {getDate('year')}
                    </div>
                    <div className="af-class-prop-event-data">···</div>
                    <div className="af-class-prop-event-data af-class-prop-event-databold">
                      {data.events.time}
                    </div>
                    <div className="af-class-prop-event-data">Hrs</div>
                  </div>
                </div>
                <div
                  id="w-node-e9ab2169-d436-5dd2-8947-3d17a0b368a4-47180e5f"
                  className="af-class-prop-hero-auxevent-wrapper"
                >
                  <div className="af-class-prop-hero-stadium">
                    <img
                      src={
                        window.location.origin +
                        '/images/icon-stadium_white.svg'
                      }
                      loading="lazy"
                      alt=""
                      className="af-class-prop-hero-stadiumicon"
                    />
                    <div>{getStadiumById(data.events.stadium_id).name}</div>
                  </div>
                  <div className="af-class-prop-hero-roundtour-wrapper">
                    {data.events.type === 'partido' ? (
                      <div>
                        <div className="af-class-prop-hero-round">
                          Jornada {data.events.jornada}
                        </div>
                        <div>
                          {getCompetitionById(data.events.competition_id).name}
                        </div>{' '}
                      </div>
                    ) : (
                      <div>{data.events.tour_name}</div>
                    )}
                  </div>
                </div>
                <div
                  id="w-node-a83abcc3-6d09-bdd2-9fc6-a380474423c5-47180e5f"
                  className="af-class-prop-hero-card"
                >
                  <h1 className="af-class-prop-hero-propname">
                    {data.palcos.name}
                  </h1>
                  <div className="af-class-cat-propcard-dataline-wrapper af-class-prop-hero-dataline">
                    <div
                      className="af-class-prop-hero-zonecolor"
                      style={{
                        backgroundColor: getColorZone(data.palcos.zone),
                      }}
                    />
                    <div className="af-class-gen-paragraph af-class-prop-datalabel">
                      Zona:
                    </div>
                    <div className="af-class-gen-paragraph">
                      {data.palcos.zone}
                    </div>
                  </div>
                  <div className="af-class-cat-propcard-dataline-wrapper af-class-prop-hero-dataline">
                    <div className="af-class-gen-paragraph af-class-prop-datalabel">
                      Accesos:
                    </div>
                    <div className="af-class-gen-paragraph">
                      {data.palcos.num_cards} tarjetas
                    </div>
                  </div>
                  <div className="af-class-prop-hero-price-wrapper">
                    <div className="af-class-prop-hero-price">
                      $
                      {data.relationPE?.price
                        ? new Intl.NumberFormat('es-MX').format(
                          data.relationPE?.price
                        )
                        : new Intl.NumberFormat('es-MX').format(
                          data.palcos.price
                        )}
                    </div>
                    <div className="af-class-prop-hero-curr">Mxn</div>
                  </div>
                  <div className="af-class-prop-hero-buttons-wrapper">
                    <Link
                      to={`/checkout/${params.id}/${params.event}`}
                      className="af-class-gen-button af-class-prop-hero-rentbutton w-button"
                    >
                      Rentar
                    </Link>
                    <HashLink
                      to="#detalles"
                      className="af-class-prop-hero-morebutton w-inline-block"
                    >
                      <img
                        src={
                          window.location.origin +
                          '/images/downArrow-deepBlue.svg'
                        }
                        loading="lazy"
                        alt=""
                        className="af-class-prop-hero-hola"
                      />
                      <div>detalles</div>
                    </HashLink>
                  </div>
                </div>
              </div>
              {data.palcos && (
                <div className="af-class-prop-hero-rightcolumn">
                  <div
                    data-animation="slide"
                    data-duration={800}
                    data-infinite={1}
                    id="mainSlider"
                    className="af-class-prop-hero-slider w-slider"
                  >
                    <div
                      className="af-class-prop-hero-lightbox w-inline-block w-lightbox"
                      onClick={() => setShowModal(true)}
                    >
                      <img
                        src={
                          window.location.origin + '/images/expand-deepBlue.svg'
                        }
                        loading="lazy"
                        alt=""
                        className="af-class-prop-hero-lightboxicon"
                      />
                      <div>Ver galería</div>
                    </div>
                    <div className="w-slider-mask">
                      {data.palcos.images
                        .slice(currentImage, currentImage + 1)
                        .map((img, idx) => {
                          return (
                            <div className="w-slide">
                              <img
                                src={`${img}`}
                                loading="lazy"
                                sizes="(max-width: 479px) 90vw, (max-width: 767px) 88vw, (max-width: 991px) 82vw, 62vw"
                                srcSet={`"${img} 500w, ${img} 1080w, ${img} 1200w"`}
                                alt=""
                                className="af-class-prop-hero-slider-img"
                              />
                            </div>
                          );
                        })}
                    </div>
                    <div
                      className="af-class-prop-slider-arrow w-slider-arrow-left"
                      onClick={() => changeImage('previous')}
                    >
                      <img
                        src={
                          window.location.origin +
                          '/images/sliderArrowLeft-deepBlue.svg'
                        }
                        loading="lazy"
                        alt=""
                        className="af-class-cat-propslider-arrowicon"
                      />
                    </div>
                    <div
                      className="af-class-prop-slider-arrow af-class-prop-slider-rightarrow w-slider-arrow-right"
                      onClick={() => changeImage('next')}
                    >
                      <img
                        src={
                          window.location.origin +
                          '/images/sliderArrowRight-deepBlue.svg'
                        }
                        loading="lazy"
                        alt=""
                        className="af-class-cat-propslider-arrowicon"
                      />
                    </div>
                    <div className="af-class-prop-hero-slider-nav w-slider-nav w-round" />
                  </div>
                  <div
                    data-animation="slide"
                    data-duration={800}
                    data-infinite={1}
                    data-thumbs-for="#mainSlider"
                    className="af-class-prop-hero-thumbslider w-slider"
                  >
                    <div className="af-class-prop-hero-thumbslider-mask w-slider-mask">
                      {data.palcos.images.map((img, idx) => {
                        if (idx > 0) {
                          return (
                            <div
                              className="af-class-prop-hero-thumb w-slide"
                              onClick={() => setCurrentImage(idx)}
                            >
                              <img
                                src={`${img}`}
                                loading="lazy"
                                sizes="(max-width: 767px) 100vw"
                                srcSet={`"${img} 500w, ${img} 1080w, ${img} 1200w"`}
                                alt=""
                                className="af-class-prop-hero-thumbimg"
                              />
                            </div>
                          );
                        }
                      })}
                    </div>
                    <div className="af-class-prop-hero-thumbslider-arrows w-slider-arrow-left">
                      <div className="w-icon-slider-left" />
                    </div>
                    <div className="af-class-prop-hero-thumbslider-arrows w-slider-arrow-right">
                      <div className="w-icon-slider-right" />
                    </div>
                    <div className="af-class-prop-hero-slider-nav w-slider-nav w-round" />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            id="detalles"
            className="af-class-gen-section af-class-prop-details-section"
          >
            <div className="af-class-gen-container af-class-prop-details-container">
              <div
                id="w-node-c4d88a3c-bcad-b81a-ff42-5655df7d98da-47180e5f"
                className="af-class-prop-data-wrapper"
              >
                <div className="af-class-prop-gendata-wrapper">
                  <img
                    alt=""
                    src={`${getStadiumById(data.events.stadium_id).images[
                      getStadiumById(data.events.stadium_id).images.length - 1
                      ]
                      }`}
                    loading="lazy"
                    className="af-class-cat-stdmap"
                    style={{
                      width: '350px',
                      marginRight: '20px',
                      marginTop: '20px',
                      marginBottom: '40px',
                    }}
                  />
                  <div className="af-class-prop-gendata-column">
                    <Link
                      onClick={() =>
                        window.open(
                          getStadiumById(data.events.stadium_id).location
                        )
                      }
                      className="af-class-prop-gmaps w-inline-block"
                    >
                      <img
                        src={window.location.origin + '/images/google-maps.svg'}
                        loading="lazy"
                        alt=""
                        className="af-class-prop-gmaps-icon"
                      />
                      <div>¿Cómo llegar?</div>
                    </Link>
                    <div className="af-class-prop-gendata-block">
                      <div className="af-class-prop-dataline af-class-prop-dataline-zone">
                        <div className="af-class-gen-paragraph af-class-prop-datalabel">
                          Zona:
                        </div>
                        <div className="af-class-gen-paragraph">
                          {data.palcos.zone}
                        </div>
                        <div
                          className="af-class-prop-zonecolor"
                          style={{
                            backgroundColor: getColorZone(data.palcos.zone),
                          }}
                        />
                      </div>
                      <div className="af-class-prop-dataline">
                        <div className="af-class-gen-paragraph af-class-prop-datalabel">
                          Accesos:
                        </div>
                        <div className="af-class-gen-paragraph">
                          {data.palcos.num_cards} tarjetas
                        </div>
                      </div>
                      <div className="af-class-prop-dataline">
                        <div className="af-class-gen-paragraph af-class-prop-datalabel">
                          Puertas de acceso:
                        </div>
                        <div className="af-class-gen-paragraph">
                          {data.palcos.access}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="af-class-prop-details-wrapper">
                  <div className="af-class-prop-details-title">
                    <img
                      src={
                        window.location.origin + '/images/icon-details_blue.svg'
                      }
                      loading="lazy"
                      alt=""
                      className="af-class-prop-details-icon"
                    />
                    <h2 className="af-class-gen-smalltitle af-class-prop-details-title">
                      Detalles
                    </h2>
                  </div>
                  <p className="af-class-gen-paragraph">
                    {data.palcos.description}
                  </p>
                </div>

                <div className="af-class-prop-details-wrapper">
                  <div className="af-class-prop-details-title">
                    <img
                      src={
                        window.location.origin + '/images/plus-circle.svg'
                      }
                      loading="lazy"
                      alt=""
                      className="af-class-prop-details-icon"
                    />
                    <h2 className="af-class-gen-smalltitle af-class-prop-details-title">
                      Amenidades
                    </h2>
                  </div>
                  
                </div>
                <div className='ameneties-list'>
                  {data.palcos.amenities.map((amenity) => (
                    <div className='flex mt-2'>
                      <div key={amenity} className={`amenetie ${getAmenityClass(amenity)}`}>
                        
                      </div>
                      <p className='af-class-gen-paragraph ml-2'>{amenity}</p>
                    </div>
                  ))}
                </div>


              </div>
              <div
                id="w-node-b1c9397f-9ae6-c33b-a9bc-5c93778df7ec-47180e5f"
                className="af-class-prop-ship-wrapper"
              >
                <div
                  id="w-node-_27a91aad-3281-8f93-1fa1-a44cf29e3d48-47180e5f"
                  className="af-class-prop-ship-title-wrapper"
                >
                  <h2 className="af-class-gen-smalltitle af-class-prop-ship-title">
                    Recibe tus entradas en la puerta de tu domicilio
                  </h2>
                  <img
                    src={
                      window.location.origin +
                      '/images/bigIcon_home_ship_aqua.svg'
                    }
                    loading="lazy"
                    alt=""
                    className="af-class-prop-ship-graphic"
                  />
                </div>
                <div
                  id="w-node-_6c273d87-0ede-9fcd-adad-b8e8cc7292d8-47180e5f"
                  className="af-class-prop-ship-textblock"
                >
                  <div className="af-class-gen-paragraph af-class-prop-ship-text af-class-prop-ship-subtitle">
                    Antes del evento
                  </div>
                  <div className="af-class-gen-paragraph af-class-prop-ship-text">
                    Al pagar, podrás seleccionar si deseas recibir tus entradas
                    en el domicilio que elijas o recogerlas en el estadio, el
                    día del evento.
                  </div>
                </div>
                <div
                  id="w-node-_852bb05b-6732-840b-9bf7-0692daeebafe-47180e5f"
                  className="af-class-prop-ship-textblock af-class-prop-ship-textblock-indent"
                >
                  <div className="af-class-gen-paragraph af-class-prop-ship-text af-class-prop-ship-subtitle">
                    Después del evento
                  </div>
                  <div className="af-class-gen-paragraph af-class-prop-ship-text">
                    Deberás entregar las entradas (tarjetas) al personal del
                    staff, cuando te retires del complejo.
                  </div>
                </div>
                <div
                  id="w-node-_228b97cf-9833-133a-e859-471df4baa8ad-47180e5f"
                  className="af-class-gen-paragraph af-class-prop-ship-finallabel"
                >
                  ¿Tienes dudas sobre el proceso de entrega de tarjetas?
                  <Link to="#" className="af-class-gen-textlink">
                    Consulta las FAQs
                  </Link>{' '}
                  o{' '}
                  <Link to="#" className="af-class-gen-textlink">
                    envíanos un mensaje
                  </Link>
                  .
                </div>
              </div>
              <div
                id="w-node-_7215e4da-fd52-ab5a-f9f5-64192cfdcd89-47180e5f"
                className="af-class-prop-side-wrapper"
              >
                <div className="af-class-prop-sticky-container">
                  <div className="af-class-prop-sideprop-wrapper">
                    <div className="af-class-prop-side-propname">
                      {data.palcos.name}
                    </div>
                    <div className="af-class-prop-side-propdata-wrapper">
                      <div className="af-class-prop-side-datamedium">
                        {data.palcos.num_cards} tarjetas
                      </div>
                      <div>{getStadiumById(data.events.stadium_id).name}</div>
                    </div>
                    <div className="af-class-cat-propcard-price-wrapper af-class-prop-side-price-wrapper">
                      <div className="af-class-cat-propcard-price">
                        $
                        {data.relationPE?.price
                          ? new Intl.NumberFormat('es-MX').format(
                            data.relationPE?.price
                          )
                          : new Intl.NumberFormat('es-MX').format(
                            data.palcos.price
                          )}
                      </div>
                      <div className="af-class-cat-propcard-curr">Mxn</div>
                    </div>
                    <Link
                      to={`/checkout/${params.id}/${params.event}`}
                      className="af-class-gen-button w-button"
                    >
                      Rentar
                    </Link>
                  </div>
                  {/* esta card que es? */}
                  {/* <div className="af-class-prop-side-event-wrapper">
                    <div className="af-class-prop-side-eventname-wrapper">
                      <div className="af-class-prop-side-event-img-wrapper">
                        <img src={window.location.origin + "/images/Atlas.png"} loading="lazy" sizes="100vw" srcSet="images/Atlas-p-500.png 500w, images/Atlas.png 535w" alt="" className="af-class-prop-event-img" />
                        <img src={window.location.origin + "/images/Chivas.svg"} loading="lazy" alt="" className="af-class-prop-event-img" />
                      </div>
                      <div className="af-class-prop-event-name-wrapper">
                        <div>Atlas F.C.</div>
                        <div className="af-class-prop-event-vs">Vs</div>
                        <div>Guadalajara</div>
                      </div>
                    </div>
                    <div className="af-class-prop-side-dateround-wrapper">
                      <div className="af-class-prop-event-datetime-wrapper">
                        <div className="af-class-prop-event-data af-class-prop-event-databold">19</div>
                        <div className="af-class-prop-event-data af-class-prop-event-datamedium">Nov</div>
                        <div className="af-class-prop-event-data">2021</div>
                        <div className="af-class-prop-event-data">···</div>
                        <div className="af-class-prop-event-data af-class-prop-event-databold">21:00</div>
                        <div className="af-class-prop-event-data">Hrs</div>
                      </div>
                      <div>Jornada 15</div>
                    </div>
                    <img src={window.location.origin + "/images/galeria4.jpg"} loading="lazy" sizes="(max-width: 767px) 100vw, (max-width: 991px) 310px, 350px" srcSet="images/galeria4-p-500.jpeg 500w, images/galeria4-p-800.jpeg 800w, images/galeria4-p-1080.jpeg 1080w, images/galeria4-p-1600.jpeg 1600w, images/galeria4-p-2000.jpeg 2000w, images/galeria4.jpg 2048w" alt="" className="af-class-prop-eventcard-std-bg" />
                    <img src={window.location.origin + "/images/triangleGraphicDown-white.svg"} loading="lazy" alt="" className="af-class-prop-side-event-triangle" />
                    <img src={window.location.origin + "/images/LigaMX-logo2-neg.png"} loading="lazy" sizes="100vw" srcSet="images/LigaMX-logo2-neg-p-500.png 500w, images/LigaMX-logo2-neg-p-800.png 800w, images/LigaMX-logo2-neg-p-1080.png 1080w, images/LigaMX-logo2-neg-p-1600.png 1600w, images/LigaMX-logo2-neg.png 1920w" alt="" className="af-class-prop-side-logo" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="af-class-gen-section af-class-prop-recom-section">
            <div className="af-class-gen-container">
              <h2 className="af-class-gen-sectiontitle af-class-prop-recom-title">
                ¿Te encantó este lugar?
                <br />
                ¡Réntalo para otros eventos!
              </h2>
              <h2 className="af-class-gen-sectiontitle af-class-prop-recom-title af-class-prop-recom-mobtitle">
                ¡Renta este lugar para otros eventos!
              </h2>
              <div className="af-class-prop-recom-slider-sider" />
              <div className="af-class-prop-recom-slider-sider af-class-prop-recom-slider-rightsider" />
              {data.allEvents.length > 0 && (
                <div
                  data-animation="slide"
                  data-hide-arrows={1}
                  data-duration={800}
                  className="af-class-prop-recom-slider w-slider"
                >
                  <div
                    id="recom-slider-mask"
                    className="af-class-prop-recom-slider-mask w-slider-mask"
                  >
                    {getEventsByDateStadium(data.events)
                      .slice(currentEvent[0], currentEvent[1])
                      .map((event, idx) => {
                        return event.type === 'partido' ? (
                          <div className="af-class-prop-recom-slider-slide w-slide">
                            <Link
                              to={`/evento/${event.id}`}
                              className="af-class-prop-recom-eventcard w-inline-block"
                            >
                              <div className="af-class-prop-recom-eventcard-graph-wrapper">
                                <img
                                  src={`${getTeamById(event.home).images[0]}`}
                                  loading="lazy"
                                  sizes="100vw"
                                  srcSet={`${getTeamById(event.home).images[0]
                                    } 500w, ${getTeamById(event.home).images[0]
                                    } 535w"`}
                                  alt=""
                                  className="af-class-prop-event-img"
                                />
                                <div className="af-class-prop-event-vs">Vs</div>
                                <img
                                  src={`${getTeamById(event.visitor).images[0]
                                    }`}
                                  loading="lazy"
                                  sizes="100vw"
                                  srcSet={`"${getTeamById(event.visitor).images[0]
                                    } 500w, ${getTeamById(event.visitor).images[0]
                                    } 800w, ${getTeamById(event.visitor).images[0]
                                    } 1080w, ${getTeamById(event.visitor).images[0]
                                    } 1200w"`}
                                  alt=""
                                  className="af-class-prop-event-img"
                                />
                                <img
                                  src={`${getStadiumById(event.stadium_id).images[0]
                                    }`}
                                  loading="lazy"
                                  sizes="(max-width: 479px) 230px, (max-width: 991px) 240px, 21vw"
                                  srcSet={`${getStadiumById(event.stadium_id).images[0]
                                    } 500w, ${getStadiumById(event.stadium_id).images[0]
                                    } 800w, ${getStadiumById(event.stadium_id).images[0]
                                    } 1080w, ${getStadiumById(event.stadium_id).images[0]
                                    } 1600w, ${getStadiumById(event.stadium_id).images[0]
                                    } 2000w, ${getStadiumById(event.stadium_id).images[0]
                                    } 2048w`}
                                  alt=""
                                  className="af-class-prop-eventcard-std-bg"
                                />
                                <img
                                  src={
                                    window.location.origin +
                                    '/images/triangleGraphicDown-white.svg'
                                  }
                                  loading="lazy"
                                  alt=""
                                  className="af-class-prop-recom-eventcard-triangle"
                                />
                              </div>
                              <div className="af-class-prop-recom-eventcard-data-wrapper">
                                <div className="af-class-cat-eventcard-title-wrapper af-class-prop-recom-eventcard-title-wrapper">
                                  <h3 className="af-class-cat-eventcard-title">
                                    {getTeamById(event?.home)?.name}
                                  </h3>
                                  <div className="af-class-cat-gamecard-vs">
                                    VS
                                  </div>
                                  <h3 className="af-class-cat-eventcard-title">
                                    {getTeamById(event?.visitor)?.name}
                                  </h3>
                                </div>
                                <div className="af-class-cat-eventcard-details-wrapper">
                                  <div className="af-class-cat-eventcard-roundleague-wrapper">
                                    <div className="af-class-cat-eventcard-round">
                                      Jornada {event.jornada}
                                    </div>
                                    <div className="af-class-cat-eventcard-league">
                                      {
                                        getCompetitionById(event.competition_id)
                                          .name
                                      }
                                    </div>
                                  </div>
                                  <div className="af-class-cat-eventcard-stadium-wrapper">
                                    <img
                                      src={
                                        window.location.origin +
                                        '/images/icon-stadium_deepBlue.svg'
                                      }
                                      loading="lazy"
                                      alt=""
                                      className="af-class-cat-eventcard-stadiumicon"
                                    />
                                    <div className="af-class-cat-eventcard-stadium">
                                      {getStadiumById(event.stadium_id).name}
                                    </div>
                                  </div>
                                  <div className="af-class-cat-eventcard-datetime-wrapper">
                                    <div className="af-class-cat-eventcard-data af-class-eventcard-databold">
                                      {getDateCard('day', event)}
                                    </div>
                                    <div className="af-class-cat-eventcard-data af-class-eventcard-datamedium">
                                      {getDateCard('month', event)}
                                    </div>
                                    <div className="af-class-cat-eventcard-data">
                                      {getDateCard('year', event)}
                                    </div>
                                    <div className="af-class-cat-eventcard-data">
                                      ···
                                    </div>
                                    <div className="af-class-cat-eventcard-data af-class-eventcard-databold">
                                      {event.time}
                                    </div>
                                    <div className="af-class-cat-eventcard-data">
                                      Hrs
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        ) : (
                          <div className="af-class-prop-recom-slider-slide w-slide">
                            <Link
                              to={`/evento/${event.id}`}
                              className="af-class-prop-recom-eventcard w-inline-block"
                            >
                              <div className="af-class-prop-recom-eventcard-graph-wrapper af-class-prop-recom-eventcard-graphgen-wrapper">
                                <img
                                  src={`${event.images[0]}`}
                                  loading="lazy"
                                  alt=""
                                  className="af-class-prop-event-img"
                                />
                                <img
                                  src={`${event.images[0]}`}
                                  loading="lazy"
                                  sizes="(max-width: 479px) 230px, (max-width: 991px) 240px, 21vw"
                                  srcSet={`${event.images[0]} 500w, ${event.images[0]} 800w, ${event.images[0]} 1080w, ${event.images[0]} 1600w, ${event.images[0]} 2000w, ${event.images[0]} 2048w`}
                                  alt=""
                                  className="af-class-prop-eventcard-std-bg"
                                />
                                <img
                                  src={
                                    window.location.origin +
                                    '/images/triangleGraphicDown-white.svg'
                                  }
                                  loading="lazy"
                                  alt=""
                                  className="af-class-prop-recom-eventcard-triangle"
                                />
                              </div>
                              <div className="af-class-prop-recom-eventcard-data-wrapper">
                                <div className="af-class-cat-eventcard-title-wrapper af-class-prop-recom-eventcard-title-wrapper">
                                  <h3 className="af-class-cat-eventcard-title">
                                    {event.name}
                                  </h3>
                                </div>
                                <div className="af-class-cat-eventcard-details-wrapper">
                                  <div className="af-class-cat-eventcard-tour af-class-prop-recom-eventcard-tour">
                                    {event.tour_name}
                                  </div>
                                  <div className="af-class-cat-eventcard-stadium-wrapper">
                                    <img
                                      src={
                                        window.location.origin +
                                        '/images/icon-stadium_deepBlue.svg'
                                      }
                                      loading="lazy"
                                      alt=""
                                      className="af-class-cat-eventcard-stadiumicon"
                                    />
                                    <div className="af-class-cat-eventcard-stadium">
                                      {getStadiumById(event.stadium_id).name}
                                    </div>
                                  </div>
                                  <div className="af-class-cat-eventcard-datetime-wrapper">
                                    <div className="af-class-cat-eventcard-data af-class-eventcard-databold">
                                      {getDateCard('day', event)}
                                    </div>
                                    <div className="af-class-cat-eventcard-data af-class-eventcard-datamedium">
                                      {getDateCard('month', event)}
                                    </div>
                                    <div className="af-class-cat-eventcard-data">
                                      {getDateCard('year', event)}
                                    </div>
                                    <div className="af-class-cat-eventcard-data">
                                      ···
                                    </div>
                                    <div className="af-class-cat-eventcard-data af-class-eventcard-databold">
                                      {event.time}
                                    </div>
                                    <div className="af-class-cat-eventcard-data">
                                      Hrs
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                  </div>
                  <div
                    className="af-class-prop-recom-slider-arrow w-slider-arrow-left"
                    onClick={() =>
                      currentEvent[0] === 0
                        ? setCurrentEvent([4, 8])
                        : setCurrentEvent([0, 4])
                    }
                  >
                    <img
                      src={
                        window.location.origin +
                        '/images/sliderArrowLeft-deepBlue.svg'
                      }
                      loading="lazy"
                      alt=""
                      className="af-class-cat-propslider-arrowicon"
                    />
                  </div>
                  <div
                    className="af-class-prop-recom-slider-arrow af-class-prop-recom-slider-rightarrow w-slider-arrow-right"
                    onClick={() =>
                      currentEvent[1] === 4
                        ? setCurrentEvent([4, 8])
                        : setCurrentEvent([0, 4])
                    }
                  >
                    <img
                      src={
                        window.location.origin +
                        '/images/sliderArrowRight-deepBlue.svg'
                      }
                      loading="lazy"
                      alt=""
                      className="af-class-cat-propslider-arrowicon"
                    />
                  </div>
                  <div className="af-class-prop-recom-slider-nav w-slider-nav w-slider-nav-invert w-round" />
                </div>
              )}
            </div>
          </div>
          <div className="af-class-gen-section">
            <div className="af-class-gen-container">
              <img
                src={
                  window.location.origin + '/images/bigIcon_questions_props.svg'
                }
                loading="lazy"
                alt=""
                className="af-class-prop-goback-graphic"
              />
              <div className="af-class-gen-paragraph af-class-prop-goback-text">
                ¿No es el lugar que buscabas? No hay problema, sigamos viendo
                opciones.
              </div>
              <Link
                onClick={() => router.goBack()}
                className="af-class-gen-button af-class-prop-goback-button w-inline-block"
              >
                <img
                  src={
                    window.location.origin + '/images/leftArrow-deepBlue.svg'
                  }
                  loading="lazy"
                  alt=""
                  className="af-class-prop-hero-hola"
                />
                <div>Volver al listado</div>
              </Link>
            </div>
          </div>
        </div>
        <PropiedadImageModal
          openModal={showModal}
          url={data.palcos.images[currentImage]}
          onDimiss={() => setShowModal(false)}
          ref={refShowModal}
          onChange={changeImage}
        />
      </span>
    );
  } else {
    return <Loader />;
  }
};

export default PagPropiedadView;

/* eslint-enable */
