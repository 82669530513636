import React from 'react'

const CreacionCuentaView = () => {

  return (
    <span>
      <style dangerouslySetInnerHTML={{
        __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/front-end-palcosplus.webflow.css);


            html {
              -webkit-tap-highlight-color: rgba(0,0,0,0);
            }
            textarea {
              resize: vertical;
            }
            .w-lightbox-backdrop {
              background: rgba(0,1,13,.85);
            }
        ` }} />
      <span className="af-view">
        <div>
          <div className="af-class-popup-wrapper">
            <div className="af-class-popup-container">
              <div className="af-class-form-block w-form">
                <form id="wf-form-signup" name="wf-form-signup" data-name="signup" className="af-class-form">
                  <div className="af-class-form-section">
                    <div className="af-class-form-sectiontitle-wrapper">
                      <img src="images/icon-userEdit_blue.svg" loading="lazy" alt className="af-class-form-sectiontitle-icon" />
                      <h2 className="af-class-form-sectiontitle">Crear nueva cuenta</h2>
                    </div>
                    <div className="af-class-form-content-wrapper">
                      <div className="af-class-form-field-wrapper">
                        <label htmlFor="name-2" className="af-class-form-label">Nombre(s) *</label>
                        <input type="text" className="af-class-form-textinput w-input" maxLength={256} name="name" data-name="name" placeholder="....." id="name" required />
                      </div>
                      <div className="af-class-form-field-wrapper">
                        <label htmlFor="lastname" className="af-class-form-label">Apellidos *</label>
                        <input type="text" className="af-class-form-textinput w-input" maxLength={256} name="lastname" data-name="lastname" placeholder="....." id="lastname" required />
                      </div>
                      <div className="af-class-form-field-wrapper">
                        <label htmlFor="email-2" className="af-class-form-label">Correo electrónico *</label>
                        <input type="email" className="af-class-form-textinput w-input" maxLength={256} name="email" data-name="email" placeholder="....." id="email" required />
                      </div>
                      <div className="af-class-form-field-wrapper">
                        <label htmlFor="phone" className="af-class-form-label">Teléfono *</label>
                        <input type="tel" className="af-class-form-textinput w-input" maxLength={256} name="phone" data-name="phone" placeholder="....." id="phone" required />
                      </div>
                      <div className="af-class-form-field-wrapper">
                        <label htmlFor="birthday" className="af-class-form-label">Fecha de nacimiento *</label>
                        <input type="text" className="af-class-form-textinput w-input" maxLength={256} name="birthday" data-name="birthday" placeholder="....." id="birthday" required />
                      </div>
                      <div className="af-class-form-field-wrapper af-class-form-imgfield-wrapper">
                        <img src="images/dum_User_sq.jpg" loading="lazy" sizes="100px" srcSet="images/dum_User_sq-p-500.jpeg 500w, images/dum_User_sq.jpg 800w" alt className="af-class-form-imgpreview" />
                        <div className="af-class-form-imgfield-content">
                          <label htmlFor="name-2" className="af-class-form-label af-class-form-imglabel">Imagen de perfil</label>
                          <div className="af-class-form-imgfield-buttonwrapper">
                            <div className="af-class-form-filename">nombreArchivo.jpeg</div>
                            <a href="#" className="af-class-form-uploadbutton w-button">Cargar</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="af-class-form-section">
                    <div className="af-class-form-sectiontitle-wrapper">
                      <img src="images/icon-passwordEdit_blue.svg" loading="lazy" alt className="af-class-form-sectiontitle-icon" />
                      <h2 className="af-class-form-sectiontitle">Establecer contraseña</h2>
                    </div>
                    <div className="af-class-form-content-wrapper">
                      <div className="af-class-form-field-wrapper">
                        <label htmlFor="password" className="af-class-form-label">Contraseña *</label>
                        <input type="password" className="af-class-form-textinput w-input" maxLength={256} name="password" data-name="password" placeholder="....." id="password" required />
                      </div>
                      <div className="af-class-form-field-wrapper">
                        <label htmlFor="repeat-password" className="af-class-form-label">Repetir contraseña *</label>
                        <input type="password" className="af-class-form-textinput w-input" maxLength={256} name="repeat-password" data-name="repeat-password" placeholder="....." id="repeat-password" required />
                      </div>
                    </div>
                  </div>
                  <div className="af-class-form-section">
                    <div className="af-class-form-sectiontitle-wrapper">
                      <img src="images/icon-fav_location_blue.svg" loading="lazy" alt className="af-class-form-sectiontitle-icon" />
                      <h2 className="af-class-form-sectiontitle">Dirección favorita de entrega</h2>
                    </div>
                    <p className="af-class-form-note">Aquí te entregaremos tus entradas cuando rentes algún palco, platea o butaca. Si no estás seguro, no te preocupes, podrás agregarla o modificarla más tarde. También, si prefieres, podrás recoger las tarjetas en nuestras oficinas o en el estadio, el día del evento.</p>
                    <div className="af-class-form-content-wrapper">
                      <div className="af-class-form-field-wrapper">
                        <div className="af-class-form-label-wrapper">
                          <label htmlFor="name-3" className="af-class-form-label">Alias</label>
                          <label htmlFor="name-3" className="af-class-form-labelnote">Casa, oficina, etc.</label>
                        </div>
                        <input type="text" className="af-class-form-textinput w-input" maxLength={256} name="fav-address-alias" data-name="fav-address-alias" placeholder="....." id="fav-address-alias" />
                      </div>
                      <div className="af-class-form-field-wrapper">
                        <label htmlFor="fav-address-country" className="af-class-form-label">País</label>
                        <select id="fav-address-country" name="fav-address-country" data-name="fav-address-country" className="af-class-form-drop w-select">
                          <option value>Seleccionar país</option>
                          <option value>México</option>
                        </select>
                      </div>
                      <div className="af-class-form-field-wrapper">
                        <label htmlFor="fav-address-state" className="af-class-form-label">Estado</label>
                        <select id="fav-address-state" name="fav-address-state" data-name="fav-address-state" className="af-class-form-drop w-select">
                          <option value>Seleccionar estado</option>
                          <option value>Jalisco</option>
                        </select>
                      </div>
                      <div className="af-class-form-field-wrapper">
                        <label htmlFor="fav-address-city" className="af-class-form-label">Ciudad</label>
                        <input type="text" className="af-class-form-textinput w-input" maxLength={256} name="fav-address-city" data-name="fav-address-city" placeholder="....." id="fav-address-city" />
                      </div>
                      <div className="af-class-form-field-wrapper">
                        <label htmlFor="fav-address-suburb" className="af-class-form-label">Colonia</label>
                        <input type="text" className="af-class-form-textinput w-input" maxLength={256} name="fav-address-suburb" data-name="fav-address-suburb" placeholder="....." id="fav-address-suburb" />
                      </div>
                      <div className="af-class-form-field-wrapper">
                        <label htmlFor="fav-address-zip" className="af-class-form-label">Código Postal</label>
                        <input type="text" className="af-class-form-textinput w-input" maxLength={256} name="fav-address-zip" data-name="fav-address-zip" placeholder="....." id="fav-address-zip" required />
                      </div>
                      <div className="af-class-form-field-wrapper">
                        <label htmlFor="fav-address-address" className="af-class-form-label">Dirección</label>
                        <input type="text" className="af-class-form-textinput w-input" maxLength={256} name="fav-address-address" data-name="fav-address-address" placeholder="....." id="fav-address-address" required />
                      </div>
                      <div className="af-class-form-field-wrapper">
                        <label htmlFor="fav-address-interior" className="af-class-form-label">Número interior</label>
                        <input type="text" className="af-class-form-textinput w-input" maxLength={256} name="fav-address-interior" data-name="fav-address-interior" placeholder="....." id="fav-address-interior" />
                      </div>
                    </div>
                  </div>
                  <div className="af-class-form-section af-class-form-lastsection">
                    <div className="af-class-form-content-wrapper">
                      <label className="w-checkbox af-class-form-checkbox-wrapper">
                        <div className="w-checkbox-input w-checkbox-input--inputType-custom af-class-form-checkbox-box" />
                        <input type="checkbox" id="accept-policies" name="accept-policies" data-name="accept-policies" required style={{ opacity: 0, position: 'absolute', zIndex: -1 }} />
                        <span htmlFor="accept-policies" className="af-class-form-checkbox-label w-form-label">He leído y acepto los <a href="#" className="af-class-form-checkbox-link">Términos y Condiciones</a>, así como el <a href="#" className="af-class-form-checkbox-link">Aviso de Privacidad</a> de PalcosPlus.com *</span>
                      </label>
                      <input type="submit" defaultValue="Registrarme" data-wait="....." className="af-class-gen-button af-class-form-sendbutton w-button" />
                    </div>
                  </div>
                </form>
                <div className="af-class-form-success-wrapper w-form-done">
                  <img src="images/title-confirmRent_blue.svg" loading="lazy" alt className="af-class-form-success-icon" />
                  <div>
                    <span className="af-class-form-success-boldtext">¡Gracias por registrarte en PalcosPlus.com!<br />
                    </span>Para continuar, verifica tu cuenta accediendo al link que recibirás en tu correo de registro.</div>
                </div>
                <div className="af-class-form-error-wrapper w-form-fail">
                  <div>Al parecer hay un error con el registro. Verifica que todos los campos obligatorios (*) estén completos e inténtalo de nuevo. Si los problemas persisten por favor contacta a soporte.</div>
                </div>
              </div>
              <div className="af-class-popup-close-wrapper">
                <img src="images/close-sp-gray.svg" loading="lazy" alt className="af-class-popup-closeicon" />
              </div>
            </div>
          </div>
        </div>
      </span>
    </span>
  )
}

export default CreacionCuentaView

/* eslint-enable */