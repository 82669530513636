import React, { forwardRef, useState, useEffect, FormEvent } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import ImageUploading from 'react-images-uploading';
import axios from 'axios';
import configData from 'config.json';
import NumberFormat from 'react-number-format';
import Switch from 'react-switch';

import Label from 'components/Label';
import Input from 'components/Input';
import Button from 'components/Button';
import Home from 'components/Icons/Home';
import Carousel from 'components/Carousel';

import './CreatePalco.css';

const CreatePalco = forwardRef((props, ref) => {
  const [imagesVista, setImagesVista] = useState([]);
  const [imagesGaleria, setImagesGaleria] = useState([]);
  const [currentImageVista, setCurrentImageVista] = useState(0);
  const [currentImageGaleria, setCurrentImageGaleria] = useState(0);
  const [stadiums, setStadiums] = useState([]);
  const [chooseStadium, setChooseStadium] = useState('');
  const [price, setPrice] = useState();
  const [comision, setComision] = useState();
  const [isImportant, setIsImportant] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [amenities, setAmenities] = useState([]);
  const [selectedAmenities, setSelectedAmenities] = useState([]);

  const [datos, setDatos] = useState({
    price: '',
    comision: '',
  });
  const history = useHistory();
  const maxNumber = 5;

  useEffect(() => {
    axios.get(`${configData.SERVER_URL}/stadiums`).then((res) => {
      const stadium = res.data;
      setStadiums(stadium.stadium);
      setAmenities([
        'Refrigerador',
        'TV',
        'Estacionamiento',
        'Baño',
        'Horno',
        'Mesero',
        'Derecho a ingresar bebidas',
        'Derecho a ingresar bebidas y alimentos',
      ]);
    });
  }, []);

  const onChangesetImagesVista = (imageList, addUpdateIndex) => {
    setImagesVista(imageList);
  };
  const onChangesetImagesGaleria = (imageList, addUpdateIndex) => {
    setImagesGaleria(imageList);
  };

  const handleAmenityChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedAmenities([...selectedAmenities, value]);
    } else {
      setSelectedAmenities(selectedAmenities.filter((amenity) => amenity !== value));
    }
  };

  const { control, handleSubmit, register, formState, errors } = useForm();

  const handleInputChange = (event) => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value.substring(1),
    });
  };

  const sendDataRequest = async (palco) => {
    try {
      const formData = new FormData();
      formData.append('name', palco.name);
      formData.append('type', palco.type);
      formData.append('zone', palco.zone);
      formData.append('access', palco.access);
      formData.append('num_cards', palco.num_cards);
      formData.append('description', palco.description);
      formData.append('price', palco.price);
      formData.append('owner_price', palco.owner_price);
      formData.append('stadium_id', palco.stadium_id);
      formData.append('comision', palco.comision);
      formData.append('active', palco.active);

      selectedAmenities.forEach((amenity) => {
        formData.append('amenities', amenity);
      });

      palco.images.map((img) => {
        formData.append('images', img);
      });
      const res = await axios.post(`${configData.SERVER_URL}/palcos`, formData);
      const response = res.data.palco;
      history.push('/managerPalco');
      history.go(0);
    } catch (err) {
      console.log(err);
    }
  };

  const onsubmit = (data, e) => {
    e.preventDefault();
    const imgVista = imagesVista.map((img, idx) => {
      const type = img.file.type.split('/');
      return Object.defineProperty(img.file, 'name', {
        writable: 'true',
        value: 'vista-' + data.name + '-' + (idx + 1) + '.' + type[1],
      });
    });
    const imgGaleria = imagesGaleria.map((img, idx) => {
      const type = img.file.type.split('/');
      return Object.defineProperty(img.file, 'name', {
        writable: 'true',
        value: 'galeria-' + data.name + '-' + (idx + 1) + '.' + type[1],
      });
    });
    const palco = {
      name: data.name,
      type: data.type,
      zone: data.zone,
      access: data.access,
      num_cards: data.cards,
      description: data.details,
      price: parseFloat(price) + parseFloat(price * (comision / 100)),
      owner_price: parseFloat(price),
      stadium_id: chooseStadium,
      comision: parseFloat(comision),
      active: isActive,
      images: [...imgVista, ...imgGaleria],
      amenities: selectedAmenities,
    };
    sendDataRequest(palco);
  };

  const getStadium = () => {
    return stadiums.map((stadium) => {
      return { value: stadium.id, label: stadium.name };
    });
  };

  const getZones = (id) => {
    if (id) {
      const stadium = stadiums.find((s) => s.id === id);
      const zones = stadium.zones.split(',');
      const options = zones.map((z) => {
        const color = z.split('-');
        return { value: color[0], label: color[0] };
      });
      return options;
    } else {
      return [{ value: '', label: '' }];
    }
  };

  const getAccessDoor = (id) => {
    if (id) {
      const stadium = stadiums.find((s) => s.id === id);
      const zones = stadium.access.split(',');
      const options = zones.map((z) => {
        return { value: z, label: z };
      });
      return options;
    } else {
      return [{ value: '', label: '' }];
    }
  };

  return (
    <form className="w-full pt-28" onSubmit={handleSubmit(onsubmit)}>
      <div className="container-pp mx-auto pb-24">
        <div className="flex flex-col-reverse md:flex-row justify-between pb-10">
          <div className="flex flex-col">
            <div id="tracking" className="pb-4">
              <h3 className="text-gris-oscuro">
                Panel de Administrador / Propiedades / Palcos / Agregar Palco
              </h3>
            </div>
            <div id="title" className="pb-2">
              <h1 className="text-sport-blue font-bold text-5xl">
                Agregar Nueva Propiedad
              </h1>
            </div>
          </div>
          <div className="flex flex-row pb-14 md:pb-0">
            <div className="">
              <Link to="/managerPalco">
                <Button className="w-40 h-14 bg-green-field rounded-lg shadow-sm hover:bg-green-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight ">
                  Listado
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="grid grid-flow-row pt-8 md:grid-cols-2 lg:grid-cols-3 gap-12">
          <div id="col1">
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Nombre de la propiedad</Label>
              </div>
              <Controller
                name="name"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={''}
                as={
                  <Input
                    name="name"
                    input="input"
                    type="text"
                    className="w-full h-10"
                    placeholder="Palco #1 o Palco de Fulanito"
                  />
                }
              />
              {errors.name && (
                <span className="text-rojo-error">{errors.name.message}</span>
              )}
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Zona</Label>
              </div>
              <Controller
                name="zone"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={''}
                as={
                  <Input
                    name="zone"
                    input="select"
                    type="text"
                    className="w-full h-10"
                    options={[
                      { value: '', label: 'Selecciona una zona' },
                      ...getZones(chooseStadium),
                    ]}
                  />
                }
              />
              {errors.zone && (
                <span className="text-rojo-error">{errors.zone.message}</span>
              )}
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Tipo de propiedad</Label>
              </div>
              <Controller
                name="type"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={'palco'}
                as={
                  <Input
                    name="type"
                    input="select"
                    className="w-full h-10"
                    options={[
                      { value: 'palco', label: 'Palco' },
                      { value: 'butaca', label: 'Butaca' },
                      { value: 'platea', label: 'Platea   ' },
                    ]}
                  />
                }
              />
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Precio de dueño</Label>
              </div>
              {/* <Controller
                name="price"
                control={control}
                defaultValue={0}
                render={(props) => (
                  <Input
                    name="price"
                    input="input"
                    className="w-full h-10"
                    placeholder="1000"
                    type="number"
                    onChange={(e) => props.onChange(handleInputChange(e))}
                  />
                )}
              /> */}
              <Controller
                name="price"
                control={control}
                defaultValue={0}
                render={(props) => (
                  <NumberFormat
                    name="price"
                    className="w-full h-10"
                    placeholder="$1,000.00"
                    customInput={Input}
                    thousandSeparator={true}
                    prefix="$"
                    decimalSeparator="."
                    allowNegative={false}
                    decimalScale={2}
                    fixedDecimalScale={2}
                    onValueChange={(e) => setPrice(e.value)}
                  />
                )}
              />
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Detalles</Label>
              </div>
              <Controller
                name="details"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={''}
                as={
                  <Input
                    name="details"
                    input="textarea"
                    className="w-full h-48"
                  />
                }
              />
              {errors.details && (
                <span className="text-rojo-error">
                  {errors.details.message}
                </span>
              )}
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Palco destacado</Label>
              </div>
              <Switch
                onColor={'#034AA6'}
                onChange={() => setIsImportant(!isImportant)}
                checked={isImportant}
                checkedIcon={false}
                uncheckedIcon={false}
              />
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Activo</Label>
              </div>
              <Switch
                onColor={'#034AA6'}
                onChange={() => setIsActive(!isActive)}
                checked={isActive}
                checkedIcon={false}
                uncheckedIcon={false}
              />
            </div>
          </div>
          <div id="col2">
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Estadio</Label>
              </div>
              <Input
                ref={register}
                onChange={(e) => setChooseStadium(e.target.value)}
                name="stadium"
                input="select"
                type="text"
                className="w-full h-10"
                options={[
                  { value: '', label: 'Selecciona un estadio' },
                  ...getStadium(),
                ]}
              />
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Puertas de acceso</Label>
              </div>
              <Controller
                name="access"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={''}
                as={
                  <Input
                    name="access"
                    input="select"
                    type="text"
                    className="w-full h-10"
                    options={[
                      { value: '', label: 'Selecciona puerta de acceso' },
                      ...getAccessDoor(chooseStadium),
                    ]}
                  />
                }
              />
              {errors.access && (
                <span className="text-rojo-error">{errors.access.message}</span>
              )}
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Numero de tarjetas</Label>
              </div>
              <Controller
                name="cards"
                control={control}
                rules={{ required: 'Este campo no debe estar vacío' }}
                defaultValue={''}
                as={
                  <Input
                    name="cards"
                    input="input"
                    className="w-full h-10"
                    type="number"
                    placeholder="10"
                  />
                }
              />
              {errors.cards && (
                <span className="text-rojo-error">{errors.cards.message}</span>
              )}
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Comisión de PalcosPlus</Label>
              </div>
              {/* <Controller
                name="comision"
                control={control}
                defaultValue={0}
                render={(props) => (
                  <Input
                    name="comision"
                    input="input"
                    className="w-full h-10"
                    type="number"
                    placeholder="10 (porcentaje del precio del dueño)"
                    onChange={(e) => props.onChange(handleInputChange(e))}
                  />
                )}
              /> */}
              <Controller
                name="comision"
                control={control}
                defaultValue={0}
                render={(props) => (
                  <NumberFormat
                    name="comision"
                    className="w-full h-10"
                    placeholder="%10"
                    customInput={Input}
                    prefix="%"
                    allowNegative={false}
                    onValueChange={(e) => setComision(e.value)}
                  />
                )}
              />
            </div>

            

            <div id="costos" className="pb-8">
              <div className="pb-5">
                <Label className="text-deep-blue">Precio de renta</Label>
                <p className="text-4xl">
                  $
                  {price && comision
                    ? parseFloat(price) + parseFloat(price * (comision / 100))
                    : ''}
                </p>
              </div>
              <div className="">
                <Label className="text-deep-blue">Comision de PalcosPlus</Label>
                <p className="text-4xl">
                  ${comision ? parseFloat(price * ('.' + comision)) : ''}
                </p>
              </div>
            </div>
          </div>
          <div id="col3">
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Amenidades</Label>
              </div>
              <div className="grid grid-cols-1 gap-4">
                {amenities.map((amenity) => (
                  <div key={amenity} className='flex items-center'>
                    <input
                      type="checkbox"
                      id={amenity}
                      name={amenity}
                      value={amenity}
                      onChange={handleAmenityChange}
                    />
                    <label htmlFor={amenity} className='ml-2 mb-0'>{amenity}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div id="subtitle">
            <h1 className="text-sport-blue font-bold text-4xl">Media</h1>
          </div>
          <div className="grid grid-flow-row pt-8 md:grid-cols-2 gap-12 pb-20">
            <ImageUploading
              multiple
              value={imagesVista}
              onChange={onChangesetImagesVista}
              maxNumber={1}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <div className="">
                  <h1 className="text-ocean-black font-bold text-xl">Vista</h1>
                  <div className={imagesVista.length === 0 && 'hidden'}>
                    {imagesVista.length !== 0 && (
                      <Carousel current={(e) => setCurrentImageVista(e)}>
                        {imagesVista}
                      </Carousel>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <div className="flex flex-row">
                      <div className="">
                        <Button
                          type="button"
                          className={
                            'w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight'
                          }
                          style={isDragging ? { color: 'red' } : undefined}
                          onClick={onImageUpload}
                          disable={imagesVista.length === 5 && true}
                          {...dragProps}
                        >
                          CARGAR
                        </Button>
                      </div>
                      {imagesVista.length > 0 && (
                        <div className="pl-3">
                          <Button
                            type="button"
                            className={
                              'w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight '
                            }
                            disable={imagesVista.length === 0 && true}
                            onClick={() => onImageRemove(currentImageVista)}
                          >
                            Borrar
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </ImageUploading>
            <ImageUploading
              multiple
              value={imagesGaleria}
              onChange={onChangesetImagesGaleria}
              maxNumber={maxNumber}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <div className="">
                  <h1 className="text-ocean-black font-bold text-xl">
                    Galería
                  </h1>
                  <div className={imagesGaleria.length === 0 && 'hidden'}>
                    {imagesGaleria.length !== 0 && (
                      <Carousel current={(e) => setCurrentImageGaleria(e)}>
                        {imagesGaleria}
                      </Carousel>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <div className="flex flex-row">
                      <div className="">
                        <Button
                          type="button"
                          className={
                            'w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight'
                          }
                          style={isDragging ? { color: 'red' } : undefined}
                          onClick={onImageUpload}
                          disable={imagesGaleria.length === 5 && true}
                          {...dragProps}
                        >
                          CARGAR
                        </Button>
                      </div>
                      {imagesGaleria.length > 0 && (
                        <div className="pl-3">
                          <Button
                            type="button"
                            className={
                              'w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight '
                            }
                            disable={imagesGaleria.length === 0 && true}
                            onClick={() => onImageRemove(currentImageGaleria)}
                          >
                            Borrar
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </ImageUploading>
          </div>
        </div>
        <div className="flex flex-row">
          <div className="pr-5">
            <Button
              type="submit"
              className="w-44 h-14 font-bold text-xl text-ocean-black bg-green-field rounded-lg shadow-sm hover:bg-green-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight "
              disable={
                imagesGaleria.length === 0 || imagesVista.length === 0
                  ? true
                  : false
              }
            >
              GUARDAR
            </Button>
          </div>
          <div className="pr-5">
            <Button
              onClick={() => history.goBack()}
              className="w-44 h-14 font-bold text-xl text-white bg-aqua-grass rounded-lg shadow-sm hover:bg-aqua-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight "
              type="button"
            >
              CANCELAR
            </Button>
          </div>
          {/* <div className="pr-5">
            <Button className="w-44 h-14 font-bold text-xl text-white bg-aqua-grass rounded-lg shadow-sm hover:bg-aqua-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight ">
              ELIMINAR
            </Button>
          </div> */}
        </div>
      </div>
    </form>
  );
});

export default CreatePalco;
