import React from 'react';
import { createScope, map, transformProxies } from './helpers';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import LottieAnimation from '../assets/documents/wow-logo-lottie.json';

const scripts = [
  {
    loading: Promise.resolve(
      'document.addEventListener("scroll",function(){const e=document.getElementById("navbar"),t=document.getElementById("cart-num"),o=document.getElementById("navbar-burguer-color"),n=document.getElementById("navbar-burguer-white"),l=document.getElementById("navbar-cartIcon-color"),c=document.getElementById("navbar-cartIcon-white"),y=document.getElementById("navbar-logo-color"),a=document.getElementById("navbar-logo-white"),s=document.getElementById("navbar-logoIcon-color"),r=document.getElementById("navbar-logoIcon-white");var i=document.getElementsByClassName("navbar-navlink-text whitenavbar-navlink-text"),d=document.scrollingElement.scrollTop;function m(e){this.style.color="#ffffff",this.style.zIndex="6"}function f(e){this.style.color="#091926",this.style.zIndex="5"}function u(e){this.style.color="#ffffff",this.style.zIndex="5"}if(d<100){if(e.style.backgroundColor="transparent",t.style.color="#ffffff",o.style.opacity=0,n.style.opacity=1,y.style.opacity=0,s.style.opacity=0,a.style.opacity=1,r.style.opacity=1,l.style.opacity=0,c.style.opacity=1,991<window.innerWidth)for(var v=0;v<i.length;v++)i[v].style.color="#ffffff",i[v].removeEventListener("mouseout",f,!1),i[v].addEventListener("mouseout",u,!1)}else if(100<d&&(e.style.backgroundColor="#e6e6e6",t.style.color="#091926",o.style.opacity=1,n.style.opacity=0,y.style.opacity=1,s.style.opacity=1,a.style.opacity=0,r.style.opacity=0,l.style.opacity=1,c.style.opacity=0,991<window.innerWidth))for(v=0;v<i.length;v++)"6"!=i[v].style.zIndex&&(i[v].style.color="#091926"),i[v].addEventListener("mouseover",m,!1),i[v].removeEventListener("mouseout",u,!1),i[v].addEventListener("mouseout",f,!1)});'
    ),
    isAsync: false,
  },
  {
    loading: Promise.resolve(
      'document.addEventListener("scroll",function(){const e=document.getElementById("navbar");var n=document.scrollingElement.scrollTop;n<100?e.style.backgroundColor="transparent":100<n&&(e.style.backgroundColor="#e6e6e6")});'
    ),
    isAsync: false,
  },
  {
    loading: fetch(
      'https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=60e399f8a778918b4c4c009b'
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch('js/webflow.js').then((body) => body.text()),
    isAsync: false,
  },
];

let Controller;

class Footer extends React.Component {
  // static get Controller() {
  //   if (Controller) return Controller;

  //   try {
  //     Controller = require('../controllers/NavbarController');
  //     Controller = Controller.default || Controller;

  //     return Controller;
  //   } catch (e) {
  //     if (e.code == 'MODULE_NOT_FOUND') {
  //       Controller = Footer;

  //       return Controller;
  //     }

  //     throw e;
  //   }
  // }

  componentDidMount() {
    const htmlEl = document.querySelector('html');
    htmlEl.dataset['wfPage'] = '60e3b809b90ca52d22335a9a';
    htmlEl.dataset['wfSite'] = '60e399f8a778918b4c4c009b';

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function(`
        `).call(window, script);

          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
    const proxies =
      Footer.Controller !== Footer ? transformProxies(this.props.children) : {};

    return (
      <span>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/front-end-palcosplus.webflow.css);


              html {
              	-webkit-tap-highlight-color: rgba(0,0,0,0);
              }
            	textarea {
              	resize: vertical;
          	}
        `,
          }}
        />
        <span className="af-view">
          <div className="af-class-stylepag-body">
            <div className="af-class-footer">
              <div className="af-class-footer-block">
                <div className="af-class-footer-links-wrapper">
                  <div className="w-layout-grid af-class-footer-sitemap-wrapper">
                    <div className="af-class-footer-sitemap-column">
                      <div className="af-class-footer-columntitle">
                        PalcosPlus.com
                      </div>
                      <Link to="/" className="af-class-footer-navlink">
                        Inicio
                      </Link>
                      <Link to="/nosotros" className="af-class-footer-navlink">
                        Nosotros
                      </Link>
                      <Link to="/estadios" className="af-class-footer-navlink">
                        Estadios
                      </Link>
                      <Link
                        to="/#events-cat"
                        className="af-class-footer-navlink"
                      >
                        Eventos
                      </Link>
                    </div>
                    <div className="af-class-footer-sitemap-column">
                      <div className="af-class-footer-columntitle">Soporte</div>
                      <Link to="/contacto" className="af-class-footer-navlink">
                        Contacto
                      </Link>
                      <Link to="/faqs" className="af-class-footer-navlink">
                        Preguntas frecuentes
                      </Link>
                      <a
                        href="https://palcosplus.s3.us-east-2.amazonaws.com/Te%CC%81rminos+y+Condiciones.pdf"
                        target="_blank"
                        rel="noreferrer"
                        download
                        className="af-class-footer-navlink"
                      >
                        Términos y Condiciones
                      </a>
                      <a
                        href="https://palcosplus.s3.us-east-2.amazonaws.com/Aviso+de+Privacidad.pdf"
                        target="_blank"
                        rel="noreferrer"
                        download
                        className="af-class-footer-navlink"
                      >
                        Políticas de Privacidad
                      </a>
                    </div>
                  </div>
                  <div className="af-class-footer-contact-wrapper">
                    <img
                      src={
                        window.location.origin + '/images/noTag-Estandar.svg'
                      }
                      loading="lazy"
                      alt=""
                      className="af-class-footer-palcospluslogo"
                    />
                    <div className="af-class-footer-contactdata-wrapper">
                      <Link
                        to="mailto:contacto@palcosplus.com?subject=%C2%A1Quiero%20contactarlos!"
                        className="af-class-footer-datalabel"
                      >
                        contacto@palcosplus.com
                      </Link>
                      <div className="af-class-footer-social-wrapper af-class-footer-datalabel">
                        <img
                          src={window.location.origin + '/images/whatsapp.png'}
                          loading="lazy"
                          alt=""
                          className="af-class-footer-socialicon pr-2"
                        />
                        <p
                          className="af-class-footer-sociallabel"
                          style={{ fontSize: '17px' }}
                        >
                          3326569988
                        </p>
                      </div>
                      <div className="af-class-footer-social-wrapper">
                        <p className="af-class-footer-sociallabel">
                          ¡Síguenos en redes sociales!
                        </p>
                        <a
                          href="https://www.facebook.com/palcosplus"
                          rel="noreferrer"
                          target="_blank"
                          className="af-class-footer-sociallink w-inline-block"
                        >
                          <img
                            src={
                              window.location.origin +
                              '/images/Facebook-sportsBlue.svg'
                            }
                            loading="lazy"
                            alt=""
                            className="af-class-footer-socialicon"
                          />
                        </a>
                        <a
                          href="https://instagram.com/palcosplus?utm_medium=copy_link"
                          rel="noreferrer"
                          target="_blank"
                          className="af-class-footer-sociallink w-inline-block"
                        >
                          <img
                            src={
                              window.location.origin +
                              '/images/Instagram-sportsBlue.svg'
                            }
                            loading="lazy"
                            alt=""
                            className="af-class-footer-socialicon"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="af-class-footer-divider" />
                <div className="af-class-footer-bottom-wrapper">
                  <p className="af-class-footer-smalllabel">
                    © 2021 PalcosPlus.com S.A de C.V. ··· Todos los derechos
                    reservados.
                  </p>
                  <a
                    data-w-id="d1c8f252-9c3a-f42a-7d2b-56f676701560"
                    href="https://www.facebook.com/wow.cstudio"
                    rel="noreferrer"
                    target="_blank"
                    className="af-class-footer-wow-wrapper w-inline-block"
                  >
                    <p className="af-class-footer-smalllabel">
                      Experiencias asombrosas por:
                    </p>
                    <Lottie
                      className="af-class-footer-wowlogo"
                      animationData={LottieAnimation}
                    />
                    {/* <div
                      data-w-id="d1c8f252-9c3a-f42a-7d2b-56f676701563"
                      data-src={
                        window.location.origin +
                        "/documents/wow-logo-lottie.json"
                      }
                      data-renderer="svg"
                      className="af-class-footer-wowlogo"
                    /> */}
                  </a>
                </div>
              </div>
            </div>
            {/* [if lte IE 9]>
                        <![endif] */}
          </div>
        </span>
      </span>
    );
  }
}

export default Footer;

/* eslint-enable */
