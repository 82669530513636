import React from "react";
import Navbar from "./Navbar";
import Sidebar from "../Sidebar";
import NavbarView from "views/NavbarView";
import Footer from "views/Footer";

const Layout = ({ children = null }) => {
  return (
        <div className="z-0">
          {children}
        </div>
  );
};

export default Layout;
