import React from 'react';
import PropTypes from 'prop-types';


const Button = props => {
  return (
    <div className={"flex relative" + props?.classHead}>
      <button
        {...props}
        className={`font-bold rounded-lg shadow-sm focus:outline-none focus:ring-2 ${props.className} ${props.disable && "cursor-not-allowed"}`}
        disabled={props.disable && true}
      >
        {props.children}
      </button>
      {props.icon && (
        <span
          className={`absolute inset-y-0  flex items-center pl-2 focus p-2 rounded-md ${props.iconclass
            } ${props.dir === "left" ? " left-0 " : "right-0 "}`}
        >
          <button className="p-1 focus:outline-none ">
            {props?.icon}
          </button>
        </span>
      )}
    </div>
  )
}
export default Button;