import { DialogOverlay, DialogContent } from '@reach/dialog';
import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import '@reach/dialog/styles.css';
import '../App.css'

const ModalEventFilters = ({ isOpen, onDimiss, stadium, sendFilters }) => {

    const [accessRange, setAccessRange] = useState([0, 8])
    const [priceRange, setPriceRange] = useState([0, 2000])
    const [dimensions, setDimensions] = useState(window.innerWidth)
    const [widthstyle, setWidthstyle] = useState()
    const { handleSubmit, register, control, formState, errors, watch } = useForm(
        {
            mode: 'all',
        },
    );

    const onSubmit = (data, e) => {
        e.preventDefault();
        console.log({
            zone: data.zone,
            type: data.type,
            access: accessRange,
            price: priceRange,
        })
        sendFilters({
            zone: data.zone,
            type: data.type,
            access: accessRange,
            price: priceRange,
        })
    }

    const zones = stadium.zones.split(",")

    useEffect(() => {
        function handleResize() {
            setDimensions(window.innerWidth) 
        }  
    
        window.addEventListener('resize', handleResize)
    
        return _ => {
          window.removeEventListener('resize', handleResize)
        }
    })
    
    useEffect(()=>{
        if(dimensions>768){
            setWidthstyle("60%")
        }else if(dimensions>591){
            setWidthstyle("70%")
        }else{
            setWidthstyle("90%")
        }
    },[dimensions])

    return (

        <DialogOverlay
            aria-label="modal"
            aria-labelledby="dialog-title"
            isOpen={isOpen}
            onDismiss={onDimiss}
            className="z-40 border"
        >
            <DialogContent
                className="container rounded-lg md:w-8"
                aria-labelledby="dialog-content"
                style={{width: widthstyle}}
            >
                <span className="af-view">
                    <div>
                        <div className="af-class-popup-wrapper">
                            <div className="af-class-popup-container">
                                <div className="af-class-form-block w-form">
                                    <form id="wf-form-prop-filters" name="wf-form-prop-filters" data-name="prop-filters" className="af-class-form" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="af-class-form-section">
                                            <div className="af-class-form-sectiontitle-wrapper">
                                                <img src={window.location.origin + "/images/icon-filters_blue.svg"} loading="lazy" alt="" className="af-class-form-sectiontitle-icon" />
                                                <h2 className="af-class-form-sectiontitle">Encuentra sólo lo que necesitas</h2>
                                            </div>
                                            <div className="af-class-form-content-wrapper">
                                                <div id="w-node-e5f4630e-5b93-1227-d587-0a169c2e5f80-9e0c980a" className="af-class-form-label-wrapper af-class-form-widelabel-wrapper">
                                                    <label htmlFor="email-2" className="af-class-form-label">Filtros generales</label>
                                                    <p className="af-class-form-labelnote af-class-form-labelnote-wide">Modifica los parámetros para personalizar tu búsqueda.</p>
                                                </div>
                                                <div className="af-class-form-field-wrapper">
                                                    <select ref={register} id="zone" name="zone" data-name="zone" className="af-class-form-drop w-select">
                                                        <option value="">Seleccionar zona</option>
                                                        {zones.map(zone => {
                                                            const value = zone.split('-')
                                                            return <option value={value[0]}>Palcos {value[0]}</option>
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="af-class-form-field-wrapper">
                                                    <select ref={register} id="type" name="type" data-name="type" className="af-class-form-drop w-select">
                                                        <option value="">Seleccionar tipo</option>
                                                        <option value="palco">Palcos</option>
                                                        <option value="platea">Plateas</option>
                                                        <option value="butaca">Butacas</option>
                                                    </select>
                                                </div>
                                                <div className="af-class-form-field-wrapper">
                                                    <div className="af-class-form-label-wrapper">
                                                        <label htmlFor="email" className="af-class-form-label">Número de accesos</label>
                                                        <div className="af-class-form-labelnote af-class-form-labelnote-value">{accessRange[0]} – {accessRange[1]}</div>
                                                    </div>
                                                    <Slider
                                                        value={accessRange}
                                                        min={0}
                                                        max={8}
                                                        marks={[{ value: 0, label: "0" }, { value: 8, label: "8+" }]}
                                                        onChange={(event, newValue) => setAccessRange(newValue)}
                                                        valueLabelDisplay="auto"
                                                        aria-labelledby="range-slider"
                                                        style={{ color: "#4c8080" }}
                                                    />
                                                </div>
                                                <div className="af-class-form-field-wrapper">
                                                    <div className="af-class-form-label-wrapper">
                                                        <label htmlFor="email-2" className="af-class-form-label">Precio</label>
                                                        <div className="af-class-form-labelnote af-class-form-labelnote-value">${priceRange[0]} – ${priceRange[1]}</div>
                                                    </div>
                                                    <Slider
                                                        value={priceRange}
                                                        min={0}
                                                        max={200000}
                                                        step={1000}
                                                        marks={[{ value: 0, label: "$0" }, { value: 200000, label: "$200,000" }]}
                                                        onChange={(event, newValue) => setPriceRange(newValue)}
                                                        valueLabelDisplay="auto"
                                                        aria-labelledby="range-slider"
                                                        style={{ color: "#4c8080" }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="af-class-form-section af-class-form-lastsection">
                                            <div className="af-class-form-content-wrapper">
                                                <p className="af-class-form-note af-class-form-finalnote">* Si la búsqueda no arroja resultados, prueba ampliar tus criterios de búsqueda.</p>
                                                <input type="submit" defaultValue="Aplicar" data-wait="....." className="af-class-gen-button af-class-form-sendbutton w-button" />
                                            </div>
                                        </div>
                                    </form>
                                    <div className="af-class-form-success-wrapper w-form-done">
                                        <img src={window.location.origin + "/images/title-confirmRent_blue.svg"} loading="lazy" alt="" className="af-class-form-success-icon" />
                                        <div>
                                            <span className="af-class-form-success-boldtext">¡Filtros aplicados!<br />
                                            </span>Revisa los resultados de búsqueda para visualizar los lugares que cumplen con tus criterios.</div>
                                    </div>
                                    <div className="af-class-form-error-wrapper w-form-fail">
                                        <div>Al parecer hay un error con los filtros de búsqueda, por favor inténtalo de nuevo. Si los problemas persisten por favor contacta a soporte.</div>
                                    </div>
                                </div>
                                <div className="af-class-popup-close-wrapper" onClick={onDimiss}>
                                    <img src={window.location.origin + "/images/close-sp-gray.svg"} loading="lazy" alt="" className="af-class-popup-closeicon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </span>
            </DialogContent>
        </DialogOverlay>
    )
}

export default ModalEventFilters