import React, { forwardRef, useState, useRef, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useClickAway } from 'react-use';
import Button from 'components/Button';
import axios from 'axios';
import configData from 'config.json';

const CreateManual = forwardRef((props, ref) => {
  const [confirmationMessage, setConfirmationMessage] = useState(false);
  const refConfirmation = useRef(null);
  const history = useHistory();

  const { control, handleSubmit, register, formState, errors } = useForm();
  useClickAway(refConfirmation, () => {
    setConfirmationMessage(false);
  });

  const [selectedFile, setSelectedFile] = useState(null);

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const onsubmit = (data, e) => {
    e.preventDefault();
    sendDataRequest();
  };

  const sendDataRequest = async () => {
    try {
      const formData = new FormData();
      formData.append('name', selectedFile.name);
      formData.append('image', selectedFile);
      const res = await axios.post(`${configData.SERVER_URL}/manual`, formData);
      // console.log(res.data.manual);
      history.push('/managerManual');
      history.go(0);
    } catch (err) {
      console.log(err);
    }
  };

  // File content to be displayed after
  // file upload is complete
  const fileData = () => {
    if (selectedFile) {
      return (
        <div>
          <h2>File Details:</h2>

          <p>File Name: {selectedFile.name}</p>

          <p>File Type: {selectedFile.type}</p>
        </div>
      );
    } else {
      return (
        <div>
          <br />
          <h4>Choose before Pressing the Upload button</h4>
        </div>
      );
    }
  };

  return (
    <form className="w-full pt-28" onSubmit={handleSubmit(onsubmit)}>
      <div className="mx-auto pb-24" style={{ maxWidth: '1080px' }}>
        <div className="flex flex-col-reverse md:flex-row justify-between pb-10">
          <div className="flex flex-col">
            <div id="tracking" className="pb-4">
              <h3 className="text-gris-oscuro">
                Panel de Administrador / Manual
              </h3>
            </div>
            <div id="title" className="pb-2">
              <h1 className="text-sport-blue font-bold text-6xl">Manual</h1>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="pb-14 md:pb-0">
              <Link to="/panel">
                <Button className="w-40 h-14 bg-green-field hover:bg-green-highlight focus:ring-green-highlight ">
                  PANEL ADMIN.
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div>
          <div className="Overflow-x-auto">
            <div>
              <h3 className="text-deep-blue">Seleccionar Manual</h3>
              <div>
                <input type="file" onChange={onFileChange} />
              </div>
              {fileData()}
            </div>
          </div>
          <div className="flex flex-row">
            <div className="pr-5">
              <Button
                type="submit"
                className="w-44 h-14 font-bold text-xl text-ocean-black bg-green-field rounded-lg shadow-sm hover:bg-green-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight "
                disable={selectedFile == null ? true : false}
              >
                GUARDAR
              </Button>
            </div>
            <div className="pr-5">
              <Button
                onClick={() => history.goBack()}
                className="w-44 h-14 font-bold text-xl text-white bg-aqua-grass rounded-lg shadow-sm hover:bg-aqua-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight "
                type="button"
              >
                CANCELAR
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
});

export default CreateManual;
