/* eslint-disable */

import React from 'react';
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, Link, useParams } from 'react-router-dom';
import { AuthContext } from './auth-context';
import configData from 'config.json';
import Loader from 'components/Loader';

const month = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sep',
  'Oct',
  'Nov',
  'Dic',
];

const ConfirmacionView = () => {
  const [loader, setLoader] = useState(true);
  const params = useParams();
  const [data, setData] = useState({
    rent: '',
    palco: '',
    event: '',
    teams: [],
    stadium: '',
    competition: '',
  });

  const getData = async () => {
    try {
      const rent = await axios
        .get(`${configData.SERVER_URL}/rents/${params.id}`)
        .then((res) => {
          return res.data.rent;
        });
      const event = await axios
        .get(`${configData.SERVER_URL}/events/${rent.event_id}`)
        .then((res) => {
          return res.data.event;
        });
      const palco = await axios
        .get(`${configData.SERVER_URL}/palcos/${rent.palco_id}`)
        .then((res) => {
          return res.data.palco;
        });
      const stadium = await axios
        .get(`${configData.SERVER_URL}/stadiums/${event.stadium_id}`)
        .then((res) => {
          return res.data.stadium;
        });
      const teams = await axios
        .get(`${configData.SERVER_URL}/teams`)
        .then((res) => {
          return res.data.teams;
        });
      var competition = '';
      if (event && event.type != 'concierto') {
        competition = await axios
          .get(`${configData.SERVER_URL}/competition/${event.competition_id}`)
          .then((res) => {
            return res.data.competition;
          });
      }
      setData({
        rent,
        palco,
        event,
        teams,
        stadium,
        competition,
      });
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getTeamById = (id) => {
    if (data.teams) {
      return data.teams.find((team) => team.id === id);
    }
  };

  if (!loader) {
    const date = data.event.date.split('-');
    return (
      <span>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/front-end-palcosplus.webflow.css);


            html {
              -webkit-tap-highlight-color: rgba(0,0,0,0);
            }
            textarea {
              resize: vertical;
            }
            .w-lightbox-backdrop {
              background: rgba(0,1,13,.85);
            }
        `,
          }}
        />
        <span className="af-view">
          <div>
            <div className="af-class-pay-section af-class-wf-section">
              <div className="af-class-gen-container">
                <div
                  id="columns-wrapper"
                  className="af-class-pay-columns-wrapper"
                >
                  <div className="af-class-pay-left-column">
                    <div className="af-class-pay-pagetitle-wrapper">
                      <img
                        src={
                          window.location.origin +
                          '/images/title-confirmRent_blue.svg'
                        }
                        loading="lazy"
                        alt
                        className="af-class-pay-pagetitle-icon"
                      />
                      <h1 className="af-class-pay-pagetitle">Confirmación</h1>
                    </div>
                    <div className="af-class-form-section">
                      <div className="af-class-form-content-wrapper">
                        <div className="af-class-form-field-wrapper">
                          <div className="af-class-form-label">Nombre(s)</div>
                          <div className="af-class-form-label af-class-conf-field-data">
                            {data.rent.name}
                          </div>
                        </div>
                        <div className="af-class-form-field-wrapper">
                          <div className="af-class-form-label">Apellidos</div>
                          <div className="af-class-form-label af-class-conf-field-data">
                            {data.rent.last_name}
                          </div>
                        </div>
                        <div className="af-class-form-field-wrapper">
                          <div className="af-class-form-label">
                            Correo electrónico
                          </div>
                          <div className="af-class-form-label af-class-conf-field-data">
                            {data.rent.email}
                          </div>
                        </div>
                        <div className="af-class-form-field-wrapper">
                          <div className="af-class-form-label">Teléfono</div>
                          <div className="af-class-form-label af-class-conf-field-data">
                            {data.rent.tel}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="af-class-form-section">
                      <div className="af-class-form-sectiontitle-wrapper">
                        <img
                          src={
                            window.location.origin +
                            '/images/icon-location_blue.svg'
                          }
                          loading="lazy"
                          alt
                          className="af-class-form-sectiontitle-icon"
                        />
                        <h2 className="af-class-form-sectiontitle">
                          Dirección de entrega
                        </h2>
                      </div>
                      <p className="af-class-form-note">
                        Aquí te entregaremos las entradas (tarjetas) de tus
                        palcos, plateas o butacas rentadas. Para conocer cómo
                        funciona el proceso de entrega de tarjetas, puedes
                        consultas las{' '}
                        <a href="#" className="af-class-form-note-link">
                          Preguntas más Frecuentes
                        </a>
                        .
                      </p>
                      {data.rent.alias === 'new' ||
                        (data.rent.alias === 'alias' && (
                          <div className="af-class-form-content-wrapper">
                            <div className="af-class-form-field-wrapper">
                              <div className="af-class-form-label">
                                Dirección de entrega
                              </div>
                              <div className="af-class-form-label af-class-conf-field-data">
                                Dirección:{' '}
                                {data.rent.alias === 'new'
                                  ? 'Nueva direccion'
                                  : 'Esta es tu direccion favorita'}
                              </div>
                            </div>
                            <div className="af-class-form-field-wrapper">
                              <div className="af-class-form-label">País</div>
                              <div className="af-class-form-label af-class-conf-field-data">
                                {data.rent.country}
                              </div>
                            </div>
                            <div className="af-class-form-field-wrapper">
                              <div className="af-class-form-label">Estado</div>
                              <div className="af-class-form-label af-class-conf-field-data">
                                {data.rent.state}
                              </div>
                            </div>
                            <div className="af-class-form-field-wrapper">
                              <div className="af-class-form-label">Ciudad</div>
                              <div className="af-class-form-label af-class-conf-field-data">
                                {data.rent.city}
                              </div>
                            </div>
                            <div className="af-class-form-field-wrapper">
                              <div className="af-class-form-label">Colonia</div>
                              <div className="af-class-form-label af-class-conf-field-data">
                                {data.rent.suburb}
                              </div>
                            </div>
                            <div className="af-class-form-field-wrapper">
                              <div className="af-class-form-label">
                                Código Postal
                              </div>
                              <div className="af-class-form-label af-class-conf-field-data">
                                {data.rent.postal_code}
                              </div>
                            </div>
                            <div className="af-class-form-field-wrapper">
                              <div className="af-class-form-label">
                                Dirección
                              </div>
                              <div className="af-class-form-label af-class-conf-field-data">
                                {data.rent.address}
                              </div>
                            </div>
                            <div className="af-class-form-field-wrapper">
                              <div className="af-class-form-label">
                                Número interior
                              </div>
                              <div className="af-class-form-label af-class-conf-field-data">
                                {data.rent.interior_num &&
                                  data.rent.interior_num}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                    {/* <div className="af-class-form-section">
                      <div className="af-class-form-sectiontitle-wrapper">
                      <img src="images/icon-payment_blue.svg" loading="lazy" alt className="af-class-form-sectiontitle-icon" />
                        <h2 className="af-class-form-sectiontitle">Información de pago</h2>
                      </div>
                      <div className="af-class-form-content-wrapper">
                        <div className="af-class-form-field-wrapper">
                          <div className="af-class-form-label">Número de tarjeta</div>
                          <div className="af-class-form-label af-class-conf-field-data">XXXX XXXX XXXX 0000</div>
                        </div>
                        <div className="af-class-form-field-wrapper">
                          <div className="af-class-form-label">Fecha de vencimiento</div>
                          <div className="af-class-form-label af-class-conf-field-data">00/00</div>
                        </div>
                      </div>
                    </div> */}
                    <div className="af-class-form-section af-class-form-lastsection">
                      <div className="af-class-form-sectiontitle-wrapper">
                        <img
                          src={
                            window.location.origin +
                            '/images/icon-rents_Summary_blue.svg'
                          }
                          loading="lazy"
                          alt
                          className="af-class-form-sectiontitle-icon"
                        />
                        <h2 className="af-class-form-sectiontitle">
                          Resumen de renta
                        </h2>
                      </div>
                      {data.event.type === 'partido' ? (
                        <div className="af-class-conf-rent-wrapper">
                          <div className="af-class-conf-rent-event-wrapper">
                            <div className="af-class-cart-rent-eventname-wrapper">
                              <div>{getTeamById(data.event.home).name}</div>
                              <div className="af-class-cart-rent-vs">Vs</div>
                              <div>{getTeamById(data.event.visitor).name}</div>
                            </div>
                            <div className="af-class-cart-rent-stadium">
                              Estadio {data.stadium.name}
                            </div>
                            <div className="af-class-cart-rent-roundtour-wrapper">
                              <div>Jornada {data.event.jornada}</div>
                              <div className="af-class-cart-rent-competition">
                                {data.competition.name}
                              </div>
                            </div>
                            <div className="af-class-cat-eventcard-datetime-wrapper">
                              <div className="af-class-cat-eventcard-data af-class-eventcard-databold">
                                {date[0]}
                              </div>
                              <div className="af-class-cat-eventcard-data af-class-eventcard-datamedium">
                                {date[1] === '0'
                                  ? month[date[1].substr(-1)]
                                  : month[date[1] - 1]}
                              </div>
                              <div className="af-class-cat-eventcard-data">
                                {date[2]}
                              </div>
                              <div className="af-class-cat-eventcard-data">
                                ···
                              </div>
                              <div className="af-class-cat-eventcard-data af-class-eventcard-databold">
                                {data.event.time}
                              </div>
                              <div className="af-class-cat-eventcard-data">
                                Hrs
                              </div>
                            </div>
                          </div>
                          <div className="af-class-conf-rent-prop-wrapper">
                            <div className="af-class-cart-rent-propname">
                              {data.palco.name}
                            </div>
                            <div className="af-class-cart-rent-propdata-wrapper">
                              <div className="af-class-cart-rent-accessnum">
                                {data.palco.num_cards} tarjetas
                              </div>
                              <div className="af-class-cart-rent-price-wrapper">
                                <div className="af-class-cart-rent-price">
                                  ${data.rent.total}
                                </div>
                                <div className="af-class-cart-rent-curr">
                                  Mxn
                                </div>
                              </div>
                            </div>
                            {/* <Link
                              to={`/propiedad/${data.palco.id}/${data.event.id}`}
                              className="af-class-cart-auxbutton af-class-conf-details-button w-button"
                            >
                              Detalles
                            </Link> */}
                          </div>
                        </div>
                      ) : (
                        <div className="af-class-conf-rent-wrapper">
                          <div className="af-class-conf-rent-event-wrapper">
                            <div className="af-class-cart-rent-eventname-wrapper">
                              <div>{data.event.name}</div>
                            </div>
                            <div className="af-class-cart-rent-stadium">
                              {data.stadium.name}
                            </div>
                            <div className="af-class-cart-rent-roundtour-wrapper">
                              <div>{data.event.tour_name}</div>
                            </div>
                            <div className="af-class-cat-eventcard-datetime-wrapper">
                              <div className="af-class-cat-eventcard-data af-class-eventcard-databold">
                                {date[0]}
                              </div>
                              <div className="af-class-cat-eventcard-data af-class-eventcard-datamedium">
                                {month[date[1].substr(-1)]}
                              </div>
                              <div className="af-class-cat-eventcard-data">
                                {date[2]}
                              </div>
                              <div className="af-class-cat-eventcard-data">
                                ···
                              </div>
                              <div className="af-class-cat-eventcard-data af-class-eventcard-databold">
                                {data.event.time}
                              </div>
                              <div className="af-class-cat-eventcard-data">
                                Hrs
                              </div>
                            </div>
                          </div>
                          <div className="af-class-conf-rent-prop-wrapper">
                            <div className="af-class-cart-rent-propname">
                              {data.palco.name}
                            </div>
                            <div className="af-class-cart-rent-propdata-wrapper">
                              <div className="af-class-cart-rent-accessnum">
                                {data.palco.num_cards} tarjetas
                              </div>
                              <div className="af-class-cart-rent-price-wrapper">
                                <div className="af-class-cart-rent-price">
                                  ${data.palco.price}
                                </div>
                                <div className="af-class-cart-rent-curr">
                                  Mxn
                                </div>
                              </div>
                            </div>
                            <Link
                              to={`/propiedad/${data.palco.id}/${data.event.id}`}
                              className="af-class-cart-auxbutton af-class-conf-details-button w-button"
                            >
                              Detalles
                            </Link>
                          </div>
                        </div>
                      )}
                      <div className="af-class-cat-contentdivider af-class-conf-summ-divider" />
                      <div className="af-class-conf-summ-wrapper">
                        <div className="af-class-chk-summ-subtotals-wrapper">
                          <div className="af-class-chk-summ-line">
                            <div>Subtotal</div>
                            <div className="af-class-chk-summ-line-value af-class-chk-value-money">
                              ${data.rent.subtotal} Mxn
                            </div>
                          </div>
                          {/* <div className="af-class-chk-summ-line">
                            <div>IVA</div>
                            <div className="af-class-chk-summ-line-value af-class-chk-value-money">
                              $0 Mxn
                            </div>
                          </div> */}
                          <div className="af-class-chk-summ-line af-class-chk-summ-ship-line">
                            <div>Envío</div>
                            <div className="af-class-chk-summ-line-value af-class-chk-value-money">
                              {/* ${data.rent?.shipping ? data.rent?.shipping : 0}{' '} */}
                              $0 Mxn
                            </div>
                          </div>
                          {/* <div className="af-class-chk-summ-line af-class-chk-summ-coupon-line">
                            <div>Cupón</div>
                            <div className="af-class-chk-summ-line-value af-class-chk-value-money">GOL10</div>
                          </div>
                          <div className="af-class-chk-summ-line af-class-chk-summ-discount-line">
                            <div>Descuento</div>
                            <div className="af-class-chk-summ-line-value af-class-chk-value-money">-$1,580 Mxn</div>
                          </div> */}
                        </div>
                        <div
                          id="w-node-aa930d0f-45fd-8286-a38d-c190b2e2b8d5-f531e2f8"
                          className="af-class-conf-summ-final-price"
                        >
                          <div className="af-class-cart-subtotal-label">
                            total
                          </div>
                          <div className="af-class-cart-subtotal-sum-wrapper">
                            <div className="af-class-cart-subtotal-value">
                              ${data.rent.total}
                            </div>
                            <div className="af-class-cart-subtotal-curr">
                              Mxn
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {data.rent.alias === 'new' || data.rent.alias === 'alias' ? (
                    <div id="r-column" className="af-class-conf-right-column">
                      <div className="af-class-conf-ship-title-wrapper">
                        <img
                          src={
                            window.location.origin +
                            '/images/bigIcon_home_ship_aqua.svg'
                          }
                          loading="lazy"
                          alt
                          className="af-class-conf-ship-graphic"
                        />
                        <h2 className="af-class-form-sectiontitle af-class-form-sectiontitle-white">
                          Recibe tus entradas en la puerta de tu domicilio
                        </h2>
                      </div>
                      <p className="af-class-conf-ship-text">
                        Cuando vayamos a realizar la entrega te avisaremos por
                        correo electrónico. Si no recibes el correo, revisa tu
                        bandeja de spam.
                      </p>
                      <div className="af-class-conf-after-event-wrapper">
                        <div className="af-class-conf-after-content-wrapper">
                          <div className="af-class-gen-paragraph af-class-prop-ship-text af-class-prop-ship-subtitle">
                            Después del evento
                          </div>
                          <p className="af-class-conf-ship-text">
                            Sólo tendrás que entregar las entradas (tarjetas) al
                            personal del staff, en la siguiente ubicación.
                          </p>
                        </div>
                        <div className="af-class-conf-after-content-wrapper">
                          <div className="af-class-gen-paragraph af-class-prop-ship-text af-class-prop-ship-subtitle">
                            Zona de entrega
                          </div>
                          <div className="af-class-conf-ship-text af-class-conf-ship-subtitle">
                            Estadio {data.stadium.name}
                          </div>
                          <p className="af-class-conf-ship-text">
                            {data.stadium.delivery_zone}
                          </p>
                        </div>
                      </div>
                      <div className="af-class-conf-ship-text">
                        ¿Tienes dudas sobre el proceso de entrega de tarjetas?{' '}
                        <Link to="/faqs" className="af-class-gen-textlink">
                          Consulta las FAQs
                        </Link>{' '}
                        o{' '}
                        <Link to="/contacto" className="af-class-gen-textlink">
                          envíanos un mensaje
                        </Link>
                        .
                      </div>
                    </div>
                  ) : data.rent.alias === 'office' ? (
                    <div className="af-class-conf-right-column">
                      <div className="af-class-conf-ship-title-wrapper">
                        <img
                          src={
                            window.location.origin +
                            '/images/bigIcon_office_pickup_aqua.svg'
                          }
                          loading="lazy"
                          alt
                          className="af-class-conf-ship-graphic"
                        />
                        <h2 className="af-class-form-sectiontitle af-class-form-sectiontitle-white">
                          Recibe tus entradas en nuestras oficinas
                        </h2>
                      </div>
                      <p className="af-class-conf-ship-text">
                        Podrás visitarnos desde las 8:00 a 18:00 hrs, de lunes a
                        viernes, en la siguiente dirección. Sólo necesitarás tu{' '}
                        <span className="af-class-gen-boldspan af-class-white-boldspan">
                          número de orden
                        </span>{' '}
                        y una{' '}
                        <span className="af-class-gen-boldspan af-class-white-boldspan">
                          identificación oficial
                        </span>
                        .
                      </p>
                      <div className="af-class-conf-office-address-wrapper">
                        <div className="af-class-conf-ship-text af-class-conf-ship-subtitle">
                          PalcosPlus Guadalajara
                        </div>
                        <p className="af-class-conf-ship-text">
                          Mariano Otero 1917 int. 3-D. Residencial victoria,
                          Zapopan. C.P. 45089.
                        </p>
                      </div>
                      <div className="af-class-conf-after-event-wrapper">
                        <div className="af-class-conf-after-content-wrapper">
                          <div className="af-class-gen-paragraph af-class-prop-ship-text af-class-prop-ship-subtitle">
                            Después del evento
                          </div>
                          <p className="af-class-conf-ship-text">
                            Sólo tendrás que entregar las entradas (tarjetas) al
                            personal del staff, en la siguiente ubicación.
                          </p>
                        </div>
                        <div className="af-class-conf-after-content-wrapper">
                          <div className="af-class-gen-paragraph af-class-prop-ship-text af-class-prop-ship-subtitle">
                            Zona de entrega
                          </div>
                          <div className="af-class-conf-ship-text af-class-conf-ship-subtitle">
                            Estadio {data.stadium.name}
                          </div>
                          <p className="af-class-conf-ship-text">
                            {data.stadium.delivery_zone}
                          </p>
                        </div>
                      </div>
                      <div className="af-class-conf-ship-text">
                        ¿Tienes dudas sobre el proceso de entrega de tarjetas?{' '}
                        <Link to="/faqs" className="af-class-gen-textlink">
                          Consulta las FAQs
                        </Link>{' '}
                        o{' '}
                        <Link to="/contacto" className="af-class-gen-textlink">
                          envíanos un mensaje
                        </Link>
                        .
                      </div>
                    </div>
                  ) : (
                    <div className="af-class-conf-right-column">
                      <div className="af-class-conf-ship-title-wrapper">
                        <img
                          src={
                            window.location.origin +
                            '/images/bigIcon_stadium_pickup_aqua.svg'
                          }
                          loading="lazy"
                          alt
                          className="af-class-conf-ship-graphic"
                        />
                        <h2 className="af-class-form-sectiontitle af-class-form-sectiontitle-white">
                          Recibe tus entradas directo en el estadio
                        </h2>
                      </div>
                      <p className="af-class-conf-ship-text">
                        Podrás recoger tus entradas (tarjetas), a partir de 2
                        horas antes del evento. Sólo necesitarás tu{' '}
                        <span className="af-class-gen-boldspan af-class-white-boldspan">
                          número de orden
                        </span>{' '}
                        y una{' '}
                        <span className="af-class-gen-boldspan af-class-white-boldspan">
                          identificación oficial
                        </span>
                        . Encontrarás al staff de PalcosPlus.com en la ubicación
                        inferior, donde podrás recoger tus tarjetas.
                      </p>
                      <div className="af-class-conf-after-event-wrapper">
                        <div className="af-class-conf-after-content-wrapper">
                          <div className="af-class-gen-paragraph af-class-prop-ship-text af-class-prop-ship-subtitle">
                            Después del evento
                          </div>
                          <p className="af-class-conf-ship-text">
                            Sólo tendrás que entregar las entradas (tarjetas) al
                            personal del staff, en la siguiente ubicación.
                          </p>
                        </div>
                        <div className="af-class-conf-after-content-wrapper">
                          <div className="af-class-gen-paragraph af-class-prop-ship-text af-class-prop-ship-subtitle">
                            Zona de entrega
                          </div>
                          <div className="af-class-conf-ship-text af-class-conf-ship-subtitle">
                            Estadio {data.stadium.name}
                          </div>
                          <p className="af-class-conf-ship-text">
                            {data.stadium.delivery_zone}
                          </p>
                        </div>
                      </div>
                      <div className="af-class-conf-ship-text">
                        ¿Tienes dudas sobre el proceso de entrega de tarjetas?{' '}
                        <Link to="/faqs" className="af-class-gen-textlink">
                          Consulta las FAQs
                        </Link>{' '}
                        o{' '}
                        <Link to="/contacto" className="af-class-gen-textlink">
                          envíanos un mensaje
                        </Link>
                        .
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </span>
      </span>
    );
  } else {
    return <Loader />;
  }
};

export default ConfirmacionView;

/* eslint-enable */
