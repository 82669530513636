import React from 'react';
import { useState, useEffect, useRef, useCallback } from 'react';
import { useClickAway } from 'react-use';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, Link, useParams } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import ModalEventFilters from './ModalEventFilters';
import configData from 'config.json';
import Loader from 'components/Loader';
import ImgModal from 'components/ImgModal';
import NumberFormat from 'react-number-format';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/swiper-bundle.min.css";
import "swiper/components/navigation/navigation.min.css";
import SwiperCore, { Navigation } from 'swiper';


const month = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sep',
  'Oct',
  'Nov',
  'Dic',
];
const amenityClasses = {
  'Refrigerador': 'fridge',
  'TV': 'tv',
  'Estacionamiento': 'parking',
  'Baño': 'wc',
  'Horno': 'oven',
  'Mesero': 'waiter',
  'Derecho a ingresar bebidas': 'drinks',
  'Derecho a ingresar bebidas y alimentos': 'food'
};

const getAmenityClass = (amenity) => {
  return amenityClasses[amenity] || '';
};

SwiperCore.use([Navigation]);

const PagEventoView = () => {
  const [data, setData] = useState({
    palcos: [],
    stadiums: [],
    events: [],
    teams: [],
    competitions: [],
    rents: [],
    relationPE: [],
  });
  const [filters, setFilters] = useState(null);
  const [notfilter, setNotfilter] = useState(false);
  const [palcosFiltered, setPalcosFiltered] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPalco, setLastPalco] = useState(0);
  const location = useLocation();
  const router = useHistory();
  const params = useParams();
  const myRef = useRef(null);
  const [loader, setLoader] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const { handleSubmit, register, control, formState, errors, watch } = useForm(
    {
      mode: 'all',
    }
  );
  const [showModal, setShowModal] = useState(false);
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setShowModal(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);
  const refShowModal = useRef(null);
  useClickAway(refShowModal, () => {
    setShowModal(false);
  });

  const onDimiss = () => {
    setOpenModal(false);
    // router.goBack();
  };

  const getStadiumByEvent = (stadiums, id) => {
    return stadiums.find((stadium) => stadium.id === id);
  };

  const getData = async () => {
    const events = await axios
      .get(`${configData.SERVER_URL}/events/${params.id}`)
      .then((res) => {
        return res.data.event;
      });
    const stadiums = await axios
      .get(`${configData.SERVER_URL}/stadiums`)
      .then((res) => {
        return res.data.stadium;
      });
    const stadium = await getStadiumByEvent(stadiums, events.stadium_id);
    const teams = await axios
      .get(`${configData.SERVER_URL}/teams`)
      .then((res) => {
        return res.data.teams;
      });
    
    
    const palcos = await axios
      .get(`${configData.SERVER_URL}/palcos/active/stadium/${stadium.id}`)
      .then(
        (res) => {
          return res.data.palcos;
        },
        (err) => {
          console.log(err);
          return [];
        }
      );
    const competitions = await axios
      .get(`${configData.SERVER_URL}/competition`)
      .then((res) => {
        return res.data.competition;
      });
    const rents = await axios
      .get(`${configData.SERVER_URL}/rents/active`)
      .then((res) => {
        return res.data.rents;
      });
    const relationPE = await axios
      .get(`${configData.SERVER_URL}/eventPalco/`)
      .then((res) => {
        return res.data.relation;
      })
      .catch((err) => {
        console.log(err);
        return [];
      });
    setData({
      palcos,
      stadiums,
      events,
      teams,
      competitions,
      rents,
      relationPE,
    });
    setLoader(false);
  };

  useEffect(() => {
    const test = async () => {
      await getData();
    };
    test();
  }, []);

  const orderRents = () => {
    function compare(a, b) {
      let comparison = 0;
      if (a.isImportant && !b.isImportant) {
        comparison = -1;
      } else if (b.isImportant && !a.isImportant) {
        comparison = 1;
      } else {
        comparison = 0;
      }
      return comparison;
    }
    data.palcos.sort(compare);
  };

  const sendFilters = (data) => {
    setFilters(data);
    onDimiss();
  };

  const FilterPalco = (palcos, filters) => {
    const filterZone = (palco, zone) =>
      zone === '' ? palcos : palco?.zone === zone;
    const filterType = (palco, type) =>
      type === '' ? palcos : palco?.type === type;
    const filterPrice = (palco, price) =>
      price[1] === 8
        ? palco
        : palco?.price >= price[0] && palco?.price <= price[1];
    const filterAccess = (palco, access) =>
      palco?.num_cards >= access[0] && palco?.num_cards <= access[1];

    const newPalcos = palcos?.filter(
      (palco) =>
        filterZone(palco, filters.zone) &&
        filterType(palco, filters.type) &&
        filterPrice(palco, filters.price) &&
        filterAccess(palco, filters.access)
    );
    if (newPalcos.length > 0) {
      setNotfilter(false);
      setPalcosFiltered(newPalcos);
    } else {
      setNotfilter(true);
    }
  };

  useEffect(() => {
    
    filters && FilterPalco(data.palcos, filters);
  }, [filters]);

  const getStadiumById = (id) => {
    if (data.stadiums) {
      return data.stadiums.find((stadium) => stadium.id === id);
    }
  };

  const getTeamById = (id) => {
    if (data.teams) {
      return data.teams.find((team) => team.id === id);
    }
  };

  const getCompetitionById = (id) => {
    if (data.competitions) {
      const result = data.competitions.find(
        (competition) => competition.id === id
      );
      return result;
    }
  };

  const getDate = (param) => {
    if (data.events.date) {
      const date = data.events.date.split('-');
      switch (param) {
        case 'day':
          return date[2];
        case 'month':
          return date[1] === '0'
            ? month[date[1].substr(-1)]
            : month[date[1] - 1];
        case 'year':
          return date[0];
        default:
          break;
      }
    }
  };

  const getCard = (palcos) => {
   
    orderRents();
    let card = [];
    const start = (currentPage - 1) * 10;
    const end = currentPage * 10;
    let j = 0;
    let i = 0;
    
    while (j < end && i < palcos.length) {
      const isActive = palcos[i].active;
      const rent = data.rents.findIndex(
        (r) => r.palco_id === palcos[i].id && r.event_id === params.id
      );
      const relation = data.relationPE.find(
        (re) => re.event_id === params.id && re.palco_id === palcos[i].id
      );
      const active =
        relation?.tarifa === 'No disponible' ? false : palcos[i].active;
      const price = relation?.price ? relation?.price : palcos[i].price;
      if (rent === -1 && active && isActive) {
        if (j >= start && j < end) {
          card.push(
            <div className="af-class-cat-right-column">
              <Link
                to={active ? `/propiedad/${palcos[i].id}/${params.id}` : '#'}
                className={
                  active
                    ? `af-class-cat-propcard w-inline-block`
                    : `af-class-cat-propcard w-inline-block cursor-not-allowed`
                }
              >
                <div
                  data-animation="slide"
                  data-nav-spacing={2}
                  data-duration={500}
                  data-infinite={1}
                  data-w-id="4a630bca-9c20-1824-95ef-926cc97efb80"
                  className="af-class-cat-propslider w-slider"
                >
                  <div className="w-slider-mask">
                    <Swiper className="swiper-card" navigation>
                      {palcos[i].images.slice(1).map((img) => {
                          return (
                           
                            <SwiperSlide>
                              <img
                                alt=""
                                src={`${img}`}
                                loading="lazy"
                                sizes="(max-width: 479px) 77vw, 300px"
                                srcSet={`${img} 500w, ${img}, ${img} 1200w`}
                                className="af-class-cat-propslider-img"
                              />
                            </SwiperSlide>
                           
                          );
                        })}
                      
                    </Swiper>

                    
                  </div>
                  <div
                    style={{ opacity: 0 }}
                    className="af-class-cat-propslider-arrow w-slider-arrow-left af-class-hidearrow"
                  >
                    <img
                      alt=""
                      src={
                        window.location.origin +
                        '/images/sliderArrowLeft-deepBlue.svg'
                      }
                      loading="lazy"
                      className="af-class-cat-propslider-arrowicon"
                    />
                  </div>
                  <div className="af-class-cat-propslider-arrow af-class-cat-propslider-rightarrow w-slider-arrow-right af-class-hidearrow">
                    <img
                      alt=""
                      src={
                        window.location.origin +
                        '/images/sliderArrowRight-deepBlue.svg'
                      }
                      loading="lazy"
                      className="af-class-cat-propslider-arrowicon"
                    />
                  </div>
                  <div className="af-class-cat-propslider-nav w-slider-nav w-shadow w-round" />
                </div>
                {!active && (
                  <div className="af-class-cat-propcard-rented">Rentado</div>
                )}
                <img
                  alt=""
                  src={
                    window.location.origin +
                    '/images/triangleGraphicPropSlider-white.svg'
                  }
                  loading="lazy"
                  className="af-class-cat-propslider-triangle"
                />
                <div className="af-class-cat-propcard-content">
                  <h3 className="af-class-cat-propcard-propname">
                    {palcos[i].name}
                  </h3>
                  <div className="af-class-cat-propcard-data-wrapper">
                    <div className="af-class-cat-propcard-dataline-wrapper">
                      <div
                        className="af-class-cat-propcard-zonecolor"
                        style={{
                          backgroundColor: getColorZone(palcos[i].zone),
                        }}
                      />
                      <div className="af-class-cat-propcard-data af-class-cat-propcard-datalabel af-class-cat-propcard-zonelabel">
                        Zona:
                      </div>
                      <div className="af-class-cat-propcard-data">
                        {palcos[i].zone}
                      </div>
                    </div>
                    <div className="af-class-cat-propcard-dataline-wrapper">
                      <div className="af-class-cat-propcard-data af-class-cat-propcard-datalabel">
                        Accesos:
                      </div>
                      <div className="af-class-cat-propcard-data">
                        {palcos[i].num_cards} tarjetas
                      </div>
                    </div>
                    <div>
                      <div className="af-class-cat-propcard-data af-class-cat-propcard-datalabel">
                        Amenidades:
                      </div>
                      <div className="ameneties-wrapper">
                        {palcos[i].amenities.map((amenity) => (
                          <div key={amenity} className={`amenetie ${getAmenityClass(amenity)}`}>
                            
                          </div>
                        ))}
                      </div>

                      
                    </div>
                  </div>
                  <div className="af-class-cat-propcard-price-wrapper">
                    <div className="af-class-cat-propcard-price">
                      <NumberFormat
                        name="price"
                        className="af-class-cat-propcard-curr"
                        thousandSeparator={true}
                        prefix="$"
                        suffix=" MXN"
                        decimalSeparator="."
                        allowNegative={false}
                        decimalScale={2}
                        fixedDecimalScale={2}
                        value={price}
                      />
                    </div>
                    {/* <div className="af-class-cat-propcard-curr">Mxn</div> */}
                  </div>
                </div>
              </Link>
              {data.events.length - 1 !== i && (
                <div className="af-class-cat-contentdivider" />
              )}
            </div>
          );
        }
        j++;
      }
      i++;
    }
    if (j < end) {
      i = 0;
      while (j < end && i < palcos.length) {
        const rent = data.rents.findIndex(
          (r) => r.palco_id === palcos[i].id && r.event_id === params.id
        );
        const relation = data.relationPE.find(
          (re) => re.event_id === params.id && re.palco_id === palcos[i].id
        );
        const active =
          relation?.tarifa === 'No disponible' ? false : palcos[i].active;
        const price = relation?.price ? relation?.price : palcos[i].price;
        if (rent !== -1 && active) {
          card.push(
            <div className="af-class-cat-right-column">
              <div
                className={`af-class-cat-propcard w-inline-block cursor-not-allowed`}
              >
                <div
                  data-animation="slide"
                  data-nav-spacing={2}
                  data-duration={500}
                  data-infinite={1}
                  data-w-id="4a630bca-9c20-1824-95ef-926cc97efb80"
                  className="af-class-cat-propslider w-slider"
                >
                  <div className="w-slider-mask">
                    {palcos[i].images.map((img) => {
                      return (
                        <div className="w-slide">
                          <img
                            alt=""
                            src={`${img}`}
                            loading="lazy"
                            sizes="(max-width: 479px) 77vw, 300px"
                            srcSet={`${img} 500w, ${img}, ${img} 1200w`}
                            className="af-class-cat-propslider-img"
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div
                    style={{ opacity: 0 }}
                    className="af-class-cat-propslider-arrow w-slider-arrow-left af-class-hidearrow"
                  >
                    <img
                      alt=""
                      src={
                        window.location.origin +
                        '/images/sliderArrowLeft-deepBlue.svg'
                      }
                      loading="lazy"
                      className="af-class-cat-propslider-arrowicon"
                    />
                  </div>
                  <div className="af-class-cat-propslider-arrow af-class-cat-propslider-rightarrow w-slider-arrow-right af-class-hidearrow">
                    <img
                      alt=""
                      src={
                        window.location.origin +
                        '/images/sliderArrowRight-deepBlue.svg'
                      }
                      loading="lazy"
                      className="af-class-cat-propslider-arrowicon"
                    />
                  </div>
                  <div className="af-class-cat-propslider-nav w-slider-nav w-shadow w-round" />
                </div>
                {<div className="af-class-cat-propcard-rented">Rentado</div>}
                <img
                  alt=""
                  src={
                    window.location.origin +
                    '/images/triangleGraphicPropSlider-white.svg'
                  }
                  loading="lazy"
                  className="af-class-cat-propslider-triangle"
                />
                <div className="af-class-cat-propcard-content">
                  <h3 className="af-class-cat-propcard-propname">
                    {palcos[i].name}
                  </h3>
                  <div className="af-class-cat-propcard-data-wrapper">
                    <div className="af-class-cat-propcard-dataline-wrapper">
                      <div
                        className="af-class-cat-propcard-zonecolor"
                        style={{
                          backgroundColor: getColorZone(palcos[i].zone),
                        }}
                      />
                      <div className="af-class-cat-propcard-data af-class-cat-propcard-datalabel af-class-cat-propcard-zonelabel">
                        Zona:
                      </div>
                      <div className="af-class-cat-propcard-data">
                        {palcos[i].zone}
                      </div>
                    </div>
                    <div className="af-class-cat-propcard-dataline-wrapper">
                      <div className="af-class-cat-propcard-data af-class-cat-propcard-datalabel">
                        Accesos:
                      </div>
                      <div className="af-class-cat-propcard-data">
                        {palcos[i].num_cards} tarjetas
                      </div>
                    </div>
                  </div>
                  <div className="af-class-cat-propcard-price-wrapper">
                    <div className="af-class-cat-propcard-price">
                      <NumberFormat
                        name="price"
                        className="af-class-cat-propcard-curr"
                        thousandSeparator={true}
                        prefix="$"
                        suffix=" MXN"
                        decimalSeparator="."
                        allowNegative={false}
                        decimalScale={2}
                        fixedDecimalScale={2}
                        value={price}
                      />
                    </div>
                    {/* <div className="af-class-cat-propcard-curr">Mxn</div> */}
                  </div>
                </div>
              </div>
              {data.events.length - 1 !== i && (
                <div className="af-class-cat-contentdivider" />
              )}
            </div>
          );
          j++;
        }
        i++;
      }
    }
    
    return card;
  };

  const changePag = (newPage) => {
    myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    setCurrentPage(newPage);
  };

  const getColorZone = (zone) => {
    let color = '#4c8080';
    const satdium_zones = getStadiumById(data.events.stadium_id).zones;
    const zones = satdium_zones.split(',');
    for (let i = 0; i < zones.length; i++) {
      const zone_color = zones[i].split('-');
      if (zone_color[0] === zone) {
        if (zone_color.length > 1) color = zone_color[1];
      }
    }
    return color;
  };

  if (!loader) {
    return (
      data.events.length !== 0 && (
        <span className="af-view">
          <div>
            {data.events.type === 'partido' ? (
              <div>
                <div className="af-class-bighero af-class-bighero-herobg">
                  <div className="af-class-catprop-heroimgs-layout">
                    <img
                      alt=""
                      src={`${getTeamById(data.events.home).images[1]}`}
                      loading="lazy"
                      sizes="(max-width: 479px) 48vw, (max-width: 991px) 49vw, 50vw"
                      srcSet={`${getTeamById(data.events?.home)?.images[1]
                        } 1080w, ${getTeamById(data.events?.home)?.images[1]
                        } 1201w`}
                      className="af-class-catprop-eventimg"
                    />
                    <img
                      alt=""
                      src={`${getTeamById(data.events.visitor).images[1]}`}
                      loading="lazy"
                      sizes="(max-width: 479px) 48vw, (max-width: 991px) 49vw, 50vw"
                      srcSet={`${getTeamById(data.events.visitor).images[1]
                        } 1080w, ${getTeamById(data.events.visitor).images[1]
                        } 1201w`}
                      className="af-class-catprop-eventimg"
                    />
                  </div>
                </div>
                <div className="af-class-bighero">
                  <HashLink
                    to="#catalogo"
                    className="af-class-catprop-hero-search w-inline-block"
                  >
                    <img
                      alt=""
                      src={
                        window.location.origin +
                        '/images/downSmallArrow-deepBlue.svg'
                      }
                      loading="lazy"
                      className="af-class-catprop-hero-searchicon"
                    />
                    <div>Buscar Lugares</div>
                  </HashLink>
                  <div className="af-class-catprop-hero-mainwrapper">
                    <div className="af-class-catprop-hero-date-std-gamewrapper">
                      <div className="af-class-catprop-hero-datetime-wrapper">
                        <div className="af-class-catprop-hero-data af-class-catprop-hero-databold">
                          {getDate('day')}
                        </div>
                        <div className="af-class-catprop-hero-data af-class-catprop-hero-datamedium">
                          {getDate('month')}
                        </div>
                        <div className="af-class-catprop-hero-data">
                          {getDate('year')}
                        </div>
                        <div className="af-class-catprop-hero-data">···</div>
                        <div className="af-class-catprop-hero-data af-class-catprop-hero-databold">
                          {data.events.time}
                        </div>
                        <div className="af-class-catprop-hero-data">Hrs</div>
                      </div>
                      <div className="af-class-catprop-hero-stadium-wrapper">
                        <div className="af-class-catprop-hero-data af-class-catprop-hero-stadium">
                          {getStadiumById(data.events.stadium_id).name}
                        </div>
                        <img
                          alt=""
                          src={
                            window.location.origin +
                            '/images/icon-stadium_deepBlue.svg'
                          }
                          loading="lazy"
                          className="af-class-catprop-hero-stadiumicon"
                        />
                      </div>
                    </div>
                    <div className="af-class-catprop-hero-teams-wrapper">
                      <div className="af-class-catprop-hero-team af-class-catprop-hero-hometeam">
                        <img
                          alt=""
                          src={`${getTeamById(data.events.home).images[0]}`}
                          loading="lazy"
                          sizes="(max-width: 767px) 124.828125px, (max-width: 991px) 142.65625px, 178.328125px"
                          srcSet={`${getTeamById(data.events.home).images[0]
                            } 500w, ${getTeamById(data.events.home).images[0]
                            } 535w`}
                          className="af-class-catprop-hero-teamemblem af-class-catprop-hero-teamemblem-home"
                        />
                        <h1 className="af-class-catprop-hero-teamname">
                          {getTeamById(data.events.home).name}
                        </h1>
                      </div>
                      <div className="af-class-catprop-hero-vs">Vs</div>
                      <div className="af-class-catprop-hero-team af-class-catprop-hero-awayteam">
                        <img
                          alt=""
                          src={`${getTeamById(data.events.visitor).images[0]}`}
                          loading="lazy"
                          className="af-class-catprop-hero-teamemblem af-class-catprop-hero-teamemblem-away"
                        />
                        <h1 className="af-class-catprop-hero-teamname">
                          {getTeamById(data.events.visitor).name}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="af-class-catprop-herograds-wrapper">
                    <div className="af-class-catprop-heroimgs-layout">
                      <div className="af-class-catprops-herograd af-class-catprops-herograd-hometeam">
                        <div className="af-class-catprop-hero-genevent-trimmer" />
                      </div>
                      <div className="af-class-catprops-herograd af-class-catprops-herograd-awayteam" />
                    </div>
                  </div>
                  <div className="af-class-bighero-topgrad" />
                  <div className="af-class-catprop-hero-league-wrapper">
                    <img
                      alt=""
                      src={`${getCompetitionById(data.events.competition_id).images[0]
                        }`}
                      loading="lazy"
                      sizes="(max-width: 479px) 100vw, (max-width: 767px) 159.953125px, (max-width: 991px) 160px, 200px"
                      srcSet={`${getCompetitionById(data.events.competition_id).images[0]
                        } 500w, ${getCompetitionById(data.events.competition_id).images[0]
                        } 800w, ${getCompetitionById(data.events.competition_id).images[0]
                        } 1080w, ${getCompetitionById(data.events.competition_id).images[0]
                        } 1600w, ${getCompetitionById(data.events.competition_id).images[0]
                        } 1920w"`}
                      className="af-class-catprop-hero-leagueimg"
                    />
                    <div className="af-class-catprop-hero-round-wrapper">
                      <div className="af-class-catprop-hero-data af-class-catprop-hero-rounddata af-class-catprop-hero-datamedium">
                        Jornada {data.events.jornada}
                      </div>
                      <div className="af-class-catprop-hero-data af-class-catprop-hero-rounddata">
                        {getCompetitionById(data.events.competition_id).name}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="af-class-bighero af-class-bighero-herobg">
                  <div className="af-class-catprop-heroimgs-layout af-class-catprop-heroimgs-layout-genevent">
                    <div
                      id="w-node-_7335bd7b-e8b9-817b-25ea-5b487d2d2ba4-63500ca7"
                      className="af-class-catprop-hero-geneventstd"
                      style={{
                        backgroundImage: `url(${getStadiumById(data.events.stadium_id).images[1]
                          })`,
                      }}
                    />
                    <img
                      alt=""
                      src={`${data.events.images[1]}`}
                      loading="lazy"
                      sizes="100vw"
                      srcSet={`${data.events.images[1]} 500w, ${data.events.images[1]} 800w, ${data.events.images[1]} 1280w`}
                      className="af-class-catprop-eventimg af-class-catprop-eventimg-genevent"
                    />
                  </div>
                </div>
                <div className="af-class-bighero">
                  <HashLink
                    to="#catalogo"
                    className="af-class-catprop-hero-search w-inline-block"
                  >
                    <img
                      alt=""
                      src={
                        window.location.origin +
                        '/images/downSmallArrow-deepBlue.svg'
                      }
                      loading="lazy"
                      className="af-class-catprop-hero-searchicon"
                    />
                    <div>Buscar Lugares</div>
                  </HashLink>
                  <div className="af-class-catprop-herograds-wrapper">
                    <div className="af-class-catprop-heroimgs-layout af-class-catprop-heroimgs-layout-genevent">
                      <div className="af-class-catprops-hero-genevent-left">
                        <div className="af-class-catprop-hero-genevent-trimmer" />
                      </div>
                      <div className="af-class-catprops-hero-genevent-main">
                        <img
                          alt=""
                          src={`${data.events.images[0]}`}
                          loading="lazy"
                          className="af-class-catprop-hero-eventemblem"
                        />
                        <div className="af-class-catprop-hero-date-std-genevent-wrapper">
                          <div className="af-class-catprop-hero-datetime-wrapper">
                            <div className="af-class-catprop-hero-data af-class-catprop-hero-databold">
                              {getDate('day')}
                            </div>
                            <div className="af-class-catprop-hero-data af-class-catprop-hero-datamedium">
                              {getDate('month')}
                            </div>
                            <div className="af-class-catprop-hero-data">
                              {getDate('year')}
                            </div>
                            <div className="af-class-catprop-hero-data">
                              ···
                            </div>
                            <div className="af-class-catprop-hero-data af-class-catprop-hero-databold">
                              {data.events.time}
                            </div>
                            <div className="af-class-catprop-hero-data">
                              Hrs
                            </div>
                          </div>
                          <div className="af-class-catprop-hero-stadium-wrapper">
                            <div className="af-class-catprop-hero-data af-class-catprop-hero-stadium">
                              {getStadiumById(data.events.stadium_id).name}
                            </div>
                            <img
                              alt=""
                              src={
                                window.location.origin +
                                '/images/icon-stadium_deepBlue.svg'
                              }
                              loading="lazy"
                              className="af-class-catprop-hero-stadiumicon"
                            />
                          </div>
                        </div>
                        <h1 className="af-class-catprop-hero-eventname">
                          {data.events.name}
                        </h1>
                        <div className="af-class-catprop-hero-tour">
                          {data.events.tour_name}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="af-class-bighero-topgrad" />
                </div>
              </div>
            )}
            <div
              id="catalogo"
              className="af-class-gen-section af-class-cat-section"
            >
              <div className="af-class-gen-container">
                <div className="af-class-gen-smalltitle-wrapper">
                  <img
                    alt=""
                    src={
                      window.location.origin + '/images/icon-smallSeat_blue.svg'
                    }
                    loading="lazy"
                    className="af-class-gen-smalltitle-graphic"
                  />
                  <h2 className="af-class-gen-smalltitle">
                    Encuentra el lugar perfecto para ti
                  </h2>
                  <img
                    alt=""
                    src={
                      window.location.origin + '/images/icon-smallSeat_blue.svg'
                    }
                    loading="lazy"
                    className="af-class-gen-smalltitle-graphic af-class-gen-smallgraphic-last"
                  />
                </div>
                <div className="af-class-cat-wrapper">
                  <div
                    data-w-id="3cc32892-4493-a8bd-f74a-1fda51d1f8ef"
                    className="af-class-cat-filterstab"
                  >
                    <div className="af-class-cat-filterstab-contentcolumn">
                      <div className="af-class-cat-filters-wrapper af-class-cat-left-gencontent">
                        <div className="af-class-cat-filters-scrollnote">
                          <img
                            alt=""
                            src={
                              window.location.origin +
                              '/images/downSmallArrow-auxiliaryGray.svg'
                            }
                            loading="lazy"
                            className="af-class-cat-filters-scrollnote-icon"
                          />
                          <div>Desliza para ver todo</div>
                        </div>
                        <div className="af-class-cat-stdname-label">
                          Mapa de zonas
                        </div>
                        <div className="af-class-cat-stdname">
                          {getStadiumById(data.events.stadium_id).name}
                        </div>
                        <img
                          alt=""
                          src={`${getStadiumById(data.events.stadium_id).images[
                            getStadiumById(data.events.stadium_id).images
                              .length - 1
                            ]
                            }`}
                          loading="lazy"
                          className="af-class-cat-stdmap cursor-pointer"
                          onClick={() => setShowModal(true)}
                        />
                        <div className="af-class-form-block w-form">
                          <form
                            id="wf-form-prop-filters"
                            name="wf-form-prop-filters"
                            data-name="prop-filters"
                            className="af-class-form"
                          >
                            <div className="af-class-form-section af-class-form-lastsection">
                              <div className="af-class-form-content-wrapper">
                                <div
                                  id="w-node-bc6b26ab-10b5-1058-b762-b433bdd5a698-63500ca7"
                                  className="af-class-form-label-wrapper af-class-form-widelabel-wrapper"
                                >
                                  <label
                                    htmlFor="email"
                                    className="af-class-form-label"
                                  >
                                    Filtros generales
                                  </label>
                                  <p className="af-class-form-labelnote af-class-form-labelnote-wide">
                                    Modifica los parámetros para personalizar tu
                                    búsqueda.
                                  </p>
                                </div>
                                <div className="af-class-cat-filtersdrop-wrapper af-class-cat-filtersdrop-props-wrapper">
                                  <select
                                    id="zone"
                                    name="zone"
                                    data-name="Zone"
                                    className="af-class-form-drop af-class-form-catfilters-drop w-select"
                                  >
                                    <option value>Seleccionar zona</option>
                                    <option value>Palcos Norte</option>
                                    <option value>Palcos Sur</option>
                                    <option value>Palcos Oriente</option>
                                    <option value>Palcos Poniente</option>
                                  </select>
                                  <select
                                    id="type"
                                    name="type"
                                    data-name="Type"
                                    className="af-class-form-drop af-class-form-catfilters-drop w-select"
                                  >
                                    <option value>Seleccionar tipo</option>
                                    <option value>Palcos</option>
                                    <option value>Plateas</option>
                                    <option value>Butacas</option>
                                  </select>
                                </div>
                                <div className="af-class-form-field-wrapper">
                                  <div className="af-class-form-label-wrapper">
                                    <label
                                      htmlFor="email"
                                      className="af-class-form-label"
                                    >
                                      Número de accesos
                                    </label>
                                    <div className="af-class-form-labelnote af-class-form-labelnote-value">
                                      3 – 7
                                    </div>
                                  </div>
                                  <input
                                    type="email"
                                    className="af-class-form-textinput af-class-form-catfilters-textinput w-input"
                                    maxLength={256}
                                    name="email"
                                    data-name="Email"
                                    placeholder="....."
                                    id="email"
                                    required
                                  />
                                </div>
                                <div className="af-class-form-field-wrapper">
                                  <div className="af-class-form-label-wrapper">
                                    <label
                                      htmlFor="email"
                                      className="af-class-form-label"
                                    >
                                      Precio
                                    </label>
                                    <div className="af-class-form-labelnote af-class-form-labelnote-value">
                                      $1,000 – $5,000
                                    </div>
                                  </div>
                                  <input
                                    type="tel"
                                    className="af-class-form-textinput af-class-form-catfilters-textinput w-input"
                                    maxLength={256}
                                    name="phone"
                                    data-name="Phone"
                                    placeholder="....."
                                    id="phone"
                                    required
                                  />
                                </div>
                                <p className="af-class-form-note af-class-form-finalnote">
                                  * Si la búsqueda no arroja resultados, prueba
                                  ampliar tus criterios de búsqueda.
                                </p>
                                <input
                                  type="submit"
                                  defaultValue="Aplicar"
                                  data-wait="....."
                                  className="af-class-gen-button af-class-form-sendbutton w-button"
                                />
                              </div>
                            </div>
                          </form>
                          {/* <div className="af-class-form-success-wrapper w-form-done">
                            <img alt="" src={window.location.origin + "/images/title-confirmRent_blue.svg"} loading="lazy" className="af-class-form-success-icon" />
                            <div>
                              <span className="af-class-form-success-boldtext">¡Filtros aplicados!<br />
                              </span>Revisa los resultados de búsqueda para visualizar los lugares que cumplen con tus criterios.</div>
                          </div> */}
                          {notfilter && (
                            <div className="af-class-form-error-wrapper w-form-fail">
                              <div>
                                Al parecer hay un error con los filtros de
                                búsqueda, por favor inténtalo de nuevo. Si los
                                problemas persisten por favor contacta a
                                soporte.
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="af-class-cat-filters-scrollfill">
                          <img
                            alt=""
                            src={
                              window.location.origin +
                              '/images/bigIcon-PP_steps_s2_deepBlue.svg'
                            }
                            loading="lazy"
                            className="af-class-cat-filters-scrollfill-img"
                          />
                          <img
                            alt=""
                            src={
                              window.location.origin +
                              '/images/bigIcon-PP_steps_s2_deepBlue.svg'
                            }
                            loading="lazy"
                            className="af-class-cat-filters-scrollfill-img"
                          />
                        </div>
                      </div>
                      <div className="af-class-cat-filters-scrollnote af-class-cat-filters-scrollnote-final">
                        <img
                          alt=""
                          src={
                            window.location.origin +
                            '/images/downSmallArrow-auxiliaryGray.svg'
                          }
                          loading="lazy"
                          className="af-class-cat-filters-scrollnote-icon"
                        />
                        <div>Desliza para ver todo</div>
                      </div>
                    </div>
                    <div
                      onClick={() => setOpenModal(true)}
                      data-w-id="3cc32892-4493-a8bd-f74a-1fda51d1f905"
                      className="af-class-cat-filtersmobbutton af-class-cat-filtersmobbutton-props"
                    >
                      <img
                        alt=""
                        src={
                          window.location.origin + '/images/bothArrow-gray.svg'
                        }
                        loading="lazy"
                        className="af-class-cat-filtersmobbutton-icon"
                      />
                      <div className="af-class-cat-filtersmobbutton-label">
                        Mapa y filtros avanzados
                      </div>
                    </div>
                  </div>
                  <div className="af-class-cat-content-wrapper">
                    <div className="af-class-cat-left-column">
                      
                      <div className="af-class-cat-filters-column">
                        <div className="af-class-cat-filters-wrapper af-class-cat-left-gencontent">
                          <div className="af-class-cat-stdname-label">
                            Mapa de zonas
                          </div>
                          <div className="af-class-cat-stdname">
                            {getStadiumById(data.events.stadium_id).name}
                          </div>
                          <img
                            alt=""
                            src={`${getStadiumById(data.events.stadium_id).images[
                              getStadiumById(data.events.stadium_id).images
                                .length - 1
                              ]
                              }`}
                            loading="lazy"
                            className="af-class-cat-stdmap cursor-pointer"
                            onClick={() => setShowModal(true)}
                          />
                          <div
                            className="af-class-cat-filters-popupbutton"
                            onClick={() => setOpenModal(true)}
                          >
                            <img
                              alt=""
                              src={
                                window.location.origin +
                                '/images/settings-deepBlue.svg'
                              }
                              loading="lazy"
                              className="af-class-cat-filters-popupbutton-icon"
                            />
                            
                            <div>Filtros avanzados</div>
                          </div>
                          <br/>
                          <div className='addPropiety'>
                              <h3>¿Quieres rentar tu palco, platea o butacas para este evento?</h3>
                              <HashLink
                                to="/contacto"
                                className="af-class-gen-button w-button"
                              >
                                Envíanos un mensaje
                              </HashLink>
                          </div>
                          
                      
                        </div>
                      </div>
                      <div className="af-class-cat-pag-wrapper">
                        <div className="af-class-gen-note">
                          Se muestran 10 resultados por página.
                        </div>
                        <div className="af-class-cat-pagbuttons-wrapper">
                          <div className="af-class-cat-pagbuttons-center-wrapper">
                            {filters
                              ? palcosFiltered.map((_, idx) => {
                                if (
                                  idx + 1 === 1 ||
                                  idx * 10 < palcosFiltered.length
                                ) {
                                  return currentPage === idx + 1 ? (
                                    <div className="af-class-cat-pagbutton af-class-pagbuttonactive">
                                      {idx + 1}
                                    </div>
                                  ) : (
                                    <div
                                      className="af-class-cat-pagbutton"
                                      onClick={() => changePag(idx + 1)}
                                    >
                                      {idx + 1}
                                    </div>
                                  );
                                }
                              })
                              : data.palcos.map((_, idx) => {
                                if (
                                  idx + 1 === 1 ||
                                  idx * 10 < data.palcos.length
                                ) {
                                  return currentPage === idx + 1 ? (
                                    <div className="af-class-cat-pagbutton af-class-pagbuttonactive">
                                      {idx + 1}
                                    </div>
                                  ) : (
                                    <div
                                      className="af-class-cat-pagbutton"
                                      onClick={() => changePag(idx + 1)}
                                    >
                                      {idx + 1}
                                    </div>
                                  );
                                }
                              })}
                          </div>
                        </div>
                      </div>
                      
                      
                    </div>
                    <div ref={myRef} className="af-class-cat-right-column">
                      {getCard(filters ? palcosFiltered : data.palcos)}
                      {/* {getRentedCard(filters ? palcosFiltered : data.palcos)} */}

                      <div className='waitList'>
                        <h3>¿Aún no encuentras la opción ideal para ti?</h3>
                        <p>Envíanos un mensaje y te notificaremos cuando esté disponible una opción acorde a lo que estás buscando</p>
                        <HashLink to="/contacto" className='af-class-gen-button w-button'>
                          Añadirme a la lista de espera
                        </HashLink>
                      </div>
                      
                      
                    </div>
                  </div>
                </div>
                <div className="af-class-cat-pag-wrapper af-class-pagmob-wrapper">
                  <div className="af-class-gen-note">
                    Se muestran 10 resultados por página.
                    
                  </div>
                  <div className="af-class-cat-pagbuttons-wrapper">
                    <div className="af-class-cat-pagbuttons-center-wrapper">
                      {data.palcos.map((_, idx) => {
                        if (idx + 1 === 1 || idx * 10 < data.palcos.length) {
                          return currentPage === idx + 1 ? (
                            <div className="af-class-cat-pagbutton af-class-pagbuttonactive">
                              {idx + 1}
                            </div>
                          ) : (
                            <div
                              className="af-class-cat-pagbutton"
                              onClick={() => changePag(idx + 1)}
                            >
                              {idx + 1}
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            {openModal && (
              <ModalEventFilters
                openModal={openModal}
                onDimiss={onDimiss}
                stadium={getStadiumById(data.events.stadium_id)}
                sendFilters={sendFilters}
              />
            )}
            <ImgModal
              openModal={showModal}
              url={
                getStadiumById(data.events.stadium_id).images[
                getStadiumById(data.events.stadium_id).images.length - 1
                ]
              }
              onDimiss={() => setShowModal(false)}
              ref={refShowModal}
            />
          </div>
        </span>
      )
    );
  } else {
    return <Loader />;
  }
};

export default PagEventoView;

/* eslint-enable */
