import React from 'react';
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, Link, useParams } from 'react-router-dom';
import { AuthContext } from './auth-context';
import configData from 'config.json';
import Loader from 'components/Loader';
import NumberFormat from 'react-number-format';

import Button from 'components/Button';
import CheckoutPopupsView from './CheckoutPopupsView';
import CheckoutSuccess from './CheckoutSuccess';
import CheckoutError from './CheckoutError';

const month = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sep',
  'Oct',
  'Nov',
  'Dic',
];

const CheckoutView = () => {
  const location = useLocation();
  const router = useHistory();
  const params = useParams();
  const auth = useContext(AuthContext);
  const [loader, setLoader] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [idRent, setIdRent] = useState('');
  const [paymentError, setPaymentError] = useState(false);
  const { handleSubmit, register, control, formState, errors, watch } = useForm(
    { mode: 'all' }
  );

  const [delivery, setDelivery] = useState('new');
  const [shipping, setShipping] = useState('');
  const [total, setTotal] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [postalCodeAlias, setPostalCodeAlias] = useState(false);
  const [cardName, setCardName] = useState('');
  const [cardNum, setCardNum] = useState('');
  const [cardDate, setCardDate] = useState('');
  const [data, setData] = useState({
    user: '',
    allEvents: [],
    palcos: '',
    stadiums: [],
    events: [],
    teams: [],
    competitions: [],
    relationPE: '',
  });

  const onDimiss = () => {
    openModal === true && setOpenModal(false);
    paymentError === true && setPaymentError(false);
  };

  const getData = async () => {
    try {
      let user = '';
      if (auth.isLoggedIn) {
        user = await axios
          .get(`${configData.SERVER_URL}/user/${auth.userId}`)
          .then((res) => {
            return res.data.user;
          });
      }
      const allEvents = await axios
        .get(`${configData.SERVER_URL}/events`)
        .then((res) => {
          return res.data.events;
        });
      const events = await axios
        .get(`${configData.SERVER_URL}/events/${params.event}`)
        .then((res) => {
          return res.data.event;
        });
      const stadiums = await axios
        .get(`${configData.SERVER_URL}/stadiums`)
        .then((res) => {
          return res.data.stadium;
        });
      const teams = await axios
        .get(`${configData.SERVER_URL}/teams`)
        .then((res) => {
          return res.data.teams;
        });
      const palcos = await axios
        .get(`${configData.SERVER_URL}/palcos/${params.propiedad}`)
        .then((res) => {
          return res.data.palco;
        });
      const competitions = await axios
        .get(`${configData.SERVER_URL}/competition`)
        .then((res) => {
          return res.data.competition;
        });
      const relationPE = await axios
        .get(
          `${configData.SERVER_URL}/eventPalco/${params.propiedad}/${params.event}`
        )
        .then((res) => {
          return res.data.relation;
        })
        .catch((err) => {
          console.log(err);
          return '';
        });
      setTotal(
        relationPE?.price
          ? parseFloat(relationPE?.price)
          : parseFloat(palcos.price)
      );
      setData({
        user,
        allEvents,
        palcos,
        stadiums,
        events,
        teams,
        competitions,
        relationPE,
      });
      // console.log(data);
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, [auth.userId]);

  const getStadiumById = (id) => {
    if (data.stadiums) {
      return data.stadiums.find((stadium) => stadium.id === id);
    }
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const getTarifa = async (postal_code) => {
    const total = data.relationPE?.price
      ? data.relationPE?.price
      : data.palcos.price;
    const totalwithtaxes = parseFloat(total) + parseFloat(total) * 0.16;
    if (postalCode !== '') {
      const tarifa = await axios
        .get(`${configData.SERVER_URL}/tarifas/${postal_code}`)
        .then((res) => {
          return res.data.price;
        });
      if (tarifa !== '') {
        setShipping(tarifa);
        const price = parseFloat(totalwithtaxes) + parseFloat(tarifa);
        setTotal(price);
      } else {
        setShipping('');
        setTotal(totalwithtaxes);
        setOpenModal(true);
      }
    }
  };

  // useEffect(() => {
  //   getTarifa(postalCode);
  // }, [postalCode]);

  const dataDelivery = (type) => {
    if (type === 'alias') {
      postalCodeAlias === false && setPostalCode(data.user.postal_code);
      postalCodeAlias === false && setPostalCodeAlias(true);
      return (
        <div className="af-class-form-content-wrapper">
          <div className="af-class-form-field-wrapper af-class-chk-addressinfo-field">
            <label
              htmlFor="ship-address-country"
              className="af-class-form-label"
            >
              País *
            </label>
            <select
              ref={register}
              id="country"
              name="country"
              data-name="ship-address-country"
              required
              className="af-class-form-drop w-select"
            >
              <option value={data.user.country} selected>
                {capitalizeFirstLetter(data.user.country)}
              </option>
            </select>
          </div>
          <div className="af-class-form-field-wrapper af-class-chk-addressinfo-field">
            <label htmlFor="ship-address-state" className="af-class-form-label">
              Estado *
            </label>
            <select
              ref={register}
              id="state"
              name="state"
              data-name="ship-address-state"
              required
              className="af-class-form-drop w-select"
            >
              <option value={data.user.state} selected>
                {capitalizeFirstLetter(data.user.state)}
              </option>
            </select>
          </div>
          <div className="af-class-form-field-wrapper af-class-chk-addressinfo-field">
            <label htmlFor="ship-address-city" className="af-class-form-label">
              Ciudad *
            </label>
            <input
              ref={register}
              value={data.user.city}
              type="text"
              className="af-class-form-textinput w-input"
              maxLength={256}
              name="city"
              data-name="ship-address-city"
              placeholder="....."
              id="city"
              required
            />
          </div>
          <div className="af-class-form-field-wrapper af-class-chk-addressinfo-field">
            <label
              htmlFor="ship-address-suburb"
              className="af-class-form-label"
            >
              Colonia *
            </label>
            <input
              ref={register}
              value={data.user.suburb}
              type="text"
              className="af-class-form-textinput w-input"
              maxLength={256}
              name="suburb"
              data-name="ship-address-suburb"
              placeholder="....."
              id="suburb"
              required
            />
          </div>
          <div className="af-class-form-field-wrapper af-class-chk-addressinfo-field">
            <label htmlFor="ship-address-zip" className="af-class-form-label">
              Código Postal *
            </label>
            <input
              ref={register}
              value={data.user.postal_code}
              type="text"
              className="af-class-form-textinput w-input"
              maxLength={256}
              name="zip"
              data-name="zip"
              placeholder="....."
              id="zip"
              onBlur={(e) => setPostalCode(e.target.value)}
              required
            />
          </div>
          <div className="af-class-form-field-wrapper af-class-chk-addressinfo-field">
            <label
              htmlFor="ship-address-address"
              className="af-class-form-label"
            >
              Dirección *
            </label>
            <input
              ref={register}
              value={data.user.address}
              type="text"
              className="af-class-form-textinput w-input"
              maxLength={256}
              name="address"
              data-name="address"
              placeholder="....."
              id="address"
              required
            />
          </div>
          <div className="af-class-form-field-wrapper af-class-chk-addressinfo-field">
            <label
              htmlFor="ship-address-interior"
              className="af-class-form-label"
            >
              Número interior
            </label>
            <input
              ref={register}
              value={data.user.interior_num ? data.user.interior_num : ''}
              type="text"
              className="af-class-form-textinput w-input"
              maxLength={256}
              name="interior"
              data-name="interior"
              placeholder="....."
              id="interior"
            />
          </div>
        </div>
      );
    } else if (type === 'office') {
      return (
        <div className="af-class-form-content-wrapper">
          <div
            id="w-node-_0d1fc258-356a-043d-ffe7-250d08d5ae64-f71489d2"
            className="af-class-chk-addressnote-office"
          >
            <div className="af-class-form-note">
              Podrás recoger tus entradas (tarjetas) en nuestras oficinas desde
              las 8:00 a 18:00 hrs, de lunes a viernes. Sólo necesitarás tu{' '}
              <span className="af-class-form-notebold">número de orden</span> y
              una{' '}
              <span className="af-class-form-notebold">
                identificación oficial
              </span>
              .
            </div>
            <div className="af-class-form-note af-class-chk-addressnote-subtitle">
              PalcosPlus Guadalajara
            </div>
            <div className="af-class-form-note af-class-form-finalnote">
              Mariano Otero 1917 int. 3-D. Residencial victoria, Zapopan. C.P.
              45089.
            </div>
          </div>
        </div>
      );
    } else if (type === 'stadium') {
      return (
        <div className="af-class-form-content-wrapper">
          <div
            id="w-node-f384597a-4cce-bd63-7901-23533d91a3db-f71489d2"
            className="af-class-chk-addressnote-stadium"
          >
            <div className="af-class-form-note">
              Podrás recoger tus entradas (tarjetas) en el estadio, desde 2
              horas antes del evento. Sólo necesitarás tu{' '}
              <span className="af-class-form-notebold">número de orden</span> y
              una{' '}
              <span className="af-class-form-notebold">
                identificación oficial
              </span>
              . Podrás encontrar al staff de PalcosPlus.com en la siguiente
              ubicación.{' '}
              <span className="af-class-form-notebold">
                Aquí podrás recoger tus tarjetas y entregarlas al final del
                evento.
              </span>
            </div>
            <div className="af-class-chk-stadium-staffloc-wrapper">
              <div className="af-class-form-note af-class-chk-addressnote-subtitle">
                Zona de entrega,{' '}
                {data.stadiums && getStadiumById(data.events.stadium_id).name}
              </div>
              <div className="af-class-form-note af-class-form-finalnote">
                {data.stadiums &&
                  getStadiumById(data.events.stadium_id).delivery_zone}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      postalCodeAlias === true && setPostalCodeAlias(false);
      return (
        <div className="af-class-form-content-wrapper">
          <div className="af-class-form-field-wrapper af-class-chk-addressinfo-field">
            <label
              htmlFor="ship-address-country"
              className="af-class-form-label"
            >
              País *
            </label>
            <select
              ref={register}
              id="country"
              name="country"
              data-name="ship-address-country"
              required
              className="af-class-form-drop w-select"
            >
              <option>Seleccionar país</option>
              <option value="mexico">México</option>
            </select>
          </div>
          <div className="af-class-form-field-wrapper af-class-chk-addressinfo-field">
            <label htmlFor="ship-address-state" className="af-class-form-label">
              Estado *
            </label>
            <select
              ref={register}
              id="state"
              name="state"
              data-name="ship-address-state"
              required
              className="af-class-form-drop w-select"
            >
              <option value>Seleccionar estado</option>
              <option value="jalisco">Jalisco</option>
            </select>
          </div>
          <div className="af-class-form-field-wrapper af-class-chk-addressinfo-field">
            <label htmlFor="ship-address-city" className="af-class-form-label">
              Ciudad *
            </label>
            <input
              ref={register({ required: true })}
              autoComplete="off"
              defaultValue=""
              type="text"
              className="af-class-form-textinput w-input"
              maxLength={256}
              name="city"
              data-name="ship-address-city"
              placeholder="....."
              id="city"
              required
            />
          </div>
          <div className="af-class-form-field-wrapper af-class-chk-addressinfo-field">
            <label
              htmlFor="ship-address-suburb"
              className="af-class-form-label"
            >
              Colonia *
            </label>
            <input
              ref={register({ required: true })}
              autoComplete="off"
              defaultValue=""
              type="text"
              className="af-class-form-textinput w-input"
              maxLength={256}
              name="suburb"
              data-name="ship-address-suburb"
              placeholder="....."
              id="suburb"
              required
            />
          </div>
          <div className="af-class-form-field-wrapper af-class-chk-addressinfo-field">
            <label htmlFor="ship-address-zip" className="af-class-form-label">
              Código Postal *
            </label>
            <input
              ref={register({ required: true })}
              autoComplete="off"
              defaultValue=""
              type="text"
              className="af-class-form-textinput w-input"
              maxLength={256}
              name="zip"
              data-name="zip"
              placeholder="....."
              id="zip"
              onBlur={(e) => setPostalCode(e.target.value)}
              required
            />
          </div>
          <div className="af-class-form-field-wrapper af-class-chk-addressinfo-field">
            <label
              htmlFor="ship-address-address"
              className="af-class-form-label"
            >
              Dirección *
            </label>
            <input
              ref={register({ required: true })}
              autoComplete="off"
              defaultValue=""
              type="text"
              className="af-class-form-textinput w-input"
              maxLength={256}
              name="address"
              data-name="address"
              placeholder="....."
              id="address"
              required
            />
          </div>
          <div className="af-class-form-field-wrapper af-class-chk-addressinfo-field">
            <label
              htmlFor="ship-address-interior"
              className="af-class-form-label"
            >
              Número interior
            </label>
            <input
              ref={register}
              autoComplete="off"
              defaultValue=""
              type="text"
              className="af-class-form-textinput w-input"
              maxLength={256}
              name="interior"
              data-name="interior"
              placeholder="....."
              id="interior"
            />
          </div>
        </div>
      );
    }
  };

  const getCompetitionById = (id) => {
    if (data.competitions) {
      const result = data.competitions.find(
        (competition) => competition.id === id
      );
      return result;
    }
  };

  const getTeamById = (id) => {
    if (data.teams) {
      return data.teams.find((team) => team.id === id);
    }
  };

  const getCard = (event, palco) => {
    let date;
    if (event) {
      date = event?.date.split('-');
    }
    return event.type === 'partido' ? (
      <div className="af-class-cart-rent-data-wrapper">
        <div className="af-class-cart-rent-eventname-wrapper">
          <div>{getTeamById(event?.home)?.name}</div>
          <div className="af-class-cart-rent-vs">Vs</div>
          <div>{getTeamById(event?.visitor)?.name}</div>
        </div>
        <div className="af-class-cart-rent-stadium">
          {getStadiumById(event?.stadium_id).name}
        </div>
        <div className="af-class-cart-rent-roundtour-wrapper">
          <div>{`Jornada ${event?.jornada}`}</div>
          <div className="af-class-cart-rent-competition">
            {getCompetitionById(event?.competition_id)?.name}
          </div>
        </div>
        <div className="af-class-cat-eventcard-datetime-wrapper">
          <div className="af-class-cat-eventcard-data af-class-eventcard-databold">
            {date[0]}
          </div>
          <div className="af-class-cat-eventcard-data af-class-eventcard-datamedium">
            {date[1] === '0' ? month[date[1].substr(-1)] : month[date[1] - 1]}
          </div>
          <div className="af-class-cat-eventcard-data">{date[2]}</div>
          <div className="af-class-cat-eventcard-data">···</div>
          <div className="af-class-cat-eventcard-data af-class-eventcard-databold">
            {event.time}
          </div>
          <div className="af-class-cat-eventcard-data">Hrs</div>
        </div>
        <div className="af-class-cart-rent-property-wrapper">
          <div className="af-class-cart-rent-propname">{palco.name}</div>
          <div className="af-class-cart-rent-propdata-wrapper">
            <div className="af-class-cart-rent-accessnum">
              {palco.num_cards} tarjetas
            </div>
            <div className="af-class-cart-rent-price-wrapper">
              <div className="af-class-cart-rent-price">
                ${data.relationPE?.price ? data.relationPE?.price : palco.price}
              </div>
              <div className="af-class-cart-rent-curr">Mxn</div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="af-class-cart-rent-data-wrapper">
        <div className="af-class-cart-rent-eventname-wrapper">
          <div>{event.name}</div>
        </div>
        <div className="af-class-cart-rent-stadium">
          {getStadiumById(event.stadium_id).name}
        </div>
        <div className="af-class-cart-rent-roundtour-wrapper">
          <div>{event.tour_name}</div>
        </div>
        <div className="af-class-cat-eventcard-datetime-wrapper">
          <div className="af-class-cat-eventcard-data af-class-eventcard-databold">
            {date[0]}
          </div>
          <div className="af-class-cat-eventcard-data af-class-eventcard-datamedium">
            {month[date[1].substr(-1)]}
          </div>
          <div className="af-class-cat-eventcard-data">{date[2]}</div>
          <div className="af-class-cat-eventcard-data">···</div>
          <div className="af-class-cat-eventcard-data af-class-eventcard-databold">
            {event.time}
          </div>
          <div className="af-class-cat-eventcard-data">Hrs</div>
        </div>
        <div className="af-class-cart-rent-property-wrapper">
          <div className="af-class-cart-rent-propname">{palco.name}</div>
          <div className="af-class-cart-rent-propdata-wrapper">
            <div className="af-class-cart-rent-accessnum">
              {palco.num_cards} tarjetas
            </div>
            <div className="af-class-cart-rent-price-wrapper">
              <div className="af-class-cart-rent-price">
                ${data.relationPE?.price ? data.relationPE?.price : palco.price}
              </div>
              <div className="af-class-cart-rent-curr">Mxn</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onsubmit = async (submit, e) => {
    // if (shipping === '' && (delivery === 'new' || delivery === 'alias')) {
    //   setOpenModal(true);
    // } else {
    /*added as new feature*/
    var shipping = 0;
    const subtotal = data.relationPE?.price
      ? data.relationPE?.price
      : data.palcos.price;
    const total = subtotal;
    // const comision = data.relationPE?.comision
    //   ? data.relationPE?.comision
    //   : data.palcos.comision;
    // const totalwithtaxes = parseFloat(subtotal) + parseFloat(subtotal) * 0.16;
    // var total = totalwithtaxes;
    /*end of new feature */
    setLoader(true);
    window.scrollTo(0, 0);
    e.preventDefault();
    const month = submit.card_exp.substring(0, 2);
    const year = submit.card_exp.substring(3, 5);
    let sendData;
    if (delivery === 'new' || delivery === 'alias') {
      // shipping = 100;
      sendData = {
        name: submit.name,
        last_name: submit.lastname,
        email: submit.email,
        tel: submit.phone,
        total: total,
        subtotal: subtotal,
        // comision: comision,
        // shipping: shipping,
        alias: delivery,
        country: submit.country,
        state: submit.state,
        city: submit.city,
        suburb: submit.suburb,
        postal_code: submit.zip,
        address: submit.address,
        interior_num: submit.interior,
        stadium_id: data.palcos.stadium_id,
        palco_id: params.propiedad,
        event_id: params.event,
        stadium_name: getStadiumById(data.palcos.stadium_id).name,
        palco_name: data.palcos.name,
        event_name:
          data.events.type === 'partido'
            ? getTeamById(data.events?.home)?.name +
            ' vs ' +
            getTeamById(data.events?.visitor)?.name
            : data.events.name,
        event_date: data.events.date,
        active: true,
      };
    } else {
      sendData = {
        name: submit.name,
        last_name: submit.lastname,
        email: submit.email,
        tel: submit.phone,
        total: total,
        subtotal: subtotal,
        // comision: comision,
        // shipping: shipping,
        alias: delivery,
        stadium_id: data.palcos.stadium_id,
        palco_id: params.propiedad,
        event_id: params.event,
        stadium_name: getStadiumById(data.palcos.stadium_id).name,
        palco_name: data.palcos.name,
        event_name:
          data.events.type === 'partido'
            ? getTeamById(data.events?.home)?.name +
            ' vs ' +
            getTeamById(data.events?.visitor)?.name
            : data.events.name,
        event_date: data.events.date,
        country: '',
        state: '',
        city: '',
        suburb: '',
        postal_code: '',
        address: '',
        interior_num: '',
        active: true,
      };
    }
    const payment = {
      stadium_id: data.palcos.stadium_id,
      palco_id: params.propiedad,
      event_id: params.event,
      number: submit.card_num,
      exp_month: month,
      exp_year: year,
      cvc: submit.card_cvv,
      amount: total,
      description: 'renta de la propiedad ' + data.palcos.name,
      customerName: submit.name + ' ' + submit.lastname,
      customerEmail: submit.email,
      cp: submit.card_cp
    };
    const mailData = {
      email: submit.email,
      name: submit.name + ' ' + submit.lastname,
      event_name:
        data.events.type === 'partido'
          ? getTeamById(data.events?.home)?.name +
          ' vs ' +
          getTeamById(data.events?.visitor)?.name
          : data.events.name,
      event_tour_name:
        data.events.type === 'partido' ? '' : data.events.tour_name,
      competition:
        data.events.type === 'partido'
          ? getCompetitionById(data.events.competition_id).name
          : '',
      prop_name: data.palcos.name,
      zone: data.palcos.zone,
      access: data.palcos.access,
      num_cards: data.palcos.num_cards,
      stadium_name: getStadiumById(data.palcos.stadium_id).name,
      date: data.events.date,
      time: data.events.time,
      delivery_type: delivery,
      delivery_address: sendData.address !== '' ? sendData.address : '',
      instructions: '',
      stadium_delivery_zone: getStadiumById(data.palcos.stadium_id)
        .delivery_zone,
      price: total,
      subtotal: subtotal,
      // iva: parseFloat(subtotal) * 0.16,
      // shipping: shipping,
      iva: 0,
      shipping: 0,
      coupon: 'niguno',
      discount: 0,
      total: total,
    };

    console.log(mailData)
    await axios.post(`${configData.SERVER_URL}/pay`, payment).then(
      async (res) => {
        const resRent = await axios
          .post(`${configData.SERVER_URL}/rents`, sendData)
          .then((res) => {
            return res.data.rent;
          });
        const req = { ...mailData, order_id: resRent._id };
        await axios
          .post(`${configData.SERVER_URL}/sendEmail/confirmation`, req)
          .then(
            (res) => {
              return res.data.message;
            },
            (err) => {
              console.log(err);
            }
          );
        await axios
          .post(`${configData.SERVER_URL}/sendEmail/confirmationAdmin`, req)
          .then(
            (res) => {
              return res.data.message;
            },
            (err) => {
              console.log(err);
            }
          );
        setSuccess(true);
        setIdRent(resRent._id);
        return res.data;
      },
      (err) => {
        setPaymentError(true);
        return err;
      }
    );
    setLoader(false);
    // }
  };

  function limit(val, max) {
    if (val.length === 1 && val[0] > max[0]) {
      val = '0' + val;
    }

    if (val.length === 2) {
      if (Number(val) === 0) {
        val = '01';

        //this can happen when user paste number
      } else if (val > max) {
        val = max;
      }
    }

    return val;
  }

  function cardExpiry(val) {
    let month = limit(val.substring(0, 2), '12');
    let year = val.substring(2, 4);

    return month + (year.length ? '/' + year : '');
  }

  function cardNumFormat(val) {
    let card = '';
    for (let i = 0; i < val.length; i++) {
      if (i % 4 === 0) {
        card = card + ' ';
      }
      card = card + val[i];
    }

    return card;
  }

  const updateDelivery = (type) => {
    setDelivery(type);
    if (shipping) {
      const value = parseFloat(total) - parseFloat(shipping);
      setTotal(value);
      setShipping('');
      setPostalCode('');
    }
  };

  if (!loader) {
    return (
      <span className="af-view">
        <div>
          <div className="af-class-pay-section af-class-wf-section">
            <div className="af-class-gen-container">
              <div className="af-class-chk-form-wrapper w-form">
                <form
                  onSubmit={handleSubmit(onsubmit)}
                  autoComplete="off"
                  id="wf-form-checkout"
                  name="wf-form-checkout"
                  data-name="checkout"
                  className="af-class-pay-columns-wrapper"
                >
                  <div className="af-class-pay-left-column">
                    <div className="af-class-pay-pagetitle-wrapper">
                      <img
                        src={`${window.location.origin}/images/title-checkout_blue.svg`}
                        loading="lazy"
                        alt=""
                        className="af-class-pay-pagetitle-icon"
                      />
                      <h1 className="af-class-pay-pagetitle">Checkout</h1>
                    </div>
                    <div className="af-class-form-section">
                      <div className="af-class-form-content-wrapper">
                        <div className="af-class-form-field-wrapper">
                          <label htmlFor="name" className="af-class-form-label">
                            Nombre(s) *
                          </label>
                          <input
                            ref={register}
                            defaultValue={data.user && data.user.name}
                            type="text"
                            className="af-class-form-textinput w-input"
                            maxLength={256}
                            name="name"
                            data-name="name"
                            placeholder="....."
                            id="name"
                            required
                          />
                        </div>
                        <div className="af-class-form-field-wrapper">
                          <label
                            htmlFor="lastname"
                            className="af-class-form-label"
                          >
                            Apellidos *
                          </label>
                          <input
                            ref={register}
                            defaultValue={data.user && data.user.last_name}
                            type="text"
                            className="af-class-form-textinput w-input"
                            maxLength={256}
                            name="lastname"
                            data-name="lastname"
                            placeholder="....."
                            id="lastname"
                            required
                          />
                        </div>
                        <div className="af-class-form-field-wrapper">
                          <label
                            htmlFor="email"
                            className="af-class-form-label"
                          >
                            Correo electrónico *
                          </label>
                          <input
                            ref={register}
                            defaultValue={data.user && data.user.email}
                            type="email"
                            className="af-class-form-textinput w-input"
                            maxLength={256}
                            name="email"
                            data-name="email"
                            placeholder="....."
                            id="email"
                            required
                          />
                        </div>
                        <div className="af-class-form-field-wrapper">
                          <label
                            htmlFor="phone"
                            className="af-class-form-label"
                          >
                            Teléfono *
                          </label>
                          <input
                            ref={register}
                            defaultValue={data.user && data.user.tel}
                            type="tel"
                            className="af-class-form-textinput w-input"
                            maxLength={256}
                            name="phone"
                            data-name="phone"
                            placeholder="....."
                            id="phone"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="af-class-form-section">
                      <div className="af-class-form-sectiontitle-wrapper">
                        <img
                          alt=""
                          src={`${window.location.origin}/images/icon-location_blue.svg`}
                          loading="lazy"
                          className="af-class-form-sectiontitle-icon"
                        />
                        <h2 className="af-class-form-sectiontitle">
                          Dirección de entrega
                        </h2>
                      </div>
                      <p className="af-class-form-note">
                        Aquí te entregaremos las entradas (tarjetas) de tus
                        palcos, plateas o butacas rentadas. También, si
                        prefieres, podrás recoger las tarjetas en nuestras
                        oficinas o en el estadio, el día del evento.
                      </p>

                      <div className="af-class-form-field-wrapper">
                        <label
                          htmlFor="ship-address-type"
                          className="af-class-form-label"
                        >
                          Dirección de entrega *
                        </label>
                        <select
                          id="ship-address-type"
                          name="ship-address-type"
                          data-name="ship-address-type"
                          required
                          className="af-class-form-drop w-select"
                          onChange={(e) => updateDelivery(e.target.value)}
                        >
                          <option value="new">Nueva dirección</option>
                          {data.user.alias && (
                            <option value="alias">
                              Dirección favorita: {data.user.alias}{' '}
                            </option>
                          )}
                          <option value="office">Recoger en oficinas</option>
                          <option value="stadium">Recoger en el estadio</option>
                        </select>
                      </div>
                      <br />
                      {dataDelivery(delivery)}
                    </div>
                    <div className="af-class-form-section af-class-chk-payment-form-section af-class-form-lastsection">
                      <div className="af-class-form-sectiontitle-wrapper">
                        <img
                          src={`${window.location.origin}/images/icon-payment_white.svg`}
                          loading="lazy"
                          alt=""
                          className="af-class-form-sectiontitle-icon"
                        />
                        <h2 className="af-class-form-sectiontitle af-class-form-sectiontitle-white">
                          Información de pago
                        </h2>
                      </div>
                      <p className="af-class-form-note af-class-form-note-white">
                        Todos las transacciones están protegidas y cifradas con
                        los más altos estándares de seguridad. En PalcosPlus.com
                        sólo colaboramos con los procesadores de pago más
                        confiables del mercado.
                      </p>
                      <div className="af-class-chk-payment-data-wrapper">
                        <div
                          id="w-node-_7b971490-473c-a59b-c1ab-cadbd75e6274-f71489d2"
                          className="af-class-form-field-wrapper"
                        >
                          <label
                            htmlFor="card_name"
                            className="af-class-form-label af-class-form-label-white"
                          >
                            Titular (nombre completo) *
                          </label>
                          <input
                            ref={register}
                            onChange={(e) => setCardName(e.target.value)}
                            type="text"
                            className="af-class-form-textinput w-input"
                            maxLength={256}
                            name="card_name"
                            data-name="card_name"
                            placeholder="....."
                            id="card_name"
                            required
                          />
                        </div>
                        <div
                          id="w-node-_7b971490-473c-a59b-c1ab-cadbd75e6273-f71489d2"
                          className="af-class-form-content-wrapper af-class-chk-form-content-data-verwrapper"
                        >
                          <div className="af-class-form-field-wrapper af-class-chk-card-form-field-wrapper">
                            <label
                              htmlFor="card_num"
                              className="af-class-form-label af-class-form-label-white"
                            >
                              Número de tarjeta *
                            </label>
                            {/* <input ref={register} onChange={e=>setCardNum(e.target.value)} type="number" className="af-class-form-textinput w-input" maxLength={256} name="card_num" data-name="card_num" placeholder="XXXX XXXX XXXX XXXX" id="card_num" required /> */}
                            <NumberFormat
                              className="af-class-form-textinput w-input"
                              getInputRef={register}
                              format="#### #### #### ####"
                              mask="X"
                              name="card_num"
                              placeholder="XXXX XXXX XXXX XXXX"
                              id="card_num"
                              required
                              onValueChange={(e) => setCardNum(e.value)}
                            />
                          </div>
                          <div className="af-class-form-field-wrapper">
                            <label
                              htmlFor="card_exp"
                              className="af-class-form-label af-class-form-label-white"
                            >
                              Vencimiento *
                            </label>
                            {/* <div>
                                <input autocomplete="off" id="month" maxlength="2" pattern="[0-9]*" inputmode="numerical" placeholder="MM" type="text" />
                                <input autocomplete="off" id="year" maxlength="2" pattern="[0-9]*" inputmode="numerical" placeholder="YY" type="text" />
                              </div> */}
                            {/* <input ref={register} onChange={e=>setCardDate(e.target.value)} type="number" className="af-class-form-textinput w-input" maxLength={"4"} name="card_exp" data-name="card_exp" placeholder="MM/AA" id="card_exp" required /> */}
                            <NumberFormat
                              getInputRef={register}
                              id="card_exp"
                              name="card_exp"
                              className="af-class-form-textinput w-input"
                              format={cardExpiry}
                              placeholder="MM/AA"
                              onValueChange={(e) => setCardDate(e.value)}
                              required
                            />
                          </div>
                          <div className="af-class-form-field-wrapper">
                            <label
                              htmlFor="card_cvv"
                              className="af-class-form-label af-class-form-label-white"
                            >
                              CVV *
                            </label>
                            <input
                              ref={register}
                              type="password"
                              className="af-class-form-textinput w-input"
                              maxLength={3}
                              name="card_cvv"
                              data-name="card_cvv"
                              placeholder="XXX"
                              id="card_cvv"
                              required
                            />
                          </div>
                          <div className="af-class-form-field-wrapper">
                            <label
                              htmlFor="card_cp"
                              className="af-class-form-label af-class-form-label-white"
                            >
                              CodigoPostal *
                            </label>
                            <input
                              ref={register}
                              type="text"
                              className="af-class-form-textinput w-input"
                              maxLength={5}
                              name="card_cp"
                              data-name="card_cp"
                              placeholder="00000"
                              id="card_cp"
                              required
                            />
                          </div>
                        </div>
                        <div
                          id="w-node-fa842994-da69-b22b-aa77-c711a744251d-f71489d2"
                          className="af-class-chk-payment-card-wrapper"
                        >
                          <div className="af-class-chk-payment-card-block">
                            <img
                              src={`${window.location.origin}/images/graph_pay_card.svg`}
                              loading="lazy"
                              alt=""
                              className="af-class-chk-payment-card-bg"
                            />
                            <div className="af-class-chk-payment-card-exp">
                              {cardExpiry(cardDate)}
                            </div>
                            <div className="af-class-chk-payment-card-num">
                              {cardNumFormat(cardNum)}
                            </div>
                            <div className="af-class-chk-payment-card-name">
                              {cardName}
                            </div>
                            <div className="af-class-chk-payment-card-prov">
                              <img
                                src={`${window.location.origin}/images/graph_card_visa_white.svg`}
                                loading="lazy"
                                alt="Logo de Visa"
                                className="af-class-chk-payment-card-prov-logo af-class-prov-visa"
                              />
                              <img
                                src={`${window.location.origin}/images/graph_card_mastercard_white.svg`}
                                loading="lazy"
                                alt=""
                                className="af-class-chk-payment-card-prov-logo af-class-prov-mastercard"
                              />
                              <img
                                src={`${window.location.origin}/images/graph_card_amex_white.svg`}
                                loading="lazy"
                                alt=""
                                className="af-class-chk-payment-card-prov-logo af-class-prov-amex"
                              />
                            </div>
                          </div>
                          <div className="af-class-chk-payment-card-note">
                            PalcosPlus.com no almacena información sobre métodos
                            de pago. Todos los procesamientos de pago se
                            realizan mediante una plataforma verificada y
                            regulada.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="r-column" className="af-class-pay-right-column">
                    <div
                      id="w-node-_3f3bb87d-95b6-4b63-71db-5add83025def-f71489d2"
                      className="af-class-chk-summ-top-content"
                    >
                      <div
                        id="w-node-_96f4d3e7-9a63-1219-50a5-ccd2ae6800a2-f71489d2"
                        className="af-class-chk-discount-wrapper"
                      >
                        <div className="af-class-form-sectiontitle-wrapper">
                          <img
                            src={`${window.location.origin}/images/icon-discount_white.svg`}
                            loading="lazy"
                            alt=""
                            className="af-class-form-sectiontitle-icon"
                          />
                          <h2 className="af-class-form-sectiontitle af-class-form-sectiontitle-white">
                            Descuento
                          </h2>
                        </div>
                        <div className="af-class-form-field-wrapper">
                          <label
                            htmlFor="discount-code"
                            className="af-class-form-label af-class-form-label-white"
                          >
                            Ingresar cupón
                          </label>
                          <input
                            type="text"
                            className="af-class-form-textinput w-input"
                            maxLength={256}
                            name="discount-code"
                            data-name="discount-code"
                            placeholder="....."
                            id="discount-code"
                            disabled
                          />
                        </div>
                        <Button
                          className="af-class-gen-button af-class-chk-discount-button w-button"
                          disable={true}
                        >
                          Aplicar cupón
                        </Button>
                        <div className="af-class-form-error-wrapper af-class-chk-discount-error-wrapper">
                          {/* <div>El cupón que has ingresado no es válido o ya ha expirado.</div> */}
                        </div>
                      </div>
                      <button
                        type="submit"
                        defaultValue="Confirmar rentas"
                        data-wait="Procesando..."
                        id="w-node-_82135b40-e485-2c54-0d36-f678d5da8920-f71489d2"
                        className="af-class-gen-button af-class-chk-send-button-ins2 w-button"
                      >
                        Pagar
                      </button>
                    </div>
                    <div
                      id="w-node-de307a2b-bd5d-33fa-e358-b7d1d608ef73-f71489d2"
                      className="af-class-chk-summary-wrapper"
                    >
                      <div className="af-class-form-sectiontitle-wrapper">
                        <img
                          src={`${window.location.origin}/images/icon-rents_Summary_blue.svg`}
                          loading="lazy"
                          alt=""
                          className="af-class-form-sectiontitle-icon"
                        />
                        <h2 className="af-class-form-sectiontitle">Resumen</h2>
                      </div>
                      <div className="af-class-chk-summ-rents-wrapper">
                        {data.palcos !== '' &&
                          getCard(data.events, data.palcos)}
                      </div>
                      <div className="af-class-cat-contentdivider af-class-cart-divider af-class-chk-summ-divider" />
                      <div className="af-class-chk-summ-subtotals-wrapper">
                        <div className="af-class-chk-summ-line">
                          <div>Subtotal</div>
                          <div className="af-class-chk-summ-line-value af-class-chk-value-money">
                            $
                            {data.relationPE?.price
                              ? data.relationPE?.price
                              : data.palcos.price}{' '}
                            Mxn
                          </div>
                        </div>
                        {/* <div className="af-class-chk-summ-line af-class-chk-summ-ship-line">
                          <div>IVA</div>
                          <div className="af-class-chk-summ-line-value af-class-chk-value-money">
                            $
                            {data.relationPE?.price
                              ? parseFloat(data.relationPE?.price) * 0.16
                              : parseFloat(data.palcos.price) * 0.16}{' '}
                            Mxn
                          </div>
                        </div>
                        <div className="af-class-chk-summ-line af-class-chk-summ-ship-line">
                          <div>Envío</div>
                          <div className="af-class-chk-summ-line-value af-class-chk-value-money">
                            $
                            {delivery === 'new' || delivery === 'alias'
                              ? '100'
                              : '0'}
                          </div>
                        </div> */}
                        {/* <div className="af-class-chk-summ-line af-class-chk-summ-discount-line">
                            <div>Descuento</div>
                            <div className="af-class-chk-summ-line-value af-class-chk-value-money">-$1,580 Mxn</div>
                          </div> */}
                      </div>
                      <div className="af-class-chk-summ-final-price">
                        <div className="af-class-cart-subtotal-label">
                          total
                        </div>
                        <div className="af-class-cart-subtotal-sum-wrapper">
                          <div className="af-class-cart-subtotal-value">
                            ${total && total}
                          </div>
                          <div className="af-class-cart-subtotal-curr">Mxn</div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <label className="w-checkbox af-class-form-checkbox-wrapper">
                        <input
                          type="checkbox"
                          id="accept-policies"
                          name="accept-policies"
                          data-name="accept-policies"
                          required
                          className="w-checkbox-input w-checkbox-input--inputType-custom af-class-form-checkbox-box"
                        />
                        <span
                          htmlFor="accept-policies"
                          className="af-class-form-checkbox-label w-form-label"
                        >
                          He leído y acepto los{' '}
                          <a
                            href="https://palcosplus.s3.us-east-2.amazonaws.com/Te%CC%81rminos+y+Condiciones.pdf"
                            target="_blank"
                            rel="noreferrer"
                            download
                            className="af-class-form-checkbox-link"
                          >
                            Términos y Condiciones
                          </a>
                          , así como el{' '}
                          <a
                            href="https://palcosplus.s3.us-east-2.amazonaws.com/Aviso+de+Privacidad.pdf"
                            target="_blank"
                            rel="noreferrer"
                            download
                            className="af-class-form-checkbox-link"
                          >
                            Aviso de Privacidad
                          </a>{' '}
                          de PalcosPlus.com *
                        </span>
                      </label>
                    </div>
                    <button
                      type="submit"
                      defaultValue="Confirmar rentas"
                      data-wait="Procesando..."
                      id="w-node-_48d050f5-b832-ca87-96be-55408e257f37-f71489d2"
                      className="af-class-gen-button af-class-chk-send-button-ins1 w-button"
                    >
                      Pagar
                    </button>
                  </div>
                </form>
                <div className="af-class-form-success-wrapper w-form-done">
                  <img
                    alt=""
                    src={`${window.location.origin}/images/title-confirmRent_blue.svg`}
                    loading="lazy"
                    className="af-class-form-success-icon"
                  />
                  <div>
                    <span className="af-class-form-success-boldtext">
                      ¡Gracias por comprar en PalcosPlus.com!
                      <br />
                    </span>
                    En tu correo electrónico recibirás la confirmación de tu
                    orden.
                  </div>
                </div>
                <div className="af-class-form-error-wrapper w-form-fail">
                  <div>
                    Al parecer hay un error con la solicitud. Verifica que todos
                    los campos obligatorios (*) estén completos e inténtalo de
                    nuevo. Si los problemas persisten, por favor contacta a
                    soporte.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loader && (
          <Loader
            type="bubble-spin"
            bgColor={'#FFFFFF'}
            title={'bubble-spin'}
            color={'#FFFFFF'}
            size={100}
          />
        )}
        {openModal && (
          <CheckoutPopupsView openModal={openModal} onDimiss={onDimiss} />
        )}
        {success && (
          <CheckoutSuccess
            openModal={success}
            onDimiss={onDimiss}
            id={idRent}
          />
        )}
        {paymentError && (
          <CheckoutError openModal={paymentError} onDimiss={onDimiss} />
        )}
      </span>
    );
  } else {
    return <Loader />;
  }
};

export default CheckoutView;
