import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'components/Button';

const Panel = () => {
  return (
    <div className="mx-auto pb-24 pt-28" style={{ maxWidth: '1024px' }}>
      <div className="flex flex-col-reverse md:flex-row justify-between pb-10">
        <div className="flex flex-col">
          <div
            id="title"
            className="pb-2 h-40 md:h-14 flex flex-col md:flex-row items-center"
          >
            <div className="pb-4 md:pb-0">
              {/* <img alt="" className="w-14 h-14" /> */}
            </div>
            <h1 className="text-sport-blue text-center font-bold text-5xl md:pl-4">
              Panel Administrador
            </h1>
          </div>
        </div>
        {/* <div className="hidden lg:flex">
          <Button className="w-full md:w-64 h-14 bg-green-field rounded-lg shadow-sm hover:bg-green-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight ">
            CERRAR SESIÓN
          </Button>
        </div> */}
      </div>
      <div className="grid grid-flow-row pt-8 md:grid-cols-2 lg:grid-cols-3 gap-10">
        <Link to="/managerEstadio">
          <Button className="w-full md:w-80 h-24 text-center font-bold text-2xl text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight">
            Gestionar Estadios
          </Button>
        </Link>
        <Link to="/managerEventos">
          <Button className="w-full md:w-80 h-24 text-center font-bold text-2xl text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight">
            Gestionar Eventos
          </Button>
        </Link>
        <Link to="/managerPalco">
          <Button className="w-full md:w-80 h-24 text-center font-bold text-2xl text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight">
            Gestionar Propiedades
          </Button>
        </Link>
        <Link to="/managerEquipos">
          <Button className="w-full md:w-80 h-24 text-center font-bold text-2xl text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight">
            Gestionar Equipos
          </Button>
        </Link>
        <Link to="/managerCompetencias">
          <Button className="w-full md:w-80 h-24 text-center font-bold text-2xl text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight">
            Gestionar Competencias
          </Button>
        </Link>
        <Link to="/activeReservation">
          <Button className="w-full md:w-80 h-24 text-center font-bold text-2xl text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight">
            Reservas activas
          </Button>
        </Link>
        <Link to="/allReservation">
          <Button className="w-full md:w-80 h-24 text-center font-bold text-2xl text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight">
            Historial de reservas
          </Button>
        </Link>
        <Link to="/managerUsers">
          <Button className="w-full md:w-80 h-24 text-center font-bold text-2xl text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight">
            Registro de usuarios
          </Button>
        </Link>
        <Link to="/managerManual">
          <Button className="w-full md:w-80 h-24 text-center font-bold text-2xl text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight">
            Manual de administrador
          </Button>
        </Link>
      </div>
      <div className="lg:hidden w-full pb-14 pt-14 md:pb-16 ">
        <Button className="w-full md:w-64 h-14 bg-green-field rounded-lg shadow-sm hover:bg-green-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight ">
          CERRAR SESIÓN
        </Button>
      </div>
    </div>
  );
};

export default Panel;
