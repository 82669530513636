import React from 'react';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, Link, useParams } from 'react-router-dom';
import configData from 'config.json';
import Loader from 'components/Loader';

const month2 = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sep',
  'Oct',
  'Nov',
  'Dic',
];
const month = [
  'enero',
  'febrero',
  'marzo',
  'abril',
  'mayo',
  'junio',
  'julio',
  'agosto',
  'septiembre',
  'octubre',
  'noviembre',
  'diciembre',
];

const PagEstadioView = () => {
  const [data, setData] = useState({
    allstadiums: [],
    competitions: [],
    stadiums: '',
    events: [],
    teams: [],
  });
  const [filters, setFilters] = useState(null);
  const [notfilter, setNotfilter] = useState(false);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  const router = useHistory();
  const params = useParams();
  const myRef = useRef(null);
  const [loader, setLoader] = useState(true);
  const { handleSubmit, register, control, formState, errors, watch } = useForm(
    {
      mode: 'all',
    }
  );

  const getData = async () => {
    const competitions = await axios
      .get(`${configData.SERVER_URL}/competition`)
      .then((res) => {
        return res.data.competition;
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
    const stadiums = await axios
      .get(`${configData.SERVER_URL}/stadiums/${params.id}`)
      .then((res) => {
        return res.data.stadium;
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
    const allstadiums = await axios
      .get(`${configData.SERVER_URL}/stadiums`)
      .then((res) => {
        return res.data.stadium;
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
    const events = await axios
      .get(`${configData.SERVER_URL}/events/nextEvents/stadium/${params.id}`)
      .then((res) => {
        return res.data.events;
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
    const teams = await axios
      .get(`${configData.SERVER_URL}/teams`)
      .then((res) => {
        return res.data.teams;
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
    const activeEvents = events.filter((event) => event?.active === true);
    setData({
      allstadiums,
      competitions,
      stadiums,
      events: activeEvents,
      teams,
    });
    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, []);

  data.events &&
    data.events.sort((a, b) =>
      a.date > b.date ? 1 : b.date > a.date ? -1 : 0
    );

  const getCompetitionById = (id) => {
    if (data.competitions) {
      const result = data.competitions.find(
        (competition) => competition.id === id
      );
      return result;
    }
  };

  const getStadiumById = (id) => {
    if (data.allstadiums) {
      return data.allstadiums.find((stadium) => stadium.id === id);
    }
  };

  const getTeamById = (id) => {
    if (data.teams) {
      return data.teams.find((team) => team.id === id);
    }
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    setFilters(data);
  };

  const Filter = (events, filters) => {
    const filterCompetition = (event, competition_id) =>
      competition_id === '' || competition_id === 'all'
        ? events
        : event?.competition_id === competition_id;
    const filterStadium = (event, stadium_id) =>
      stadium_id === '' || stadium_id === 'all'
        ? events
        : event?.stadium_id === stadium_id;

    const eventsFiltered = events?.filter(
      (event) =>
        filterCompetition(event, filters?.competition) &&
        filterStadium(event, filters?.stadium)
    );
    if (eventsFiltered.length > 0) {
      setNotfilter(false);
      setFilteredEvents(eventsFiltered);
    } else {
      setNotfilter(true);
    }
  };

  useEffect(() => {
    filters && Filter(data.events, filters);
  }, [filters]);

  data?.events &&
    data?.events.sort((a, b) =>
      a.date > b.date ? 1 : b.date > a.date ? -1 : 0
    );

  const getTeams = (teams) => {
    return teams.split(',');
  };

  const getDate = (param) => {
    if (data.stadiums.fundation_date) {
      const date = data.stadiums?.fundation_date.split('-');
      switch (param) {
        case 'day':
          return date[2];
        case 'month':
          return date[1] === '0'
            ? month[date[1].substr(-1)]
            : month[date[1] - 1];
        case 'year':
          return date[0];
        default:
          break;
      }
    }
  };

  const getCard = (events) => {
    const nowDate = new Date().toISOString().slice(0, 10);
    const start = (currentPage - 1) * 10;
    const end = currentPage * 10;
    return events?.slice(start, end).map((event, idx) => {
      if (event.date >= nowDate) {
        const date = event?.date.split('-');
        return (
          <div className="af-class-cat-right-column">
            {event.type === 'partido' ? (
              <Link
                to={`/evento/${event.id}`}
                className="af-class-cat-eventcard w-inline-block"
              >
                <div className="af-class-cat-eventcard-available">
                  Lugares disponibles
                </div>
                <div className="af-class-cat-eventcard-data-wrapper">
                  <div className="af-class-cat-eventcard-title-wrapper">
                    <h3 className="af-class-cat-eventcard-title">
                      {getTeamById(event?.home)?.name}
                    </h3>
                    <div className="af-class-cat-gamecard-vs">VS</div>
                    <h3 className="af-class-cat-eventcard-title">
                      {getTeamById(event?.visitor)?.name}
                    </h3>
                  </div>
                  <div className="af-class-cat-eventcard-details-wrapper">
                    <div className="af-class-cat-eventcard-roundleague-wrapper">
                      <div className="af-class-cat-eventcard-round">{`Jornada ${event?.jornada}`}</div>
                      <div className="af-class-cat-eventcard-league">
                        {getCompetitionById(event?.competition_id)?.name}
                      </div>
                    </div>
                    <div className="af-class-cat-eventcard-stadium-wrapper">
                      <img
                        src={
                          window.location.origin +
                          '/images/icon-stadium_deepBlue.svg'
                        }
                        loading="lazy"
                        alt=""
                        className="af-class-cat-eventcard-stadiumicon"
                      />
                      <div className="af-class-cat-eventcard-stadium">
                        {getStadiumById(event?.stadium_id).name}
                      </div>
                    </div>
                    <div className="af-class-cat-eventcard-datetime-wrapper">
                      <div className="af-class-cat-eventcard-data af-class-eventcard-databold">
                        {date[0]}
                      </div>
                      <div className="af-class-cat-eventcard-data af-class-eventcard-datamedium">
                        {date[1] === '0'
                          ? month2[date[1].substr(-1)]
                          : month2[date[1] - 1]}
                      </div>
                      <div className="af-class-cat-eventcard-data">
                        {date[2]}
                      </div>
                      <div className="af-class-cat-eventcard-data">···</div>
                      <div className="af-class-cat-eventcard-data af-class-eventcard-databold">
                        {event.time}
                      </div>
                      <div className="af-class-cat-eventcard-data">Hrs</div>
                    </div>
                  </div>
                </div>
                <div className="af-class-cat-eventcard-graphics-wrapper">
                  <img
                    src={
                      window.location.origin +
                      '/images/triangleGraphicUp-white.svg'
                    }
                    loading="lazy"
                    alt=""
                    className="af-class-cat-eventcard-triangle"
                  />
                  <img
                    src={
                      window.location.origin +
                      '/images/triangleGraphicDown-white.svg'
                    }
                    loading="lazy"
                    alt=""
                    className="af-class-cat-eventcard-trianglemob"
                  />
                  <div className="af-class-cat-eventcard-teamemblems-wrapper">
                    <img
                      src={`${getTeamById(event.home).images[0]}`}
                      loading="lazy"
                      sizes="(max-width: 767px) 71.328125px, 98.078125px"
                      srcSet={`${getTeamById(event.home).images[0]} 500w, ${getTeamById(event.home).images[0]
                        } 535w`}
                      alt=""
                      className="af-class-cat-eventcard-teamemblem"
                    />
                    <div className="af-class-cat-gamecard-emblemsvs">Vs</div>
                    <img
                      src={`${getTeamById(event.visitor).images[0]}`}
                      loading="lazy"
                      alt=""
                      className="af-class-cat-eventcard-teamemblem"
                    />
                  </div>
                  <img
                    loading="lazy"
                    sizes="(max-width: 479px) 76vw, (max-width: 767px) 75vw, (max-width: 991px) 51vw, 33vw"
                    srcSet={`${getStadiumById(event.stadium_id).images[0]
                      } 500w, ${getStadiumById(event.stadium_id).images[0]
                      } 500w, ${getStadiumById(event.stadium_id).images[0]
                      } 800w, ${getStadiumById(event.stadium_id).images[0]
                      } 1080w, ${getStadiumById(event.stadium_id).images[0]
                      } 1600w, ${getStadiumById(event.stadium_id).images[0]
                      } 2000w, ${getStadiumById(event.stadium_id).images[0]
                      } 2048w`}
                    alt=""
                    className="af-class-cat-eventcard-stadiumbg"
                  />
                </div>
              </Link>
            ) : (
              <Link to="#" className="af-class-cat-eventcard w-inline-block">
                <div className="af-class-cat-eventcard-available">
                  Lugares disponibles
                </div>
                <div className="af-class-cat-eventcard-data-wrapper">
                  <div className="af-class-cat-eventcard-title-wrapper">
                    <h3 className="af-class-cat-eventcard-title">
                      {event.name}
                    </h3>
                  </div>
                  <div className="af-class-cat-eventcard-details-wrapper">
                    <div
                      className="af-class-cat-eventcard-tour"
                      style={{ backgroundColor: event.event_color }}
                    >
                      {event.tour_name}
                    </div>
                    <div className="af-class-cat-eventcard-stadium-wrapper">
                      <img
                        src={
                          window.location.origin +
                          '/images/icon-stadium_deepBlue.svg'
                        }
                        loading="lazy"
                        alt=""
                        className="af-class-cat-eventcard-stadiumicon"
                      />
                      <div className="af-class-cat-eventcard-stadium">
                        {getStadiumById(event.stadium_id).name}
                      </div>
                    </div>
                    <div className="af-class-cat-eventcard-datetime-wrapper">
                      <div className="af-class-cat-eventcard-data af-class-eventcard-databold">
                        {date[0]}
                      </div>
                      <div className="af-class-cat-eventcard-data af-class-eventcard-datamedium">
                        {month2[date[1].substr(-1)]}
                      </div>
                      <div className="af-class-cat-eventcard-data">
                        {date[2]}
                      </div>
                      <div className="af-class-cat-eventcard-data">···</div>
                      <div className="af-class-cat-eventcard-data af-class-eventcard-databold">
                        {event.time}
                      </div>
                      <div className="af-class-cat-eventcard-data">Hrs</div>
                    </div>
                  </div>
                </div>
                <div className="af-class-cat-eventcard-graphics-wrapper">
                  <img
                    src={
                      window.location.origin +
                      '/images/triangleGraphicUp-white.svg'
                    }
                    loading="lazy"
                    alt=""
                    className="af-class-cat-eventcard-triangle"
                  />
                  <img
                    src={
                      window.location.origin +
                      '/images/triangleGraphicDown-white.svg'
                    }
                    loading="lazy"
                    alt=""
                    className="af-class-cat-eventcard-trianglemob"
                  />
                  <img
                    src={`${event.images[0]}`}
                    loading="lazy"
                    alt=""
                    className="af-class-cat-eventcard-eventlogo"
                  />
                  <img
                    src={`${event.images[1]}`}
                    loading="lazy"
                    sizes="(max-width: 479px) 76vw, (max-width: 767px) 75vw, (max-width: 991px) 51vw, 33vw"
                    srcSet={`${event.images[1]} 500w, ${event.images[1]} 800w, ${event.images[1]} 1280w`}
                    alt=""
                    className="af-class-cat-eventcard-eventbg"
                    style={{ backgroundColor: event.event_color }}
                  />
                  <div className="af-class-cat-eventcard-gradient" />
                </div>
              </Link>
            )}
            {data.events.length - 1 !== idx && (
              <div className="af-class-cat-contentdivider" />
            )}
          </div>
        );
      }
    });
  };

  const changePag = (newPage) => {
    myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    setCurrentPage(newPage);
  };

  if (!loader) {
    return (
      <span className="af-view">
        <div>
          <div className="af-class-std-hero af-class-std-bghero">
            <img
              src={`${data.stadiums?.images[0]}`}
              loading="lazy"
              sizes="100vw"
              srcSet={`${data.stadiums?.images[0]} 500w, ${data.stadiums?.images[0]} 800w, ${data.stadiums?.images[0]} 1080w, ${data.stadiums?.images[0]} 1600w, ${data.stadiums?.images[0]} 2000w, ${data.stadiums?.images[0]} 2048w`}
              alt=""
              className="af-class-prop-hero-bgimg"
            />
          </div>
          <div className="af-class-std-hero">
            <div className="af-class-std-container">
              <div className="af-class-std-left-wrapper">
                <div className="af-class-std-location">
                  <img
                    alt=""
                    src={
                      window.location.origin +
                      '/images/flag-mexico_1f1f2-1f1fd.png'
                    }
                    loading="lazy"
                    className="af-class-std-countryflag"
                  />
                  <div>{data.stadiums?.city}</div>
                </div>
                <h1 className="af-class-std-name">{data.stadiums?.name}</h1>
                <div className="af-class-std-data-wrapper">
                  <p className="af-class-gen-paragraph">
                    {data.stadiums?.description}
                  </p>
                  <div className="af-class-std-specs-wrapper">
                    <div className="af-class-std-spec">
                      <img
                        alt=""
                        src={
                          window.location.origin + '/images/group-deepBlue.svg'
                        }
                        loading="lazy"
                        className="af-class-std-spec-icon"
                      />
                      <div>{data.stadiums?.capacity}</div>
                    </div>
                    <div className="af-class-std-spec">
                      <img
                        alt=""
                        src={
                          window.location.origin + '/images/wall-deepBlue.svg'
                        }
                        loading="lazy"
                        className="af-class-std-spec-icon"
                      />
                      <div>
                        {getDate('day') +
                          ' de ' +
                          getDate('month') +
                          ' de ' +
                          getDate('year')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="af-class-std-right-wrapper">
                <div className="af-class-std-teamemblems-wrapper">
                  {/* {data.stadiums?.local_teams && getTeams(data.stadiums?.local_teams)?.map(team => {
                    return (
                      <img alt="" src={`${getTeamById(team).images[0]}`} loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 66.875px, 89.171875px" srcSet={`${getTeamById(team).images[0]} 500w, ${getTeamById(team).images[0]} 535w`} className="af-class-std-teamemblem" />
                    )
                  })} */}
                </div>
                <Link
                  onClick={() => window.open(data.stadiums.location)}
                  className="af-class-std-gmaps w-inline-block"
                >
                  <img
                    alt=""
                    src={window.location.origin + '/images/google-maps.svg'}
                    loading="lazy"
                    className="af-class-std-gmaps-icon"
                  />
                  <div>¿Cómo llegar?</div>
                </Link>
              </div>
            </div>
          </div>
          <div
            id="events-cat"
            className="af-class-gen-section af-class-cat-section"
          >
            <div className="af-class-gen-container">
              <img
                alt=""
                src={window.location.origin + '/images/bigIcon-seats_blue.svg'}
                loading="lazy"
                className="af-class-cat-graphic"
              />
              <h2 className="af-class-gen-sectiontitle af-class-cat-title">
                Próximos eventos en el {data.stadiums.name}
              </h2>
              <div className="af-class-cat-wrapper">
                <div
                  data-w-id="3615ce92-c225-e823-c86a-35d4f00b1b01"
                  className="af-class-cat-filterstab"
                >
                  <div className="af-class-cat-filterstab-contentcolumn">
                    <div className="af-class-cat-filters-wrapper w-form">
                      {/* filtros */}
                      <form
                        id="wf-form-events-filters-form"
                        name="wf-form-events-filters-form"
                        data-name="events-filters-form"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <div className="af-class-gen-paragraph">
                          Filtra según lo que necesitas.
                        </div>
                        <div className="af-class-cat-filtersdrop-wrapper">
                          <select
                            ref={register}
                            id="competition"
                            name="competition"
                            data-name="Filter League 2"
                            className="af-class-cat-filtersdrop-toggle w-select"
                          >
                            <option>Competición</option>
                            {data.competitions &&
                              data.competitions.map((competition) => {
                                return (
                                  <option value={competition.id}>
                                    {competition.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div className="af-class-gen-paragraph">
                          Busca según el evento.
                        </div>
                        <div className="af-class-cat-filterstextinput-wrapper">
                          <input
                            ref={register}
                            type="text"
                            className="af-class-cat-filterstextinput w-input"
                            maxLength={256}
                            name="search-2"
                            data-name="Search 2"
                            placeholder="Buscar..."
                            id="search-2"
                          />
                          <div className="af-class-cat-filterstextinput-note">
                            Ejemplo: Atlas, Jornada 10, Guadalajara, etc.
                          </div>
                        </div>
                        <input
                          type="submit"
                          defaultValue="Aplicar"
                          data-wait="....."
                          className="af-class-gen-button af-class-filtersapplybutton w-button"
                        />
                      </form>
                      {notfilter && (
                        <div className="af-class-cat-filterserror-wrapper w-form-fail">
                          <div>
                            ¡Algo salió mal! Por favor, inténtalo de nuevo.
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    data-w-id="3615ce92-c225-e823-c86a-35d4f00b1b17"
                    className="af-class-cat-filtersmobbutton"
                  >
                    <img
                      alt=""
                      src={
                        window.location.origin + '/images/bothArrow-gray.svg'
                      }
                      loading="lazy"
                      className="af-class-cat-filtersmobbutton-icon"
                    />
                    <div className="af-class-cat-filtersmobbutton-label">
                      Filtros de búsqueda
                    </div>
                  </div>
                </div>
                <div className="af-class-cat-content-wrapper">
                  <div className="af-class-cat-left-column">
                    <div className="af-class-cat-filters-column">
                      <div className="af-class-cat-filters-wrapper w-form">
                        {/* filters */}
                        <form
                          id="wf-form-events-filters-form"
                          name="wf-form-events-filters-form"
                          data-name="events-filters-form"
                          onSubmit={handleSubmit(onSubmit)}
                        >
                          <div className="af-class-gen-paragraph">
                            Filtra según lo que necesitas.
                          </div>
                          <div className="af-class-cat-filtersdrop-wrapper">
                            <select
                              id="competition"
                              name="competition"
                              data-name="Filter League 2"
                              className="af-class-cat-filtersdrop-toggle w-select"
                            >
                              <option>Competición</option>
                              {data.competitions &&
                                data.competitions.map((competition) => {
                                  return (
                                    <option value={competition.id}>
                                      {competition.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="af-class-gen-paragraph">
                            Busca según el evento.
                          </div>
                          <div className="af-class-cat-filterstextinput-wrapper">
                            <input
                              type="text"
                              className="af-class-cat-filterstextinput w-input"
                              maxLength={256}
                              name="search"
                              data-name="Search"
                              placeholder="Buscar..."
                              id="search"
                            />
                            <div className="af-class-cat-filterstextinput-note">
                              Ejemplo: Atlas, Jornada 10, Guadalajara, etc.
                            </div>
                          </div>
                          <input
                            type="submit"
                            defaultValue="Aplicar"
                            data-wait="....."
                            className="af-class-gen-button af-class-filtersapplybutton w-button"
                          />
                        </form>
                        {notfilter && (
                          <div className="af-class-cat-filterserror-wrapper w-form-fail">
                            <div>
                              ¡Algo salió mal! Por favor, inténtalo de nuevo.
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="af-class-cat-pag-wrapper">
                      <div className="af-class-gen-note">
                        Se muestran 10 eventos por página.
                      </div>
                      <div className="af-class-cat-pagbuttons-center-wrapper">
                        {data.events.map((_, idx) => {
                          if (idx + 1 === 1 || idx * 10 < data.events.length) {
                            return currentPage === idx + 1 ? (
                              <div className="af-class-cat-pagbutton af-class-pagbuttonactive">
                                {idx + 1}
                              </div>
                            ) : (
                              <div
                                className="af-class-cat-pagbutton"
                                onClick={() => changePag(idx + 1)}
                              >
                                {idx + 1}
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                  {/* card evento */}
                  <div ref={myRef} className="af-class-cat-right-column">
                    {getCard(
                      filteredEvents & (filteredEvents.length > 0)
                        ? filteredEvents
                        : data.events
                    )}
                  </div>
                </div>
              </div>
              <div className="af-class-cat-pag-wrapper af-class-pagmob-wrapper">
                <div className="af-class-gen-note">
                  Se muestran 10 eventos por página.
                </div>
                <div className="af-class-cat-pagbuttons-center-wrapper">
                  {data.events.map((_, idx) => {
                    if (idx + 1 === 1 || idx * 10 < data.events.length) {
                      return currentPage === idx + 1 ? (
                        <div className="af-class-cat-pagbutton af-class-pagbuttonactive">
                          {idx + 1}
                        </div>
                      ) : (
                        <div
                          className="af-class-cat-pagbutton"
                          onClick={() => changePag(idx + 1)}
                        >
                          {idx + 1}
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="af-class-gen-section">
            <div className="af-class-gen-container">
              <div className="af-class-socialinvite-wrapper">
                <div className="af-class-socialinvite-img-wrapper">
                  <img
                    alt=""
                    src={
                      window.location.origin +
                      '/images/socialMedia-invite_comp.png'
                    }
                    loading="lazy"
                    sizes="(max-width: 767px) 100vw, (max-width: 991px) 278.015625px, 405.125px"
                    srcSet="images/socialMedia-invite_comp-p-500.png 500w, images/socialMedia-invite_comp-p-800.png 800w, images/socialMedia-invite_comp.png 1920w"
                    className="af-class-socialinvite-image"
                  />
                  <img
                    alt=""
                    src={
                      window.location.origin +
                      '/images/socialMedia-invite_movil_comp.png'
                    }
                    loading="lazy"
                    sizes="(max-width: 479px) 76vw, (max-width: 767px) 350px, 100vw"
                    srcSet="images/socialMedia-invite_movil_comp-p-500.png 500w, images/socialMedia-invite_movil_comp-p-800.png 800w, images/socialMedia-invite_movil_comp.png 1786w"
                    className="af-class-socialinvite-imgmob"
                  />
                  <img
                    alt=""
                    src={window.location.origin + '/images/sombreBloque.png'}
                    loading="lazy"
                    className="af-class-socialinvite-mobshadow"
                  />
                </div>
                <div className="af-class-socialinvite-content-wrapper">
                  <div className="af-class-socialinvite-graphic-wrapper">
                    <img
                      alt=""
                      src={
                        window.location.origin +
                        '/images/bigIcon-social_media_vertical.svg'
                      }
                      loading="lazy"
                      className="af-class-socialinvite-graphicver"
                    />
                    <img
                      alt=""
                      src={
                        window.location.origin +
                        '/images/bigIcon-social_media_horiz.svg'
                      }
                      loading="lazy"
                      className="af-class-socialinvite-graphichor"
                    />
                  </div>
                  <h2 className="af-class-gen-sectiontitle">
                    Mantente al día con lo último
                  </h2>
                  <p className="af-class-gen-paragraph af-class-socialinvite-desc">
                    Síguenos en redes sociales y mantente actualizado con las
                    últimas noticias y promociones.
                  </p>
                  <div className="af-class-socialinvite-platforms-wrapper">
                    <img
                      alt=""
                      src={
                        window.location.origin +
                        '/images/bigIcon-social_media_mobile.svg'
                      }
                      loading="lazy"
                      className="af-class-socialinvite-graphicmob"
                    />
                    <div className="af-class-socialinvite-buttons-wrapper">
                      <Link
                        to="#"
                        className="af-class-socialinvite-button af-class-socialinvite-fb w-inline-block"
                      >
                        <img
                          alt=""
                          src={
                            window.location.origin +
                            '/images/Facebook-white.svg'
                          }
                          loading="lazy"
                          className="af-class-socialinvite-buttonicon"
                        />
                        <div>Me gusta</div>
                      </Link>
                      <Link
                        to="#"
                        className="af-class-socialinvite-button af-class-socialinvite-ig w-inline-block"
                      >
                        <img
                          alt=""
                          src={
                            window.location.origin +
                            '/images/Instagram-white.svg'
                          }
                          loading="lazy"
                          className="af-class-socialinvite-buttonicon"
                        />
                        <div>Seguir</div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="af-class-socialinvite-grayblock" />
              </div>
            </div>
          </div>
        </div>
      </span>
    );
  } else {
    return <Loader />;
  }
};

export default PagEstadioView;
