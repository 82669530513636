import React, { forwardRef, useState, useEffect, FormEvent } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import ImageUploading from "react-images-uploading";
import axios from 'axios';
import configData from "config.json";

import Label from "components/Label";
import Input from "components/Input";
import Button from "components/Button";
import Home from "components/Icons/Home";
import Carousel from "components/Carousel";

const CreateCompetencia = forwardRef((props, ref) => {
  const [imagesVista, setImagesVista] = useState([]);
  const [imagesGaleria, setImagesGaleria] = useState([]);
  const [currentImageVista, setCurrentImageVista] = useState(0);
  const [currentImageGaleria, setCurrentImageGaleria] = useState(0);
  const history = useHistory();
  const maxNumber = 5;

  const onChangesetImagesVista = (imageList, addUpdateIndex) => {
    setImagesVista(imageList);
  };
  const onChangesetImagesGaleria = (imageList, addUpdateIndex) => {
    setImagesGaleria(imageList);
  };

  const { control, handleSubmit, register, formState, errors } = useForm();

  const sendDataRequest = async (competition) => {
    try {
      const formData = new FormData();
      formData.append('name', competition.name);
      formData.append('type', competition.type);
      formData.append('jornadas', competition.jornadas);
      // formData.append('images', (competition.images));
      competition.images.map(img => {
        formData.append('images', img);
      })
      const res = await axios.post(`${configData.SERVER_URL}/competition`, formData)
      const response = res.data.competition;
      history.go(0)
    } catch (err) {
      console.log(err)
    }
  }

  const onsubmit = (data, e) => {
    e.preventDefault();
    const imgVista = imagesVista.map((img, idx) => {
      const type = img.file.type.split("/")
      return Object.defineProperty(img.file, 'name', {
        writable: 'true',
        value: 'vista-' + data.name + '-' + (idx + 1) + '.' + type[1]
      })
    })
    const imgGaleria = imagesGaleria.map((img, idx) => {
      const type = img.file.type.split("/")
      return Object.defineProperty(img.file, 'name', {
        writable: 'true',
        value: 'galeria-' + data.name + '-' + (idx + 1) + '.' + type[1]
      })
    })
    const competition = {
      name: data.name,
      type: data.type,
      jornadas: data.jornadas,
      images: [...imgVista, ...imgGaleria],
    }
    sendDataRequest(competition);
  };

  return (
    <form className="w-full pt-28" onSubmit={handleSubmit(onsubmit)}>
      <div className="container-pp mx-auto pb-24">
        <div className="flex flex-col-reverse md:flex-row justify-between pb-10">
          <div className="flex flex-col">
            <div id="tracking" className="pb-4">
              <h3 className="text-gris-oscuro">
                Panel de Administrador / Equipos / Agregar Competencia
              </h3>
            </div>
            <div id="title" className="pb-2">
              <h1 className="text-sport-blue font-bold text-5xl">
                Agregar Nueva Competencia
              </h1>
            </div>
          </div>
          <div className="flex flex-row pb-14 md:pb-0">
            <div className="">
              <Link to="/managerCompetencias">
                <Button className="w-40 h-14 bg-green-field rounded-lg shadow-sm hover:bg-green-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight ">
                  Listado
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="grid grid-flow-row pt-8 md:grid-cols-2 lg:grid-cols-3 gap-12">
          <div id="col1">
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Tipo de Competencia</Label>
              </div>
              <Controller
                name="type"
                control={control}
                defaultValue={"Nacional"}
                as={
                  <Input
                    name="type"
                    input="select"
                    type="text"
                    className="w-full h-10"
                    options={[
                      { value: "Nacional", label: "Nacional" },
                      { value: "Internacional", label: "Internacional" },
                    ]}
                  />
                }
              />
              {errors.type && (
                <span className="text-rojo-error">{errors.type.message}</span>
              )}
            </div>
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">Jornadas</Label>
              </div>
              <Controller
                name="jornadas"
                control={control}
                rules={{ required: "Este campo no debe estar vacío" }}
                defaultValue={""}
                as={
                  <Input
                    name="jornada"
                    input="textarea"
                    className="w-full h-48"
                    placeholder="1,2,3,4,5,6,7"
                  />
                }
              />
              {errors.jornada && (
                <span className="text-rojo-error">{errors.jornada.message}</span>
              )}
            </div>
          </div>
          <div id="col2">
            <div id="data" className="pb-8">
              <div className="pb-1">
                <Label className="text-deep-blue">
                  Nombre de la competencia
                </Label>
              </div>
              <Controller
                name="name"
                control={control}
                rules={{ required: "Este campo no debe estar vacío" }}
                defaultValue={""}
                as={
                  <Input
                    name="name"
                    input="input"
                    type="text"
                    className="w-full h-10"
                    placeholder="LigaMx Clausura 2021"
                  />
                }
              />
              {errors.name && (
                <span className="text-rojo-error">{errors.name.message}</span>
              )}
            </div>
          </div>
        </div>
        <div>
          <div id="subtitle">
            <h1 className="text-sport-blue font-bold text-4xl">Media</h1>
          </div>
          <div className="grid grid-flow-row pt-8 md:grid-cols-2 gap-12 pb-20">
            <ImageUploading
              multiple
              value={imagesVista}
              onChange={onChangesetImagesVista}
              maxNumber={1}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <div className="">
                  <h1 className="text-ocean-black font-bold text-xl">
                    Logo
                  </h1>
                  <div className={imagesVista.length === 0 && "hidden"}>
                    {imagesVista.length !== 0 && (
                      <Carousel current={(e) => setCurrentImageVista(e)}>{imagesVista}</Carousel>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <div className="flex flex-row">
                      <div className="">
                        <Button
                          type="button"
                          className={"w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight"}
                          style={isDragging ? { color: "red" } : undefined}
                          onClick={onImageUpload}
                          disable={imagesVista.length === 5 && true}
                          {...dragProps}
                        >
                          CARGAR
                        </Button>
                      </div>
                      {imagesVista.length > 0 &&
                        <div className="pl-3">
                          <Button type="button"
                            className={"w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight "}
                            disable={imagesVista.length === 0 && true} onClick={() => onImageRemove(currentImageVista)}>
                            Borrar
                          </Button>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              )}
            </ImageUploading>
            <ImageUploading
              multiple
              value={imagesGaleria}
              onChange={onChangesetImagesGaleria}
              maxNumber={maxNumber}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <div className="">
                  <h1 className="text-ocean-black font-bold text-xl">
                    Logo negativo
                  </h1>
                  <div className={imagesGaleria.length === 0 && "hidden"}>
                    {imagesGaleria.length !== 0 && (
                      <Carousel current={(e) => setCurrentImageGaleria(e)}>{imagesGaleria}</Carousel>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <div className="flex flex-row">
                      <div className="">
                        <Button
                          type="button"
                          className={"w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight"}
                          style={isDragging ? { color: "red" } : undefined}
                          onClick={onImageUpload}
                          disable={imagesGaleria.length === 5 && true}
                          {...dragProps}
                        >
                          CARGAR
                        </Button>
                      </div>
                      {imagesGaleria.length > 0 &&
                        <div className="pl-3">
                          <Button type="button"
                            className={"w-20 h-8 font-bold text-l text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight "}
                            disable={imagesGaleria.length === 0 && true} onClick={() => onImageRemove(currentImageGaleria)}>
                            Borrar
                          </Button>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              )}
            </ImageUploading>
          </div>
        </div>
        <div className="flex flex-row">
          <div className="pr-5">
            <Button
              type="submit"
              className="w-44 h-14 font-bold text-xl text-ocean-black bg-green-field rounded-lg shadow-sm hover:bg-green-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight "
              disable={imagesGaleria.length === 0 || imagesVista.length === 0 ? true : false}
            >
              GUARDAR
            </Button>
          </div>
          <div className="pr-5">
            <Button onClick={() => history.goBack()} className="w-44 h-14 font-bold text-xl text-white bg-aqua-grass rounded-lg shadow-sm hover:bg-aqua-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight " type="button">
              CANCELAR
            </Button>
          </div>
          {/* <div className="pr-5">
            <Button className="w-44 h-14 font-bold text-xl text-white bg-aqua-grass rounded-lg shadow-sm hover:bg-aqua-highlight focus:outline-none focus:ring-2 focus:ring-green-highlight ">
              ELIMINAR
            </Button>
          </div> */}
        </div>
      </div>
    </form>
  );
});

export default CreateCompetencia;
