import React, { forwardRef, useState, useRef, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useClickAway } from 'react-use';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import XLSX from 'xlsx';
import configData from 'config.json';

import Tables, { DataCell } from 'components/Tables';
import Label from 'components/Label';
import Input from 'components/Input';
import Button from 'components/Button';
import Home from 'components/Icons/Home';
import ConfirmationModal from 'components/ConfirmationModal';

const ManagerUsers = forwardRef((props, ref) => {
  const [arrayDelete, setArrayDelete] = useState([]);
  const [confirmationMessage, setConfirmationMessage] = useState(false);
  const refConfirmation = useRef(null);
  const router = useHistory();
  useClickAway(refConfirmation, () => {
    setConfirmationMessage(false);
  });
  const { control, handleSubmit, register, formState, errors } = useForm();
  const [users, setUsers] = useState([]);

  const getData = async () => {
    try {
      const users = await axios
        .get(`${configData.SERVER_URL}/user`)
        .then((res) => {
          return res.data.users;
        });
      // console.log(users);
      setUsers(users);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const onsubmit = (data, e) => {
    e.preventDefault();
  };

  const titleHeaders = [
    'Sel.',
    'Nombre',
    'Correo',
    'Tel.',
    'Fecha de nacimiento',
    'País',
    'Estado',
    'Ciudad',
    'Colonia',
    'Codigo Postal',
    'Dirección',
    'Numero Interior',
    'Creación',
    'Ultima modif.',
  ];

  const titleHeadersConfirmation = ['Nombre', 'Correo'];

  function displayRows(user, idx) {
    return (
      <tr className="hover:bg-gray-100">
        <DataCell className="">
          <Input
            id=""
            className="ring-2 ring-gris-oscuro cursor-pointer"
            height="h-5"
            width="w-5"
            name="checkbox"
            type="checkbox"
            onClick={() => clickChecbox(idx)}
          />
        </DataCell>
        <DataCell className="space-x-4">{`${user.name} ${user.last_name}`}</DataCell>
        <DataCell>{user.email}</DataCell>
        <DataCell>{user.tel}</DataCell>
        <DataCell>{user.date_birth}</DataCell>
        <DataCell>{user.country}</DataCell>
        <DataCell>{user.state}</DataCell>
        <DataCell>{user.city}</DataCell>
        <DataCell>{user.suburb}</DataCell>
        <DataCell>{user.postal_code}</DataCell>
        <DataCell>{user.address}</DataCell>
        <DataCell>{user.interior_num}</DataCell>
        <DataCell>{user.inserted_date}</DataCell>
        <DataCell>{user.modified_date}</DataCell>
      </tr>
    );
  }

  const exportData = (users) => {
    let req = [];
    users.map((user) => {
      const xlsData = {
        Nombre: `${user.name} ${user.last_name}`,
        Correo: user.email,
        'Tel.': user.tel,
        'Fecha de nacimiento': user.date_birth,
        País: user.country,
        Estado: user.state,
        Ciudad: user.city,
        Colonia: user.suburb,
        'Codigo Postal': user.postal_code,
        Dirección: user.address,
        'Numero Interior': user.interior_num,
        Creación: user.inserted_date,
        'Ultima modif.': user.modified_date,
      };
      req.push(xlsData);
    });
    convertJSONtoXLSX(req);
  };

  const convertJSONtoXLSX = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();

    // Cambiar el nombre, no se si sea necesario especificar que es o se puede poner la fecha
    XLSX.utils.book_append_sheet(workbook, worksheet, 'PalcosplusData'); //PalcosPlusData es el nombre de la hoja dentro del libro xlsx
    // Buffer
    XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    // Binary String
    XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    // Create a file
    XLSX.writeFile(workbook, 'usuarios.xlsx'); //data.xlsx es el nombre del archivo, seria cambiarlo o buscar uno mas adoc
  };

  const clickChecbox = (idx) => {
    const found = arrayDelete.findIndex((number) => number === idx);
    if (found === -1) {
      setArrayDelete([...arrayDelete, idx]);
    } else {
      let newArrray = arrayDelete;
      newArrray.splice(found, 1);
      if (newArrray.length === 0) {
        setArrayDelete([]);
      } else {
        setArrayDelete(newArrray);
      }
    }
  };

  const confirmDelete = async () => {
    for (let i = 0; i < users.length; i++) {
      const found = arrayDelete.findIndex((number) => number === i);
      if (found !== -1) {
        try {
          await axios.delete(`${configData.SERVER_URL}/user/${users[i].id}`);
          router.go(0);
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  return (
    <form className="w-full pt-28" onSubmit={handleSubmit(onsubmit)}>
      <div className="mx-auto pb-24" style={{ maxWidth: '1080px' }}>
        <div className="flex flex-col-reverse md:flex-row justify-between pb-10">
          <div className="flex flex-col">
            <div id="tracking" className="pb-4">
              <h3 className="text-gris-oscuro">
                Panel de Administrador / Usuarios
              </h3>
            </div>
            <div id="title" className="pb-2">
              <h1 className="text-sport-blue font-bold text-6xl">
                Gestionar Usuarios
              </h1>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="pb-14 md:pb-0">
              <Link to="/panel">
                <Button className="w-40 h-14 bg-green-field hover:bg-green-highlight focus:ring-green-highlight ">
                  PANEL ADMIN.
                </Button>
              </Link>
            </div>
            <div className="hidden lg:hidden md:flex flex-row">
              <div>
                <Button
                  onClick={() => exportData(users)}
                  className="w-full sm:w-36 h-14 text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight"
                >
                  DESCARGAR
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="md:hidden lg:flex w-full md:w-80 flex flex-col md:flex-row md:justify-between">
          <div>
            <Button
              onClick={() => exportData(users)}
              className="w-full md:w-36 h-14 text-white bg-aqua-grass hover:bg-aqua-highlight focus:ring-green-highlight"
            >
              DESCARGAR
            </Button>
          </div>
        </div>
        <div>
          <div className="flex flex-row-reverse">
            <Button
              className="w-32 h-10 bg-rojo-error text-white p-2"
              onClick={() => setConfirmationMessage(true)}
              disable={arrayDelete.length > 0 ? false : true}
            >
              ELIMINAR
            </Button>
          </div>
          <div className="pt-2 overflow-x-auto">
            <Tables className="w-auto" headers={titleHeaders}>
              {users && users.map((user, idx) => displayRows(user, idx))}
            </Tables>
          </div>
          <div className="pt-8">
            <Button className="w-56 h-14 bg-gris-auxiliar hover:bg-gris-medio focus:ring-gris-medio ">
              CARGAR MÁS
            </Button>
          </div>
        </div>
      </div>
      <ConfirmationModal
        openModal={confirmationMessage}
        onUpdate={() => confirmDelete()}
        onDimiss={() => setConfirmationMessage(false)}
        ref={refConfirmation}
        className={`${confirmationMessage
            ? 'z-50 transform transition-all ease-in border absolute bg-white rounded-lg shadow-md left-0 right-0 w-6/12 h-auto mx-auto'
            : 'hidden'
          }`}
        message={'Estas seguro de eliminar los siguientes datos?'}
      >
        <Tables
          className="w-full overflow-x-auto"
          headers={titleHeadersConfirmation}
        >
          {users &&
            users.map((user, idx) => {
              const found = arrayDelete.findIndex((number) => number === idx);
              if (found !== -1) {
                return (
                  <tr>
                    <DataCell className="truncate">
                      {user.name} {user.last_name}
                    </DataCell>
                    <DataCell className="truncate">{user.email}</DataCell>
                  </tr>
                );
              }
            })}
        </Tables>
      </ConfirmationModal>
    </form>
  );
});

export default ManagerUsers;
