import React, { ReactNode } from "react";

const HeadersCollumns = (headers, classHead, id) => (
  <tr>
    {headers.map((title, idx) => {
      if (id === idx) {
        // console.log(1 + " " + id + idx + title);
        return (
          <th
            className={
              classHead + ` px-4 py-2 font-normal text-lg text-left truncate`
            }
            key={title + "." + idx}
          >
            {title}
          </th>
        );
      } else {
        // console.log(2 + " " + id + idx + title);
        return (
          <th
            className={classHead + ` px-4 py-2 font-normal text-lg text-left truncate`
            }
            key={title + "." + idx}
          >
            {title}
          </th>
        );
      }
    })}
  </tr>
);

export const TableRow = (props) => <tr>{props.children}</tr>;

export const DataCell = (props) => (
  <td
    {...props}
    className={"px-4 py-2 tabular-nums truncate" + (props.className ?? "")}
  >
    {props.children}
  </td>
);

const Tables = (props) => {
  return (
    <table className={"table-fixed " + (props.className ?? "w-full")}>
      <thead className="border-b-4 border-sport-blue">
        {HeadersCollumns(props.headers, props.classHead, props.idheader)}
      </thead>
      <tbody className={props.classBody}>{props.children}</tbody>
    </table>
  );
};

export default Tables;
